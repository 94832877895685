import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker, DatePickerInput } from "rc-datepicker";
import * as moment from "moment";
import "moment/min/moment-with-locales";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";


//const configData= require('config');
const configData = require( '../../config.json');


const initialValues = {
    weight: '',
    height: '',
    date: '',
    
    
}
export default class ServicesList extends React.Component  {
    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
            serviceName:'',
            serviceDesc:'',
            serviceCode:'',
            predecessor:'',
            serviceType:'',
            level:'',
            status:'',
            start_date:'',
            end_date:'',
            _id:undefined,
            servicefiles: [],
            columns:[],
            originalservicefiles:[],
            searchInput: "",
            filteredData: [],
            chkisactive:true,           
            userrole:{create:''}

        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

    }     
    
    componentDidMount() {
     
        this.fetchServiceMasterListAPI();

        this.getColumns();
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Services';
        });
        let columns = [
          {
            Header: "Service Name",
            accessor: "serviceName",
            sortable: true,
            show: true,
            displayValue: " Service Name" 
          },
          {
            Header: "Service Description",
            accessor: "serviceDesc",
            sortable: true,
            show: true,
            displayValue: "Service Desc ",
            Cell:row=>(<span>{row.original.serviceDesc.length > 30 ?
                `${row.original.serviceDesc.substring(0, 30)}...` : row.original.serviceDesc} </span>)
          },
          {
            Header: "Service Code",
            accessor: "serviceCode",
            sortable: true,
            show: true,
            displayValue: " serviceCode "
          },
          {
            Header: "Service Type",
            accessor: "serviceType",
            sortable: true,
            show: true,
            displayValue: " Service Type"
          },
          {
            Header: "Level",
            accessor: "level",
            sortable: true,
            show: true,
            displayValue: " level ",
            maxWidth: 100,
            minWidth: 50,
            width: 50,
            Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>
          },
          {
            Header: "Start Date",
            accessor: "start_date",
            sortable: true,
            show: true,
            displayValue: "start_date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          
          {
            Header: "End Date",
            accessor: "end_date",
            sortable: true,
            show: true,
            displayValue: "end_date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "status",
            maxWidth: 100,
            minWidth: 56,
            width: 56,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };
   
      fetchServiceMasterListAPI = () => {
        this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getServiceMasterList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setServiceMasterList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setServiceMasterList=(result)=>{
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Services';
        });
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            this.setState({servicefiles: result, originalservicefiles:result,userrole: userrl, isFetching: false});
            this.globalSearch();
        }
        else
         this.setState({...this.state,userrole: userrl, isFetching: false})
        

    }

     handleChange = e => {
        let { value, name } = e.target;         
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date
        });
    };
    handleAddMember=()=>{
        this.state.serviceName = '';
        this.state.serviceDesc ='';
        this.state.start_date = '';
        this.state.end_date = '';
        this.state.serviceCode = '';
        this.state.serviceType = '';
        this.state.level = '';
        this.state.status = '';

        this.state._id = undefined;
        this.setState({show: true, editshow:false });
    }
    handleClose=()=> {
        this.state.serviceName = '';
        this.state.serviceDesc ='';
        this.state.start_date = '';
        this.state.end_date = '';
        this.state.serviceCode = '';
        // this.state.predecessor = '';
        this.state.serviceType = '';
        this.state.level = '';
        this.state.status = '';

        this.state._id = undefined;
        this.setState({ show: false });
    }
  
     handleClick = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

     handleEditClick = (row,event) => {
        this.state.serviceName = row.original.serviceName;
        this.state.serviceDesc = row.original.serviceDesc;
        this.state.start_date = row.original.start_date;
        this.state.end_date = row.original.end_date;
        this.state.serviceCode = row.original.serviceCode;
        this.state.serviceType = row.original.serviceType;
        this.state.level = row.original.level;
        this.state.status = row.original.status;

        this.state._id = row.original._id;
        this.setState({ show: true, editshow: false });
       // this.props.history.push("/Item?id=" + row.id);
    };

     editImgFormatter = (cell, row) => {              
        return (
            <a
                className="editbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a>
        );
    }

     deleteImgFormatter = (cell, row) => {              
        return (
            <a
                className="deletebutton center"
                aria-label="Delete"
                onClick={() => this.handleDeleteClick(row)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a>
        );
    }

    handleDeleteClick = (row,event) => {
        if(window.confirm('Are you sure to delete this record?')){ 
        //delete
        if (row.original._id!= undefined) {
            axios
                .post(configData.SERVER_URL + `fincorp_deleteServiceMaster`,

                    { '_id': row.original._id }
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                .then(
                    res => this.reloadDataGrid(res.data)
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
        else {
            //error            
            toast.warn("Service Master Not Deleted");
        }
    }
    }
    reloadDataGrid = (res) => {

        toast.success("Service Master  Deleted Successfully");
        setTimeout(this.fetchServiceMasterListAPI(), 4000);
    }

    onStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                start_date: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                end_date: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };

    handleSaveClick = (event) => {

        const regServiceCode= /^[a-zA-Z0-9.-]*$/;
        
         if (this.state.serviceName == undefined || this.state.serviceName == "" || this.state.serviceName.length == 0) {
            toast.warn("Enter service Name");
            event.preventDefault();


        }       
        else if (this.state.serviceDesc == undefined || this.state.serviceDesc == "") {
            toast.warn("Enter Service Desc");
            event.preventDefault();
        }
        else if (this.state.serviceCode == undefined || this.state.serviceCode == "") {
            toast.warn("Enter Service Code");
            event.preventDefault();
        }
        else if (regServiceCode.test(this.state.serviceCode) === false){
            toast.warn("Service Code Must Contain Only Alphanumeric");
            event.preventDefault();        
        }
        else if (this.state.serviceType == undefined || this.state.serviceType == "") {
            toast.warn("Choose Service Type");
            event.preventDefault();
        }
        else if (this.state.level == undefined || this.state.level == "") {
            toast.warn("Enter Service Level");
            event.preventDefault();
        }
       
        else if (this.state.start_date == undefined || this.state.start_date == "" || this.state.start_date == "Invalid date") {
            toast.warn("Enter StartDate");
            event.preventDefault();


        }  
        else if (Date.parse(this.state.start_date) > Date.parse(this.state.end_date)) {
            toast.warn("End Date should be greater than the StartDate");
            event.preventDefault();
        }     
        else if (this.state.status == undefined || this.state.status == "") {
            toast.warn("Choose Status");
            event.preventDefault();


        }
        else {

            if (this.state._id!= undefined) {
                if(window.confirm('Do you want to save changes?')){ 
                this.state.start_date=  this.state.start_date == "Invalid date"?null:this.state.start_date;
                this.state.end_date=  this.state.end_date == "Invalid date"?null:this.state.end_date;

                axios
                    .post(configData.SERVER_URL + `fincorp_updateServiceMaster`,

                        { '_id': this.state._id, 'serviceName': this.state.serviceName, 
                        'serviceDesc': this.state.serviceDesc,
                         'serviceCode': this.state.serviceCode, 
                         'serviceType':this.state.serviceType,
                         'level':this.state.level,
                          'start_date': this.state.start_date, 
                          'end_date': this.state.end_date,
                           'status': this.state.status }
                        , {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8",
                                "Access-Control-Allow-Origin": "*"
                            }
                        })
                    .then(
                        res =>  this.UpdateChanges(res.data) 


                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });
                }

            }
            else {
                
                axios
                    .post(configData.SERVER_URL + `fincorp_insertServiceMaster`,

                        { 'serviceName': this.state.serviceName, 
                        'serviceDesc': this.state.serviceDesc,
                        'serviceCode': this.state.serviceCode, 
                        'serviceType':this.state.serviceType,
                        'level':this.state.level,
                        'start_date': this.state.start_date, 
                        'end_date': this.state.end_date,
                        'status': this.state.status}
                        , {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8",
                                "Access-Control-Allow-Origin": "*"
                            }
                        })
                    .then(
                        res => this.SaveChanges(res.data) 
                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });

            }

        }
    };
    SaveChanges = (res) => {        
        if(res.MessageKey!=undefined){
            toast.warn(res.Message);
        }
        else{
         toast.success("Service Master  Inserted Successfully");
         setTimeout( this.fetchServiceMasterListAPI(),4000);
         this.setState({ show: false, editshow: false });
        }
    }
    UpdateChanges = (res) => {
        
        if(res.MessageKey!=undefined){
            toast.warn(res.Message);
        }
        else{
        toast.success("Service Master  Updated Successfully");
        setTimeout( this.fetchServiceMasterListAPI(),4000);
        this.state.serviceName = '';
        this.state.serviceDesc ='';
        this.state.start_date = '';
        this.state.end_date = '';
        this.state.serviceCode = '';
        this.state.serviceType = '';
        this.state.level = '';
        this.state.status = '';

        this.state._id = undefined;

        this.setState({ show: false, editshow: false });
        }
   }
    
    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };

      toggleChange = () => {
        
      this.setState({
          chkisactive: !this.state.chkisactive,
      });
      this.globalActiveSearch(!this.state.chkisactive) ;
        }
      globalActiveSearch = (param) => {
        
      let { searchInput } = this.state;       
      let filteredData = this.state.originalservicefiles.filter(value => {
          if(param){

              return (
                (value.serviceName.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.serviceDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.serviceCode.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                value.serviceType.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                value.level.toString().toLowerCase().includes(searchInput.toLowerCase()) ||                
                value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                value.status.toString().includes('Active')  
                 
              );

          }else{
              return (
                value.serviceName.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.serviceDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.serviceCode.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                value.serviceType.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                value.level.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
                );
      }
      });
      this.setState({ servicefiles: filteredData });
    };

      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalservicefiles.filter(value => {
            if(this.state.chkisactive){   
          return (
            (value.serviceName.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.serviceDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.serviceCode.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
            value.serviceType.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
            value.level.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
            value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
            ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
            value.status.toString().includes('Active')
            
          );
            }else{
                return (
                    value.serviceName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceCode.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceType.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.level.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                    value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
                    );
            }
        });
        this.setState({ servicefiles: filteredData });
      };

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let { data, columns, searchInput } = this.state; 
    return (
        <>
            <div className="container userinfo-container" role="main">
                {/* <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/serviceshome">Services Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Services Master List
                    </Breadcrumb.Item>
                </Breadcrumb> */}
                <div className="centeralign">
                    <span className="mainheadings"> 
                    {Labels.filter(lbl => lbl.keyPair=='ServiceMasterList_Title' && lbl.pageName=='ServiceMasterList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}</span>
                </div>
                <div className="fullwidth">
                    <div className="float-left">
                    
                    <button className={this.state.userrole.create=='Y'?"btn btn-primary  float-left ":"hidden"}  onClick={this.handleAddMember} > 
                    {Labels.filter(lbl => lbl.keyPair=='ServiceMasterList_Addbtn' && lbl.pageName=='ServiceMasterList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                        </button>
                    </div> 
                    <div className="float-right">
                    <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" aria-label="Status Filter" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='ServiceMasterList_Search' && lbl.pageName=='ServiceMasterList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                        ))}
                        </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />
                        </div>                        
                    </div>                     

                </div>
               <div className="fullwidth row m0">
                <div className="col-md-12 mt-10 p0">
                     <ReactTable
                    data={this.state.servicefiles}
                    columns={this.state.columns}
                    defaultPageSize={20}
                    className="-striped -highlight fullwidth"
                    />  
                </div>      
               </div>
                <div>
                <Modal
                        className="ServicesMaster-modal"
                         show={this.state.show}
                         onHide={this.handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                            {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_Title' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                            <span>
                            {this.state._id==undefined?"Add":"Edit"} {filteredPerson.keyValue}
                            </span>
                            ))}                            
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Row >                                    
                                <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmservicename">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_Name' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                                     <span>
                                        {filteredPerson.keyValue}
                                     </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmservicename"
                                        type="text"
                                        name="serviceName"
                                        placeholder="Service Name"
                                        disabled={this.state._id!=undefined?true:false}
                                        value={this.state.serviceName}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="descr">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_Desc' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                                     <span>
                                        {filteredPerson.keyValue}
                                     </span>
                                    ))} 
                                </Form.Label>{" "}
                                </Col>
                                <Col md={8}>   
                                <Form.Control
                                            id="descr"
                                            className="desc-width"
                                            as="textarea"
                                            rows="1"
                                            name="serviceDesc"
                                            placeholder="Description"
                                             value={this.state.serviceDesc}
                                            onChange={this.handleChange}
                                            className="required"
                                            
                                        />
                                </Col>
                            </Col>
                                </Form.Row>
                                    <Form.Row className="mt-10">
                                    <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmservicecode">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_Code' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                                     <span>
                                        {filteredPerson.keyValue}
                                     </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmservicecode"
                                        type="text"
                                        className="form-control"
                                        controlId="servicecode"
                                        name="serviceCode"                                        
                                        placeholder="Service Code"
                                        onChange={this.handleChange}
                                        disabled={this.state._id!=undefined?true:false}
                                        value={this.state.serviceCode}
                                        required
                                    >
                                    </Form.Control>
                                </Col>
                            </Col>
                            <Col md={6} className="row" >
                                <Col md={4}>
                                    <Form.Label className="required" for="frmservicetype">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_Type' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                                     <span>
                                        {filteredPerson.keyValue}
                                     </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmservicetype"
                                        as="select"
                                        name="serviceType"                                        
                                         value={this.state.serviceType}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">Select Type</option>    
                                        <option value="Primary">Primary</option>
                                        <option value="Secondary">Secondary</option>
                                    </Form.Control>
                                </Col>
                            </Col>
                                    </Form.Row>
                                    <Form.Row className="mt-10">
                                    <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmservicelevel">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_Level' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                                     <span>
                                        {filteredPerson.keyValue}
                                     </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmservicelevel"
                                        type="number"
                                        name="level"
                                        placeholder="Service Level"                                        
                                        value={this.state.level}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Col>                           
                        <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="servicesmasterstatus">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_Status' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                                     <span>
                                        {filteredPerson.keyValue}
                                     </span>
                                    ))}
                                    </Form.Label>{" "}                                    
                                </Col>
                                <Col md={8}>
                                <Form.Control
                                        id="servicesmasterstatus"
                                        as="select"
                                        name="status"
                                        value={this.state.status}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control>
                                </Col>    
                            </Col>                                       
                            </Form.Row>

                            <Form.Row className="mt-10">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_StartDate' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                                     <span>
                                        {filteredPerson.keyValue}
                                     </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                <DatePickerInput
                                            id="startdate"
                                            name="start_date"
                                            onChange={this.onStartDateChange.bind(this, 'start_date') }
                                             placeholder="MM/DD/YYYY"
                                             value={this.state.start_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            aria-label="startdate"
                                            readOnly
                                        />                                       
                                </Col>                                
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label>
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_EndDate' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                                     <span>
                                        {filteredPerson.keyValue}
                                     </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <DatePickerInput
                                            id="enddate"
                                            name="end_date"
                                             onChange={this.onEndDateChange.bind(this, 'end_date') }
                                            placeholder="MM/DD/YYYY"
                                             value={this.state.end_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            aria-label="enddate"
                                            readOnly
                                        />
                                </Col>
                            </Col>                               
                            </Form.Row>  
                         </Form>
                            </Modal.Body>
                            <Modal.Footer>
                               
                                <Button variant="primary" onClick={this.handleSaveClick}>
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_SaveBtn' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                                    <span>
                                        {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                </Button>
                                 <Button variant="secondary" onClick={this.handleClose} >
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_CancelBtn' && lbl.pageName=='ServiceMaster').map(filteredPerson => (
                                    <span>
                                        {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        
                </div>
   
                
            </div>
        </>
    );
    }
}

