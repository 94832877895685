import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import { Link } from "react-router-dom";
import axios from "axios";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Select from 'react-select';
import * as moment from "moment";
import { browserHistory } from "react-router";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";
import "moment/min/moment-with-locales";
import _ from "lodash";
import './ApprovalLimits.css';
 
//const configData= require('config');
const configData = require( '../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
    
}
export default class ApprovalLimits extends React.Component  {

    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
            selectedOption: null,           
            approval:{
                org_name:'',
                role_name : "",
                predecessor_item : "",
                activity_name: "",
                activitylevel : "",
                predecessor_reporting_to : "",
                al_from : "",
                al_to : "",
                startdate : "",
                enddate : "",
                status : ""
                },

            
           roles: [
                { _id: "au", name: "Australia" },
                { _id: "ca", name: "Canada" },
                { _id: "us", name: "USA" },
                { _id: "pl", name: "Poland" },
                { _id: "es", name: "Spain" },
                { _id: "fr", name: "France" },
              ]
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();
       
    }
     componentDidMount() {
        var querystring = queryString.parse(this.props.location.search);        
        if(querystring.id!=undefined){
            this.fetchApprovalWithFetchAPI();
        }
        this.fetchRolesWithFetchAPI();
        this.fetchOrganizationsAPI();
    }

    fetchRolesWithFetchAPI = () => {        
        this.setState({...this.state, isFetching: true});
        fetch(configData.SERVER_URL+'fincorp_getRoles')
            .then(response => response.json())
            .then(result => {
                 this.bindUserRole(result)
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };
    bindUserRole=(result)=>{
        
        if(result!='No document(s) found with defined find criteria!' && result.length>0)
        {
            
             this.setState({...this.state,roles: result, isFetching: false})
        }
        else
        {
             
            this.setState({roles: [], isFetching: false})
        }
         
    }
    
    fetchOrganizationsAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getOrganization` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setOrganization(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };
    setOrganization=(result)=>{
             
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            var templist=[];
            var orglist=[];
            const maxid = _.maxBy(result, 'id');           
            _.forEach(result, function(value) { 
                    
                if(templist.length>0){
                    var i=0;
                    _.forEach(templist, function(tmp) {
                        
                        if(tmp.id==value.parentId && tmp.parentId!=value.parentId)
                        {
                            if(templist[i].children==undefined)
                                templist[i].children=[];
                            value.displayname=tmp.name+'/'+value.label;
                            templist[i].children.push(value);
                            orglist.push(value);
                        }
                        else{
                            if(tmp.children.length>0){
                                var j=0;
                                var childlevel1=tmp.children;
                                _.forEach(childlevel1, function(tmpch1) {
                                    if(tmpch1.id==value.parentId && tmpch1.parentId!=value.parentId)
                                    {
                                        if(tmp.children[j].children==undefined){
                                            tmp.children[j].children=[];
                                        }
                                        value.displayname=tmp.name+'/'+tmpch1.label+'/'+ value.label;
                                        tmp.children[j].children.push(value);
                                        orglist.push(value);
                                    // templist[i].children=tmp;
                                    } 
                                    var k=0;
                                
                                    //second level org structure
                                    _.forEach(tmp.children[j].children, function(tmpch2) {
                                        if(tmpch2.id==value.parentId && tmpch2.parentId!=value.parentId)
                                        {
                                            if(tmp.children[j].children[k].children==undefined){
                                                tmp.children[j].children[k].children=[];
                                            }
                                            value.displayname=tmp.name+'/'+tmpch1.label+'/'+tmpch2.label+'/'+ value.label;
                                            tmp.children[j].children[k].children.push(value);
                                            orglist.push(value);
                                            
                                        }
                                        var l=0;
                                
                                        //third level org structure
                                        _.forEach(tmp.children[j].children[k].children, function(tmpch3) {
                                            if(tmpch3.id==value.parentId && tmpch3.parentId!=value.parentId)
                                            {
                                                if(tmp.children[j].children[k].children[l].children==undefined){
                                                    tmp.children[j].children[k].children[l].children=[];
                                                }
                                                value.displayname=tmp.name+'/'+tmpch1.label+'/'+tmpch2.label+'/'+tmpch3.label+'/'+ value.label;
                                                tmp.children[j].children[k].children[l].children.push(value)
                                                
                                            }
                                            var m=0;
                                
                                            //four level org structure
                                            _.forEach(tmp.children[j].children[k].children[l].children, function(tmpch4) {
                                                if(tmpch4.id==value.parentId && tmpch4.parentId!=value.parentId)
                                                {
                                                    if(tmp.children[j].children[k].children[l].children[m].children==undefined){
                                                        tmp.children[j].children[k].children[l].children[m].children=[];
                                                    }
                                                    value.displayname=tmp.name+'/'+tmpch1.label+'/'+tmpch2.label+'/'+tmpch3.label+'/'+tmpch4.label+'/' +value.label;
                                                    tmp.children[j].children[k].children[l].children[m].children.push(value);
                                                    orglist.push(value);
                                                } 
                                                m++;
                                            });  
                                            l++;
                                        }); 
                                        k++;
                                    });
                                    j++;
                                });
                            }
                            else{
                                value.displayname=value.name;
                                templist.push(value);
                                orglist.push(value);
                            }
                            
                        }
                        i++;
                    });
                }
                else{
                    value.displayname=value.name;
                    templist.push(value);
                    orglist.push(value);
                }
                console.log(value); 
            });
             console.log( orglist);
            this.setState({...this.state,treeData: orglist,  isFetching: false,maxuniqueid:maxid})
            
        }
        
    
    }


    fetchApprovalWithFetchAPI = () => {

        var querystring = queryString.parse(this.props.location.search);        
        this.setState({...this.state, isFetching: true});

        axios.post(configData.SERVER_URL+`fincorp_getApprovalLimitsBasedOnId/`,
    
        {'_id': querystring.id}
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindApprovalBasedId(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };
 
    setBindApprovalBasedId=data=>{       
        if(data.length>0)
            this.setState({approval:data[0], isFetching: false})
            this.globalSearch();
    }

 handleChange = e => {
        let { value, name } = e.target;            
        var approve = this.state.approval;

        if(e.target.type=='checkbox'){
            approve[e.target.name] = e.target.checked;
        }                   
        else
        {
            approve[e.target.name] = e.target.value;
        }
        this.setState( { approval: approve } );
        
    };
   
    onStartDateChange = (jsDate, dateString) => {
        
        this.setState(prevState => ({        
            approval: {
               
                // object that we want to update
                ...prevState.approval, // keep all other key-value pairs
                
                startdate: moment(dateString).format("MM-DD-YYYY")
                
            }
            
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            approval: {
                // object that we want to update
                ...prevState.approval, // keep all other key-value pairs
                
                enddate: moment(dateString).format("MM-DD-YYYY")
                
            }
        }));
    };


   
    handleSaveClick = (event) => {
        debugger;
         // 
        //  const regExDate = /^[0-9\/]+$/;

        if(this.state.approval.org_name ==undefined || this.state.approval.org_name.length==0){
            toast.warn("Enter Org Name");
            event.preventDefault();        
        }
        else if(this.state.approval.role_name ==undefined || this.state.approval.role_name =="" || this.state.approval.role_name=='SELECT STATUS'){
            toast.warn("Select Role Name");
            event.preventDefault();            
        }       
        else if (this.state.approval.activity_name == undefined || this.state.approval.activity_name == ""  ) {
            toast.warn("Enter Activity Name");
            event.preventDefault();               
        }
        else if(this.state.approval.status == undefined || this.state.approval.status ==""){
            toast.warn("Select Status");
            event.preventDefault();            
        }
          else if (this.state.approval.predecessor_reporting_to == undefined || this.state.approval.predecessor_reporting_to == ""  ) {
            toast.warn("Enter Reporting To");
            event.preventDefault();               
        }
        else if (this.state.approval.activitylevel == undefined || this.state.approval.activitylevel == ""  ) {
            toast.warn("Enter Activity Level");
            event.preventDefault();               
        }
        else if (this.state.approval.al_from == undefined || this.state.approval.al_from == ""  ) {
            toast.warn("Enter Approval Limit From");
            event.preventDefault();               
        }
        else if (this.state.approval.al_to == undefined || this.state.approval.al_to == ""  ) {
            toast.warn("Enter Approval Limit To");
            event.preventDefault();               
        }  
        else if (Number(this.state.approval.al_from) > Number(this.state.approval.al_to)) {
            toast.warn("Limit To Must Be Greater Than Limit From");
            event.preventDefault();
        }     
        else if (this.state.approval.startdate == undefined || this.state.approval.startdate == "" || this.state.approval.startdate == "Invalid date" ) {
            toast.warn("Enter Start Date");
             event.preventDefault();               
        }  
        else if (Date.parse(this.state.approval.startdate) > Date.parse(this.state.approval.enddate)) {
            toast.warn("End Date should be greater than the StartDate");
            event.preventDefault();
        }     
        
        else {
                       
            //update existing Approval 
        if(this.state.approval._id!=undefined){
            if(window.confirm('Do you want to save changes?')){   
                this.state.approval.startdate=  this.state.approval.startdate == "Invalid date"?null:this.state.approval.startdate;
                this.state.approval.enddate=  this.state.approval.enddate == "Invalid date"?null:this.state.approval.enddate;
            axios
            .post(configData.SERVER_URL+`fincorp_updateApprovalLimits`,
             
            this.state.approval
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res => this.UpdateChange(res.data,event)
                
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
            event.preventDefault();
            }
           
        }

        else{
            //insert new Approval
            debugger;
            axios
            .post(configData.SERVER_URL+`fincorp_insertApprovalLimits`,
            
            this.state.approval
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res => this.SaveChange(res.data,event)
                
                
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
            event.preventDefault();
            }
    }
    };

    SaveChange=(result,event)=>{
        debugger;
           
        this.state.approval._id=result;
        console.log(result);
        toast.success("Approval Inserted Successfully");
        setTimeout(()=>this.props.history.push("/approvallimitslist"), 4000);
        
    }
    UpdateChange=(result,event)=>{
           
        this.state.approval._id=result;
        console.log(result);
        toast.success("Approval Updated Successfully");
        setTimeout(()=>this.props.history.push("/approvallimitslist"), 4000);
        
    }
    handleCancelClick = () => {
        this.props.history.push("/approvallimitslist");
    }
    
    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    
    handleSelectChange = selectedOption => {
         
        this.setState(prevState => ({
            approval: {
                ...prevState.approval,  
                role_name: selectedOption
            }
        }));
        
      };
     
    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const optionsArray =this.state.roles!=null&& this.state.roles!=undefined? this.state.roles:[]; 
        const savetype= this.state.approval._id!=undefined?"Edit":"Add";
    return (
        <>
            <div className="container approvallimits-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/approvallimitslist">
                        {Labels.filter(lbl => lbl.keyPair=='ApprovalLimitsList_Title' && lbl.pageName=='ApprovalLimitsList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_Title' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}   
                   
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Form  >
                    <div className="centeralign">
                        <span className="mainheadings">
                        {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_Title' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                        <span>
                       {savetype} {filteredPerson.keyValue}
                        </span>
                        ))}                      
                        </span>
                    </div>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="org">
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_Org' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                
                                <Col>
                                    <Form.Control
                                        id="org"
                                        as="select"
                                        name="org_name"
                                        placeholder="Select Organization"
                                        value={this.state.approval.org_name}
                                        onChange={this.handleChange}
                                        required
                                    >
                                        {this.state.treeData &&
                                            this.state.treeData.length > 0 &&
                                            this.state.treeData.map((org,index) => {
                                                return <option data-index={JSON.stringify(org)} value={org.displayname}> {org.displayname}  </option>;
                                            })}   

                                    </Form.Control>

                                        </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" id="frmrolename" >
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_Role' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>   
                                    <Select
                                    aria-labelledby='frmrolename'
                                    value={this.state.approval.role_name}
                                    onChange={this.handleSelectChange}
                                    // isMulti
                                    options={optionsArray.map(opt => ({ label: opt.name, value: opt._id }))}
                                />
                                </Col>
                            </Col>                            
                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">                           
                        

                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmactivitylevel">
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_ActivityLevel' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))} 
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmactivitylevel"
                                        type="number"
                                        className="form-control"
                                        controlId="activitylevel"
                                        name="activitylevel"
                                        onChange={this.handleChange}
                                        value={this.state.approval.activitylevel }
                                        required
                                    >
                                    </Form.Control>
                                </Col>  
                            </Col> 
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmactivityname">
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_ActivityName' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmactivityname"
                                        type="text"
                                        name="activity_name"
                                        placeholder="Activity Name"
                                        value={this.state.approval.activity_name}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>  
                            </Col>     
                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">                           
                          <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="approvallimitsstatus">
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_Status' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}                                   
                                </Col>
                                <Col md={8}>
                                <Form.Control
                                        id="approvallimitsstatus"
                                        as="select"
                                        name="status"
                                     value={this.state.approval.status}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control>                                    
                                </Col>
                            </Col>   
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmrepertingto">
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_Reporting' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmrepertingto"
                                        type="text"
                                        className="form-control"
                                        controlId="reportingto"
                                        name="predecessor_reporting_to"
                                        onChange={this.handleChange}
                                        value={this.state.approval.predecessor_reporting_to }
                                        required
                                    >
                                    </Form.Control>
                                </Col>

                               
                            </Col>    
                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">                           
                        <Col md={6} className="row">

                        <Col md={4}>
                                    <Form.Label className="required" for="frmlimitfrom">
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_From' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmlimitfrom"
                                        type="number"
                                        className="form-control"
                                        controlId="limitfrom"
                                        name="al_from"
                                        onChange={this.handleChange}
                                        value={this.state.approval.al_from }
                                        placeholder="$"
                                        required
                                    >
                                    </Form.Control>
                                </Col>                               
                            </Col>
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmlimitto">
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_To' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmlimitto"
                                        type="number"
                                        className="form-control"
                                        controlId="limitto"
                                        name="al_to"
                                        onChange={this.handleChange}
                                        value={this.state.approval.al_to }
                                        placeholder="$"
                                        required
                                    >
                                    </Form.Control>
                                </Col>  
                            </Col>    
                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for='startdate'>
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_StartDate' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                <DatePickerInput
                                            id="startdate"
                                            name="startdate"
                                            onChange={this.onStartDateChange.bind(this, 'startdate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.approval.startdate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />                                       
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for='enddate'>
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_EndDate' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <DatePickerInput
                                            id="enddate"
                                            //onChange={this.onEndDateChange}
                                            onChange={this.onEndDateChange.bind(this, 'enddate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.approval.enddate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row> 
                     
                    <div className="float-right mt-10 mr-9p"> 
                    <Button type="submit" variant="primary" onClick={this.handleSaveClick} >               
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_SaveBtn' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}                          
                    </Button>                  
                    <Button className="" variant="secondary" className="ml-10" onClick={this.handleCancelClick}>
                                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimits_CancelBtn' && lbl.pageName=='ApprovalLimitsSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                    </Button>                    
                    </div>  
                   

                </Form>
            </div>
        </>
    );
}
}
 