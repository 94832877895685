import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker, DatePickerInput } from "rc-datepicker";
import * as moment from "moment";
import "moment/min/moment-with-locales";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";


//const configData= require('config');
const configData = require( '../../config.json');


const initialValues = {
    weight: '',
    height: '',
    date: '',
    
    
}
export default class ExecutableProgram extends React.Component  {
    constructor(...args) {
        super(...args)
 
        this.state = {
            show: false,
            selectedOption: null,      
            
                program_name:'',
                program_location : "",  
                program_owner : "",             
                program_parameters : "",
                program_command : "",             
                program_type : "",                            
                startdate : "",
                enddate : "",
                program_status : "",
                _id:undefined,    
                                             
                columns:[],
                executableprogramlist:[], 
                originalexecutableprogramlist:[],
                searchInput: "",
                filteredData: [],
                chkisactive:true,           
                userrole:{create:''}
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();
       
    }     
    
    componentDidMount() {
     
        this.fetchExecutableProgramList();
        this.getColumns();
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Executables';
        });
        let columns = [
            {
                Header: "id",
                accessor: "id",
                sortable: true,
                show: true,
                displayValue: "id",
                width: 50 
              },
          {
            Header: "Name",
            accessor: "program_name",
            sortable: true,
            show: true,
            displayValue: "Program Name",
            width: 180 
          },          
          {
            Header: "Location",
            accessor: "program_location",
            sortable: true,
            show: true,
            displayValue: "Program Location",
            width: 100 
          },
          {
            Header: "Owner",
            accessor: "program_owner",
            sortable: true,
            show: true,
            displayValue: "Program Owner",    
            width: 120         
          },          
          {
            Header: "Parameter",
            accessor: "program_parameters",
            sortable: true,
            show: true,
            displayValue: "Program Parameter",
            width: 100 
          },  
          {
            Header: "Command",
            accessor: "program_command",
            sortable: true,
            show: true,
            displayValue: "Program Command",
            width: 100 
          },       
          {
            Header: "Type",
            accessor: "program_type",
            sortable: true,
            show: true,
            displayValue: "Program Type",
            width: 100 
          },  
          {
            Header: "Start Date",
            accessor: "startdate",
            sortable: true,
            show: true,
            displayValue: "Start Date"
          },
          {
            Header: "End Date",
            accessor: "enddate",
            sortable: true,
            show: true,
            displayValue: "End Date"
          },
          {
            Header: "Status",
            accessor: "program_status",
            sortable: true,
            show: true,
            displayValue: "Status",
            minWidth: 70,
            width: 70,
          },        
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }

        ];
        this.setState({ columns });
      };
   
      fetchExecutableProgramList = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getExecutableProgramList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setExecutableProgramList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    
    setExecutableProgramList=(result)=>{ 
        
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Executables';
        });
        debugger;

        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){            
            this.setState({executableprogramlist: result,originalexecutableprogramlist:result,userrole: userrl, isFetching: false});
            this.globalSearch();
        }
        else
         this.setState({...this.state,userrole: userrl, isFetching: false})
        

    }

    handleChange = e => {
        let { value, name } = e.target;
        
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date

        });
        if(name=='search')
        {
            this.refs.table.handleFilterData({ name: value });
            // this.refs.table.handleFilterData({value});
        }
        else if(name=='status' && value=='Inactive'){
            const currentdate = new Date();
            currentdate.setDate(currentdate.getDate()-1);
            var momentDateObj = moment(currentdate).format('MM-DD-YYYY');
            var enddate=new Date(this.state.enddate);
            if(currentdate<enddate){
                var stdate=moment(this.state.startdate).format('MM-DD-YYYY')>moment(currentdate).format('MM-DD-YYYY')?moment(currentdate).format('MM-DD-YYYY'):moment(this.state.startdate).format('MM-DD-YYYY');

                this.setState({startdate:stdate, enddate:momentDateObj });
            }
            
        }
    };

    handleAddMember=()=>{
        this.state.program_name = '';
        this.state.program_status ='';
        this.state.startdate = '';
        this.state.enddate = '';
        this.state.program_owner = '';
        this.state.program_parameters = '';
        this.state.program_location = '';
        this.state.program_command = '';
        this.state.program_type = '';


        this.state._id = undefined;
        this.setState({show: true, editshow:false });
    }
    handleClose=()=> {
        this.state.program_name = '';
        this.state.program_status ='';
        this.state.startdate = '';
        this.state.enddate = '';
        this.state.program_owner = '';
        this.state.program_parameters = '';
        this.state.program_location = '';
        this.state.program_command = '';
        this.state.program_type = '';

        this.state._id = undefined;
        this.setState({ show: false });
    }
  
     handleClick = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

     handleEditClick = (row,event) => {

        this.state.program_name = row.original.program_name;
        this.state.program_status =row.original.program_status;
        this.state.startdate = row.original.startdate;
        this.state.enddate = row.original.enddate;
        this.state.program_owner = row.original.program_owner;
        this.state.program_parameters = row.original.program_parameters;
        this.state.program_location = row.original.program_location;
        this.state.program_command = row.original.program_command;
        this.state.program_type = row.original.program_type;


        this.state._id = row.original._id;
        this.setState({ show: true, editshow: false });
       // this.props.history.push("/Item?id=" + row.id);
    };

     editImgFormatter = (cell, row) => {              
        return (
            <a
                className="editbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a>
        );
    }

     deleteImgFormatter = (cell, row) => {              
        return (
            <a
                className="deletebutton center"
                aria-label="Delete"
                onClick={() => this.handleDeleteClick(row)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a>
        );
    }

    handleDeleteClick = (row,event) => {
        if(window.confirm('Are you sure to delete this record?')){ 
        //delete
        if (row.original._id!= undefined) {
            axios
                .post(configData.SERVER_URL + `fincorp_deleteExecutableProgram`,

                    { '_id': row.original._id }
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                .then(
                    res => this.reloadDataGrid(res.data)
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
        else {
            //error            
            toast.warn("Executable Program Not Deleted");
        }
    }
    }
    reloadDataGrid = (res) => {

        toast.success("Executable Program Deleted Successfully");
        setTimeout(this.fetchExecutableProgramList(), 4000);
    }

    onStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                startdate: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                enddate: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };

    handleSaveClick = (event) => {
         
        var userorgid= localStorage.getItem("userorgid");
         // 
        //  const regExDate = /^[0-9\/]+$/;
        
        if(this.state.program_name ==undefined || this.state.program_name.length==0){
            toast.warn("Enter Program Name");
            event.preventDefault();        
        }
        else if(this.state.program_status ==undefined || this.state.program_status==' '|| this.state.program_status=='Select Status'){
            toast.warn("Choose Program Status");
            event.preventDefault();        
        }
        // else if(this.state.program_parameters ==undefined || this.state.program_parameters.length==0){
        //     toast.warn("Enter Program Parameters");
        //     event.preventDefault();        
        // }
         
        else if(this.state.program_owner ==undefined || this.state.program_owner =="" ){
            toast.warn("Select Program Owner");
            event.preventDefault();            
        }  
        else if(this.state.program_location == undefined || this.state.program_location ==""){
            toast.warn("Select Program Location");
            event.preventDefault();            
        } 
        // else if (this.state.program_command == undefined || this.state.program_command == ""  ) {
        //     toast.warn("Enter Program Command");
        //     event.preventDefault();               
        // }
        else if (this.state.program_type == undefined || this.state.program_type == "" || this.state.run_method=='Select Type' ) {
            toast.warn("Enter Program Type");
            event.preventDefault();               
        }
        else if (this.state.startdate == undefined || this.state.startdate == "" || this.state.startdate == "Invalid date" ) {
            toast.warn("Enter Start Date");
             event.preventDefault();               
        }  
        else if (Date.parse(this.state.startdate) > Date.parse(this.state.enddate)) {
            toast.warn("End Date should be greater than the StartDate");
            event.preventDefault();
        } 
        else {
           
            this.state.orgid=userorgid;
            
            //update existing Executable Program 
        if(this.state._id!=undefined){
            if(window.confirm('Do you want to save changes?')){   
                this.state.startdate=  this.state.startdate == "Invalid date"?null:this.state.startdate;
                this.state.enddate=  this.state.enddate == "Invalid date"?null:this.state.enddate;
            axios
            .post(configData.SERVER_URL+`fincorp_updateExecutableProgram`,
             
            {
                'program_name':this.state.program_name,
                'program_location' : this.state.program_location,  
                'program_owner' : this.state.program_owner,             
                'program_parameters' : this.state.program_parameters,
                'program_command' : this.state.program_command,             
                'program_type' : this.state.program_type,                            
                'startdate' : this.state.startdate,
                'enddate' : this.state.enddate,
                'program_status' : this.state.program_status,
                'orgid':this.state.orgid,
                '_id':this.state._id
               }
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res => this.UpdateChanges(res.data)
                
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
            event.preventDefault();
            }
        }
                else{
                    debugger;
                  var lg=  this.state.originalexecutableprogramlist.length;
                  var tempid=this.state.originalexecutableprogramlist[lg-1].id+1;
                    
                //insert new Executable Program
                axios
                .post(configData.SERVER_URL+`fincorp_insertExecutableProgram`,
                
               {
                'id':tempid,
                'program_name':this.state.program_name,
                'program_location' : this.state.program_location,  
                'program_owner' : this.state.program_owner,             
                'program_parameters' : this.state.program_parameters,
                'program_command' : this.state.program_command,             
                'program_type' : this.state.program_type,                            
                'startdate' : this.state.startdate,
                'enddate' : this.state.enddate,
                'program_status' : this.state.program_status,
                'orgid':this.state.orgid,
                '_id':undefined,
               }
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.SaveChanges(res)
                    
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
                //event.preventDefault();
                }
        }
    
    };

    SaveChanges = (result) => {   
        debugger; 
        console.log(result);    
       if (result.Message!= undefined && (result.Message==-1))
          {
            toast.warning("Executable Program Alredy Exist");
          }
        else { 
        this.state._id=result;
        console.log(result);
        toast.success("Executable Program Inserted Successfully");
        setTimeout(this.ReloadData(), 4000);
        //setTimeout(()=>this.props.history.push("/executableprogramslist"), 4000);  
        } 
    }
    ReloadData=()=>{
        this.fetchExecutableProgramList();
        this.handleClose();
    }
    UpdateChanges = (result) => {
        
        if(result.Message!= undefined && (result.Message==-1)){
            toast.warn(result.Message);
        }
        else{
            toast.success("Executable Program Updated Successfully");
            setTimeout(this.ReloadData(), 4000);   
           
 
        }
   }
    
    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    handleChange1 = event => {
        
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };
      toggleChange = () => {
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }

      globalActiveSearch = (param) => {
          
        let { searchInput } = this.state;    
        console.log(searchInput);  
        if(searchInput==undefined){
            searchInput='';
        }
debugger;
        let filteredData = this.state.originalexecutableprogramlist.filter(value => {
            if(param){               

                return (
                    (value.program_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.program_owner.toLowerCase().includes(searchInput.toLowerCase()) ||                     
                    value.program_parameters.toLowerCase().includes(searchInput.toLowerCase()) ||                    
                    value.program_location.toLowerCase().includes(searchInput.toLowerCase()) ||                       
                    value.program_command.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.program_type.toLowerCase().includes(searchInput.toLowerCase()) || 
                    value.program_status.toLowerCase().includes(searchInput.toLowerCase()) ||                                           
                    value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                    value.program_status.toString().includes('Active') 
                   
                );

            }else{
                return (
                    (value.program_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.program_owner.toLowerCase().includes(searchInput.toLowerCase()) ||                     
                    value.program_parameters.toLowerCase().includes(searchInput.toLowerCase()) ||                    
                    value.program_location.toLowerCase().includes(searchInput.toLowerCase()) ||                       
                    value.program_command.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.program_type.toLowerCase().includes(searchInput.toLowerCase()) || 
                    value.program_status.toLowerCase().includes(searchInput.toLowerCase()) ||                                           
                    value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):'')) ||                                      
                    value.program_status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        });
        this.setState({ executableprogramlist: filteredData });
      };
      globalSearch = () => {
          
        let { searchInput } = this.state;
        let filteredData = this.state.originalexecutableprogramlist.filter(value => {
        if(this.state.chkisactive){    
          return (

            (value.program_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.program_owner.toLowerCase().includes(searchInput.toLowerCase()) ||                     
            value.program_parameters.toLowerCase().includes(searchInput.toLowerCase()) ||                    
            value.program_location.toLowerCase().includes(searchInput.toLowerCase()) ||                       
            value.program_command.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.program_type.toLowerCase().includes(searchInput.toLowerCase()) || 
            value.program_status.toLowerCase().includes(searchInput.toLowerCase()) ||                                           
            value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
            ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
            value.program_status.toString().includes('Active') 
                );
        }else{
            return (

                (value.program_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.program_owner.toLowerCase().includes(searchInput.toLowerCase()) ||                     
                value.program_parameters.toLowerCase().includes(searchInput.toLowerCase()) ||                    
                value.program_location.toLowerCase().includes(searchInput.toLowerCase()) ||                       
                value.program_command.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.program_type.toLowerCase().includes(searchInput.toLowerCase()) || 
                value.program_status.toLowerCase().includes(searchInput.toLowerCase()) ||                                           
                value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):'')) ||                                      
                value.program_status.toString().toLowerCase().includes(searchInput.toLowerCase())
              );  
        }
        });
        this.setState({ executableprogramlist: filteredData });
      };

      handleSelectChange = selectedOption => {
         
        this.setState(prevState => ({
            executableprogram: {
                ...prevState.executableprogram,  
                role_name: selectedOption
            }
        }));
        
      };

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let { data, columns, searchInput } = this.state; 
    return (
        <>
            <div className="container userinfo-container" role="main">
               
                <div className="centeralign">
                    <span className="mainheadings"> 
                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgramList_Title' && lbl.pageName=='ExecutableProgramList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))} 
                    </span>
                </div>
                <div className="fullwidth">
                    <div className="float-left">

                    <button className={this.state.userrole.create=='Y'?"btn btn-primary  float-left ":"hidden"}  onClick={this.handleAddMember} >
                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgramList_AddBtn' && lbl.pageName=='ExecutableProgramList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                        </button>
                    </div> 
                    <div className="float-right">
                    <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" aria-label="Status Filter" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='ExecutableProgramList_Search' && lbl.pageName=='ExecutableProgramList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                        ))}
                        </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />
                        </div>                        
                    </div>                     

                </div>
               <div className="fullwidth row m0">
                <div className="col-md-12 mt-10 p0">
                     <ReactTable
                    data={this.state.executableprogramlist}
                    columns={this.state.columns}
                    defaultPageSize={20}
                    className="-striped -highlight fullwidth"
                    />  
                </div>      
               </div>
                <div>
                <Modal
                        className="ExecutableProgram-modal"
                         show={this.state.show}
                         onHide={this.handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                            {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_Title' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                            <span>
                            {this.state._id==undefined?"Add":"Edit"} {filteredPerson.keyValue}
                            </span>
                            ))}                            
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Row >                                    
                                <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmprogramname">
                                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_ProgramName' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                     <span>
                                        {filteredPerson.keyValue}
                                     </span>
                                    ))} 
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmprogramname"
                                        type="text"
                                        className="form-control"
                                        controlId="programname"
                                        name="program_name"
                                        placeholder="Program Name"
                                        onChange={this.handleChange}
                                        value={this.state.program_name }                                       
                                        required
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label  className="required" for="frmprogramstatus">
                                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_Status' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>   
                                <Form.Control
                                        id="frmprogramstatus"
                                        as="select"
                                        name="program_status"
                                     value={this.state.program_status}
                                        onChange={this.handleChange}
                                        // aria-labelledby="frmexecutablestatus"
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control>
                            </Col>
                            </Col>
                                </Form.Row>
                                    <Form.Row className="mt-10">
                                    <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="" for="frmprogramparameters">
                                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_ProgramParameters' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))} 
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                <Form.Control
                                        id="frmprogramparameters"
                                        type="text"
                                        className="form-control"
                                        controlId="programparameters"
                                        name="program_parameters"
                                        placeholder="Program Parameters"
                                        onChange={this.handleChange}
                                        value={this.state.program_parameters }                                       
                                        // required
                                    >
                                    </Form.Control>
                                </Col>
                            </Col>
                            <Col md={6} className="row" >
                            <Col md={4}>
                                <Form.Label className="required" for="owner">
                                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_Owner' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                     <span>
                                        {filteredPerson.keyValue}
                                     </span>
                                    ))}   
                                </Form.Label>{" "}
                                </Col>
                                <Col md={8}>   
                                <Form.Control
                                            id="owner"
                                            className="form-control"
                                            as="textarea"
                                            rows="1"
                                            name="program_owner"
                                            placeholder="Owner"
                                             value={this.state.program_owner}
                                            onChange={this.handleChange}
                                            required
                                            
                                        />
                                </Col>
                            </Col>
                                    </Form.Row>
                                    <Form.Row className="mt-10">
                                    <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="frmprogramlocation">
                                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_ProgramLocation' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                <Form.Control
                                        id="frmprogramlocation"
                                        type="text"
                                        className="form-control"
                                        controlId="programlocation"
                                        name="program_location"                                       
                                        placeholder="Program Location"
                                        onChange={this.handleChange}
                                        value={this.state.program_location }
                                        required
                                    >
                                    </Form.Control>
                                </Col>
                            </Col>                           
                        <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="" for="frmprogramcommand">
                                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_Command' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label> 
                                </Col>
                                <Col md={8}>
                                <Form.Control
                                        id="frmprogramcommand"
                                        type="text"
                                        className="form-control"
                                        controlId="programcommand"
                                        name="program_command"                                        
                                        placeholder="Program Command"
                                        onChange={this.handleChange}
                                        value={this.state.program_command }
                                        // required
                                    >
                                    </Form.Control>
                                </Col>    
                            </Col>                                       
                            </Form.Row>

                            <Form.Row className="mt-10">
                            <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for='startdate'>
                                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_StartDate' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                <DatePickerInput
                                            id="startdate"
                                            name="startdate"
                                            onChange={this.onStartDateChange.bind(this, 'startdate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.startdate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />                                    
                                </Col>                                
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label for='enddate'>
                                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_EndDate' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                <DatePickerInput
                                            id="enddate"
                                            name="enddate"
                                            //onChange={this.onEndDateChange}
                                            onChange={this.onEndDateChange.bind(this, 'enddate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.enddate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />
                                </Col>
                               </Col>                               
                            </Form.Row>  
                            <Form.Row className="mt-10">
                            <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label  className="required" for="frmprogramtype">
                                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_Type' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label> 
                                </Col>
                                <Col md={8}>   
                                <Form.Control
                                        id="frmprogramtype"
                                        controlId="programtype"
                                        type="text"
                                        name="program_type"
                                        value={this.state.program_type}
                                        placeholder="Program Type"
                                        onChange={this.handleChange}
                                       
                                    >                                      
                                    </Form.Control>
                            </Col>
                            </Col>
                                                        
                            </Form.Row>
                         </Form>
                            </Modal.Body>
                            <Modal.Footer>
                               
                                <Button variant="primary" onClick={this.handleSaveClick}>
                                   Save {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_SaveBtn' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                    <span>
                                        {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                </Button>
                                 <Button variant="secondary" onClick={this.handleClose} >
                                  Cancel  {Labels.filter(lbl => lbl.keyPair=='ServiceMaster_CancelBtn' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                                    <span>
                                        {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        
                </div>
   
                
            </div>
        </>
    );
    }
}

