import React, { useState } from 'react';
import Moment from 'moment'
import FileBrowser, {Icons} from 'react-keyed-file-browser';
import { Button, Modal, Table, Card, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import { ToastContainer, toast} from 'react-toastify';
import axios from "axios";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import "react-table/react-table.css"; 
import _ from "lodash";

import "font-awesome/css/font-awesome.css";
import "react-keyed-file-browser/dist/react-keyed-file-browser.css";
 

//const configData= require('config');
const configData = require( '../../config.json');
 


const mount = document.querySelectorAll('div.demo-mount-flat-simple')
var flatArray=[];
export default class FileViewer extends React.Component  {
    constructor(...args) {
        super(...args)
          
        this.state={
           selectedFile: [],
           orgname:'',
           loginname:'',
           filetype:null,
           fileuploaddetails:[],
           columns:[],
           fileArray: [],
          files:[]
          }         
      
        this.setState({Labels:[],  isFetching: true});       
        this.fetchLabelMessagesAPI();        
    }
     componentDidMount() {
        
        var session=  localStorage.getItem("session");
        
        var userlogin=JSON.parse(session);
        var fullorgname=userlogin.Result[0].org;
        var n=fullorgname.lastIndexOf("/");
        var org=fullorgname.substring(n+1,fullorgname.length)
         axios
         .post(configData.SERVER_URL+`fincorp_getFileViewer` ,
         {'orgname': org}
         , {
             headers: {
                 "Content-Type": "application/json;charset=UTF-8",
                 "Access-Control-Allow-Origin": "*"
             }
         })
         .then(
             res=>  {this.setFileList(res.data) }
         )
         .catch(err => {
             console.log("AXIOS ERROR: ", err);
         });
         
      
       
    }

    setFileList=data=>{
      
    var flatObject = {};
     for (var index = 0; index < data.children.length; index++) {
       var fileobj=data.children[index];
       if(fileobj.type==='file'){
         console.log(fileobj);
          
         var filefullpath=fileobj.path.replaceAll('\\\\','/');
         filefullpath=filefullpath.replaceAll('\\','/');
         var nwfile={key:filefullpath,size:fileobj.size,modified:new Date(fileobj.mtime) }

         flatArray.push(nwfile);
       }
       else{
         
         this.setFileList(fileobj);
       }
     
     }
     this.setState({...this.state,files: flatArray});
      
    }

   
  handleChange = e => {
      
    let { value, name } = e.target;    
        
         this.setState({...this.state,filetype:value});      
        
};


fetchLabelMessagesAPI = () => {
  this.setState({...this.state, isFetching: true});
  
  axios
  .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
  , {
      headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*"
      }
  })
  .then(
      res=>  {this.setBindMessage(res.data) }
  )
  .catch(err => {
      console.log("AXIOS ERROR: ", err);
  });
};

setBindMessage=(result)=>{
        
  if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
      
      this.setState({Labels: result, isFetching: false});
  }       
}

handleCreateFolder = (key) => {
    this.setState(state => {
      state.files = state.files.concat([{
        key: key,
      }])
      return state
    })
  }
  handleCreateFiles = (files, prefix) => {
    this.setState(state => {
      const newFiles = files.map((file) => {
        let newKey = prefix
        if (prefix !== '' && prefix.substring(prefix.length - 1, prefix.length) !== '/') {
          newKey += '/'
        }
        newKey += file.name
        return {
          key: newKey,
          size: file.size,
          modified: +Moment(),
        }
      })

      const uniqueNewFiles = []
      newFiles.map((newFile) => {
        let exists = false
        state.files.map((existingFile) => {
          if (existingFile.key === newFile.key) {
            exists = true
          }
        })
        if (!exists) {
          uniqueNewFiles.push(newFile)
        }
      })
      state.files = state.files.concat(uniqueNewFiles)
      return state
    })
  }
  handleRenameFolder = (oldKey, newKey) => {
    this.setState(state => {
      const newFiles = []
      state.files.map((file) => {
        if (file.key.substr(0, oldKey.length) === oldKey) {
          newFiles.push({
            ...file,
            key: file.key.replace(oldKey, newKey),
            modified: +Moment(),
          })
        } else {
          newFiles.push(file)
        }
      })
      state.files = newFiles
      return state
    })
  }
  handleRenameFile = (oldKey, newKey) => {
    this.setState(state => {
      const newFiles = []
      state.files.map((file) => {
        if (file.key === oldKey) {
          newFiles.push({
            ...file,
            key: newKey,
            modified: +Moment(),
          })
        } else {
          newFiles.push(file)
        }
      })
      state.files = newFiles
      return state
    })
  }
  handleDeleteFolder = (folderKey) => {
    this.setState(state => {
      const newFiles = []
      state.files.map((file) => {
        if (file.key.substr(0, folderKey.length) !== folderKey) {
          newFiles.push(file)
        }
      })
      state.files = newFiles
      return state
    })
  }
  handleDeleteFile = (fileKey) => {
    this.setState(state => {
      const newFiles = []
      state.files.map((file) => {
        if (file.key !== fileKey) {
          newFiles.push(file)
        }
      })
      state.files = newFiles
      return state
    })
}



  render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let { fileArray } = this.state;
        

        return (
        <>
         <ToastContainer autoClose={false} /> 
            <div className="container userinfo-container fileuploadlist" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='FileViewer_Title' && lbl.pageName=='FileViewer').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='FileViewer_Title' && lbl.pageName=='FileViewer').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                    </span>
                </div> 
                <div className="fullwidth row m0">
                
                  <h5>
                  <FileBrowser  files={this.state.files}
                            icons={Icons.FontAwesome(4)}
                            
                            onCreateFolder={this.handleCreateFolder}
                            onCreateFiles={this.handleCreateFiles}
                            onMoveFolder={this.handleRenameFolder}
                            onMoveFile={this.handleRenameFile}
                            onRenameFolder={this.handleRenameFolder}
                            onRenameFile={this.handleRenameFile}
                            onDeleteFolder={this.handleDeleteFolder}
                            onDeleteFile={this.handleDeleteFile}  />
                  </h5>
                 
                </div>
            </div>
        </>
    );
                   
}
}


 