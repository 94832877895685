import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import { Link } from "react-router-dom";
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import axios from "axios";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Select from 'react-select';
import * as moment from "moment";
import { browserHistory } from "react-router";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";
import "moment/min/moment-with-locales";
import _ from "lodash";
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
//const configData= require('config');
import './User.css';
const configData = require( './../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
    user: {
        login: '',
        status: ''
    }
}
export default class User extends React.Component  {

    constructor(...args) {
        super(...args)

        
        var data = [{
            active: true,
            additionalInfo: null,
            address1: null,
            address2: null,
            cellPhone: null,
            children: [{
                name:'Ch1',
                label:'Ch1',
                id:'2'
            }],
            city: null,
            country: "USA",
            created: "2019-08-30 05:05:43 AM",
            createdBy: null,
            createdInMillis: 1567141543351,
            description: 'desc',
            email: 'ceo@abc.com',
            endDate: null,
            ext: null,
            faxNumber: null,
            id: 1,
            label: "CEO",
            level: 1,
            mailCode: null,
            modified: "2020-06-29 02:53:57 AM",
            modifiedBy: "admin",
            name: "CEO",
            notes: null,
            parentId: null,
            phoneNumber: null,
            pocFirstName: null,
            pocLastName: null,
            qualifiedName: "HHS FDA",
            refreshClientState: false,
            startDate: "2019-08-30",
            state: null,
            url: null,
            zipCode: null
        } 
        ];
        
        this.handleSelectEvent = this.handleSelectEvent.bind(this);
        this.onFirstExpRadioChangeValue = this.onFirstExpRadioChangeValue.bind(this);
        this.state = {
            show: false,
            editshow:false,
            iseditrolemode:false,
            changedRole:-1,
            isedit:false,
            selectedOption: null,
            treeData: data,
            orgdisplayname:'Select Org',
            orgid:'',
            confirmPassword:'',
            savedisablestatus:false,
            user: {
               
                status:undefined,
                startdate:undefined,
                enddate:undefined,
                unencryptedPassword:undefined,
                mobile:undefined,
                email:undefined,
                firstName:undefined,
                lastName:undefined,
                jobTitle:undefined,
                payPlan:undefined,
                grade:undefined,
                step:undefined,
                addressTemplate:undefined,
                address1:undefined,
                address2:undefined,
                country:undefined,
                state:undefined,
                zipCode:undefined,
                role:undefined,
                primaryrole:undefined,
                pwdModifiedDate:undefined,
                orgid:'',
        
            },
            role:{
                rolename:undefined,
                rolestartdate:undefined,
                roleenddate:undefined,
                rolestatus:undefined
            },
            userroles:[]
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();
        //this.fetchRolesWithFetchAPI();
    }
     componentDidMount() {
       
      this.fetchUsersWithFetchAPI();
      this.fetchRolesWithFetchAPI();
      this.fetchCountryListAPI();
      this.fetchOrganizationsAPI();
      this.getColumns();
    }

    getColumns = () => {
        let columns = [
          {
            Header: "Role Name",
            accessor: "rolename",
            sortable: true,
            show: true,
            displayValue: "role name",
            Cell:row=>(<span>{row.original.rolename.label}</span>)
          },          
          {
            Header: "Start Date",
            accessor: "rolestartdate",
            sortable: true,
            show: true,
            displayValue: " StartDate ",
            maxWidth: 100,
            minWidth: 90,
            width: 90
          },
          {
            Header: "End Date",
            accessor: "roleenddate",
            sortable: true,
            show: true,
            displayValue: " EndDate ",
            maxWidth: 100,
            minWidth: 90,
            width: 90
          },
          {
            Header: "Status",
            accessor: "rolestatus",
            sortable: true,
            show: true,
            displayValue: "Status",
            maxWidth: 100,
            minWidth: 56,
            width: 56,
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                 onClick={(event) => this.handleRoleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,

            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                 onClick={(event) => this.handleRoleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };

     

      reloadDataGrid=(res)=>{       
            
        toast.success("User Delete Successfully");
        // setTimeout( this.fetchUsersWithFetchAPI(),4000);
      }

    fetchRolesWithFetchAPI = () => {        
        this.setState({...this.state, isFetching: true});
        fetch(configData.SERVER_URL+'fincorp_getRoles')
            .then(response => response.json())
            .then(result => {
                 this.bindUserRole(result)
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };
    bindUserRole=(result)=>{
        if(result!='No document(s) found with defined find criteria!' && result.length>0)
        {
            
             this.setState({...this.state,roles: result, isFetching: false})
        }
        else
        {
             
            this.setState({roles: [], isFetching: false})
        }
         
    }
    fetchUsersWithFetchAPI = () => {

        var querystring = queryString.parse(this.props.location.search);
        
        this.setState({...this.state, isFetching: true});
       
            axios.post(configData.SERVER_URL+`fincorp_getUserBasedOnId/`,
        
            {'_id': querystring.id}
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res=>  {this.setBindUserBasedId(res.data) }
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };
 
    setBindUserBasedId=data=>{
        if(data!='No document(s) found with defined find criteria!' && data.length>0){

            //this.state.user.unencryptedPassword !=this.state.confirmPassword
            this.setState({user:data[0],orgdisplayname:data[0].org,selectedOption:data[0].role,firstExpPeriodOption:data[0].firstExpPeriodOption,
                subSeqExpPeriodOption:data[0].subSeqExpPeriodOption,  isedit:true, isFetching: false,confirmPassword:data[0].unencryptedPassword,userroles:data[0].role,
                selectedPrimaryRoleOption:data[0].primaryrole})
            this.fetchStateListAPI();
        }
    }
 
    
fetchOrganizationsAPI = () => {
    this.setState({...this.state, isFetching: true});
    
    axios
    .get(configData.SERVER_URL+`fincorp_getOrganization` 
    , {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*"
        }
    })
    .then(
        res=>  {this.setOrganization(res.data) }
    )
    .catch(err => {
        console.log("AXIOS ERROR: ", err);
    });
};
setOrganization=(result)=>{
         
    if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
        var templist=[];
        const maxid = _.maxBy(result, 'id');
 
        _.forEach(result, function(value) { 
             
            if(templist.length>0){
    
                
                var i=0;
                _.forEach(templist, function(tmp) {
                     
                    if(tmp.id==value.parentId && tmp.parentId!=value.parentId)
                    {
                        if(templist[i].children==undefined)
                            templist[i].children=[];
                        value.displayname=tmp.name+'/'+value.label;
                        templist[i].children.push(value)
                    }
                    else{
                        if(tmp.children.length>0){
                            var j=0;
                            var childlevel1=tmp.children;
                            _.forEach(childlevel1, function(tmpch1) {
                                if(tmpch1.id==value.parentId && tmpch1.parentId!=value.parentId)
                                {
                                    if(tmp.children[j].children==undefined){
                                        tmp.children[j].children=[];
                                    }
                                    value.displayname=tmp.name+'/'+tmpch1.label+'/'+ value.label;
                                    tmp.children[j].children.push(value)
                                   // templist[i].children=tmp;
                                } 
                                var k=0;
                               
                                //second level org structure
                                _.forEach(tmp.children[j].children, function(tmpch2) {
                                    if(tmpch2.id==value.parentId && tmpch2.parentId!=value.parentId)
                                    {
                                        if(tmp.children[j].children[k].children==undefined){
                                            tmp.children[j].children[k].children=[];
                                        }
                                        value.displayname=tmp.name+'/'+tmpch1.label+'/'+tmpch2.label+'/'+ value.label;
                                        tmp.children[j].children[k].children.push(value)
                                        
                                    }
                                    var l=0;
                               
                                    //third level org structure
                                    _.forEach(tmp.children[j].children[k].children, function(tmpch3) {
                                        if(tmpch3.id==value.parentId && tmpch3.parentId!=value.parentId)
                                        {
                                            if(tmp.children[j].children[k].children[l].children==undefined){
                                                tmp.children[j].children[k].children[l].children=[];
                                            }
                                            value.displayname=tmp.name+'/'+tmpch1.label+'/'+tmpch2.label+'/'+tmpch3.label+'/'+ value.label;
                                            tmp.children[j].children[k].children[l].children.push(value)
                                            
                                        }
                                        var m=0;
                               
                                        //four level org structure
                                        _.forEach(tmp.children[j].children[k].children[l].children, function(tmpch4) {
                                            if(tmpch4.id==value.parentId && tmpch4.parentId!=value.parentId)
                                            {
                                                if(tmp.children[j].children[k].children[l].children[m].children==undefined){
                                                    tmp.children[j].children[k].children[l].children[m].children=[];
                                                }
                                                value.displayname=tmp.name+'/'+tmpch1.label+'/'+tmpch2.label+'/'+tmpch3.label+'/'+tmpch4.label+'/' +value.label;
                                                tmp.children[j].children[k].children[l].children[m].children.push(value)
                                              } 
                                            m++;
                                        });  
                                        l++;
                                    }); 
                                    k++;
                                });
                                j++;
                            });
                        }
                        else{
                            value.displayname=value.name;
                            templist.push(value);
                        }
                        
                    }
                    i++;
                });
            }
            else{
                value.displayname=value.name;
                templist.push(value);
            }
            console.log(value); 
          });
        this.setState({...this.state,treeData: templist,  isFetching: false,maxuniqueid:maxid})
        
    }
    

}
truncate(node) {

    if (node.name != undefined) {
        return node.name.length > 50 ? node.name.substring(0, 47) + "..." : node.name;
    }
    else {
        return "";
    }
};

 handleChange = (event) => {
      
        let { value, name } = event.target;
        console.log(this.state.user);
        if(name=='formconfirmpassword'){
            this.setState(prevState => ({
                    ...prevState,  
                    'confirmPassword':value  
            })); 
        }
        // else{
            
        //     if(name=="country"){
                
        //         if(value=="US"){
        //             this.fetchStateListAPI(value);
        //         }
        //     }
        //     else if(name=="state"){
        //         this.fetchCityListAPI(value);
        //     }
        //     else if(name=="city"){
                
        //         let obj = JSON.parse(e.target.selectedOptions[0].dataset.index);
        //         value=obj.city_pk;
        //         this.setState(prevState => ({
        //             user: {
        //                 // object that we want to update
        //                 ...prevState.user, // keep all other key-value pairs
        //                 'zipCode': obj.zipcode,
        //                 'cityName':obj.city // update the value of specific key
        //             }
        //         })); 
        //     }
        //     this.setState(prevState => ({
        //         user: {
        //             // object that we want to update
        //             ...prevState.user, // keep all other key-value pairs
        //             [name]: value // update the value of specific key
        //         }
        //     }));
            

            
        // }
        else{
            if(name=="country"){
                
                if(value=="US"){
                   
                    this.fetchStateListAPI(value);
                }
                else{
                    this.setState(prevState => ({
                         'StateList':[],
                          'CityList':[],
                        user: {
                            // object that we want to update
                            //...prevState.org, // keep all other key-value pairs
                            ...prevState.user, 
                            // 'country':value,
                            'state':'',
                           
                            'zipCode': '',
                            'city':'' // update the value of specific key
                        }
                    }));  
                }
            }
            else if(name=="state"){
                debugger;
                this.fetchCityListAPI(value);                
                this.setState(prevState => ({
                    user: {
                        // object that we want to update
                        ...prevState.user, // keep all other key-value pairs
                        'zipCode': '',
                        // 'state':'',
                        'city':'' // update the value of specific key
                    },
                    savedisablestatus:false
                })); 
            }
            
            else if(name=="city"){
                
                let obj = JSON.parse(event.target.selectedOptions[0].dataset.index);
                value=obj.city_pk;
                
                this.setState(prevState => ({
                    user: {
                        // object that we want to update
                        ...prevState.user, // keep all other key-value pairs
                        'zipCode': obj.zipcode,
                        'city':obj.city_pk,
                        'cityName':obj.city // update the value of specific key
                    },
                    savedisablestatus:false
                })); 
            }
            else if(name=="zipCode"){

                
                var cityindex =_.findIndex(this.state.CityList, function(obj) {
                    return obj.zipcode === value;
                  });

                if(cityindex==-1){
                    this.setState(prevState => ({
                        savedisablestatus:true
                    })); 
                    toast.warn("Enter Valid Zip code");
                    event.preventDefault();
                }
                else{
                this.setState(prevState => ({
                    savedisablestatus:false
                })); 
                }
            }
                this.setState(prevState => ({
                            user: {
                                // object that we want to update
                                ...prevState.user, // keep all other key-value pairs
                                [name]: value // update the value of specific key
                            }
                        }));
 
        }
        
    };

    onStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            user: {
                // object that we want to update
                ...prevState.user, // keep all other key-value pairs
                
                startdate: moment(dateString).format("MM-DD-YYYY")
                
            }
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            user: {
                // object that we want to update
                ...prevState.user, // keep all other key-value pairs
                
                enddate: moment(dateString).format("MM-DD-YYYY")
                
            }
        }));
    };

    
    onSpecificDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            user: {
                // object that we want to update
                ...prevState.user, // keep all other key-value pairs
                
                specificdate: moment(dateString).format("MM-DD-YYYY")
                
            }
        }));
    };
    
    fetchCountryListAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCountryList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindCountryList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindCountryList=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
           
            this.setState(prevState => ({
                    // object that we want to update
                    ...prevState, // keep all other key-value pairs
                    CountryList: result 
            }));
           
        }
        

    }

    fetchStateListAPI = (val) => {
        this.setState({...this.state, isFetching: true});
        axios
        .post(configData.SERVER_URL+`fincorp_getStates/`,
        
        {'country_pk': val}
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        
        .then(
            res=>  {this.setBindStateList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindStateList=(result)=>{
      
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
           // this.setState({StateList: result, isFetching: false});
            // this.setState(prevState => ({
            //     // object that we want to update
            //     ...prevState, // keep all other key-value pairs
            //     StateList: result,

            //  }));
             this.setState(prevState => ({
                StateList: result,
                isFetching: false,
                user: {
                    // object that we want to update
                    //...prevState.org, // keep all other key-value pairs
                    ...prevState.user, 
                    // 'country':'US',
                    //'state':'',
                    //'zipCode': '',
                    //'city':'' // update the value of specific key
                } 
             }));
            if(this.state.user._id!=undefined){
                this.fetchCityListAPI();
            }
        }
        else{
            //this.setState({StateList: [], isFetching: false}); 
            this.setState(prevState => ({
                'StateList':[],
                isFetching: false,
              user: {
                  // object that we want to update
                  ...prevState.org, // keep all other key-value pairs
                  city:'',
                  zipCode:''
              }
          })); 
        }
        

    }

    fetchCityListAPI = (val) => {
        this.setState({...this.state, isFetching: true});
        var st_pk=val!=undefined?val:this.state.user.state;

        axios
        .post(configData.SERVER_URL+`fincorp_getCityList/`,
        {'state_pk':st_pk} 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindCityList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindCityList=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            //this.setState({CityList: result, isFetching: false});
            this.setState(prevState => ({
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                CityList: result,
                isFetching: false,
                user: {
                    // object that we want to update
                    ...prevState.user // keep all other key-value pairs
                    
                }
        }));

        }
        else{
             
            this.setState(prevState => ({
                 
                 'CityList':[],
                 isFetching: false,
               user: {
                   // object that we want to update
                   ...prevState.org, // keep all other key-value pairs
                   'zipCode': '',
                   'city':'' // update the value of specific key
               }
           }));  
                
                
        }

    }
    
    //Save the user details
    handleSaveClick = (event) => {

               
        console.log(this.state.user);
        const regExRoleName = /^[a-zA-Z]*$/;
        const regExPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{11,})/;
        const regExEmail = /[a-zA-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;
        const regExZip = /[0-9]{5}/;
        if (this.state.user._id==undefined && regExPassword.test(this.state.user.unencryptedPassword) === false){
            toast.warn("Password Must Contain Minimum 11 Charecters, One Upper, One Lower, One Number, One Special Charecter");
            event.preventDefault();        
        } 
        
        else if(this.state.user._id==undefined && this.state.user.unencryptedPassword !=this.state.confirmPassword){
            toast.warn("Entered password and confirmpassword not matched");
            event.preventDefault();        
        }
       
        // else if(this.state.user.status ==undefined || this.state.user.status =="" || this.state.user.status=='SELECT STATUS'){
        //     toast.warn("Select Status");
        //     event.preventDefault();            
        // }
        // else if (this.state.user.startdate == undefined || this.state.user.startdate == "" || this.state.user.startdate == "Invalid date") {
        //     toast.warn("Enter StartDate");
        //     event.preventDefault();   
        //     console.log(this.state.user.startdate);
        // }
        
        else if(this.state.user.status ==undefined || this.state.user.status =="" ||this.state.user.status =="SELECT STATUS" ){
            toast.warn("Select Status");
            event.preventDefault();            
        }

        else if (this.state.user.startdate == undefined || this.state.user.startdate == "" || this.state.user.startdate == "Invalid date") {
            toast.warn("Enter StartDate");
            event.preventDefault();   
            console.log(this.state.user.startdate);

        }  
        else if (Date.parse(this.state.user.startdate) >  Date.parse(this.state.user.enddate)) {           
            toast.warn("End Date should be greater than the StartDate");
            event.preventDefault();
        }        
        else if(this.state.userroles ==undefined || this.state.userroles.length ==0 ){
            toast.warn("Select Role");
            event.preventDefault();            
        } 
        else if(this.state.selectedPrimaryRoleOption ==undefined || this.state.selectedPrimaryRoleOption.length ==0 ){
            toast.warn("Select Primary Role");
            event.preventDefault();            
        }
        else if (this.state.user.mobile == undefined || this.state.user.mobile == ""){
            toast.warn("Enter Mobile Number");
            event.preventDefault();        
        }   
        else if (regExEmail.test(this.state.user.email) === false){
            toast.warn("Enter Correct Email");
            event.preventDefault();        
        }          
        else if (regExRoleName.test(this.state.user.firstName) === false){
            toast.warn("First Name Must Contain Only Alphabets");
            event.preventDefault();        
        }  
        else if (regExRoleName.test(this.state.user.lastName) === false){
            toast.warn("Last Name Must Contain Only Alphabets");
            event.preventDefault();        
        }
        else if(this.state.orgdisplayname =="Select Org"){
            toast.warn("Select Organization");
            event.preventDefault();            
        }   
        else if (this.state.user.address1 == undefined || this.state.user.address1 == "") {
            toast.warn("Enter Address1");
            event.preventDefault();
        }
        else if(this.state.user.country ==undefined || this.state.user.country =="" || this.state.user.country=='Select Country'){
            toast.warn("Select Country");
            event.preventDefault();            
        }
        else if(this.state.user.state ==undefined || this.state.user.state =="" || this.state.user.state=='Select State'){
            toast.warn("Select State");
            event.preventDefault();            
        }
        else if(this.state.user.city ==undefined || this.state.user.city =="" || this.state.user.city=='Select City'){
            toast.warn("Select City");
            event.preventDefault();            
        }
        else if (this.state.user.zipCode == undefined || this.state.user.zipCode == "") {
            toast.warn("Enter Zip Code");
            event.preventDefault();
        }
            
        else {
            this.state.user.org=this.state.orgdisplayname ;
            this.state.user.orgid=this.state.orgid ;

            this.state.user.role=this.state.userroles;
            this.state.user.primaryrole=this.state.selectedPrimaryRoleOption;
            //update existing User
            if(this.state.user._id!=undefined){
                if(window.confirm('Do you want to save changes?')){ 
                this.state.user.startdate=  this.state.user.startdate == "Invalid date"?null:this.state.user.startdate;
                this.state.user.enddate=  this.state.user.enddate == "Invalid date"?null:this.state.user.enddate;
                axios
                .post(configData.SERVER_URL+`fincorp_updateUser`,
                
                this.state.user
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.UpdateChange(res.data,event)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
                event.preventDefault();
            }
            }
            else{
                //insert new role
                axios
                .post(configData.SERVER_URL+`fincorp_insertUser`,
                
                this.state.user
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.SaveChange(res.data,event)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
                event.preventDefault();
            }
        }
    };

    SaveChange=(result,event)=>{
        if(result.MessageKey!=undefined){
            toast.warn(result.Message);
        }
        else{
           
        this.state.user._id=result;
        console.log(result);
        toast.success("User Inserted Successfully");
        setTimeout(()=>this.props.history.push("/userlist"), 4000);
        }
        
    }
    UpdateChange=(result,event)=>{
        this.state.user._id=result;
        console.log(result);
        toast.success("User Updated Successfully");
        
        setTimeout(()=>this.props.history.push("/userlist"), 4000);
        
    }
    handleCancelClick = () => {
        this.props.history.push("/userlist");
    }
     
    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    handleSelectEvent(node) {
        
        //Select Org
        this.setState(prevState => ({
            // object that we want to update
            ...prevState, // keep all other key-value pairs
            orgdisplayname:  node.displayname,
            orgid:node._id,
            show:!this.state.show
            }));
            
    }


    handleClose=()=> {

        this.setState({ show: false });
    }
    
    handleSelectChange = selectedPrimaryRoleOption => {
        
        this.setState({ selectedPrimaryRoleOption });
        console.log(`Option selected:`, selectedPrimaryRoleOption);
      };

      handleRoleSelectChange = selectedRoleOption => {
        this.setState({ selectedRoleOption });
        console.log(`Option selected:`, selectedRoleOption);
      };
      handleBtnOrgSelectSubmit = (param) => {
        
       // this.fetchOrganizationsAPI(); 
       this.setState(prevState => ({
            // object that we want to update
            ...prevState, // keep all other key-value pairs
            show: !this.state.show
            }));
      }
      handleBtnRoleSelectSubmit = (param) => {
        
        this.setState(prevState => ({
             ...prevState,  
             roleshow: !this.state.roleshow,
             iseditrolemode:false
             }));
       }
       
      onFirstExpRadioChangeValue = (param) => {
          
        var selectedoption=param.target.value;
        this.setState(prevState => ({
             ...prevState,  
             firstExpPeriodOption:selectedoption
             }));

             
             this.setState(prevState => ({
                user: {
                    // object that we want to update
                    ...prevState.user, // keep all other key-value pairs
                    'fpExpiryPeriodOnDate': '',
                    'fpExpiryPeriodInDays':'',
                    'firstExpPeriodOption':selectedoption
                }
            })); 
       }

       
       onSubSeqExpRadioChangeValue = (param) => {
          
        var selectedoption=param.target.value;
        this.setState(prevState => ({
             ...prevState,  
             subSeqExpPeriodOption:selectedoption
             }));
            // this.state.subSeqExpPeriodOption === "cspExpiryPeriodInDays"
             if(param.target.value=="spExpiryPeriodUseDefault"){
             this.setState(prevState => ({
                    user: {
                        // object that we want to update
                        ...prevState.user, // keep all other key-value pairs
                        'spExpiryPeriodInDays': '',
                        'subSeqExpPeriodOption' :selectedoption
                    }
                })); 
            }
       }

       onRoleStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            role: {
                 
                ...prevState.role,  
                
                rolestartdate: moment(dateString).format("MM-DD-YYYY")
                
            }
        }));
    };
    onRoleEndDateChange = (jsDate, dateString) => {
        
        this.setState(prevState => ({
            role: {
                // object that we want to update
                ...prevState.role,  
                
                roleenddate: moment(dateString).format("MM-DD-YYYY")
                
            }
        }));
    };
    handleSelectStatusChange = e => {

        let { value, name } = e.target;
        this.setState(prevState => ({
            role: {
                // object that we want to update
                ...prevState.role,  
                
                rolestatus: value
                
            }
        }));
    }

    
    handleAddRoleClick = (event) => {
        
        if(this.state.selectedRoleOption==undefined){
            toast.warn("Select Rolename");
            event.preventDefault();
        }
        else if(this.state.role.rolestatus== undefined)
        {
            toast.warn("Select Role Status");
            event.preventDefault();
            
        }
        else if(this.state.role.rolestartdate== undefined){
            toast.warn("Select Role Start Date");
            event.preventDefault();
        }
        
        else{
            this.state.role.rolename=this.state.selectedRoleOption;
        
            let data= this.state.userroles;
          
           // _.remove( data, {rolename: this.state.selectedRoleOption});
            if(this.state.changedRole!=undefined && this.state.changedRole>-1){
                data[this.state.changedRole]=this.state.role;
                toast.success("role updated Successfully");
            }
            else{
            data.push(this.state.role);
            toast.success("role added Successfully");
             }
            //;
            const newData = data.map(d=>({...d, name1:"Test"}));
            this.setState(prevState => (
                {...prevState,
                    userroles: newData,
                    role:{  rolename:undefined,
                            rolestartdate:undefined,
                            roleenddate:undefined,
                            rolestatus:"SELECT STATUS"
                        },
                  changedRole:-1,
                  iseditrolemode:false,
                  selectedRoleOption:""
                }));
                
                event.preventDefault();
        }
    }
    handleCloseRoleClick = (event) => {
            this.setState(prevState => ({
                ...prevState,
                  roleshow:false,
                  selectedRoleOption:"",
                  role:{  rolename:undefined,
                    rolestartdate:undefined,
                    roleenddate:undefined,
                    rolestatus:"SELECT STATUS"
                },
             }));
        
    }
    handleRoleEditClick(row,event) {  
        
        this.setState(prevState => ({
            ...prevState, role:row.original,roleshow:true ,selectedRoleOption:row.original.rolename,iseditrolemode:true,changedRole:row.index
       }));
      }

      handleRoleDeleteClick=(row,event)=>{ 
          
          if(window.confirm('Are you sure to delete this record?')){
          let data= this.state.userroles;
          data.splice(row.index, 1)
            const newData = data.map(d=>({...d, name1:"Test"}));
            this.setState(prevState => (
                {...prevState,
                    userroles: newData,
                    role:{  rolename:undefined,
                            rolestartdate:undefined,
                            roleenddate:undefined,
                            rolestatus:undefined
                        },
                  changedRole:{},
                  selectedRoleOption:undefined,
                  roleshow: true
                }));
                toast.success("role deleted Successfully");
            }
        // if(window.confirm('Are you sure to delete this record?')){  
             
        //     if(row.original._id!=undefined){
        //         axios
        //         .post(configData.SERVER_URL+`fincorp_deleteUser`,
                
        //         {'_id': row.original._id}
        //         , {
        //             headers: {
        //                 "Content-Type": "application/json;charset=UTF-8",
        //                 "Access-Control-Allow-Origin": "*"
        //             }
        //         })
        //         .then(
        //             res => this.reloadDataGrid(res.data)
                    
        //         )
        //         .catch(err => {
        //             console.log("AXIOS ERROR: ", err);
        //         });
        //     // event.preventDefault();
        //     }
        //     else{
        //         //error            
        //         toast.warn("User Not Deleted");
        //     }
        // }
      }

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
       
        const optionsArray =this.state.roles!=null&& this.state.roles!=undefined? this.state.roles:[]; 

        const savetype= this.state.user._id!=undefined?"Edit":"Add";

        const savedisablestatus=this.state.savedisablestatus;
       
    return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/userlist">
                        {Labels.filter(lbl => lbl.keyPair=='UserList_Title' && lbl.pageName=='UserList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Title' && lbl.pageName=='UserSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                    </Breadcrumb.Item>
                </Breadcrumb>

                <ToastContainer />

                <div>
                    <Modal
                        className="addeditrole-modal"
                         show={this.state.roleshow}
                         onHide={this.handleCloseRoleClick}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                                <h3>
                                {Labels.filter(lbl => lbl.keyPair=='UserSetup_PopUpAdd_Edit_RoleTitle' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                <span>
                                {filteredPerson.keyValue}
                                </span>
                                ))}
                                </h3>                               
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                 
                                    <Form.Row>
                                        <Col md-6>
                                        <Form.Group as={Col} controlId="formGrpMemberDesignation">
                                        <Form.Label className="labelvalue required">
                                        {Labels.filter(lbl => lbl.keyPair=='UserSetup_PopUpRole' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                        ))}                                       
                                        </Form.Label>
                                        <Select
                                        value={this.state.selectedRoleOption}
                                        onChange={this.handleRoleSelectChange}
                                        
                                        options={optionsArray.map(opt => ({ label: opt.name, value: opt._id }))}
                                        />

                                    </Form.Group>
                                        </Col>
                                        <Col md-6>
                                        <Form.Group as={Col} controlId="formGrpMemberrEmail">
                                        <Form.Label className="labelvalue required">
                                        {Labels.filter(lbl => lbl.keyPair=='UserSetup_PopUpStatus' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                        ))}                                       
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="formGrpMemberStatus"
                                            controlId="grpmemstatus"
                                            name="rolestatus"
                                            value={this.state.role.rolestatus}
                                            onChange={this.handleSelectStatusChange}
                                           
                                            required
                                            >
                                            <option value="SELECT STATUS">Select Status</option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                            </Form.Control>

                                    </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                    <Col md-6 s-12>
                                        <Form.Group as={Col} controlId="formGrpMemberLocation">
                                        <Form.Label className="labelvalue required">
                                        {Labels.filter(lbl => lbl.keyPair=='UserSetup_PopUpStartDate' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                        ))}                                                                                   
                                        </Form.Label>
                                        <DatePickerInput
                                            id="rolestartdate"
                                            name="rolestartdate"
                                            onChange={this.onRoleStartDateChange.bind(this, 'rolestartdate') }
                                            placeholder="MM/DD/YYYY"
                                            minDate={moment().toDate()}
                                            value={this.state.role.rolestartdate}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />

                                         </Form.Group>
                                        </Col>
                                        <Col md-6 s-12>
                                        <Form.Group as={Col} controlId="formGrpMemberStatus">
                                            <Form.Label>
                                            {Labels.filter(lbl => lbl.keyPair=='UserSetup_PopUpEndDate' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                                            </Form.Label>
                                            <DatePickerInput
                                            id="roleenddate"
                                            name="roleenddate"
                                            onChange={this.onRoleEndDateChange.bind(this, 'roleenddate') }
                                            placeholder="MM/DD/YYYY"
                                            minDate={moment().toDate()}
                                            value={this.state.role.roleenddate}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />
                                           
                                        </Form.Group>
                                        </Col>
                                       
                                    </Form.Row>
                                    <Form.Row>
                                    <Col md-12 s-12>  
                                    <Button variant="primary" onClick={this.handleAddRoleClick}  >
                                        {this.state.iseditrolemode==true?"Update":"Add"}
                                            {/* {Labels.filter(lbl => lbl.keyPair=='UserSetup_PopUpAddBtn' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                            <span>
                                          { filteredPerson.keyValue}
                                            </span>
                                            ))}              */}
                                    </Button>
                                 <Button variant="secondary" className="btnrolecancel"  onClick={this.handleCloseRoleClick}  >
                                            {Labels.filter(lbl => lbl.keyPair=='UserSetup_PopUpCancelBtn' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}       
                                </Button>
                                </Col>
                                       
                                       </Form.Row>
                                       <Form.Row>
                                       <div className="fullwidth row m0">
                                        <div className="col-md-12 mt-10 p0">
                                        <ReactTable
                                         data={this.state.userroles}
                                         columns={this.state.columns}
                                         defaultPageSize={5}
                                        className="-striped -highlight fullwidth"
                                        />  
                                        </div>      
                                        </div>  

                                       
                                       
                                       </Form.Row>
                                </Form>

                               


                            </Modal.Body>
                            <Modal.Footer>
                               
                               
                            </Modal.Footer>
                        </Modal>
                    </div>
                <Form   name="contactform" className="contactform" >
                      
                    <div className="centeralign">
                        <span className="mainheadings">
                        {Labels.filter(lbl => lbl.keyPair=='UserSetup_Title' && lbl.pageName=='UserSetup').map(filteredPerson => (
                        <span>
                        {savetype} {filteredPerson.keyValue}
                        </span>
                    ))}  
                        </span>
                    </div>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            {/* <Col md={6} className={this.state.isedit ? "hide" : " row"}> */}
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmusername">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Name' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmusername"
                                        type="text"
                                        name="login"
                                        placeholder="Name"                                       
                                        disabled={this.state.user._id!=undefined?true:false}
                                        value={this.state.user.login}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Col>

                            

                            <Col md={6} className="row" >
                                <Col md={4}>
                                    <Form.Label className="required" for="userstatus">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Status' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="userstatus"
                                        as="select"
                                        name="status"
                                        value={this.state.user.status}
                                        onChange={this.handleChange}
                                    >
                                        <option value="SELECT STATUS">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control>
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for='startdate'>
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_StartDate' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                <DatePickerInput
                                            id="startdate"
                                            name="startdate"
                                            //onChange={this.onEndDateChange}
                                            onChange={this.onStartDateChange.bind(this, 'startdate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.user.startdate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />
                                         
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for='enddate'>
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_EndDate' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    
                                    <DatePickerInput
                                            id="enddate"
                                            //onChange={this.onEndDateChange}
                                            onChange={this.onEndDateChange.bind(this, 'enddate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.user.enddate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row>
                    <Form.Row className={this.state.isedit==true ? "d-none" : " row"}>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row" >
                                <Col md={4}>
                                    <Form.Label className="required" for="frmpassword">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Password' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmpassword"
                                        type="password"
                                        name="unencryptedPassword"
                                        placeholder="Password"
                                        value={this.state.user.unencryptedPassword}
                                        onChange={this.handleChange}
                                        required={!this.state.isedit}
                                    />
                                     <p className="usrpasscondn" for="frmpassword">
                                ( Password Must Contain Minimum 11 Charecters, One Upper, One Lower, One Number, One Special Charecter )  
                                </p>
                                </Col>
                               
                            </Col>


                            <Col md={6} className="row" >
                                <Col md={4}>
                                    <Form.Label className="required" for="frmcpassword" >
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_ConfPass' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}  
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmcpassword"
                                        type="password"
                                        name="formconfirmpassword"
                                        placeholder="Confirm Password"
                                        value={this.state.confirmPassword}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Col>                           
                        </Row>
                    </Form.Row>


                    <Form.Row>
                     <Row className="margin-t10 col-md-12">        
                        <Col md={6} className={this.state.isedit!=true ? "d-none" : " row"}>
                                <Col md={4}>
                                    <Form.Label aria-label="LastPasswordChanged">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_LastPassChanged' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Label
                                        aria-label="LastPasswordChangedValue"
                                        style={{
                                            color: "gray",
                                            fontWeight: "normal",
                                            fontStyle: "italic"
                                        }}
                                    >
                                        {this.state.user.pwdModifiedDate!=undefined?new Date(this.state.user.pwdModifiedDate).toLocaleString():""}
                                    </Form.Label>
                                </Col>
                        </Col>
                        
                        <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" >
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Roles' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                   
                                    {/* <DropdownMultiselect options={this.state.roles!=undefined && this.state.roles.length > 0 ?optionsArray:optionsArray} placeholder="select Role" optionLabel="name" optionKey="_id" name="Role" />
                                     */}
                                    {/* <Select
                                    value={this.state.selectedOption}
                                    onChange={this.handleSelectChange}
                                    isMulti
                                    options={optionsArray.map(opt => ({ label: opt.name, value: opt._id }))}
                                /> */}
                                <button
                                    id="addeditrole-btn"
                                    className="addeditrole-select"
                                    type="button"
                                    onClick={this.handleBtnRoleSelectSubmit.bind('addedotrole-btn')}
                                >
                                        {Labels.filter(lbl => lbl.keyPair=='UserSetup_AddEditRoleBtn' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                                </button>
                                </Col>
                            </Col>         

                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" id="frmprimaryrole" >
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_PrimaryRole' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>  
                                    <Select                                    
                                    value={this.state.selectedPrimaryRoleOption}
                                    onChange={this.handleSelectChange}                                   
                                    options={this.state.userroles.map(opt => ({ label: opt.rolename.label, value: opt.rolename.value }))}
                                    aria-labelledby='frmprimaryrole'
                                />
                                </Col>
                            </Col>                                        

                     </Row>
                    </Form.Row>                    


                    <Form.Row>
                        <Row className="margin-t10 col-md-12" style={{ border: "1px solid black", borderRadius: "5px" }}>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_FirstPassExpPeriod' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Group controlId="formFirstPasswordExpiryNever">
                                        <Form.Check
                                            aria-label="neverformFirstPasswordExpiryNeverexp"
                                            type="radio"
                                            name="firstexpperiod"
                                            checked={this.state.firstExpPeriodOption === "fpExpiryPeriodNever"}
                                            onChange={this.onFirstExpRadioChangeValue}
                                            label="Never"
                                            value="fpExpiryPeriodNever"
                                            
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formFirstPasswordExpiryImmediates">
                                        <Form.Check
                                            aria-label="formFirstPasswordExpiryImmediate"
                                            type="radio"
                                            name="firstexpperiod"
                                            label="Immediately"
                                            value="fpExpiryPeriodImmediately"
                                            checked={this.state.firstExpPeriodOption === "fpExpiryPeriodImmediately"}
                                            onChange={this.onFirstExpRadioChangeValue}
                                           
                                        />
                                    </Form.Group>

                                    <Form.Group className="firstperiodindayscontainer">
                                        <Form.Check
                                            type="radio"
                                             
                                            label="In"
                                            id="formFirstPasswordExpiryInDays" 
                                             
                                            value="cfpExpiryPeriodInDays"
                                            name="cfpExpiryPeriodInDays"
                                            checked={this.state.firstExpPeriodOption === "cfpExpiryPeriodInDays"}
                                            onChange={this.onFirstExpRadioChangeValue}
                                        />
                                        <div className="firstperiodtxtcontainer">
                                        <Form.Control
                                            className="txtwidth"
                                            type="text"
                                            id="txtFirstPasswordExpiryInDays" 
                                            name="fpExpiryPeriodInDays"
                                            value={this.state.user.fpExpiryPeriodInDays}
                                            placeholder="0"
                                            disabled={!(this.state.firstExpPeriodOption === "cfpExpiryPeriodInDays")}
                                            onChange={this.handleChange}
                                        />
                                        Days
                                        </div>
                                     </Form.Group>

                                    <Form.Group controlId="formFirstPasswordExpiryOnSpecificDate">
                                        <Form.Check
                                            type="radio"
                                            value="cfpExpiryPeriodOnDate"
                                             
                                            className="pull-left"
                                            label="On Specific Date :"
                                            name="cfpExpiryPeriodOnDate"
                                            checked={this.state.firstExpPeriodOption === "cfpExpiryPeriodOnDate"}
                                            onChange={this.onFirstExpRadioChangeValue}
                                       
                                        />

                                        <DatePickerInput
                                            id="specificdate"
                                            aria-label="specificdate"
                                             onChange={this.onSpecificDateChange}
                                            placeholder="MM/DD/YYYY"
                                            disabled={!this.state.firstExpPeriodOption === "cfpExpiryPeriodOnDate"}
                                            name="fpExpiryPeriodOnDate"
                                            value={this.state.user.fpExpiryPeriodOnDate}
                                            className="my-custom-datepicker-component specificdatewidth pull-left"
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_SubPassExpPeriod' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8} >
                                    <Form.Group controlId="formUseGlobalDefault">
                                        <Form.Check
                                            type="radio"
                                            label="Use Global Default"
                                            name="subseqexpperiod"
                                            
                                            value="spExpiryPeriodUseDefault"
                                             
                                            checked={this.state.subSeqExpPeriodOption === "spExpiryPeriodUseDefault"}
                                            onChange={this.onSubSeqExpRadioChangeValue}
                                            aria-label='default'
                                         />
                                    </Form.Group>
                                    <Form.Group className="formEverySpecificDaysContainer">
                                        <Form.Check
                                            type="radio"
                                            id="formEverySpecificDays"
                                            for='txtEverySpecificDays'
                                             
                                            label="Every"
                                            value="cspExpiryPeriodInDays"
                                            name="subseqexpperiod"
                                            checked={this.state.subSeqExpPeriodOption === "cspExpiryPeriodInDays"}
                                            onChange={this.onSubSeqExpRadioChangeValue}
                                            aria-label='EveryDay'
                                         
                                        />
                                        <div className="subseqtxtcontainer">
                                        <Form.Control
                                            className="txtwidth "
                                            type="text"
                                            id="txtEverySpecificDays"
                                            name="spExpiryPeriodInDays"
                                            value={this.state.user.spExpiryPeriodInDays}
                                            disabled={!(this.state.subSeqExpPeriodOption === "cspExpiryPeriodInDays")}
                                            placeholder="0"
                                            onChange={this.handleChange}
                                            aria-label='InDays'
                                        />
                                        Days
                                        </div>
                                     </Form.Group>
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row>

                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                        <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="mobile">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Mobile' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}  
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="mobile"
                                        type="number"
                                        className="form-control"
                                        controlId="mobile"
                                        name="mobile"
                                        onChange={this.handleChange}
                                        value={this.state.user.mobile}
                                        required
                                    >
                                    </Form.Control>
                                </Col>
                            </Col>

                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmemail">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Email' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmemail"
                                        type="text"
                                        name="email"
                                        placeholder="email"
                                        value={this.state.user.email}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="formfirstName">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_FirstName' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="formfirstName"
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                        onChange={this.handleChange}
                                        value={this.state.user.firstName}
                                        required
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="formlastname">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_LastName' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="formlastname"
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                        onChange={this.handleChange}
                                        value={this.state.user.lastName}
                                        required
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for="formjobtitle">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_JobTitle' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="formjobtitle"
                                        type="text"
                                        name="jobTitle"
                                        placeholder="Job Title"
                                        onChange={this.handleChange}
                                        value={this.state.user.jobTitle}                                       
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for="frmpayplan">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_PayPlan' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmpayplan"
                                        as="select"
                                        name="payPlan"
                                        placeholder="Pay Plan"
                                        onChange={this.handleChange}
                                        value={this.state.user.payPlan}
                                        required
                                    >
                                        <option value="Select Pay Plan">Select Pay Plan</option>
                                        <option value="HOURLY">Hourly</option>
                                        <option value="GENERAL_SCHEDULE">General Schedule</option>
                                        <option value="OTHER">Other</option>
                                    </Form.Control>
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row>

                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label  for="formgrade">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Grade' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="formgrade"
                                        as="select"
                                        name="grade"
                                        placeholder="Grade"
                                        onChange={this.handleChange}
                                        value={this.state.user.grade}
                                        required
                                    >
                                        <option value="Select Grade">Select Grade</option>
                                        <option value="GS2">GS2</option>
                                        <option value="GS3">GS3</option>
                                        <option value="GS4">GS4</option>
                                        <option value="GS5">GS5</option>
                                        <option value="GS6">GS6</option>
                                        <option value="GS7">GS7</option>
                                        <option value="GS8">GS8</option>
                                        <option value="GS9">GS9</option>
                                        <option value="GS10">GS10</option>
                                        <option value="GS11">GS11</option>
                                        <option value="GS12">GS12</option>
                                        <option value="GS13">GS13</option>
                                        <option value="GS14">GS14</option>
                                        <option value="GS15">GS15</option>
                                        <option value="SES">SES</option>
                                    </Form.Control>
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for="formstep">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Step' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}    
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="formstep"
                                        as="select"
                                        name="step"
                                        placeholder="Step"
                                        onChange={this.handleChange}
                                        value={this.state.user.step}
                                        
                                    >
                                        <option value="">Select Step</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Form.Control>
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row>

                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="formoffice">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_OrgName' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                {/* <Col
                                    md={8}
                                    style={{
                                        textDecoration: "underline",
                                        fontWeight: "600"
                                    }}
                                >
                                    <a href="#"

                                    //onClick={this.handleShowOfficeModal}
                                    >
                                        {state.selectedParentOffice} -
                                        {state.selectedOffice}
                                    </a>
                                </Col> */}
                                <Col>

                                <button
                                    id="Org-btn"
                                    className="org-select"
                                    type="button"
                                    onClick={this.handleBtnOrgSelectSubmit.bind('org')}
                                >
                                {this.state.orgdisplayname}
                                </button>
                                <div id='orglist' className={this.state.show?'showorglist':'hideorglist'} style={{ height: 600,width:475 }}>
                               
                                
                                <SortableTree
                                    treeData={this.state.treeData}
                                    onChange={treeData => this.setState({ treeData })}
                                    generateNodeProps={({ node, path }) => ({
                                        buttons: [
                                            <a className="title" aria-label="Organization Name">
                                                {this.truncate(node)}
                                            </a>,
                                          
                                            <Button variant="link" onClick={() => this.handleSelectEvent(node)} > Select Org </Button>,
                                             
                                        ]
                                    })}
                                />
                                
                               
                                 </div>
                                {/* <Form.Control
                                            as="select"
                                            id="org"
                                            controlId="org"
                                            name="org"
                                            
                                            required
                                        >
                                            <option value="Select Org">Select Org</option>
                                            <option value="Org1">Org1</option>
                                            <option value="Org2">Org2</option>
                                        </Form.Control> */}
                                        </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for="formaddresstemplate">
                                        Address Template
                  </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        as="select"
                                        id="formaddresstemplate"
                                        controlId="addresstemplate"
                                        name="addressTemplate"
                                        
                                        onChange={this.handleChange}
                                        value={this.state.user.addressTemplate}
                                        required
                                    >
                                        <option value="USA">USA</option>
                                    </Form.Control>
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row>

                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="formaddress1">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Address1' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}    
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="formaddress1"
                                        type="text"
                                        name="address1"
                                        placeholder="Address 1"
                                        onChange={this.handleChange}
                                        value={this.state.user.address1}
                                        required
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for="formaddress2">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Address2' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="formaddress2"
                                        type="text"
                                        name="address2"
                                        placeholder="Address 2"
                                        onChange={this.handleChange}
                                        value={this.state.user.address2}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row>

                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="countryname">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Country' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        as="select"
                                        id="countryname"
                                        className="form-control"
                                        controlId="countryname"
                                        name="country"
                                        onChange={this.handleChange}
                                        value={this.state.user.country}

                                        placeholder="Select Country"
                                        required
                                    >
                                        {this.state.CountryList &&
                                            this.state.CountryList.length > 0 &&
                                            this.state.CountryList.map(country => {
                                                return <option value={country.code}> {country.Countryname}</option>;
                                            })}    

                                    </Form.Control>
                                </Col>
                            </Col>

                            <Col md={6} className="row">
                               
                                <Col md={4}>
                                        <Form.Label className="required" for="formstate">
                                        {Labels.filter(lbl => lbl.keyPair=='UserSetup_State' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                        ))}
                                        </Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Control
                                            as="select"
                                            id="formstate"
                                            controlId="state"
                                            name="state"
                                            value={this.state.user.state}
                                            onChange={this.handleChange}
                                            required
                                        //value={currentstatename}
                                        //disabled={isusacountry}
                                        >
                                            {this.state.StateList &&
                                                this.state.StateList.length > 0 &&
                                                this.state.StateList.map(stat => {
                                                    return <option value={stat.state}> {stat.state_name}</option>;
                                                })}
                                        </Form.Control>
                                    </Col>
                            </Col>
                        </Row>
                    </Form.Row>

                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="formcity">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_City' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                <Form.Control
                                        id="formcity"
                                        as="select"
                                        name="city"
                                        placeholder="Select City"
                                        value={this.state.user.city}
                                        onChange={this.handleChange}
                                        required
                                    >
                                        {this.state.CityList &&
                                            this.state.CityList.length > 0 &&
                                            this.state.CityList.map((cty,index) => {
                                                return <option data-index={JSON.stringify(cty)} value={cty.city_pk}> {cty.city}  {cty.zipcode}</option>;
                                            })}   

                                    </Form.Control>
                                </Col>
                                
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="formzip">
                                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_Zip' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="formzip"
                                        type="text"
                                        className="width50p pull-left"
                                        name="zipCode"
                                        placeholder="ZipCode"
                                        onChange={this.handleChange}
                                        value={this.state.user.zipCode}                                       
                                        
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row> 
                   
                    <div className="float-right mt-10 mb-30"> 
                   
                    <Button type="submit" variant="primary" aria-label='Save' disabled={savedisablestatus} onClick= {this.handleSaveClick}>               
                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_SaveBtn' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}                      
                    </Button>                  
                    <Button   variant="secondary" className="ml-10" onClick={this.handleCancelClick} aria-label='Cancel'>
                    {Labels.filter(lbl => lbl.keyPair=='UserSetup_CancelBtn' && lbl.pageName=='UserSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                    </Button>                    
                    </div> 
                   
                    </Form>
               
                
                
            </div>
        </>
    );
}
}
 