import React, { useState } from 'react';
import { Button, Modal, Table, Card, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import { ToastContainer, toast} from 'react-toastify';
import axios from "axios";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import "react-table/react-table.css"; 
import _ from "lodash";
import * as moment from "moment";
import "moment/min/moment-with-locales";
import { map,omit } from 'lodash/fp';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
 
//const configData= require('config');
const configData = require( '../../config.json');
var ExcelDateToJSDate=(serial)=> {
   
   console.log(serial);
   var dtvalue = serial.toString().split('-')
   if(dtvalue.length>=3)
   {
     return serial;
   }
   else if (serial == null || serial == 'undefined')
   {
     return " ";
   }
   else{

   console.log(serial)
  var utc_days  = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;                                        
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;
  console.log(date_info.getMonth()+'-'+ date_info.getDate()+'-'+ date_info.getFullYear())
  //return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  return (date_info.getMonth()+ 1)+'-'+ date_info.getDate()+'-'+ date_info.getFullYear();
  }

}
var convertarray=(array)=>{
  
  var first = array[0];//.rows[0].join('|')
          var headers = first;//.split('|');
           
          var jsonData = [];
         
          for ( var i = 1, length = array.length; i < length; i++ )
          {
        
            var myRow = array[i];//.join('|');
            var row = myRow;//.split('|');
            // row.push('total');
            var data = {};
            for ( var x = 0; x < row.length; x++ )
            {
              if(headers[x]=="startdate" ||headers[x]=="enddate" ||headers[x]=="start_date" ||headers[x]=="end_date" ||headers[x]=="rolestartdate"||headers[x]=="roleenddate"){
                
                data[headers[x]] = row[x].toString().length>0? ExcelDateToJSDate(row[x]):'';
              }
              else{
                data[headers[x]] = row[x];
              }
            }
           
            jsonData.push(data);
           // array.rows[i][10]=1000;
          }
          var total=0;
          for (i = 0; i < jsonData.length; i++) {  //loop through the array
            total += parseFloat( jsonData[i].activitylevel);  //Do the math!
          }
          return jsonData;
}
const fileType =
"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const fileExtension = ".xlsx";
 
export default class FileTypeLoader extends React.Component  {
    constructor(...args) {
        super(...args)
        
         
        this.state={
            isOpen: false,
            dataLoaded: false,
            isFormInvalid: false,
            rows: null,
            cols: null,
            userrole:{create:''},
            show: false ,
            arlist:[],
            originalData:null,
            filetypemanagers:[],
            filenamestatus:null,
            filecolumnlength:null,
            filecolumnnamestatus:null,
            filecolumntypestatus:null
          }
         
        this.fileHandler = this.fileHandler.bind(this);
        this.toggle = this.toggle.bind(this);
        this.openFileBrowser = this.openFileBrowser.bind(this);
        this.renderFile = this.renderFile.bind(this);
        this.fileInput = React.createRef();
        this.setState({Labels:[],  isFetching: true});
        
       // this.fetchLabelMessagesAPI();
    }
     componentDidMount() {
      
      this.fetchAllFileTypeManagerListFetchAPI();
      this.fetchRolesWithFetchAPI();
    }

    renderFile = (fileObj) => {
      
      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
        if(err){
          console.log(err);            
        }
        else {
            
            this.convertToJSON(resp)
         
        }
      }); 
  }

  fileHandler = (event) => {    
    if(event.target.files.length){
      let fileObj = event.target.files[0];
      let fileName = fileObj.name;
      var userlist=[]
      if(this.state.tablename=="User"){
        if(fileName.slice(fileName.lastIndexOf('.')+1) === "xlsx"){
          this.setState({
            uploadedFileName: fileName,
            isFormInvalid: false
          });
          this.renderFile(fileObj)
        }    
        else{
          this.setState({
            isFormInvalid: true,
            uploadedFileName: ""
          })
        }
      var files = event.target.files, f = files[0];
      var reader = new FileReader();
      var self = this;
      reader.onload = function (e) {
         
          var data = e.target.result;
          let readedData = XLSX.read(data, {type: 'binary'});
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];
  
          /* Convert array to json*/
          var array = XLSX.utils.sheet_to_json(ws, {header:1});

          const prirolewsname = readedData.SheetNames[1];
          const prirolews = readedData.Sheets[prirolewsname];
  
          /* Convert array to json*/
          var prirolearray = XLSX.utils.sheet_to_json(prirolews, {header:1});
          var prirolelist=convertarray(prirolearray);

          const rolewsname = readedData.SheetNames[1];
          const rolews = readedData.Sheets[rolewsname];
  
          /* Convert array to json*/
          var rolearray = XLSX.utils.sheet_to_json(rolews, {header:1});
          var rolelist=convertarray(rolearray);
          
          console.log(array);
          userlist=convertarray(array);
          var first = array[0];//.rows[0].join('|')
          var headers = first;//.split('|');
           
          var jsonData = [];
          console.log(self.state);
         // self.state.arlist=userlist;
         var userroles=[];
        
        //find the user role and primary role details

          for ( var i = 0; i<=prirolelist.length;  i++ )
          {
           if(prirolelist[i]!=undefined && prirolelist[i].email!=undefined){
            var userrole={};
            var userrl =_.findIndex(userroles, function(obj) {
              return obj.email === prirolelist[i].email;
            });
            
            var roleindex =_.findIndex(self.state.roles, function(obj) {
              return obj.name === prirolelist[i].role_label;
            });
            var primaryroleindex =_.findIndex(self.state.roles, function(obj) {
              return obj.name === prirolelist[i].primaryrole_label;
            });
            
            if(userrl!=undefined && userrl!=-1 ){
              var roleid=roleindex !=-1?self.state.roles[roleindex]._id:'';
              userroles[userrl].role.push({rolename:{label:prirolelist[i].role_label,value:roleid},
                rolestartdate:prirolelist[i].rolestartdate,roleenddate:prirolelist[i].roleenddate,rolestatus:prirolelist[i].rolestatus});
            }else{
              userrole.email=prirolelist[i].email;
              var priroleid=primaryroleindex !=-1?self.state.roles[primaryroleindex]._id:'';
              userrole.primaryrole={label:prirolelist[i].primaryrole_label,value:priroleid};

              userrole.role=[];
              var roleid=roleindex !=-1?self.state.roles[roleindex]._id:'';
             
              userrole.role.push({rolename:{label:prirolelist[i].role_label,value:roleid},rolestartdate:prirolelist[i].rolestartdate,roleenddate:prirolelist[i].roleenddate,rolestatus:prirolelist[i].rolestatus});
              userroles.push(userrole);
            }
          }
          }
          for ( var j = 0; j<userroles.length;  j++ )
          {
            var userrl =_.findIndex(userlist, function(obj) {
              return obj.email === userroles[j].email;
            });
            
             
            if(userrl!=undefined && userrl!=-1){
            userlist[userrl].role=userroles[j].role;
            userlist[userrl].primaryrole=userroles[j].primaryrole;
            }
          }
          console.log(userlist);
          self.setState({
            dataLoaded: true,
            
            arlist:userlist
          });
   
      };
      reader.readAsBinaryString(f)
      console.log(userlist);
      
    }
    else if(this.state.tablename=="Role"){
       
      if(fileName.slice(fileName.lastIndexOf('.')+1) === "xlsx"){
        this.setState({
          uploadedFileName: fileName,
          isFormInvalid: false
        });
        this.renderFile(fileObj)
      }    
      else{
        this.setState({
          isFormInvalid: true,
          uploadedFileName: ""
        })
      }
      var files = event.target.files, f = files[0];
      var reader = new FileReader();
      var self = this;
      reader.onload = function (e) {
        
          var data = e.target.result;
          let readedData = XLSX.read(data, {type: 'binary'});
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];
        
          /* Convert array to json*/
          var array = XLSX.utils.sheet_to_json(ws, {header:1});

          const prirolewsname = readedData.SheetNames[0];
          const prirolews = readedData.Sheets[prirolewsname];
  
          /* Convert array to json*/
          var prirolearray = XLSX.utils.sheet_to_json(prirolews, {header:1});
          //var prirolelist=convertarray(prirolearray);

          const rolewsname = readedData.SheetNames[1];
          const rolews = readedData.Sheets[rolewsname];
  
          /* Convert array to json*/
          var rolearray = XLSX.utils.sheet_to_json(rolews, {header:1});
          var rolelist=convertarray(rolearray);
           
          userlist=convertarray(array);
          var first = array[0];//.rows[0].join('|')
          var headers = first;//.split('|');
           
          var jsonData = [];
          
          console.log(self.state.location);
         // self.state.arlist=userlist;
         var roles=[];
        
        //find the user role and primary role details

          for ( var i = 0; i<=rolelist.length;  i++ )
          {
           if(rolelist[i]!=undefined && rolelist[i].rolename!=undefined){
            var role={};
            var userrl =_.findIndex(roles, function(obj) {
              return obj.name === rolelist[i].rolename;
            });
            var locationindex =_.findIndex(self.state.location, function(obj) {
              return obj.location === rolelist[i].location;
            });
            if(userrl!=undefined && userrl!=-1){
              roles[userrl].location.push({_id:self.state.location[locationindex]._id,location:rolelist[i].location,create:rolelist[i].create,view:rolelist[i].view,edit:rolelist[i].edit,delete:rolelist[i].delete,email:rolelist[i].email,fileUpload:rolelist[i].fileUpload,dataUpload:rolelist[i].dataUpload,fileDownload:rolelist[i].fileDownload,others:rolelist[i].others,all:rolelist[i].all });
            }else{
              role.name=rolelist[i].rolename;
              role.location=[];
              role.location.push({_id:self.state.location[locationindex]._id,location:rolelist[i].location,create:rolelist[i].create,view:rolelist[i].view,edit:rolelist[i].edit,delete:rolelist[i].delete,email:rolelist[i].email,fileUpload:rolelist[i].fileUpload,dataUpload:rolelist[i].dataUpload,fileDownload:rolelist[i].fileDownload,others:rolelist[i].others,all:rolelist[i].all})
              roles.push(role);
            }
          }
          }
          for ( var j = 0; j<roles.length;  j++ )
          {
            var userrl =_.findIndex(userlist, function(obj) {
              return obj.name === roles[j].name;
            });
             
            if(userrl!=undefined && userrl!=-1){
            userlist[userrl].location=roles[j].location;
            
            }
          }
          console.log(userlist);
          
          
          self.setState({
            dataLoaded: true,
             
            arlist:userlist
          });
   
      };
      reader.readAsBinaryString(f)
      console.log(userlist);
      
    }else{
      //check for file extension and pass only if it is .xlsx and display error message otherwise
      if(fileName.slice(fileName.lastIndexOf('.')+1) === "xlsx"){
        this.setState({
          uploadedFileName: fileName,
          isFormInvalid: false
        });
        this.renderFile(fileObj)
      }    
      else{
        this.setState({
          isFormInvalid: true,
          uploadedFileName: ""
        })
      }
    }
    }               
  }
  convertToArray=(array)=>{
    var first = array[0];//.rows[0].join('|')
          var headers = first;//.split('|');
         
          var jsonData = [];

          for ( var i = 1, length = array.length; i < length; i++ )
          {
        
            var myRow = array[i];//.join('|');
            var row = myRow;//.split('|');
            // row.push('total');
            var data = {};
            for ( var x = 0; x < row.length; x++ )
            {
              if(headers[x]=="startdate" ||headers[x]=="enddate" ||headers[x]=="start_date" ||headers[x]=="end_date" ){
                data[headers[x]] = row[x].length>0? this.ExcelDateToJSDate(row[x]):'';
              }
              else{
                data[headers[x]] = row[x];
              }
            }
           
            jsonData.push(data);
           // array.rows[i][10]=1000;
          }
          var total=0;
          for (i = 0; i < jsonData.length; i++) {  //loop through the array
            total += parseFloat( jsonData[i].activitylevel);  //Do the math!
          }
          return jsonData;
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  openFileBrowser = () => {
    this.fileInput.current.click();
  }

  convertToJSON=(array)=> {
     
    var first = array.rows[0].join('|')
    var headers = first.split('|');
    this.state.filecolumnlength=' ';
    this.state.filecolumnnamestatus='';
    var jsonData = [];
    console.log(this.state.filetypemanagers);
    console.log(this.state.uploadedFileName);
    var filename=this.state.uploadedFileName.split('.');
    var filelength=filename[0].length;
    let resname = _.truncate(
      filename[0], {
         'length': filelength-9 ,
         'omission': ''
       }
    );
    console.log(resname);
    var fileexists =_.findIndex(this.state.filetypemanagers, function(obj) {
      return obj.file_type_name === resname;
    });
    if(fileexists>-1){
      //file name match
      this.state.filenamestatus='Success !';
      //colunm count match
      var columncountlist=this.state.filetypemanagers[fileexists];
       
      if(columncountlist.data.length==headers.length){
        this.state.filecolumnlength='Success !';
        var columnnameflag=0;
        for ( var i = 0; i < columncountlist.data.length; i++ )
        {
          if(columncountlist.data[i].column_name!=undefined && _.lowerCase(columncountlist.data[i].column_name).trim()== _.lowerCase(headers[i]).trim()){
            columnnameflag=1;
          }
          else{
            columnnameflag=0;
            break;
          }
        }
        if(columnnameflag==1){
          //column names match

          this.state.filecolumnnamestatus='Success !';

          //find the data type of each row from excel sheet
          
          for ( var i = 1, length = array.rows.length; i < length; i++ )
          {
        
            var myRow = array.rows[i].join('|');
            var row = myRow.split('|');
            // row.push('total');
            var data = {};
            for ( var x = 0; x < row.length; x++ )
            {
              var coltype=typeof row[x];
              var test=row[x].toString().toLowerCase();
              var boolvaluelist=["yes","no"];
              var boolvalue =_.findIndex(boolvaluelist, function(obj) {
                return obj === test;
              });
              if(columncountlist.data[x].column_type=='string' && columncountlist.data[x].column_type!==  coltype  ){
                this.state.filecolumntypestatus=headers[x]+' incorrect type';
                break;  
             } 
              else if(columncountlist.data[x].column_type=='integer' && (isNaN(parseFloat(row[x])) || typeof parseInt(row[x])!='number') ){
                  this.state.filecolumntypestatus=headers[x]+' incorrect type';
                  break;  
               } 
              else if(columncountlist.data[x].column_type=='decimal' && typeof parseFloat(row[x])!='number' ){
                this.state.filecolumntypestatus=headers[x]+' incorrect type';
                break;  
             } 
            else if(columncountlist.data[x].column_type=='boolean' &&   boolvalue==-1 ){

              this.state.filecolumntypestatus=headers[x]+' incorrect type';
              break;  
           } 
           else if(columncountlist.data[x].column_type=='date' && coltype !== 'string' ){
            this.state.filecolumntypestatus=headers[x]+' incorrect type';
            break;  
         } 
            }            
          }
          
          if( this.state.filecolumntypestatus ==null || this.state.filecolumntypestatus=='')
          {
            this.state.filecolumntypestatus='Success!';
          }
        }
        else{
          this.state.filecolumnnamestatus='Column header names not matched';
        }
      }
      else{
        this.state.filecolumnlength='Columnn header count not  matched';
      }

    }
    else{
      this.state.filenamestatus='Failed';
    }

    for ( var i = 1, length = array.rows.length; i < length; i++ )
    {
  
      var myRow = array.rows[i].join('|');
      var row = myRow.split('|');
      // row.push('total');
      var data = {};
      if(columncountlist!=undefined){
      for ( var x = 0; x < row.length; x++ )
      {
                
        var datecolumncheck =_.findIndex(columncountlist.data, function(obj) {
          return obj.column_name === headers[x] && obj.column_type==='date';
        });
        if(datecolumncheck>-1 ){
          data[headers[x]] = row[x].length>0? ExcelDateToJSDate(row[x]):'';
          array.rows[i][x]= data[headers[x]];
        }
        else{
          data[headers[x]] = row[x];
        }
      }
      jsonData.push(data);
    }
    else{
      this.state.filecolumnlength='Columnn header count not  matched';
    }

     // array.rows[i]=data;
      //array.rows[i][10]=1000;
    
    }
    //array.rows=jsonData;
    var total=0;
    for (i = 0; i < jsonData.length; i++) {  //loop through the array
      total += parseFloat( jsonData[i].activitylevel);  //Do the math!
  }
    
    
    // array.rows.push({0:"Total",3:total})
 
    this.setState({
      dataLoaded: true,
      cols: array.cols,
      rows: array.rows ,
      arlist:jsonData
    });
    return jsonData;
  };
    bindUserList=()=>{

        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'User';
        });
       
         
         this.setState({...this.state,  userrole: userrl, isFetching: false})
         
    }
    handleCalcClick=()=>{
      
      console.log(this.state.arlist);
    }
    fetchAllFileTypeManagerListFetchAPI = () => {
      
      this.setState({...this.state, isFetching: true});
       
       
      axios
      .get(configData.SERVER_URL+`fincorp_getFileTypeManagerList/`,
       {
          headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*"
          }
      })
      .then(
          res=>  {this.setBindFileTypeManagerList(res.data) }
      )
      .catch(err => {
          console.log("AXIOS ERROR: ", err);
      });
  };

  setBindFileTypeManagerList=(result)=>{
      
      if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
         //result[0].view="Y";
          this.setState({filetypemanagers: result, isFetching: false});
      }
      

  }
  fetchRolesWithFetchAPI = () => {        
    this.setState({...this.state, isFetching: true});
    fetch(configData.SERVER_URL+'fincorp_getRoles')
        .then(response => response.json())
        .then(result => {
             this.bindUserRole(result)
        })
        .catch(e => {
            console.log(e);
            this.setState({...this.state, isFetching: false});
        });
};
bindUserRole=(result)=>{
    
    if(result!='No document(s) found with defined find criteria!' && result.length>0)
    {
        
         this.setState({...this.state,roles: result, isFetching: false})
    }
    else
    {
         
        this.setState({roles: [], isFetching: false})
    }
     
}

    handleSaveClick = (event) => {
        
      console.log(this.state.arlist);
      
      
      if(this.state.arlist==undefined || this.state.arlist.length==0){
          toast.warn("No record exists to upload");
          event.preventDefault();        
      }
             
      // else {
      //   var apipath='';
      //   if(this.state.tablename=="User"){
      //     apipath=`fincorp_insertUserMany`
      //   }
      //   else if(this.state.tablename!=undefined){
      //     apipath=`fincorp_insertTableMany`
      //   }
      //     axios
      //         .post(configData.SERVER_URL+apipath,
              
      //        {'tablename':this.state.tablename,'list': this.state.arlist}
      //         , {
      //             headers: {
      //                 "Content-Type": "application/json;charset=UTF-8",
      //                 "Access-Control-Allow-Origin": "*"
      //             }
      //         })
      //         .then(
      //             res => this.SaveChange(res.data,event)
                  
      //         )
      //         .catch(err => {
      //             console.log("AXIOS ERROR: ", err);
      //         });
      //         event.preventDefault();
      //     }
       
  };

  SaveChange=(result,event)=>{
         
     // this.state.user._id=result;
      console.log(result);
      toast.success("Inserted Successfully");
      setTimeout(()=>this.state.arlist=[], 4000);
     
      
  }
  handleChange = e => {
      
    let { value, name } = e.target;
     
       
         this.setState({...this.state,tablename:value});
        

     
    
};
 

exportToCSV = (fileName) => {

 
    this.fetchDataWithFetchAPI(fileName);
 
};



fetchDataWithFetchAPI = (fileName) => {
  this.setState({...this.state, isFetching: true});
  var url =''
  if (fileName == 'User')
     url=configData.SERVER_URL+'fincorp_getUsers';
   else if (fileName=='Role')
  {
    url=configData.SERVER_URL+'fincorp_getRoles';
  }   
  else if (fileName=='Organization')
  {
    url=configData.SERVER_URL+'fincorp_getOrganization';
  }
  else if (fileName=='ApprovalLimits')
  {
    url=configData.SERVER_URL+'fincorp_getApprovalLimitsList';
  }
   else if (fileName=='ServiceMaster')
  {
    url=configData.SERVER_URL+'fincorp_getServiceMasterList';
  } 
  else if (fileName=='ServiceArea')
  {
    url=configData.SERVER_URL+'fincorp_getServiceAreaList';
  }
  else if (fileName=='ServiceActivity')
  {
    url=configData.SERVER_URL+'fincorp_getServiceActivityList';
  }
  else if (fileName=='ServiceTask')
  {
    url=configData.SERVER_URL+'fincorp_getServiceTaskList';
  }
  else if (fileName=='ServiceTaskDetail')
  {
    url=configData.SERVER_URL+'fincorp_getServiceTaskDetailList';
  }
  else if (fileName=='CostDriver')
  {
    url=configData.SERVER_URL+'fincorp_getCostDriverList';
  }
  fetch(url)
      .then(response => response.json())
      .then(result => {
          this.bindDataList(result,fileName)
      })
      .catch(e => {
          console.log(e);
          this.setState({...this.state, isFetching: false});
      });
};

bindDataList=(result,fileName)=>{

   
  if(result!='No document(s) found with defined find criteria!' && result.length>0){
   var results = _.map(result,function(res){
      return _.omit(res,['_id']);
    })
    console.log(results);
   this.setState({...this.state,originalData:results})
   const ws = XLSX.utils.json_to_sheet(results,{dateNF:"YYYY-MM-DD",cellDates:true,raw:true});
   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
   const data = new Blob([excelBuffer], { type: fileType });
   FileSaver.saveAs(data, fileName + fileExtension);
  }
   
}


 
  render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let { data, columns, searchInput } = this.state;
        return (
        <>
            <div className="container userinfo-container adminlist" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                     Admin List
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">
                    File Type Management  
                    </span>
                </div>
                <div className="fullwidth">
                    <div className="float-left">
                    
                        <Link to="/user" className= {this.state.userrole.create=='Y'?"btn btn-primary":"hidden"}>
                        {Labels.filter(lbl => lbl.keyPair=='UserList_AddBtn' && lbl.pageName=='UserList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                        </Link>
                    </div>
                                   
                </div>
                
               <div className="fullwidth row m0">
                   <div className="col-md-12 mt-10 p0">
                       <div className="float-left">
                            <input type="file" aria-label="File Import/Export" hidden onChange={this.fileHandler.bind(this)} ref={this.fileInput} 
                            onClick={(event)=> { event.target.value = null }} style={{"padding":"10px"}} />  
                             <Button color="info" style={{color: "white",margiRight:"10",zIndex: 0}} onClick={this.openFileBrowser.bind(this)}><i className="cui-file"></i> Browse File</Button></div>
                          {/* <div className="float-left">
                          <input type="text"   value={this.state.uploadedFileName} readOnly invalid={this.state.isFormInvalid} />
                             <span className="ml-20">{this.state.uploadedFileName}</span>
                             <Button className="ml-10" onClick={this.handleSaveClick.bind(this)}>Validator</Button>
                              </div>   */}
                              {/* <div className="float-right">
                              <button onClick={(e) => this.exportToCSV(this.state.tablename)} className='btn btn-primary mr-10'>Export</button>
                              <Button onClick={this.handleCalcClick.bind(this)}>Calculate</Button>
                               
                                  </div>    */}
                   </div>
                </div>   
                <div className="fullwidth row m0">
                   <div className="col-md-12 mt-10 p0">
                   <div className="text-center"><span className="ml-20">{this.state.uploadedFileName}</span></div>
                   <div className="col-md-12 mt-10 p0">
                   <div className="text-center"> <span>FileName Status :</span> <span className="ml-20"> {this.state.filenamestatus}</span></div>
                   </div>
                   <div className="col-md-12 mt-10 p0">
                   <div className="text-center"> <span>Number Of Columns Status :</span> <span className="ml-20"> {this.state.filecolumnlength}</span></div>
                   </div>
                   <div className="col-md-12 mt-10 p0">
                   <div className="text-center"> <span>Column Name Status :</span> <span className="ml-20"> {this.state.filecolumnnamestatus}</span></div>
                   </div>
                   <div className="col-md-12 mt-10 p0">
                   <div className="text-center"> <span>Column Type and Content Status :</span> <span className="ml-20"> {this.state.filecolumntypestatus}</span></div>
                   </div>
                   {this.state.dataLoaded &&                    
                    <div style={{"overflow":"scroll"}}>
                     
                        <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                                            
                    </div>}    
                   </div>
                </div>  
                         
            </div>
        </>
    );
}
}

 