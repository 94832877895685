import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from 'react-select';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker, DatePickerInput } from "rc-datepicker";
import * as moment from "moment";
import "moment/min/moment-with-locales";
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";

//const configData= require('config');
const configData = require( '../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
   
}

export default class ServicesActivityDetailList extends React.Component  {
    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
            serviceTaskDetailName:'',
            serviceTaskDetailDesc:'',
            serviceTaskDetailCode:'',
            predecessor:'',
            serviceTaskDetailType:'',
            level:'',
            status:'',
            start_date:'',
            end_date:'',
            _id:undefined,
            servicetaskdetailfiles: [],
            columns:[],
            originalservicetaskdetailfiles:[],
            searchInput: "",
            serviceTaskList:[],
            filteredData: [],
            chkisactive:true,           
            userrole:{create:''}
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

    }
     componentDidMount() {
     
        this.fetchServiceTaskDetailListAPI();
        this.getColumns();
         //load service task records 
        this.fetchServiceTaskListAPI();
       
    }    

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Services';
        });
        let columns = [
          {
            Header: "Detail Name",
            accessor: "serviceTaskDetailName",
            sortable: true,
            show: true,
            displayValue: " Service Name" 
          },
          {
            Header: "Predecessor",
            accessor: "predecessor",
            sortable: true,
            show: true,
            displayValue: "predecessor",
            Cell:row=>(<span>{row.original.predecessor}</span>)
          },
          {
            Header: "Detail Description",
            accessor: "serviceTaskDetailDesc",
            sortable: true,
            show: true,
            displayValue: "Service Desc",
            Cell:row=>(<span>{row.original.serviceTaskDetailDesc.length > 30 ?
                `${row.original.serviceTaskDetailDesc.substring(0, 30)}...` : row.original.serviceTaskDetailDesc} </span>)
          },
          {
            Header: "Detail Code",
            accessor: "serviceTaskDetailCode",
            sortable: true,
            show: true,
            displayValue: " serviceCode "
          },
          {
            Header: "Detail Type",
            accessor: "serviceTaskDetailType",
            sortable: true,
            show: true,
            displayValue: " Service Type"
          },
          {
            Header: "Level",
            accessor: "level",
            sortable: true,
            show: true,
            displayValue: " level ",
            maxWidth: 100,
            minWidth: 50,
            width: 50,
            Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>
          },
          
          
          {
            Header: "Start Date",
            accessor: "start_date",
            sortable: true,
            show: true,
            displayValue: "start_date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          
          {
            Header: "End Date",
            accessor: "end_date",
            sortable: true,
            show: true,
            displayValue: "end_date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "status",
            maxWidth: 100,
            minWidth: 56,
            width: 56,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };

    fetchServiceTaskDetailListAPI = () => {
       // this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getServiceTaskDetailList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setServiceTaskDetailList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setServiceTaskDetailList=(result)=>{
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Services';
        });
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            this.setState({servicetaskdetailfiles: result, originalservicetaskdetailfiles:result,userrole: userrl,isFetching: false });
            this.globalSearch();
        }    
        else
         this.setState({...this.state,userrole: userrl, isFetching: false})    
    }

    handleChange = e => {
        let { value, name } = e.target;         
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date
        });
    };
    
    handleAddMember=()=>{
        this.state.serviceTaskDetailName = '';
        this.state.serviceTaskDetailDesc ='';
        this.state.start_date = '';
        this.state.end_date = '';
        this.state.serviceTaskDetailCode = '';
        this.state.predecessor = '';
        this.state.serviceTaskDetailType = '';
        this.state.level = '';
        this.state.status = '';

        this.state._id = undefined;
        this.setState({show: true, editshow:false });
    }
    handleClose=()=> {
        this.state.serviceTaskDetailName = '';
        this.state.serviceTaskDetailDesc ='';
        this.state.start_date = '';
        this.state.end_date = '';
        this.state.serviceTaskDetailCode = '';
        this.state.predecessor = '';
        this.state.serviceTaskDetailType = '';
        this.state.level = '';
        this.state.status = '';

        this.state._id = undefined;
        this.setState({ show: false });
    }
    
    handleClick = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

    handleEditClick = (row) => {
        this.state.serviceTaskDetailName = row.original.serviceTaskDetailName;
        this.state.serviceTaskDetailDesc = row.original.serviceTaskDetailDesc;
        this.state.start_date = row.original.start_date;
        this.state.end_date = row.original.end_date;
        this.state.serviceTaskDetailCode = row.original.serviceTaskDetailCode;
        var predecessorval=undefined;
        
        _.forEach(this.state.serviceTaskList, function(value) {
          
         if(row.original.predecessor==value.serviceTaskName){
            predecessorval=value;
         }
     });
     this.state.predecessor ={'label':predecessorval.serviceTaskName,'value':predecessorval._id};

        //this.state.predecessor = row.original.predecessor;
        this.state.serviceTaskDetailType = row.original.serviceTaskDetailType;
        this.state.level = row.original.level;
        this.state.status = row.original.status;

        this.state._id = row.original._id;
        this.setState({ show: true, editshow: false});
       // this.props.history.push("/Item?id=" + row.id);
    };

    editImgFormatter = (cell, row) => {              
        return (
            <a
                className="editbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a>
        );
    }

     deleteImgFormatter = (cell, row) => {              
        return (
            <a
                className="deletebutton"
                aria-label="Delete"
                onClick={() => this.handleDeleteClick(row)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a>
        );
    }

    handleDeleteClick = (row,event) => {
        if(window.confirm('Are you sure to delete this record?')){ 
        //delete
        if (row.original._id!= undefined) {
            axios
                .post(configData.SERVER_URL + `fincorp_deleteServiceTaskDetail`,

                    { '_id': row.original._id }
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                .then(
                    res => this.reloadDataGrid(res.data)
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
        else {
            //error            
            toast.warn("Service Task Detail Not Deleted");
        }
    }
    }
    reloadDataGrid = (res) => {

        toast.success("Service Task  Detail Deleted Successfully");
        setTimeout(this.fetchServiceTaskDetailListAPI(), 4000);
    }



    onStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                start_date: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                end_date: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };

    handleSaveClick = (event) => {
        
        const regServiceCode= /^[a-zA-Z0-9.-]*$/;
        
        if (this.state.serviceTaskDetailName == undefined || this.state.serviceTaskDetailName == "" || this.state.serviceTaskDetailName.length == 0) {
           toast.warn("Enter service Task Detail Name");
           event.preventDefault();
       }       
       else if (this.state.serviceTaskDetailDesc == undefined || this.state.serviceTaskDetailDesc == "") {
           toast.warn("Enter Service Task Detail Desc");
           event.preventDefault();
       }
       else if (this.state.serviceTaskDetailCode == undefined || this.state.serviceTaskDetailCode == "") {
        toast.warn("Enter Service Task Detail Code");
        event.preventDefault();
    }
    else if (regServiceCode.test(this.state.serviceTaskDetailCode) === false){
        toast.warn("Service Task Detail Code Must Contain Only Alphanumeric");
        event.preventDefault();        
    }
    else if (this.state.serviceTaskDetailType == undefined || this.state.serviceTaskDetailType == "") {
        toast.warn("Choose Service Task Detail Type");
        event.preventDefault();
    }
    else if (this.state.level == undefined || this.state.level == "") {
        toast.warn("Enter Service Task Detail Level");
        event.preventDefault();
    }
    else if (this.state.predecessor == undefined || this.state.predecessor == "") {
        toast.warn("Enter Service Task Detail Predecessor");
        event.preventDefault();
    }
       else if (this.state.start_date == undefined || this.state.start_date == "" || this.state.start_date == "Invalid date") {
           toast.warn("Enter StartDate");
           event.preventDefault();


       }  
       else if (Date.parse(this.state.start_date) > Date.parse(this.state.end_date)) {
           toast.warn("End Date should be greater than the StartDate");
           event.preventDefault();


       }     
       else if (this.state.status == undefined || this.state.status == "") {
           toast.warn("Choose Status");
           event.preventDefault();


       }
       else {

           if (this.state._id!= undefined) {
            this.state.start_date=  this.state.start_date == "Invalid date"?null:this.state.start_date;
            this.state.end_date=  this.state.end_date == "Invalid date"?null:this.state.end_date;
            if(window.confirm('Do you want to save changes?')){ 

               axios
                   .post(configData.SERVER_URL + `fincorp_updateServiceTaskDetail`,

                       { '_id': this.state._id, 'serviceTaskDetailName': this.state.serviceTaskDetailName, 
                       'serviceTaskDetailDesc': this.state.serviceTaskDetailDesc,
                        'serviceTaskDetailCode': this.state.serviceTaskDetailCode, 
                        'predecessor': this.state.predecessor.label,
                        'serviceTaskDetailType':this.state.serviceTaskDetailType,
                        'level':this.state.level,
                         'start_date': this.state.start_date, 
                         'end_date': this.state.end_date,
                          'status': this.state.status }
                       , {
                           headers: {
                               "Content-Type": "application/json;charset=UTF-8",
                               "Access-Control-Allow-Origin": "*"
                           }
                       })
                   .then(
                       res =>  this.UpdateChanges(res.data) 


                   )
                   .catch(err => {
                       console.log("AXIOS ERROR: ", err);
                   });
                }

           }
           else {
               
               axios
                   .post(configData.SERVER_URL + `fincorp_insertServiceTaskDetail`,

                       { 'serviceTaskDetailName': this.state.serviceTaskDetailName, 
                       'serviceTaskDetailDesc': this.state.serviceTaskDetailDesc,
                       'serviceTaskDetailCode': this.state.serviceTaskDetailCode, 
                       'predecessor': this.state.predecessor.label,
                       'serviceTaskDetailType':this.state.serviceTaskDetailType,
                       'level':this.state.level,
                       'start_date': this.state.start_date, 
                       'end_date': this.state.end_date,
                       'status': this.state.status}
                       , {
                           headers: {
                               "Content-Type": "application/json;charset=UTF-8",
                               "Access-Control-Allow-Origin": "*"
                           }
                       })
                   .then(
                       res => this.SaveChanges(res.data) 
                   )
                   .catch(err => {
                       console.log("AXIOS ERROR: ", err);
                   });

           }

       }
   };
   SaveChanges = (res) => {
    if(res.MessageKey!=undefined){
        toast.warn(res.Message);
    }
    else{
        toast.success("Service Task Detail Inserted Successfully");
        setTimeout( this.fetchServiceTaskDetailListAPI(),4000);
        this.setState({ show: false, editshow: false });
         }
   }
   UpdateChanges = (res) => {
    if(res.MessageKey!=undefined){
        toast.warn(res.Message);
    }
    else{
       toast.success("Service Task Detail Updated Successfully");
       setTimeout( this.fetchServiceTaskDetailListAPI(),4000);
       this.state.serviceTaskDetailName = '';
       this.state.serviceTaskDetailDesc ='';
       this.state.start_date = '';
       this.state.end_date = '';
       this.state.serviceTaskDetailCode = '';
       this.state.predecessor = '';
       this.state.serviceTaskDetailType = '';
       this.state.level = '';
       this.state.status = '';

       this.state._id = undefined;

       this.setState({ show: false, editshow: false });
    }
  }    
    
    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }       

    }

    handleChange = e => {
        let { value, name } = e.target;         
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date
        });
    };

    handleSelectChangeTask = (selectedOption) => {
        this.setState(prevState => ({
                   ...prevState,  
                   predecessor: selectedOption
             
        }));
        
      };

    handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };

      toggleChange = () => {
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }

      globalActiveSearch = (param) => {
        let { searchInput } = this.state;       
        let filteredData = this.state.originalservicetaskdetailfiles.filter(value => {
            if(param){

                return (
                    (value.serviceTaskDetailName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceTaskDetailDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceTaskDetailCode.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceTaskDetailType.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.level.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.predecessor.toLowerCase().includes(searchInput.toLowerCase())||                    
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                    value.status.toString().includes('Active') 
                   
                );

            }else{
                return (
                    value.serviceTaskDetailName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceTaskDetailDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceTaskDetailCode.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceTaskDetailType.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.level.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.predecessor.toLowerCase().includes(searchInput.toLowerCase())||
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                    value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        });
        this.setState({ servicetaskdetailfiles: filteredData });
      };

      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalservicetaskdetailfiles.filter(value => {
        if(this.state.chkisactive){   
          return (
            (value.serviceTaskDetailName.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.serviceTaskDetailDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.serviceTaskDetailCode.toString().toLowerCase().includes(searchInput.toLowerCase())||
            value.serviceTaskDetailType.toString().toLowerCase().includes(searchInput.toLowerCase())||
            value.level.toString().toLowerCase().includes(searchInput.toLowerCase())||
            value.predecessor.toLowerCase().includes(searchInput.toLowerCase())||
            value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
            ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
            value.status.toString().includes('Active')
            
                );
        }else{
            return (
                value.serviceTaskDetailName.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.serviceTaskDetailDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.serviceTaskDetailCode.toString().toLowerCase().includes(searchInput.toLowerCase())||
                value.serviceTaskDetailType.toString().toLowerCase().includes(searchInput.toLowerCase())||
                value.level.toString().toLowerCase().includes(searchInput.toLowerCase())||
                value.predecessor.toLowerCase().includes(searchInput.toLowerCase())||
                value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
            );  
        }
        });
        this.setState({ servicetaskdetailfiles: filteredData });
      };

      fetchServiceTaskListAPI = () => {
       // this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getServiceTaskList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setServiceTaskList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setServiceTaskList=(result)=>{
        
        if(result.Message=='Invalid Session'){
            this.props.history.push("/login")
        }
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            this.setState({serviceTaskList: result,  isFetching: false});
        }     
    }


    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let { data, columns, searchInput } = this.state; 
        const optionsTaskArray =this.state.serviceTaskList!=null&& this.state.serviceTaskList!=undefined? this.state.serviceTaskList:[]; 
    return (
        <>
            <div className="container userinfo-container" role="main">
                {/* <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/serviceshome">Services Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Services Tasks List
                    </Breadcrumb.Item>
                </Breadcrumb> */}
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetailList_Title' && lbl.pageName=='ServiceTaskDetailList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </span>
                </div>
                <div className="fullwidth">
                    <div className="float-left">
                    <button className={this.state.userrole.create=='Y'?"btn btn-primary  float-left ":"hidden"}  onClick={this.handleAddMember} >  
                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetailList_Addbtn' && lbl.pageName=='ServiceTaskDetailList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </button>
                    </div> 
                    <div className="float-right">
                    <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" aria-label="Status Filter" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetailList_Search' && lbl.pageName=='ServiceTaskDetailList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                        ))}
                        </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />
                        </div>                         
                    </div>
                    </div>                    

                
                <div className="fullwidth row m0">
                 <div className="col-md-12 mt-10 p0">
                     <ReactTable
                    data={this.state.servicetaskdetailfiles}
                    columns={this.state.columns}
                    defaultPageSize={20}
                    className="-striped -highlight fullwidth"
                    />  
                 </div>      
               </div>
                <div className="row col-md-12 m0 p0">
                    {/* <BootstrapTable
                        data={this.state.servicetaskdetailfiles}
                        striped
                        hover
                        condensed
                        pagination={true}
                        insertRow
                        deleteRow
                        search
                        className="servicefileslist"
                        tableHeaderClass="my-header-class"
                        tableBodyClass="my-body-class"
                        containerClass="my-container-class"
                        tableContainerClass="my-table-container-class"
                        headerContainerClass="usersystem-grid-header-container-class"
                        bodyContainerClass="my-body-container-class"
                    >
                        <TableHeaderColumn dataField="serviceTaskDetailName" width="18%" isKey dataSort>
                            Service Tasks Detail Name
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="serviceTaskDetailDesc" width="14%" >
                            Service Tasks Detail Desc
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="serviceTaskDetailCode" width="10%" >
                            Service Tasks Detail Code
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="serviceTaskDetailType" width="10%" >
                            Service Tasks Detail Type
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="level" width="4%" >
                            Level
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="predecessor" width="8%" >
                            Predecessor
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="start_date" width="10%" dataAlign="center">
                            Start Date
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="end_date" width="10%" dataAlign="center">
                            End Date
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="status" width="6%">
                            Status
                        </TableHeaderColumn>
                        <TableHeaderColumn dataAlign="edit" width="5%" dataFormat={this.editImgFormatter} dataAlign="center">
                                Edit
                        </TableHeaderColumn>
                            <TableHeaderColumn dataAlign="delete" width="5%" dataFormat={this.deleteImgFormatter} dataAlign="center">
                                Delete
                        </TableHeaderColumn>
                    </BootstrapTable> */}
                    {/* <div className="row col-md-12">
                    <div className="fullwidth"> 
                    <Link to="/serviceshome" className="btn btn-secondary float-right  ml-10">Cancel</Link>                  
                    <Link to="/view" className="btn btn-info float-right">View</Link>                
                    </div> 
                    </div> */}
                    
                
            </div>
            <div>
                <Modal
                        className="ServicesTaskDetail-modal"
                         show={this.state.show}
                         onHide={this.handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                            {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_Title' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                <span>
                                 {this.state._id==undefined?"Add":"Edit"} {filteredPerson.keyValue}
                                </span>
                            ))}                          
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Form>                   
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmtaskdetailename">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_Name' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmtaskdetailname"
                                        type="text"
                                        name="serviceTaskDetailName"
                                        placeholder="Tasks Name"
                                        disabled={this.state._id!=undefined?true:false}
                                        value={this.state.serviceTaskDetailName}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="frmdescr">
                                        {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_Desc' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))} 
                                </Form.Label>{" "}
                                </Col>
                                <Col md={8}>   
                                <Form.Control
                                            id="frmdescr"
                                            className="desc-width"
                                            as="textarea"
                                            rows="1"
                                            name="serviceTaskDetailDesc"
                                            placeholder="Description"
                                            value={this.state.serviceTaskDetailDesc}
                                            onChange={this.handleChange}
                                             
                                            
                                        />
                                </Col>
                            </Col>


                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">                           
                        <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmtaskdetailcode">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_Code' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmtaskdetailcode"
                                        type="text"
                                        className="form-control"
                                        controlId="subactivitycode"
                                        name="serviceTaskDetailCode"
                                        placeholder="Tasks Code"
                                        onChange={this.handleChange}
                                        disabled={this.state._id!=undefined?true:false}
                                        value={this.state.serviceTaskDetailCode}
                                        required
                                    >
                                    </Form.Control>
                                </Col>
                            </Col>
                            <Col md={6} className="row" >
                                <Col md={4}>
                                    <Form.Label className="required" for="frmtaskdetailtype">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_Type' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmtaskdetailtype"
                                        as="select"
                                        name="serviceTaskDetailType"                                        
                                        value={this.state.serviceTaskDetailType}
                                        onChange={this.handleChange}                                    >
                                        <option value="">Select Type</option>    
                                        <option value="Primary">Primary</option>
                                        <option value="Secondary">Secondary</option>
                                    </Form.Control>
                                </Col>
                            </Col>  
                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">   
                        <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmtaskdetaillevel">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_Level' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmtaskdetaillevel"
                                        type="number"
                                        name="level"
                                        placeholder="Tasks Level"                                        
                                        value={this.state.level}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Col>                           
                        <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmtaskdetailpredecessor">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_Predecessor' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}  
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    {/* <Form.Control
                                        id="frmtaskdetailpredecessor"
                                        type="text"
                                        className="form-control"
                                        controlId="subactivitypredecessor"
                                        name="predecessor"
                                        onChange={this.handleChange}
                                        placeholder="Predecessor"
                                        value={this.state.predecessor}
                                        required
                                    >
                                    </Form.Control> */}
                                    <Select
                                    name="serviceTask"
                                    value={this.state.predecessor}
                                    onChange={this.handleSelectChangeTask}
                                     
                                    options={optionsTaskArray.map(opt => ({ label: opt.serviceTaskName, value: opt._id }))}
                                    />
                                </Col>
                            </Col>                             
                        </Row>
                    </Form.Row>      
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_StartDate' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                <DatePickerInput
                                            id="startdate"
                                            name="start_date"
                                            onChange={this.onStartDateChange.bind(this, 'start_date') }
                                             placeholder="MM/DD/YYYY"
                                             value={this.state.start_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            aria-label="startdate"
                                            readOnly
                                        />                                       
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label>
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_EndDate' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>                                   
                                <DatePickerInput
                                            id="enddate"
                                            name="end_date"
                                             onChange={this.onEndDateChange.bind(this, 'end_date') }
                                            placeholder="MM/DD/YYYY"
                                             value={this.state.end_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            aria-label="enddate"
                                            readOnly
                                        />
                                </Col> 
                            </Col>
                        </Row>
                    </Form.Row>                 


                    <Form.Row>
                     <Row className="margin-t10 col-md-12">        
                     <Col md={6} className="row" >
                                <Col md={4}>
                                    <Form.Label className="required" for="servicetaskdetailstatus">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_Status' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="servicetaskdetailstatus"
                                        as="select"
                                        name="status"
                                        value={this.state.status}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control>
                                </Col>
                            </Col>  
                     </Row>
                    </Form.Row>     
                </Form>
          
                        </Modal.Body>
                            <Modal.Footer>
                               
                                <Button variant="primary" onClick={this.handleSaveClick}>
                                {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_SaveBtn' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                ))}
                                </Button>
                                 <Button variant="secondary" onClick={this.handleClose} >
                                 {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail_CancelBtn' && lbl.pageName=='ServiceTaskDetail').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                ))}
                                </Button>
                            </Modal.Footer>
                    </Modal>                        
                </div>

        </div>

        </>
    );
}
}