import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import { Link } from "react-router-dom";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Icon from '@material-ui/core/Icon';
import EditIcon from '@material-ui/core/Icon';
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const initialValues = {
    weight: '',
    height: '',
    date: '',
    user: {
        login: '',
        status: ''
    }
}
type Props = {
    onSelection: Function,
    onExpansion: Function,
    checked: Array<string>,
    expanded: Array<string>
};
type State = {
    orglist: Array<any>
};
export default class Scheduler extends React.Component<Props, State> {
    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
            editshow: false,
            events: [
                {
                    start: moment().toDate(),
                    end: moment()
                        .add(1, "days")
                        .toDate(),
                    title: "Some title"
                },
                {
                    "title": "Event 1",
                    "start": "2020-12-05T09:00:00",
                    "end": "2020-12-05T18:00:00"
                },
                {
                    "title": "Event 2",
                    "start": "2020-12-08",
                    "end": "2020-12-08"
                }
            ],
            selectedevent:'',
        }
    }

    handleSelect = ({ start, end }) => {
        this.setState({ show: false, editshow: true, selectedevent: ''});
        //const title = window.prompt('New Event name')
        //if (title)
        //    this.setState({
        //        events: [
        //            ...this.state.events,
        //            {
        //                start,
        //                end,
        //                title,
        //            },
        //        ],
        //    })
    }


    handleClose=()=> {

        this.setState({ show: false });
    }
    handleSave = () => {
        this.setState({ show: false });
    }
    handleEdit = () => {
        this.setState({ show: false, editshow:true });
    }
    handleEditClose = () => {
        this.setState({ show: false, editshow: false });
    }

    getInformation = (event) => {
         
        this.setState({ show: true, selectedevent:event });
    }
    render() {

        return (

            <>
                <div className="container userinfo-container" role="main">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/Home">Home </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Scheduler</Breadcrumb.Item>
                    </Breadcrumb>

                    {/*  show the details of scheduler */}
                    <div>
                        <Modal
                            className="scheduler-preview-modal"
                            show={this.state.show}
                            onHide={this.handleClose}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title className="margin_auto">
                                     
                                    <button type="button" onClick={ this.handleEdit }><Icon >editFilled</Icon> </button>
                                    <button type="button"><Icon >deleteRounded</Icon> </button>
                                    <button type="button" onClick={this.handleClose}><Icon >closeRounded</Icon> </button>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridTreeName">
                                            <Form.Label className="labelvalue">Event Title : </Form.Label>
                                            {this.state.selectedevent.title}
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridTreeName">
                                            <Form.Label>Short Name : </Form.Label>
                                            {this.state.label}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridTreeName">
                                            <Form.Label>Description :</Form.Label>
                                            {this.state.description}
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridTreeName">
                                            <Form.Label>Active Status :</Form.Label>
                                            {this.state.activestatus}
                                        </Form.Group>
                                    </Form.Row>
                                    
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleClose} >
                                    Close
               </Button>
                                {/* <Button variant="primary" onClick={this.handleSaveChanges}>
                 Save Changes
               </Button> */}
                            </Modal.Footer>
                        </Modal>
                    </div>
                    {/*  add or edit the scheduler */}
                    <div>
                        <Modal
                            className="scheduler-edit-modal"
                            show={this.state.editshow}
                            onHide={this.handleClose}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title className="margin_auto">
                                    <button type="button" onClick={this.handleEditClose}><Icon >closeRounded</Icon> </button>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridTreeName">
                                            <Form.Label className="labelvalue">Event Title : </Form.Label>
                                            {this.state.selectedevent.title}
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridTreeName">
                                            <Form.Label>Short Name : </Form.Label>
                                            {this.state.label}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridTreeName">
                                            <Form.Label>Description :</Form.Label>
                                            {this.state.description}
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridTreeName">
                                            <Form.Label>Active Status :</Form.Label>
                                            {this.state.activestatus}
                                        </Form.Group>
                                    </Form.Row>

                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleSave} >
                                    Save Changes
               </Button>
                                {/* <Button variant="primary" onClick={this.handleSaveChanges}>
                 Save Changes
               </Button> */}
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <Form  >
                        <div className="centeralign">
                            <span className="mainheadings"> Scheduler</span>
                        </div>
                        <div >
                            <Form.Row style={{ marginLeft: "10px", border: "1px solid black", borderRadius: "5px" }}>
                                <Row className="margin-t10 col-md-12 m-10">
                                    <Col md={12} className="row">

                                        <Calendar

                                            style={{ height: "100vh" }}
                                            selectable
                                            localizer={localizer}
                                            events={this.state.events}
                                            defaultView={Views.MONTH}
                                            scrollToTime={new Date(1970, 1, 1, 6)}
                                            defaultDate={new Date(2020, 11, 3)}
                                            onSelectEvent={this.getInformation}
                                            onSelectSlot={this.handleSelect}
                                        />
                                    </Col>

                                   

                                </Row>

                            </Form.Row>
                        </div>
                         

                        
                    </Form>
                </div>
            </>
        );
    }
}
 