import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import axios from "axios";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker, DatePickerInput } from "rc-datepicker";
import * as moment from "moment";
import Select from 'react-select';
import "moment/min/moment-with-locales";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import Icon from '@material-ui/core/Icon';
import "react-table/react-table.css"; 
import "./react-tabs.css";
import _ from "lodash";
import {output} from '../../sourcedata';
const configData = require( '../../config.json');
var gdlist=[];
var outgdlist=[];
class Sample extends React.Component {
    constructor(props) {
      super(props);
  
      
      this.state = {
        "show": false,
        "show3":false,
        "dbname":'',
        "table1name":'',
        "table2name":'',
        "dbnamelist":null,
        "selectedDbName":'',
        "tablenamelist":null,
        "selectedTableName":'',
        "selectedColName":'',
        "colnamelist":null,
        "selectedNewTableName":'',
        "selectedNewColName":'',
        "newcolnamelist":null,
        "selectedNewList":[],
        "columns":[],
        "operatorcolumns":[],
        "computedcolumns":[],
        "tablenames":'',
        "tablenamesdist":'',
        "existtablename":null,
        "pagenumber":1,
        "operatorlist":[{"operatorkey":1,"operatorvalue":"sum"},
        {"operatorkey":2,"operatorvalue":"avg"},
        {"operatorkey":3,"operatorvalue":"count"}],
        "selectedoperatorname":null,
        "computedtablename":[{"operatorkey":1,"operatorvalue":"Column"},
            {"operatorkey":2,"operatorvalue":"Cumulative Column"},
            {"operatorkey":3,"operatorvalue":"Number"}
        ],
        "operatorname":null,
        "selectedOperatorList":[],
        "selectedOperatorFormat":null,
        "selectedComputedTableName":null,
        "outputtablenamelist":[],
        "selectedOutNewTableName":null,
        "outputcolnamelist":null,
        "selectedOutputColName":null,
        "outputcolumns":[],
        "grdOutputNewList":[],
        "txtoutputarea":'',
        "txtdisplayoutputarea":'',
        "computedcolumnlist":[],
        "selectedComputedColumnName":'',
        "columnrelations":'',
        "computedcolumnnname":'',
        "computednumbervalue":'',
        "grdComputedColumnList":[],
        "finalizecolumnvalue":'',
        "finalresultcolumns":[],
        "grdFinalResultList":[],
        "tableResult1":[],
        "tablerecords":[]

      };
  
     
    }
    getColumns = () => {
        let columns = [
          {
            Header: "Source Table",
            accessor: "sourceTable",
            sortable: true,
            show: true,
            displayValue: "sourceTable",
            Cell:row=>(<span>{row.original.sourceTable}</span>)
          },          
          {
            Header: "Source Column",
            accessor: "sourceColumnName",
            sortable: true,
            show: true,
            displayValue: "sourceColumnName",
            Cell:row=>(<span>{row.original.sourceColumnName}</span>)
          },          
          {
            Header: "Dest Table",
            accessor: "destTable",
            sortable: true,
            show: true,
            displayValue: "destTable",
            Cell:row=>(<span>{row.original.destTable}</span>)
          },          
          {
            Header: "Dest ColumnName",
            accessor: "destColumnName",
            sortable: true,
            show: true,
            displayValue: "destColumnName",
            Cell:row=>(<span>{row.original.destColumnName}</span>)
          },           
        ];
        this.setState({ columns });
      };
      getOperatorColumns = () => {
        let operatorcolumns = [
          {
            Header: "Name",
            accessor: "operatorName",
            sortable: true,
            show: true,
            displayValue: "operatorName",
            Cell:row=>(<span>{row.original.operatorName}</span>)
          },          
          {
            Header: "Operator",
            accessor: "operator",
            sortable: true,
            show: true,
            displayValue: "operator",
            Cell:row=>(<span>{row.original.operator}</span>)
          },          
          {
            Header: "Tablename",
            accessor: "tablename",
            sortable: true,
            show: true,
            displayValue: "tablename",
            Cell:row=>(<span>{row.original.tablename}</span>)
          },          
          {
            Header: "Column Name",
            accessor: "tableColumnName",
            sortable: true,
            show: true,
            displayValue: "tableColumnName",
            Cell:row=>(<span>{row.original.tableColumnName}</span>)
          },           
        ];
        this.setState({ operatorcolumns });
      };
      getOutputColumns = () => {
        let outputcolumns = [
          {
            Header: "Source Table",
            accessor: "sourceTable",
            sortable: true,
            show: true,
            displayValue: "sourceTable",
            Cell:row=>(<span>{row.original.sourceTable}</span>)
          },          
          {
            Header: "Source Column",
            accessor: "sourceColumnName",
            sortable: true,
            show: true,
            displayValue: "sourceColumnName",
            Cell:row=>(<span>{row.original.sourceColumnName}</span>)
          }          
        ];
        this.setState({ outputcolumns });
      };

      getComputedColumns = () => {
        let computedcolumns = [
          {
            Header: "ComputedColumnName",
            accessor: "computedColumnName",
            sortable: true,
            show: true,
            displayValue: "computedColumnName",
            Cell:row=>(<span>{row.original.computedColumnName}</span>)
          },          
          {
            Header: "ComputedColumnName Value",
            accessor: "computedColumnValue",
            sortable: true,
            show: true,
            displayValue: "computedColumnValue",
            Cell:row=>(<span>{row.original.computedColumnValue}</span>)
          },                 
          {
            Header: "ComputedColumn",
            accessor: "computedColumnDisplayValue",
            sortable: true,
            show: true,
            displayValue: "computedColumnDisplayValue",
            Cell:row=>(<span>{row.original.computedColumnDisplayValue}</span>)
          }         
        ];
        this.setState({ computedcolumns });
      };
      getformat=(param)=>{
          
          var val=parseFloat(param);
          return val.toFixed(2);
         // console.log(param);
      }

      getFinalResultColumns = () => {
        let finalresultcolumns = [
          {
            Header: "Employee ID",
            accessor: "emp.emp_id",
            sortable: true,
            show: true,
            displayValue: "emp.emp_id",
            Cell:row=>(<span>{row.original["emp.emp_id"]}</span>)
          }, 
          {
            Header: "Employee Name",
            accessor: "emp_name",
            sortable: true,
            show: true,
            displayValue: "emp_name",
            Cell:row=>(<span>{row.original["emp.emp_name"]}</span>)
          },
          {
            Header: "Department Name",
            accessor: "dept_name",
            sortable: true,
            show: true,
            displayValue: "dept_name",
            Cell:row=>(<span>{row.original["dept.dept_name"]}</span>)
          },
          {
            Header: "Net Pay",
            accessor: "net_pay",
            sortable: true,
            show: true,
            displayValue: "net_pay",
            Cell:row=>(<span>{row.original["pay.net_pay"]}</span>)
          }, 
          {
            Header: "Percent Pay",
            accessor: "percent_pay",
            sortable: true,
            show: true,
            displayValue: "percent_pay",
            Cell:row=>this.getformat(row.original["r$.percent_pay"])
          }       
        ];
        this.setState({ finalresultcolumns });
      };
    componentDidMount() {
      
       this.fetchAllDBNameAPI();
       this.getColumns();
       this.getOperatorColumns();
       this.getOutputColumns();
       this.getComputedColumns();
       this.getFinalResultColumns();
      }
      fetchAllDBNameAPI = () => {

        
        this.setState({...this.state, isFetching: true});
       
            axios.get(configData.SERVER_URL+`fincorp_getDatabaseList/`,
            
             {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res=>  {this.setBindDBName(res.data) }
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };
 
    setBindDBName=data=>{
       
        if(data!='No document(s) found with defined find criteria!' && data.databases.length>0){
            
            //this.state.user.unencryptedPassword !=this.state.confirmPassword
            this.setState({...this.state,
            dbnamelist:data.databases
            });
            
        }
    }
    //load the table or collection details based on db name
    handleDbSelectChange=e=>{
        
        //let { value, label } = e.target;
        this.setState({
            ...this.state,
            selectedDbName:  e,
            

        });
        //get all table list
        this.fetchAllTableNameAPI(e);
    }

    fetchAllTableNameAPI = (param) => {

        
            axios
            .post(configData.SERVER_URL+`fincorp_getCollectionList/`,
            
           {'dbname':param.label}
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res=>  {this.setBindTableName(res.data) }
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };
 
    setBindTableName=data=>{
       
        if(data!='No document(s) found with defined find criteria!' && data.length>0){
            var tblname='';
            for(var i=0;i<data.length;i++){
                // console.log(data[i]);
                 tblname=tblname+data[i].TABLENAME+", ";
             }
              
            //this.state.user.unencryptedPassword !=this.state.confirmPassword
            this.setState({...this.state,
            tablenamelist:data,
            tablenames:tblname
            });
            
            
            
        }
    }

    
    handleSelectTableChange=e=>{
        
         var tblname=[];
         tblname.push(e);
         this.setState({
            ...this.state,
            selectedTableName:  e,
            existtablename:tblname,
            colnamelist:e.value.COLUMN_DET

        });
        
        this.fetchAllDataDynamicCollectionNameAPI(this.state.selectedDbName.label,e.label);
        
    }
    handleSelectComputedTableChange=e=>{
        var tblname=[];
        tblname.push(e);
         
        var opformat=[];
        if(e.label!=null && e.label =="Cumulative Column"){
            for(var i=0;i<this.state.selectedOperatorList.length;i++){
                opformat.push({'key':this.state.selectedOperatorList[i].operatorName,'value':this.state.selectedOperatorList[i].operator+'?'+this.state.selectedOperatorList[i].operatorName+'|'+this.state.selectedOperatorList[i].tablename+'.'+this.state.selectedOperatorList[i].tableColumnName+','});
            }
        }
         
        this.setState({
           ...this.state,
           selectedComputedTableName:  e,
           existtablename:tblname,
           colnamelist:e.value.COLUMN_DET,
           computedcolumnlist:opformat

       });

      
       
   }
    handleSelectColChange=e=>{
         
        //let { value, label } = e.target;
        this.setState({
            ...this.state,
            selectedColName:  e 
        });
        
    }
    handleSelectNewTableChange =e=>{
         
         var cmn=this.state.existtablename;
         cmn.push(e);
         this.setState({
            ...this.state,
            selectedNewTableName:  e,
            existtablename:cmn,
            newcolnamelist:e.value.COLUMN_DET
        });
        this.fetchAllDataDynamicCollectionNameAPI(this.state.selectedDbName.label,e.label);
        console.log(cmn);
        
    }
    handleSelectColChange=e=>{
         
        //let { value, label } = e.target;
        this.setState({
            ...this.state,
            selectedColName:  e 
        });
        
    }
    handleSelectNewColChange=e=>{
         
        //let { value, label } = e.target;
        this.setState({
            ...this.state,
            selectedNewColName:  e 
        });
        
    }
    handleChange = e => {
        let { value, name } = e.target;
       
       
        this.setState({
            ...this.state,
            [name]: value,
            

        });
    }
    handleOutSelectNewTableChange =e=>{
          
         var tbllist=this.state.tablenamelist;
         var outputcollist= tbllist.filter(tb => tb.TABLENAME==e.label);
         var outputval=[];
         if(outputcollist!=null && outputcollist.length>0)
         {
            outputval= outputcollist[0].COLUMN_DET;

         }
         this.setState({
            ...this.state,
            selectedOutNewTableName:  e,
            outputcolnamelist:outputval
        });


         
    }
    handleSelectOutputColChange= e=>{
        this.setState({
            ...this.state,
            selectedOutputColName:  e,
             
        });
    }
    handleSaveClick = (event) => {
    var tbllistdist=this.state.tablenamesdist!=null?this.state.tablenamesdist:'';
    gdlist=this.state.selectedNewList!=null && this.state.selectedNewList.length!=0?this.state.selectedNewList:[];
    gdlist.push({'sourceTable':this.state.selectedTableName.label,'sourceColumnName':this.state.selectedColName.label,'destTable':this.state.selectedNewTableName.label,'destColumnName':this.state.selectedNewColName.label});
    const newData = gdlist.map(d=>({...d, name1:"Test"}));
 
    outgdlist=this.state.outputtablenamelist!=null && this.state.outputtablenamelist.length!=0?this.state.outputtablenamelist:[];
    //var tlist=_.find(outgdlist.sourceTable,this.state.selectedTableName.label);
    let tlist = _.findIndex(outgdlist, (e) => {
        return e.sourceTable == this.state.selectedTableName.label;
    }, 0);
     
    if(tlist==-1 ){
    outgdlist.push({'sourceTable':this.state.selectedTableName.label});
    tbllistdist=tbllistdist+this.state.selectedTableName.label+',';
    }
    let tlist1 = _.findIndex(outgdlist, (e) => {
        return e.sourceTable == this.state.selectedNewTableName.label;
    }, 0);
    //var tlist1=_.find(outgdlist.sourceTable,this.state.selectedNewTableName.label);
    if(tlist1==-1 ){
        outgdlist.push({'sourceTable':this.state.selectedNewTableName.label});
        tbllistdist=tbllistdist+this.state.selectedNewTableName.label+',';
    }
    
    const outData = outgdlist.map(d=>({...d, name1:"Test"}));
    this.setState({
        ...this.state,
        selectedNewList: newData,
        outputtablenamelist:outData,
        tablenamesdist:tbllistdist
    });
    // if(newData.length>0){
    //     this.setState(prevState => ({...prevState,
    //         selectedNewList: newData 
    //     }));
    // }
};   
handleRelationJoinClick=(event)=>{
    var outData='';
     
    for(var i=0;i<this.state.selectedNewList.length;i++){
        outData=outData+this.state.selectedNewList[i].sourceTable+'.'+this.state.selectedNewList[i].sourceColumnName+','+this.state.selectedNewList[i].destTable+'.'+this.state.selectedNewList[i].destColumnName+'|';
    }
    this.setState({
        ...this.state,
         
        columnrelations:outData
    });
}
handleOutputSaveClick = (event) => {
          
             

gdlist=this.state.grdOutputNewList!=null && this.state.grdOutputNewList.length!=0?this.state.grdOutputNewList:[];
gdlist.push({'sourceTable':this.state.selectedOutNewTableName.label,'sourceColumnName':this.state.selectedOutputColName.label});
const newData = gdlist.map(d=>({...d, name1:"Test"}));
 
this.setState({
...this.state,
grdOutputNewList: newData 
});
 
}; 
UpdateStudentTestChanges=(res)=>{
 
  console.log(res.data.data);
  if(res.data.Status!=-1){
      toast.success("Posted Successfully");
      
  }
  else{
      toast.warn(res.data.Message);
  }
}

handleProcessClick=()=>{
    axios
    .post(configData.SERVER_URL+`fincorp_getCustomFilter`,
    
    {'dbname':this.state.dbname, 'table1':this.state.table1name,'table2':this.state.table2name,'table3':'bookcategory','table1columns':'{"_id":1, "name": 1,"category":1,"authors":1}','table2columns':'{"_id":1,"cname":1}','table1key':'category','table2key':'_id'} , {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*"
        }
    })
    .then(
        res =>  this.UpdateTableChanges(res)
    )
    .catch(err => {
        console.log("AXIOS ERROR: ", err);
    }); 

}
UpdateTableChanges=(res)=>{
    
    console.log(res.data.data);
     
    toast.success("Posted Successfully");
        
     
  }

handlePopUpClick = () => {
  this.setState({ show: true});  
}

handlePopUpClick3 = () => {
    this.setState({ show3: true});  
  }

handleClose=()=> {

  this.setState({ show: false });
}
  
handleClose3=()=> {

    this.setState({ show3: false });
  }
   
handleNextPage=()=> {
     
    var pgnbr=parseInt(this.state.pagenumber)+1;
    this.setState({ pagenumber: pgnbr });
}

handlePrevPage=()=> {
    var pgnbr=parseInt(this.state.pagenumber)-1;
    this.setState({ pagenumber: pgnbr });
}
handleSelectOperatorColChange=e=>{
         
    //let { value, label } = e.target;
    this.setState({
        ...this.state,
        selectedoperatorname:  e 
    });
    
}
handleOperatorSaveClick=(event)=>{
    
    gdlist=this.state.selectedOperatorList!=null && this.state.selectedOperatorList.length!=0?this.state.selectedOperatorList:[];
    gdlist.push({'operatorName':this.state.operatorname,'operator':this.state.selectedoperatorname.label,'tablename':this.state.selectedNewTableName.label,'tableColumnName':this.state.selectedNewColName.label});
    const newData = gdlist.map(d=>({...d, name1:"Test"}));
    this.setState({
        ...this.state,
        selectedOperatorList: newData 
    });
}
handleOperatorJoinClick=(event)=>{
    // gdlist=this.state.selectedOperatorList!=null && this.state.selectedOperatorList.length!=0?this.state.selectedOperatorList:[];
    // gdlist.push({'operatorName':this.state.operatorname,'operator':this.state.selectedColName.label,'tablename':this.state.selectedNewTableName.label,'tableColumnName':this.state.selectedNewColName.label});
    // const newData = gdlist.map(d=>({...d, name1:"Test"}));
    // this.setState({
    //     ...this.state,
    //     selectedOperatorList: newData 
    // });
    var opformat="";
    for(var i=0;i<this.state.selectedOperatorList.length;i++){
        opformat=this.state.selectedOperatorList[i].operator+'?'+this.state.selectedOperatorList[i].operatorName+'|'+this.state.selectedOperatorList[i].tablename+'.'+this.state.selectedOperatorList[i].tableColumnName+','
    }
    this.setState({
        ...this.state,
        selectedOperatorFormat: opformat 
    });
}
handlePSaveClick=(event)=>{
    var outputval='';
    var displayoutputval='';
    if(this.state.txtoutputarea.length>0)
        outputval= _.trimEnd(this.state.txtoutputarea, ",")+'opr|+;';
    if(this.state.txtdisplayoutputarea.length>0)
        displayoutputval= _.trimEnd(this.state.txtdisplayoutputarea, "")+'+';
        //outputval=this.state.txtoutputarea+'+';
    this.setState({
        ...this.state,
        txtoutputarea: outputval,
        txtdisplayoutputarea:displayoutputval
    });
}
handleMSaveClick=(event)=>{
   // txtoutputarea
   var outputval='';
   var displayoutputval='';
    if(this.state.txtoutputarea.length>0)
        outputval= _.trimEnd(this.state.txtoutputarea, ",")+'opr|-;';
    if(this.state.txtdisplayoutputarea.length>0)
        displayoutputval= _.trimEnd(this.state.txtdisplayoutputarea, "")+'-';
    this.setState({
        ...this.state,
        txtoutputarea: outputval,
        txtdisplayoutputarea:displayoutputval
    });
}

handleMLSaveClick=(event)=>{
    var outputval='';
    var displayoutputval='';
    if(this.state.txtoutputarea.length>0)
        outputval= _.trimEnd(this.state.txtoutputarea, ",")+'opr|*;';
    if(this.state.txtdisplayoutputarea.length>0)
        displayoutputval= _.trimEnd(this.state.txtdisplayoutputarea, "")+'*';
        
     this.setState({
         ...this.state,
         txtoutputarea: outputval,
         txtdisplayoutputarea:displayoutputval
     });
 }
 handleDISaveClick=(event)=>{
    var outputval='';
    var displayoutputval='';

    if(this.state.txtoutputarea.length>0)
        outputval= _.trimEnd(this.state.txtoutputarea, ",")+'opr|/;';
    if(this.state.txtdisplayoutputarea.length>0)
        displayoutputval= _.trimEnd(this.state.txtdisplayoutputarea, "")+'/';
     this.setState({
         ...this.state,
         txtoutputarea: outputval,
         txtdisplayoutputarea:displayoutputval
     });
 }
 handleClearComputedColumnClick=(event)=>{
      this.setState({
         ...this.state,
         txtoutputarea: '',
         txtdisplayoutputarea:''
     });
 }
handleOutSaveClick=(event)=>{
     
    var outputval='';
    var displayoutputval='';
     
    if(this.state.selectedComputedTableName!=null && this.state.selectedComputedTableName.label=="Column"){
     outputval=this.state.txtoutputarea+(this.state.txtoutputarea.length==0 || this.state.txtoutputarea==undefined?'r$.'+this.state.computedcolumnnname+'?':'')+'col|'+ this.state.selectedTableName.label+'.'+this.state.selectedColName.label+';';
     displayoutputval=this.state.txtdisplayoutputarea+(this.state.txtdisplayoutputarea.length==0 || this.state.txtdisplayoutputarea==undefined?this.state.computedcolumnnname+'=':'')+this.state.selectedTableName.label+'.'+this.state.selectedColName.label+'';
    
    }
    if(this.state.selectedComputedTableName!=null && this.state.selectedComputedTableName.label=="Cumulative Column"){
      
       // outputval=this.state.txtoutputarea+this.state.selectedComputedColumnName.value;
        outputval=this.state.txtoutputarea+(this.state.txtoutputarea.length==0 || this.state.txtoutputarea==undefined?'r$.'+this.state.computedcolumnnname+'?':'')+'cal|'+ this.state.selectedComputedColumnName.label+';';
        displayoutputval=this.state.txtdisplayoutputarea+(this.state.txtdisplayoutputarea.length==0 || this.state.txtdisplayoutputarea==undefined?this.state.computedcolumnnname+'=':'')+ this.state.selectedComputedColumnName.label;
    
    }
    if(this.state.selectedComputedTableName!=null && this.state.selectedComputedTableName.label=="Number"){
      
        // outputval=this.state.txtoutputarea+this.state.selectedComputedColumnName.value;
         outputval=this.state.txtoutputarea+(this.state.txtoutputarea.length==0 || this.state.txtoutputarea==undefined?'r$.'+this.state.computedcolumnnname+'?':'')+'num|'+ this.state.computednumbervalue+';';
         displayoutputval=this.state.txtdisplayoutputarea+(this.state.txtdisplayoutputarea.length==0 || this.state.txtdisplayoutputarea==undefined?this.state.computedcolumnnname+'=':'')+ this.state.computednumbervalue;
    
        }
    this.setState({
        ...this.state,
        txtoutputarea: outputval,
        txtdisplayoutputarea:displayoutputval
    });
}

handleInsertComputedCol =(event)=>{
 
    let tlist = _.findIndex(this.state.grdComputedColumnList, (e) => {
        return e.computedColumnName == this.state.computedcolumnnname;
    }, 0);
   
    var gdlist=[];
    
    if(tlist==-1 ){
        gdlist=this.state.grdComputedColumnList;
        gdlist.push({"computedColumnName":this.state.computedcolumnnname,"computedColumnValue":this.state.txtoutputarea,"computedColumnDisplayValue":this.state.txtdisplayoutputarea});
        //gdlist.push({"computedColumnName":this.state.computedcolumnnname,"computedColumnValue":'test'});
        const newData = gdlist.map(d=>({...d, name1:"Test"}));
        this.setState({
        ...this.state,
        grdComputedColumnList: newData 
        });
    }
}

handleFinalizeCol=(event)=>{
    var opformat='';
    
    for(var i=0;i<this.state.grdOutputNewList.length;i++){
        opformat=opformat+this.state.grdOutputNewList[i].sourceTable+'.'+ this.state.grdOutputNewList[i].sourceColumnName+','
    }
    for(var i=0;i<this.state.grdComputedColumnList.length;i++){
        opformat=opformat+this.state.grdComputedColumnList[i].computedColumnValue+',' 
    }
    opformat= _.trimEnd(opformat, ",")+
     
    this.setState({
        ...this.state,
        finalizecolumnvalue: opformat 
        });
}
handleSelectComputedColumnChange=(event)=>{
    
    this.setState({
        ...this.state,
        selectedComputedColumnName: event 
    });
    // var opformat="";
    // for(var i=0;i<this.state.selectedOperatorList.length;i++){
    //     opformat=this.state.selectedOperatorList[i].operator+'?'+this.state.selectedOperatorList[i].operatorName+'|'+this.state.selectedOperatorList[i].tablename+'.'+this.state.selectedOperatorList[i].tableColumnName+','
    // }
}

fetchAllDataDynamicCollectionNameAPI = (dbname,colname) => {

        
    axios
    .post(configData.SERVER_URL+`fincorp_getAllDataDynamicCollection/`,
    
   {'dbname':dbname,'collectionname':colname}
    , {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*"
        }
    })
    .then(
        res=>  {this.setDataCollectionBind(res.data) }
    )
    .catch(err => {
        console.log("AXIOS ERROR: ", err);
    });
};

setDataCollectionBind=data=>{
 
if(data!='No document(s) found with defined find criteria!' && data.length>0){
    var tblname='';
    console.log(data);
    var tblrecords=this.state.tablerecords;
    tblrecords.push(data);
    
    //this.state.user.unencryptedPassword !=this.state.confirmPassword
    this.setState({...this.state,
        tableResult1:data, 
        tablerecords:tblrecords
    });
    
    
    
}
}

handleFinalizeResult=(event)=>{
    
    //grdFinalResultList   
    var res=output(this.state.tablerecords);
    console.log('test');
    console.log(res); 
   
    this.setState({ ...this.state, pagenumber: 6,grdFinalResultList:res });
    
}
    render() { 
        
        const optionsDBNameArray =this.state.dbnamelist!=null&& this.state.dbnamelist!=undefined? this.state.dbnamelist:[]; 
        const optionsTableNameArray =this.state.tablenamelist!=null&& this.state.tablenamelist!=undefined? this.state.tablenamelist:[]; 
        const optionsColumnNameArray =this.state.colnamelist!=null&& this.state.colnamelist!=undefined? this.state.colnamelist:[]; 
        const optionsNewColumnNameArray =this.state.newcolnamelist!=null&& this.state.newcolnamelist!=undefined? this.state.newcolnamelist:[]; 
        const optionsExistTableNameArray =this.state.existtablename!=null&& this.state.existtablename!=undefined? this.state.existtablename:[]; 
        const optionsOperatorColumnNameArray =this.state.operatorlist!=null&& this.state.operatorlist!=undefined? this.state.operatorlist:[]; 
        const optionsComputedTableNameArray =this.state.computedtablename!=null&& this.state.computedtablename!=undefined? this.state.computedtablename:[]; 
         
        const optionsOutputTableNameArray =this.state.outputtablenamelist!=null&& this.state.outputtablenamelist!=undefined? this.state.outputtablenamelist:[]; 
        const optionsOutputColumnNameArray=this.state.outputcolnamelist!=null&& this.state.outputcolnamelist!=undefined? this.state.outputcolnamelist:[]; 

        const optionsComputedColumnNameArray = this.state.computedcolumnlist!=null && this.state.computedcolumnlist!=undefined?this.state.computedcolumnlist:[];
        
        const outputcolumntype=this.state.selectedComputedTableName!=null && this.state.selectedComputedTableName.label!=null?this.state.selectedComputedTableName.label:null;
      return (
          <>
      <div>
                <div className="container">
                  <h3 className="text-center">DATA MODEL</h3>

                    <div className={this.state.pagenumber==1?"content card":"hidden"}>
                        <h6 className="text-center ">Select Primary Tables</h6>
                            <Form.Row >
                                  <Col md-6 sm-12>                                        
                                          <Form.Label>
                                          Choose Database                                  
                                          </Form.Label>
                                  {/* </Col>
                                  <Col md-6 sm-12>
                                        */}
                                       <Select
                               value={this.state.selectedDbName}
                               onChange={this.handleDbSelectChange}
                                
                               options={optionsDBNameArray.map(opt => ({ label: opt.name, value: opt.name }))}
                               />                                  
                                     
                               
                               </Col>
                                  <Col md-6 sm-12>                                        
                                          <Form.Label>
                                          Choose Primary Table                                  
                                          </Form.Label>
                                  {/* </Col>
                                  <Col md-6 sm-12>                                         */}
                                    <Select
                                    value={this.state.selectedTableName}
                                    onChange={this.handleSelectTableChange}
                                     
                                    options={optionsTableNameArray.map(opt => ({ label: opt.TABLENAME, value: opt }))}
                                    />    
                                       
                                      
                                      
                                    </Col> 
                                 
                                </Form.Row>
                                <div >

                            <Button variant="primary" onClick={this.handleNextPage} className="ml-10 mt-10">
                                    Next 
                                </Button>
                            </div>
                    </div>
                    <div className ={this.state.pagenumber==2?"model-content card":"hidden"}>
                    <h6 className="text-center ">Add Other Tables</h6>
                        <Form>
                    <Form.Row className="mt-10 row">
                                   
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                       Choose Existing Table                                  
                                   </Form.Label>
                                   <Select
                               value={this.state.selectedTableName}
                               onChange={this.handleSelectTableChange}
                                
                               options={optionsExistTableNameArray.map(opt => ({ label: opt.label, value: opt }))}
                               />       
                                   </Col>
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                       Choose Rel Column Existing Table                                   
                                   </Form.Label>
                                   <Select
                               value={this.state.selectedColName}
                               onChange={this.handleSelectColChange}
                                
                               options={optionsColumnNameArray.map(opt => ({ label: opt.COLUMN_NAME, value: opt }))}
                               />       
                                   </Col>  
                               </Form.Row> 
                               <Form.Row className="mt-10">
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                       Choose New Table                                  
                                   </Form.Label>
                                   <Select
                                   value={this.state.selectedNewTableName}
                                   onChange={this.handleSelectNewTableChange}
                                
                                   options={optionsTableNameArray.map(opt => ({ label: opt.TABLENAME, value: opt }))}
                                   />    
                                  
                                   </Col>
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                       Choose Rel Column New Table                                  
                                   </Form.Label>
                                   <Select
                                   value={this.state.selectedNewColName}
                                   onChange={this.handleSelectNewColChange}
                                
                                    options={optionsNewColumnNameArray.map(opt => ({ label: opt.COLUMN_NAME, value: opt }))}
                                   />    
                                   </Col>  
                               </Form.Row>
                               <Form.Row className="mt-10">
                               
                               <Button variant="warning" onClick={this.handleSaveClick}  className="ml-10">
                                   Add   
                               </Button> 
                               {/* <Button variant="primary" onClick={this.handleRelationJoinClick}className="ml-10">
                                   Join   
                               </Button>  */}
                               <Button variant="secondary" onClick={this.handleClose} className="ml-10">
                                   Cancel 
                               </Button>
                               </Form.Row>
                               
                       <Form.Row>
                                  <div className="fullwidth row m0">
                                   <div className="col-md-12 mt-10 p0">
                                   {/* <p className="mt-10 bg-danger text-white">Grid show error</p> */}
                                 <ReactTable
                                    data={this.state.selectedNewList}
                                    columns={this.state.columns}
                                    defaultPageSize={5}
                                    
                                   />   
                                   </div>      
                                   </div> 
                                    {/* Step 1 */}
                                   {/* <div className="fullwidth row m0">
                                       <div className="col-md-12 mt-10 p0">
                                          
                                           <span>Step1: Tables:</span><span>{this.state.tablenamesdist}</span>
                                       </div>
                                   </div> 
                                   <div className="fullwidth row m0">
                                       <div className="col-md-12 mt-10 p0">
                                           <span>Step2: Relations:</span><span>{this.state.columnrelations}</span>
                                       </div>
                                   </div>          */}
                        </Form.Row>         
                        <div className="d-flex flex-row justify-content-between">
                            <Button variant="primary" onClick={this.handlePrevPage} className="ml-10 mt-10">
                                    Previous
                                </Button>
                            <Button variant="primary" onClick={this.handleNextPage} className="ml-10 mt-10">
                                    Next 
                                </Button>
                </div>
                               </Form>
                  
                    </div>
                    <div className ={this.state.pagenumber==3?"model-content card":"hidden"}>
                    <h6 className="text-center ">Add Cumulative Computed Values</h6>
                        <Form>
                    <Form.Row className="mt-10 row">
                                   
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                      Enter Name for Cumulative Value                                
                                   </Form.Label>
                                   <Form.Control
                                        id="frmoperatorname"
                                        type="text"
                                        name="operatorname"
                                        className="txtbox"
                                        placeholder="Name"
                                        value={this.state.operatorname}
                                        onChange={this.handleChange}
                                            // onBlur={this.onBlur}
                                            required
                                    />      
                                   </Col>
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                       Select Operator                                   
                                   </Form.Label>
                                   <Select
                               value={this.state.selectedoperatorname}
                               onChange={this.handleSelectOperatorColChange}
                               options={optionsOperatorColumnNameArray.map(opt => ({ label: opt.operatorvalue, value: opt.operatorvalue }))}
                               />       
                                   </Col>  
                               </Form.Row> 
                               <Form.Row className="mt-10">
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                       Select Table                                  
                                   </Form.Label>
                                   <Select
                                   value={this.state.selectedNewTableName}
                                   onChange={this.handleSelectNewTableChange}
                                
                                   options={optionsTableNameArray.map(opt => ({ label: opt.TABLENAME, value: opt }))}
                                   />    
                                  
                                   </Col>
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                      Select Coloumn                                 
                                   </Form.Label>
                                   <Select
                                   value={this.state.selectedNewColName}
                                   onChange={this.handleSelectNewColChange}
                                
                                    options={optionsNewColumnNameArray.map(opt => ({ label: opt.COLUMN_NAME, value: opt }))}
                                   />    
                                   </Col>  
                               </Form.Row>
                               <div className="rad d-flex align-items-center  mt-10">
                                   {/* <input type="checkbox"></input>
                               <label>
                                      RND                                 
                                   </label> */}
                               {/* <Col md-6 sm-12 className="w-50">

                                   <Select className="w-25"
                                   value={this.state.selectedNewColName}
                                   onChange={this.handleSelectNewColChange}
                                
                                    options={optionsNewColumnNameArray.map(opt => ({ label: opt.COLUMN_NAME, value: opt }))}
                                   />    
                                   </Col>    */}
                               </div>
                               <Form.Row className="mt-10">
                               
                               <Button variant="warning" onClick={this.handleOperatorSaveClick}  className="ml-10">
                                   Add   
                               </Button> 
                               {/* <Button variant="primary" onClick={this.handleOperatorJoinClick}className="ml-10">
                                   Join   
                               </Button>  */}
                               <Button variant="secondary" onClick={this.handleClose} className="ml-10">
                                   Cancel 
                               </Button>
                               </Form.Row>
                               
                       <Form.Row>
                                  <div className="fullwidth row m0">
                                   <div className="col-md-12 mt-10 p0">
                                       {/* <p className="mt-10 bg-danger text-white">Grid show error</p> */}
                                   <ReactTable
                                    data={this.state.selectedOperatorList}
                                    columns={this.state.operatorcolumns}
                                    defaultPageSize={5}
                                    
                                   />  
                                   </div>      
                                   </div> 
                                   {/* Step 3 */}
                                   {/* <div className="fullwidth row m0">
                                       <div className="col-md-12 mt-10 p0">
                                           
                                           <span>Step 3: Cumulative Column:</span><span>{this.state.selectedOperatorFormat}</span>
                                       </div>
                                   </div>          */}
                        </Form.Row>         
                        <div className="d-flex flex-row justify-content-between">
                            <Button variant="primary" onClick={this.handlePrevPage} className="ml-10 mt-10">
                                    Previous
                            </Button>
                            <Button variant="primary" onClick={this.handleNextPage} className="ml-10 mt-10">
                                    Next 
                            </Button>
                        </div>
                    </Form>
                  
                    </div>
                    <div className={this.state.pagenumber==4?"compute-content card":"hidden"}>
                    <h6 className="text-center ">Select Output Coloumns</h6>
                        <Form>
                            <Form.Row className="mt-10 row">
                               </Form.Row> 
                               <Form.Row className="mt-10">
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                       Select Table                                  
                                   </Form.Label>
                                   <Select
                                   value={this.state.selectedOutNewTableName}
                                   onChange={this.handleOutSelectNewTableChange}
                                
                                   options={optionsOutputTableNameArray.map(opt => ({ label: opt.sourceTable, value: opt }))}
                                   />    
                                  
                                   </Col>
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                      Select Coloumn                                 
                                   </Form.Label>
                                   <Select
                                   value={this.state.selectedOutputColName}
                                   onChange={this.handleSelectOutputColChange}
                                
                                    options={optionsOutputColumnNameArray.map(opt => ({ label: opt.COLUMN_NAME, value: opt }))}
                                   />    
                                   </Col>  
                               </Form.Row>

                               <Form.Row className="mt-10">
                               
                               <Button variant="warning" onClick={this.handleOutputSaveClick}  className="ml-10">
                                   Add  
                               </Button> 
                               {/* <Button variant="primary" onClick={this.handleSaveClick}className="ml-10">
                                   Add Computed Coloumn  
                               </Button>  */}
                                
                               </Form.Row>
                               
                            <Form.Row>
                                  <div className="fullwidth row m0">
                                  <p className="mt-10 w-100  text-center fs-5 fw-bolder ">Output Column List</p>
                                   <div className="col-md-12 mt-10 p0">
                                        
                                   <ReactTable
                                    data={this.state.grdOutputNewList}
                                    columns={this.state.outputcolumns}
                                    defaultPageSize={5}                                    
                                   />  
                                   </div>      
                                   </div> 
                                   <div className="fullwidth row m0">
                                       <div className="col-md-12 mt-10 p0">
                                           <span>Table Names:</span><span>{this.state.tablenames}</span>
                                       </div>
                                   </div>         
                        </Form.Row>         
                        <div className="d-flex flex-row justify-content-between">
<Button variant="primary" onClick={this.handlePrevPage} className="ml-10 mt-10">
        Previous
    </Button>
    <Button variant="primary" onClick={this.handleNextPage} className="ml-10 mt-10">
                                Next 
                        </Button>
</div>
                               </Form>
                  
                    
                    

                    </div>
               
                    <div className={this.state.pagenumber==5?"compute-content ":"hidden"}>
                    <h6 className="text-center ">Add Computed Coloumn</h6>
                    <Form> 
                    <Form.Row  className='mt-10 row'>
                                   
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                      Enter Name for Computed Column                                
                                   </Form.Label>
                                   <Form.Control
                                        id="frmoperatorname"
                                        type="text"
                                        name="computedcolumnnname"
                                        className="txtbox"
                                        placeholder="Name"
                                        value={this.state.computedcolumnnname}
                                        onChange={this.handleChange}
                                            // onBlur={this.onBlur}
                                            required
                                    />      
                                   </Col>
                                   </Form.Row>     
                    <Form.Row>
                            <Col md-6 sm-12>
                                <Form.Label>
                                Select Value                                
                                </Form.Label>
                               <Select
                               value={this.state.selectedComputedTableName}
                               onChange={this.handleSelectComputedTableChange}
                               options={optionsComputedTableNameArray.map(opt => ({ label: opt.operatorvalue, value: opt.operatorvalue }))} />       
                            </Col>
                            <Col md-6 sm-12 className={outputcolumntype=="Cumulative Column"?'show':'hidden'}>
                                <Form.Label>
                                Select Computed Column                                
                                </Form.Label>
                               <Select 
                               value={this.state.selectedComputedColumnName}
                               onChange={this.handleSelectComputedColumnChange}
                               options={optionsComputedColumnNameArray.map(opt => ({ label: opt.key, value: opt.value }))} />       
                            </Col>
                    </Form.Row>                               

                    
                    <Form.Row className={outputcolumntype=="Column"?'show mt-10 row':'hidden'}>
                                   
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                      Select Table                               
                                   </Form.Label>
                                   <Select
                               value={this.state.selectedTableName}
                               onChange={this.handleSelectTableChange}
                                
                               options={optionsOutputTableNameArray.map(opt => ({ label: opt.sourceTable, value: opt }))}
                               />       
                                   </Col>
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                       Select coloumn                                   
                                   </Form.Label>
                                   <Select
                               value={this.state.selectedColName}
                               onChange={this.handleSelectColChange}
                                
                               options={optionsOutputColumnNameArray.map(opt => ({ label: opt.COLUMN_NAME, value: ["avg"] }))}
                               />       
                                   </Col>  
                               </Form.Row> 
                               <Form.Row className={outputcolumntype=="Number"?'show mt-10 row':'hidden'}>
                                   
                                   <Col md-6 sm-12>
                                   <Form.Label>
                                      Number Value                                
                                   </Form.Label>
                                   <Form.Control
                                        id="frmoperatorname"
                                        type="text"
                                        name="computednumbervalue"
                                        className="txtbox"
                                        placeholder="Name"
                                        value={this.state.computednumbervalue}
                                        onChange={this.handleChange}
                                            // onBlur={this.onBlur}
                                            required
                                    />      
                                   </Col>
                                   </Form.Row>       
                    <Form.Row>

                    </Form.Row>
        <div className=" rad d-flex align-items-center  mt-10">
            {/* <input type="checkbox"></input>
            <label>
                RND                                 
            </label>
        <Col md-6 sm-12 className="w-50">

            <Select className="w-25"
            value={this.state.selectedNewColName}
            onChange={this.handleSelectNewColChange}
        
            options={optionsNewColumnNameArray.map(opt => ({ label: opt.COLUMN_NAME, value: opt }))}
            />    
            </Col>    */}
        </div>
        <Form.Row className="mt-10 ml-20" style={{backgroundColor:'lightgrey', padding:10, width:400}}>
    
            <Button variant="secondary" onClick={this.handlePSaveClick}  className="ml-10">
                +   
            </Button> 
            <Button variant="secondary" onClick={this.handleMSaveClick}className="ml-10">
                -   
            </Button> 
            <Button variant="secondary" onClick={this.handleMLSaveClick} className="ml-10">
                * 
            </Button>
            <Button variant="secondary" onClick={this.handleDISaveClick} className="ml-10">
                /
            </Button>
            {/* <Button variant="secondary" onClick={this.handleSaveClick}  className="ml-10">
            Avg 
            </Button> 
            <Button variant="secondary" onClick={this.handleSaveClick}className="ml-10">
            Sum  
            </Button>  */}
            </Form.Row>    

            <Form.Row className="mt-10">
            
                <Button variant="warning" onClick={this.handleOutSaveClick}  className="ml-10">
                    Append   
                </Button> 
                {/* <Button variant="primary" onClick={this.handleSaveClick}className="ml-10">
                    Join   
                </Button>  */}
                <Button variant="secondary" onClick={this.handleClearComputedColumnClick} className="ml-10">
                    Clear 
                </Button>
            </Form.Row>
            <Form.Row className="mt-10 ml-20" style={{backgroundColor:'lightgrey', padding:10}}>
                <textarea value={this.state.txtdisplayoutputarea} style={{backgroundColor:'lightgrey', padding:10, width:900}}></textarea>
            </Form.Row>
            <Form.Row>
                                  <div className="fullwidth row m0">
                                  <p className="mt-10 w-100  text-center fs-5 fw-bolder ">Output Computed List</p>
                                   <div className="col-md-12 mt-10 p0">
                                        
                                   <ReactTable
                                    data={this.state.grdComputedColumnList}
                                    columns={this.state.computedcolumns}
                                    defaultPageSize={5}                                    
                                   />  
                                   </div>      
                                   </div> 
                                   <div className="fullwidth row m0">
                                       <div className="col-md-12 mt-10 p0">
                                           {/* Step 4 */}
                                           <span>Step4: Final List :</span><span>{this.state.finalizecolumnvalue}</span>
                                       </div>
                                   </div>         
                        </Form.Row>  
                <div className="d-flex flex-row justify-content-between">
                        <Button variant="primary" onClick={this.handleInsertComputedCol} className="ml-10 mt-10">
                                Add Column
                            </Button>
                            {/* <Button variant="primary" onClick={this.handleFinalizeCol} className="ml-10 mt-10">
                                Finalize Query
                            </Button> */}
                            <Button variant="primary" onClick={this.handleFinalizeResult} className="ml-10 mt-10">
                                Show Result
                            </Button>
                            <Button variant="primary" onClick={this.handlePrevPage} className="ml-10 mt-10">
                                Previous
                            </Button>
                </div>

            </Form>

            </div>
           
                    <div className={this.state.pagenumber==6?"finalresult":"hidden"}>
                    <h6 className="text-center ">Final Result</h6>
                    <Form> 
                    <Form.Row  className='mt-10 row'>
                      <Col md-12 sm-12>
                                   <ReactTable
                                    data={this.state.grdFinalResultList}
                                    columns={this.state.finalresultcolumns}
                                    defaultPageSize={5}                                    
                                   />                
                        </Col>
                     </Form.Row> 
                     <Form.Row className='mt-10 row'>
                      <Col md-12 sm-12>
                      <Button variant="primary" onClick={this.handlePrevPage} className="ml-10 mt-10">
                                Previous
                            </Button>
                      </Col>
                         
                     </Form.Row>
                    </Form>  
                 </div>
            </div>
       
      </div>
      </>);
    }
  } 
  export default Sample;