import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import * as moment from "moment";
import "moment/min/moment-with-locales";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";

//const configData= require('config');
const configData = require( '../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',  
    filetype: ['xls', 'pdf']
}
export default class LookupValuesList extends React.Component  {

    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
             lookupvaluelist: [],
             columns: [],
             originallookupvaluelist:[],
             searchInput: "",
             chkisactive:true,
             userrole:{create:''}
        }
        this.setState({Labels:[],  isFetching: true});
        this.fetchLabelMessagesAPI();

    }
    componentDidMount() {  
        
        this.fetchLookupValueList();       
        this.getColumns();
       
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'LookupValue';
        });
        let columns = [        
          {
            Header: "Lookup Name",
            accessor: "lookup_name",
            sortable: true,
            show: true,
            displayValue: "Lookup Name"
          },
          {
            Header: "Lookup Type",
            accessor: "lookup_type.label",
            sortable: true,
            show: true,
            displayValue: "Lookup Type",            
          },
          {
            Header: "Lookup Value",
            accessor: "lookup_value.label",
            sortable: true,
            show: true,
            displayValue: "Lookup Value"
                     
          },  
          {
            Header: "Object Type",
            accessor: "object_type",
            sortable: true,
            show: true,
            displayValue: "Object Type"
                     
          },    
          {
            Header: "Start Date",
            accessor: "startdate",
            sortable: true,
            show: true,
            maxWidth: 100,
            minWidth: 80,
            width: 80,
            displayValue: "Start Date"
                      
          },
          {
            Header: "End Date",
            accessor: "enddate",
            sortable: true,
            show: true,
            maxWidth: 100,
            minWidth: 80,
            width: 80,                      
            displayValue: "End Date"
            
          },
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "Status",
            maxWidth: 100,
            minWidth: 50
          },           
          {
            Header: "Comments",
            accessor: "comments",
            sortable: true,
            show: true,
            displayValue: "Comments"
                      
          },  
          
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }    
        ];
        this.setState({ columns });
      };

     fetchLookupValueList(){
        // this.setState({...this.state, isFetching: true});       
        // var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        // var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        
        axios
        .get(configData.SERVER_URL+`fincorp_getLookupValueList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                // "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setLookupValueList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
     } 

     setLookupValueList=(result)=>{ 
        
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'LookupValue';
        });
          
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){            
            this.setState({lookupvaluelist: result,originallookupvaluelist:result,userrole: userrl, isFetching: false});
            this.globalSearch();
        }
        // else if (result.Message=="Invalid Session")
        // {
        //     this.setState({
        //         ...this.state,
        //         loggedusername: undefined 
        //         });
        //     localStorage.setItem("session",undefined);
        //     window.location="/login";
        // }
        else
         this.setState({...this.state,userrole: userrl, isFetching: false})
        

    }
    
    handleChange = e => {
        let { value, name } = e.target;
        
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date

        });
        if(name=='search')
        {
            this.refs.table.handleFilterData({ name: value });
            // this.refs.table.handleFilterData({value});
        }
        else if(name=='status' && value=='Inactive'){
            const currentdate = new Date();
            currentdate.setDate(currentdate.getDate()-1);
            var momentDateObj = moment(currentdate).format('MM-DD-YYYY');
            var enddate=new Date(this.state.enddate);
            if(currentdate<enddate){
                var stdate=moment(this.state.startdate).format('MM-DD-YYYY')>moment(currentdate).format('MM-DD-YYYY')?moment(currentdate).format('MM-DD-YYYY'):moment(this.state.startdate).format('MM-DD-YYYY');

                this.setState({startdate:stdate, enddate:momentDateObj });
            }
            
        }
    };

    selectImgFormatter = (cell, row) => {              
        return (
            <input type="checkbox"
                className="checkbutton"
                // onClick={() => handleSelectClick(row)}
            >
                 
            </input>
        );
    }

    //  handleClick = () => {
    //     this.change(this.state);
    //     this.setState(initialValues);
    // };

     handleEditClick = (row) => {
           
        this.props.history.push("/lookupvalue?id=" + row.original._id);
    };

    //  viewImgFormatter = (cell, row) => {              
    //     return (
    //         <a
    //             className="viewbutton"
    //             aria-label="Edit"
    //             onClick={() => this.handleEditClick(row)}
    //         >
    //             <i className="">
    //                 <img
    //                     alt="View"
    //                     className=""
    //                     src="/images/vieworange_icon.png"
    //                 />
    //             </i>
    //         </a>
    //     );
    // }    

    

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
    }
 
    // const [state, setState] = useState(initialValues);

     
        handleChange1 = event => {
          
            this.setState({ searchInput: event.target.value }, () => {
              this.globalSearch();
            });
          };
          toggleChange = () => {
            this.setState({
                chkisactive: !this.state.chkisactive,
            });
            this.globalActiveSearch(!this.state.chkisactive) ;
          }
    
          globalActiveSearch = (param) => {
                
            let { searchInput } = this.state;       
            let filteredData = this.state.originallookupvaluelist.filter(value => {
                if(param){
    
                    return (
                        (value.lookup_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.lookup_type.label.toLowerCase().includes(searchInput.toLowerCase()) ||             
                        value.lookup_value.lebel.toLowerCase().includes(searchInput.toLowerCase()) ||                        
                        value.comments.toLowerCase().includes(searchInput.toLowerCase()) ||                        
                        value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                        ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                        value.status.toString().includes('Active') 
                       
                    );
    
                }else{
                    return (
                        value.lookup_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.lookup_type.label.toLowerCase().includes(searchInput.toLowerCase()) ||             
                        value.lookup_value.label.toLowerCase().includes(searchInput.toLowerCase()) ||                     
                        value.comments.toLowerCase().includes(searchInput.toLowerCase()) ||      
                        value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                    value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
              );
            }
            });
            this.setState({ lookupvaluelist: filteredData });
          };
          globalSearch = () => {
            let { searchInput } = this.state;
              
            let filteredData = this.state.originallookupvaluelist.filter(value => {
                  
            if(this.state.chkisactive){    
              return (
    
                ( 
                       value.lookup_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.lookup_type.label.toLowerCase().includes(searchInput.toLowerCase()) ||             
                        value.lookup_value.label.toLowerCase().includes(searchInput.toLowerCase()) ||
                       
                        value.comments.toLowerCase().includes(searchInput.toLowerCase()) ||                   
                        value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                        ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                        value.status.toString().includes('Active') 
                       
                    );
            }else{
                return (
    
                   value.lookup_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                   value.lookup_type.label.toLowerCase().includes(searchInput.toLowerCase()) ||             
                   value.lookup_value.label.toLowerCase().includes(searchInput.toLowerCase()) ||
                  
                   value.comments.toLowerCase().includes(searchInput.toLowerCase()) ||
                   value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                   ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                   value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
                  );   
            }
            });
            this.setState({ lookupvaluelist: filteredData });
          };

        
        handleSubmit = () => {
            this.change(this.state);
            this.setState(initialValues);
        };
         handleSaveClick = () => {
    
        };
    
         
        // handleEditClick = (row,event) => {
        //     this.props.history.push("/valuelookupinfo?id=" + row.original._id);
        // };
        
        handleDeleteClick=(row,event)=>{
            if(window.confirm('Are you sure to delete this record?')){
                event.preventDefault();
                event.stopPropagation();           
                if(row.original._id!=undefined){
                    axios
                    .post(configData.SERVER_URL+`fincorp_deleteLookupValue`,
                    
                    {'_id': row.original._id}
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                    .then(
                        res => this.reloadDataGrid(res.data)
                        
                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });
                // event.preventDefault();
                }
                else{
                    //error            
                    toast.warn("LookupValue Not Deleted");
                }
            }
            else{
                event.preventDefault();
                event.stopPropagation();
            }
          }
          reloadDataGrid=(res)=>{       
                  
            toast.success("LookupValue Deleted Successfully");
            setTimeout( this.fetchLookupValueList(),4000);
          }   
    

        editImgFormatter = (cell, row) => {              
            return (
                <a
                    className="editbutton"
                    aria-label="Edit"
                    onClick={() => this.handleEditClick(row)}
                >
                    <i className="">
                        <img
                            alt="Edit"
                            className=""
                            src="/images/edit_icon.png"
                        />
                    </i>
                </a>
            );
        }
    
        deleteImgFormatter = (cell, row) => {              
            return (
                <a
                    className="deletebutton center"
                    aria-label="Delete"
                    onClick={() => this.handleDeleteClick(row)}
                >
                    <i className="">
                        <img
                            alt="Delete"
                            className=""
                            src="/images/delete_icon.png"
                        />
                    </i>
                </a>
            );
        }
    

    
    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const {searchInput} =this.state;
    return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='LookupValueList_Title' && lbl.pageName=='LookupValueList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))} 
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='LookupValueList_Title' && lbl.pageName=='LookupValueList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))} 
                    </span>
                <ToastContainer />
                </div>

                <div className="fullwidth">
                        <div className="float-left">
                        <div className="ml-2">                                            
                        <Link to="/lookupvalue" className= {this.state.userrole.create=='Y'?"btn btn-primary":"hidden"}>
                        {Labels.filter(lbl => lbl.keyPair=='LookupValueList_AddBtn' && lbl.pageName=='LookupValueList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}  
                        </Link>  
                        </div>  
                        </div> 
                        <div className="float-right"> 
                        <div className="isactivecontainer">
                            <span>Active</span>
                            <input type="checkbox" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            aria-label='Status'
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">   
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='LookupValueList_Search' && lbl.pageName=='LookupValueList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}
                        </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"                            
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />  
                        </div>                     
                    </div>                     
                        
                                          
                    </div>  

                    <div className="fullwidth mt-50">                          
                        <div className="row col-md-12 m0 p0">
                        <ReactTable
                        data={this.state.lookupvaluelist}
                        columns={this.state.columns}
                        defaultPageSize={20}
                        className="-striped -highlight fullwidth"
                        /> 
                        
                        </div>
                        <br />
                    </div> 
               
                <br />
               
            </div>



        </>
    );
}
}

