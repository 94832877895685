import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";

 
//const configData= require('config');
const configData = require( '../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
    
}
export default class ChangePassword extends React.Component  {

    constructor(...args) {
        super(...args)

        this.state = {                      
             oldpassword:undefined,
             newpassword:undefined,
             confirmpassword:undefined,
             userid:undefined 
          
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();
       
    }
     componentDidMount() {
                
        var sessionval= JSON.parse(localStorage.getItem("session")).Result[0]; 
        
        this.setState({...this.state,userid: sessionval.login, isFetching: false})     
    }

 handleChange = e => {
        let { value, name } = e.target;
         
        this.setState(prevState => ({
                ...prevState,  
                [name]: value  
        }));
        
    };

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
   
       handleSaveClick = (event) => {
          
            //event.preventDefault();
            if(this.state.oldpassword == undefined || this.state.oldpassword.length==0){
                toast.warn("Enter current password");
                event.preventDefault();
            
            }
            else if (this.state.newpassword == undefined || this.state.newpassword == "") {
                toast.warn("Enter new password");
                event.preventDefault();
    
    
            }
            else if (this.state.confirmpassword == undefined || this.state.confirmpassword == "") {
                toast.warn("Enter confirm password");
                event.preventDefault();
    
    
            }
            else if (this.state.newpassword !=   this.state.confirmpassword ) {
                toast.warn("New password and confirm password not same");
                event.preventDefault();
    
    
            }
                  
            else{
                   var currentdate=new Date();
                    axios
                    .post(configData.SERVER_URL+`fincorp_updateUserPassword`,
                    
                    { 'username':this.state.userid, 'oldpassword':this.state.oldpassword,'newpassword':this.state.newpassword,'pwdchgddate':currentdate}
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                    .then(
                        res =>  this.UpdatePasswordChanges(res)
                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });
                  
               
            
        
        }
    };   
    
    UpdatePasswordChanges=(res)=>{
        
        console.log(res);
        if(res.data.Status!=-1){
            toast.success("Password Changed Successfully");
            setTimeout( this.props.history.push("/home"),4000);
        }
        else{
            toast.warn(res.data.Message);
        }
    }
    handleCancelClick = () => {
        this.props.history.push("/home");
    }
    
     
    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        
    return (
        <>
            <div className="container approvallimits-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='ChangePwd_Title' && lbl.pageName=='ChangePassword').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Form >
                    <div className="centeralign">
                        <span className="mainheadings">
                        {Labels.filter(lbl => lbl.keyPair=='ChangePwd_Title' && lbl.pageName=='ChangePassword').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}              
                        </span>
                    </div>  


                    {/* <div className="margin-t10 col-md-6">   */}
                    <Col md={{ span: 6, offset: 3 }}>              
                    
                        <Row className="margin-t10"> 
                           
                            <Col md={4}>
                                    <Form.Label className="required" for="frmcurrentpwd">
                                    {Labels.filter(lbl => lbl.keyPair=='ChangePwd_CurrentPwd' && lbl.pageName=='ChangePassword').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>
                            </Col>
                            <Col md={8}>
                                    <Form.Control
                                        id="frmcurrentpwd"
                                        type="password"
                                        className="form-control"
                                        controlId="currentpwd"
                                        name="oldpassword"
                                         onChange={this.handleChange}
                                         value={this.state.oldpassword }
                                        required
                                    >
                                    </Form.Control>
                            </Col> 
                        </Row>
                    
                        <Row className="margin-t10"> 
                           
                            <Col md={4}>
                                    <Form.Label className="required" for="frmnewpwd">
                                    {Labels.filter(lbl => lbl.keyPair=='ChangePwd_NewPwd' && lbl.pageName=='ChangePassword').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                     ))}    
                                    </Form.Label>
                            </Col>
                            <Col md={8}>
                                    <Form.Control
                                        id="frmnewpwd"
                                        type="password"
                                        className="form-control"
                                        controlId="newpwd"
                                        name="newpassword"
                                        onChange={this.handleChange}
                                        value={this.state.newpassword }
                                        required
                                    >
                                    </Form.Control>
                            </Col> 
                        </Row>
                   
                        <Row className="margin-t10"> 
                           
                            <Col md={4}>
                                    <Form.Label className="required" for="frmconfirmpwd">
                                    {Labels.filter(lbl => lbl.keyPair=='ChangePwd_ConfirmPwd' && lbl.pageName=='ChangePassword').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                     ))}
                                    </Form.Label>
                            </Col>
                            <Col md={8}>
                                    <Form.Control
                                        id="frmconfirmpwd"
                                        type="password"
                                        className="form-control"
                                        controlId="confirmpwd"
                                        name="confirmpassword"
                                        onChange={this.handleChange}
                                        value={this.state.confirmpassword }
                                        required
                                    >
                                    </Form.Control>
                            </Col> 
                        </Row>
                        <Row className="margin-t10"> 
                           
                           <Col md={{ span: 8, offset: 4 }}>
                                <div className=" mt-10"> 
                                <Button type="button" variant="primary" onClick={this.handleSaveClick} >               
                                {Labels.filter(lbl => lbl.keyPair=='ChangePwd_SaveBtn' && lbl.pageName=='ChangePassword').map(filteredPerson => (
                                                <span>
                                                {filteredPerson.keyValue}
                                                </span>
                                                ))}                        
                                </Button>                  
                                <Button className="" variant="secondary" className="ml-10" onClick={this.handleCancelClick}>
                                {Labels.filter(lbl => lbl.keyPair=='ChangePwd_CancelBtn' && lbl.pageName=='ChangePassword').map(filteredPerson => (
                                                <span>
                                                {filteredPerson.keyValue}
                                                </span>
                                                ))}
                                </Button>                    
                                </div>  
                            </Col>
                        </Row>
                    </Col>
            
                </Form>
            </div>
        </>
    );
}
}
 