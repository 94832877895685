let tableArr = [];
// comment let tableNames=['tableArr1','tableArr2','tableArr3'];
//  tableArr[0] = [{emp_id:1,emp_name:'john'}
//         ,{emp_id:2,emp_name:'Bill'},
//         {emp_id:3,emp_name:'Chris'}];

// tableArr[0]=[{emp_id:1,emp_name:'Kumar'},{emp_id:2,emp_name:'Ajay'},{emp_id:3,emp_name:'Kannan'},{emp_id:4,emp_name:'Vijay'},{emp_id:5,emp_name:'Selvam'}];

//  tableArr[1] = [{dept_id:1,dept_name:'design',emp_id:1}
//         ,{dept_id:2,dept_name:'production',emp_id:2},
//         {dept_id:3,dept_name:'sales',emp_id:3}];


// tableArr[1] =[{dept_id:1,dept_name:'Design',emp_id:2},{dept_id:2,dept_name:'Prototype',emp_id:3},{dept_id:3,dept_name:'Production',emp_id:1},{dept_id:4,dept_name:'Sales',emp_id:4},
// {dept_id:5,dept_name:'Marketing',emp_id:5}];    

//  tableArr[2]=[{emp_id:1,basic_pay:20000n,benefits:3000,deductions:2000,net_pay:21000},
//         {emp_id:2,basic_pay:30000n,benefits:6000,deductions:6000,net_pay:30000},
//         {emp_id:3,basic_pay:40000n,benefits:7000,deductions:12000,net_pay:35000}
//         ];

// tableArr[2]=[{emp_id:1,basic_pay:25000,benifits:5000,deductions:3000,net_pay:27000},
// {emp_id:2,basic_pay:30000,benifits:6000,deductions:4000,net_pay:32000},
// {emp_id:3,basic_pay:28000,benifits:5000,deductions:3000,net_pay:30000},
// {emp_id:4,basic_pay:40000,benifits:10000,deductions:6000,net_pay:44000},
// {emp_id:5,basic_pay:16000,benifits:4000,deductions:1000,net_pay:19000}];


    let tableNames = ['emp','dept','pay'];
    
  let outElements =['emp.emp_id','emp.emp_name','dept.dept_name','pay.net_pay','r$.percent_pay'];  
  let colRelations = 'emp.emp_id,dept.emp_id|emp.emp_id,pay.emp_id';
  // let colrel = colRelations.split('|');        
  // colrelArrSort=["emp.emp_id,pay.emp_id","emp.emp_id,dept.emp_id"];  
  
  function getTableName(tabcolName){
    return tabcolName.substring(0,tabcolName.indexOf('.')); 
  }
  function getColumnName(tabcolName){
    return tabcolName.substring(tabcolName.indexOf('.') +1);
  }
  function gettableNamesDistinct(outElem){
    let tableNamesDist =[];
  outElem.forEach(e =>{
    let  tname = e.substring(0,e.indexOf('.'))
    let  cname = e.substring(e.indexOf('.') +1);
       if (((tableNamesDist.indexOf(tname)) == -1) &&
        (tname.indexOf('#') == -1))
       {
       tableNamesDist.push(tname);
       }
      } 
   )
   return tableNamesDist;
  }
  let tableNamesDistinct = gettableNamesDistinct(outElements);
  
     console.log('tablenamesdistinct');
   console.log(tableNamesDistinct);
   
   function getColrelArrSort(colRel,tNamesDistinct){
   let colrelArr = colRel.split('|');
   console.log(colrelArr);
   let crelArrSort = [];
   let tableNameSort =[tNamesDistinct[0]];
   let tnamerel1,tnamerel2;
   let relArr = []
   for (var ii = 0;ii < tNamesDistinct.length;ii++){
  
       colrelArr.forEach(e =>{
          relArr = [];
          relArr = e.split(',');
          tnamerel1 = relArr[0].substring(0,relArr[0].indexOf('.')); 
          tnamerel2 = relArr[1].substring(0,relArr[1].indexOf('.')); 
  
          if (tnamerel1 == tableNameSort[ii]) {
              if ((crelArrSort.indexOf(e)) == -1) 
             {
              crelArrSort.push(e);
              if ((tableNameSort.indexOf(tnamerel2)) == -1) {
                  tableNameSort.push(tnamerel2);
              }  
             }           
          } else 
  
          if (tnamerel2 == tableNameSort[ii]) {
              if ((crelArrSort.indexOf(e)) == -1) 
             {
              crelArrSort.push(e);
              if ((tableNameSort.indexOf(tnamerel1)) == -1) {
                  tableNameSort.push(tnamerel1);
              }  
             }
          } 
      
   }
   )
  //    tableNameSort.push(tableNamesDistinct[ii]);
     console.log(tableNamesDistinct[ii]);
   }
   return crelArrSort;
  }
  let colrelArrSort = getColrelArrSort(colRelations,tableNamesDistinct);
   console.log(colrelArrSort);

  function sum(inArr,sumCol){
    let sumOut = 0;
    inArr.forEach(element => {

        Object.entries(element).forEach(([key, value]) => {
        if ((`${key}`) ==sumCol){
             sumOut = sumOut+ parseFloat((`${value}`));    
       }
          });

    }
    );
       return sumOut;
   }

let sumValue = 0;//sum(tableArr[2],'net_pay');
console.log(sumValue);

function retquery(arrName,colName,colValue){
    let match;
   arrName.forEach(element => {
     if (element[colName] === colValue) {
        match = element; }
    }
    );
    return match;
}

function setRelColName(tNames,outElems){
    console.log(tNames);
     console.log(outElems);
    
     let reltabcolName;
     let relcolMap = new Map();
   outElems.forEach(e=>{
     
     if (getTableName(e)==tNames[0]) {
       relcolMap.set(e,e);
     } else if (e.indexOf('$') ==-1){
       reltabcolName = getRelColName(e);
       relcolMap.set(e,reltabcolName);
     }
     
   }
   )
     return relcolMap;
   }
   let relMap = setRelColName(tableNames,outElements);
   console.log("relMap");
   console.log(relMap);
   
   function getRelColName(relcolumnName){
     let reltableName = getTableName(relcolumnName); 
            let matchFound = -1;
            let relatedColumn;
       colrelArrSort.forEach(e =>{
           let   relArr = [];
           relArr = e.split(',');
          let tnamerel1 = getTableName(relArr[0]); 
          let tnamerel2 = getTableName(relArr[1]); 
          
           if (reltableName == tnamerel1 && (matchFound == -1)) {
               matchFound = 1;
               relatedColumn = relArr[1];
           }   
           if (reltableName == tnamerel2 && (matchFound == -1)) {
               matchFound = 1;
               relatedColumn = relArr[0];
           }   
       }
         )
       return relatedColumn;
     }

export function output(res){
  tableArr=res;
  var param1=tableArr[0];
    let out1 = [];;
    let loop1 = 0;
    
 param1.forEach(element => {
     console.log(element);
     let line = {};
     let cname,tname;
     let rowDetail;
     let relcolName,relcolpart;
     outElements.forEach(e =>{

        tname = getTableName(e);
        cname = getColumnName(e);
       //  console.log(cname);
        let tableIndex =tableNames.indexOf(tname);

        // console.log(str.substring(0,str.indexOf('.')));
        // console.log(tableNames.indexOf('dept'));

        if (tableIndex == 0 ){
            line[e] = element[cname];

        }
        else if (e.indexOf('$') ==-1){
        
         console.log(e);
        rowDetail = retquery(tableArr[tableIndex],getColumnName(relMap.get(e)),line[relMap.get(e)]);
        line[e] = rowDetail[cname];
       
    //   if (e == 'pay.net_pay') {
    //     rowDetail = retquery(tableArr[tableIndex],'emp_id',element.emp_id);
    //     line[e] = rowDetail['net_pay'];
    //    }
    }
    if (e == 'r$.percent_pay') {
        // console.log(e);
    // let  payrow1 = retquery(pay,'emp_id',element.emp_id);
    line[e] = line['pay.net_pay']/sumValue * 100;
    }
    }) 
    out1[loop1] = line;
     loop1++;
 }
 );
    return out1;
    //  console.log(out1);
}
//let out2 = output();
//console.log(out2);

