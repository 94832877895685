import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "react-table/react-table.css"; 

//const configData= require('config');
const configData = require( './../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
    searchInput: "",
    chkisactive:true 
   
}
export default class Dashboard extends React.Component  {

    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
             processedfileslist: [],
             columns: [],
             originalprocessedfileslist:[],
             searchInput: "",
             chkisactive:false
        }
        this.setState({Labels:[], isFetching: true});
        // this.fetchLabelMessagesAPI();

    }
    componentDidMount() {     
       
       
    }

     handleChange = e => {
        let { value, name } = e.target;
       
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.componentDidCatchstate,
            [name]: value,
            date
        });
    };
     handleClick = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

     handleEditClick = (row) => {
        this.props.history.push("/ProcessedFile?id=" + row.id);
    };

     viewImgFormatter = (cell, row) => {              
        return (
            <a
                className="viewbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="View"
                        className=""
                        src="/images/vieworange_icon.png"
                    />
                </i>
            </a>
        );
    }    

   
     handleSelectClick=(row)=>{

    }

     selectImgFormatter = (cell, row) => {              
        return (
            <input type="checkbox"
                className="checkbutton"
                // onClick={() => handleSelectClick(row)}
            >
                 
            </input>
        );
    }

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    render() {
   
    return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">Welcome to Dashboard</span>
                <ToastContainer />
                </div>
               
            </div>



        </>
    );
}
}

