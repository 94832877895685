import React from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import '../Home/Home.css';
import './ExecutablesHome.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import ExecutableProgram from './ExecutableProgram'
import ExecutableProcess from './ExecutableProcess' 



//const configData= require('config');
const configData = require( '../../config.json');

export default class ExecutablesHome extends React.Component {
    constructor(...args) {
        super(...args)

        this.state = {
            value: '',
            show: false,
            servicefiles: [{
                ser_name: 'HR Service',
                ser_desc: 'Resources',
                ser_code: '1000',
                ser_type: 'HR',
                level: '1',
                predecessor: '32',
                startdate: '12-20-2020',
                enddate: '12-20-2022',
                status: 'Active'
            }]
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

    }

     handleBtnSubmit = (param) => {
        // change(state);
        //setState(initialValues);
        
        if (param.currentTarget.id == "executableprogram-btn")
            window.location = '/executableprogramlist';
        else if (param.currentTarget.id == "executableprocess-btn")
            window.location = '/executableprocesslist';
       
       
    };

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
     
    handleSubmit = () => {
        // change(state);
        //setState(initialValues);
        alert("Work in Progress..");
    };

     handleReportSubmit = () => { 
      
    };
    handleSaveClick = () => {

    };
     //add new member
    
     
      onChangeValueHandler = (val) => {
        this.setState({ value: val.target.value })
      }

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
    return (
        <div className='container-fluid executables-container'>

                <Breadcrumb className='ml-20'>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='Home_ExecutableProgram' && lbl.pageName=='Home').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    
                    </Breadcrumb.Item>
                </Breadcrumb>
           
            <ToastContainer />
            <br/>
            <Tabs>
                <TabList>
                    <Tab>
                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProgram_Title' && lbl.pageName=='ExecutableProgramSetup').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))}    
                    </Tab>
                    <Tab>
                    {Labels.filter(lbl => lbl.keyPair=='ExecutableProcess_Title' && lbl.pageName=='ExecutableProcessSetup').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))}
                    </Tab>
                                                        
                   
                </TabList>               
                
                <TabPanel>
                 <ExecutableProgram  />                    
                </TabPanel>                
                <TabPanel>
                <ExecutableProcess  />  
                </TabPanel>
               
                 
            </Tabs>
          
        </div>
    );
        }
}


 
