import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { Link } from "react-router-dom";
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";
const queryString = require('query-string');
// const _ = require('lodash');

//const configData= require('config');
const configData = require( './../../config.json');
const initialValues = {
    weight: '',
    height: '',
    date: '',
    custom: [{
        keypair: 'Roles_Name',
        keyvalue: 'Role Name',
        page:'Roles'
    }]
} 

export default class Customize extends React.Component  {
    constructor(...args) {
        super(...args)
        var querystring = queryString.parse(this.props.location.search);

        console.log(queryString.parse(this.props.location.search));
        
        this.state = {
            show: false,
            items:  [],
            lookupItem:{},
            selecteditems:[],
            originalselecteditems:[],
            columns:[],            
            searchInput: "",
            filteredData: [],
            userrole:{create:''}
        }

       
    }
     componentDidMount() {
       
      this.fetchCustomMessageAPI();
      this.getColumns();
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'CustomizeLabels';
        });
        let columns = [
          {
            Header: "Page Name",
            accessor: "pageName",
            sortable: true,
            show: true,
            displayValue: "Page Name" 
          },
          {
            Header: "Key Value",
            accessor: "keyValue",
            sortable: true,
            show: true,
            displayValue: "Key Value"
          },            
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        //   , 
        //   {
        //     Header:"Delete",
        //     maxWidth: 100,
        //     minWidth: 70,
        //     width: 70,
        //     Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
        //         className="deletebutton"
        //         aria-label="Delete"
        //         onClick={(event) => this.handleDeleteClick(row,event)}
        //     >
        //         <i className="">
        //             <img
        //                 alt="Delete"
        //                 className=""
        //                 src="/images/delete_icon.png"
        //             />
        //         </i>
        //     </a></div></div>)
        //   }
        ];
        this.setState({ columns });
      };

    fetchCustomMessageAPI = () => {
        console.log('config data'+configData.SERVER_URL);

        this.setState({...this.state, isFetching: true});
        fetch(configData.SERVER_URL+'fincorp_getCustomMessage')
            .then(response => response.json())
            .then(result => {
                var pgname= _.uniqBy(result, 'pageName');
                
                this.setState({items: result,selecteditems: result,pgNames:pgname, originalselecteditems:result, isFetching: false})
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };

    //const [state, setState] = useState(initialValues);

    handleChange = e => {
        let { value, name } = e.target;
       
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date,
            customMessage:''

        });
        
        if(name=='keypage'){
            //var filtereditm= _.uniqBy(this.state.items, 'pageName');
            var filtereditm = _.map(this.state.items, function(o) {
                if (o.pageName == value) return o;
            });
            filtereditm = _.without(filtereditm, undefined)
            
            this.setState({selecteditems: filtereditm , isFetching: false})
        }
    };

     handleSelect = ({ start, end }) => {
        this.setState({ show: false, editshow: true});
        
    }

     handleSubmit = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

     handleEditClick = (row,event) => {
         
         this.state.keyvalue=row.original.keyValue;
          this.state.keypair=row.original.keyPair;
          this.state.keypage=row.original.pageName;
         this.state.keydesc=row.original.description;        
         this.state._id=row.original._id;
        this.setState({ show: true, editshow:false  });
        //this.props.history.push("/Item?id=" + row.id);
    };

     

     handleSaveClick = (event) => {        
         var itmlist=this.state.items;
                
             //event.preventDefault();
             if(this.state.keyvalue ==undefined || this.state.keyvalue.length==0){
                toast.warn("Enter KeyValue");
                event.preventDefault();            
            }
            
            else if(this.state.keypage ==undefined || this.state.keypage.length==0){
                toast.warn("Enter KeyPage");
                event.preventDefault();            
            }
            else if(this.state.keydesc ==undefined || this.state.keydesc.length==0){
                toast.warn("Enter Description");
                event.preventDefault();            
            }            
            else{
                
                //update
                if(this.state._id!=undefined){
                    axios
                    .post(configData.SERVER_URL+`fincorp_updateCustomMessage`,
                    
                    {'_id':this.state._id,'keyPair':this.state.keypair, 'keyValue':this.state.keyvalue,
                    'description':this.state.keydesc,'pageName':this.state.keypage}
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                    .then(
                        res => this.ReloadGridData(res)
                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });
                }               
        
        }
    };
    ReloadGridData=(res)=>{
        console.log(res.data);
        toast.success("Label Updated Successfully");
        this.setState({  show: false, editshow:false });
        this.fetchCustomMessageAPI();
    }
    
     handleClose=()=> {

        this.setState({ show: false });
    }
    
    
     handleEdit = () => {
        this.setState({ show: false, editshow:true });
    }
     handleEditClose = () => {
        this.setState({ show: false, editshow: false });
    }
    // editImgFormatter = (cell, row) => {              
    //     return (
    //         <a
    //             className="editbutton"
    //             aria-label="Edit"
    //             onClick={() => this.handleEditClick(row)}
    //         >
    //             <i className="">
    //                 <img
    //                     alt="Edit"
    //                     className=""
    //                     src="/images/edit_icon.png"
    //                 />
    //             </i>
    //         </a>
    //     );
    // }
    
    handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };
      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalselecteditems.filter(value => {
            
          return (
            value.pageName.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.keyValue.toLowerCase().includes(searchInput.toLowerCase()) 
           
            
          );
        });
        this.setState({ selecteditems: filteredData });
      };

    

    render() {
        let { data, columns, searchInput } = this.state; 
       
        return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                       Customize Values
                    </Breadcrumb.Item>

                </Breadcrumb>
 
                    {/*  add/edit popup details of Lookup Item */}
                    <div>
                        <Modal
                            className="lookupitem-modal"
                             show={this.state.show}
                            onHide={this.handleClose}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title className="margin_auto">
                                    <h3> Customize Values</h3> 
                                    {/* <button type="button" className="modal-editicon" onClick={ this.handleEdit }><Icon >editFilled</Icon> </button> */}
                                    {/* <button type="button"className="modal-deleteicon" onClick={ this.handleDelete }><Icon >deleteRounded</Icon> </button> */}
                                    {/* <i className="modal-editicon" onClick={ this.handleEdit }><Icon >deleteRounded</Icon> </i>
                                    <i className="modal-deleteicons"><Icon >deleteRounded</Icon> </i> */}
                                    
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Row>
                                    <Form.Label className="keypage">Page Name :</Form.Label>{this.state.keypage} 
                                    </Form.Row>
                                    <Form.Row>
                                    <Form.Label className="keypair" for='frmlookupitem'>Field :</Form.Label>  {this.state.keypair}
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formLookUpItem">
                                            <Form.Label className="labelvalue required">Label Name</Form.Label>
                                            <Form.Control
                                        id="frmlookupitem"
                                        className="desc-width"
                                        as="textarea"
                                        rows="1"
                                        name="keyvalue"
                                        placeholder="Key Value"
                                        disabled={this.state.isedit}
                                        value={this.state.keyvalue}
                                        onChange={this.handleChange}
                                        required
                                    />                                
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formDesc">
                                            <Form.Label className="labelvalue required" for='frmdesc'>Description</Form.Label>
                                            <Form.Control
                                        id="frmdesc"
                                        className="desc-width"
                                        as="textarea"
                                        rows="1"
                                        name="keydesc"
                                        placeholder="Description"
                                        disabled={this.state.isedit}
                                        value={this.state.keydesc}
                                        onChange={this.handleChange}
                                        required
                                    />                                
                                        </Form.Group>
                                    </Form.Row>                                                                  
                                    
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" aria-label='Close' onClick={this.handleClose} >
                                    Close
                                </Button>
                                <Button variant="primary" aria-label='Save' onClick={this.handleSaveClick}>
                                    Save
                                 </Button> 
                            </Modal.Footer>
                        </Modal>
                    </div>


                <div className="centeralign">
                    <span className="mainheadings">Customize Values</span>
                    <ToastContainer />
                </div>
               
                <div className="fullwidth">
                    <div className="float-left">
                    <Form.Group as={Col} controlId="formPageName">
                                            {/* <Form.Label> Page Name</Form.Label> */}
                                            <Form.Control
                                            id="keypage"
                                            as="select"
                                            name="keypage"
                                             value={this.state.keypage}
                                            onChange={this.handleChange}
                                            aria-label="Select Page"
                                            required
                                    >
                                     <option value="SELECT PAGE">Select Page</option>

                                    
                                        {this.state.pgNames &&
                                            this.state.pgNames.length > 0 &&
                                            this.state.pgNames.map(pgnm => {
                                                return <option value={pgnm.pageName}> {pgnm.pageName}</option>;
                                            })}
                                    
                                        
                                    </Form.Control>
                                        </Form.Group>
                    </div>
                    <div className="float-right">
                        <span className="searchlabel">Search </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="searchInput"
                            placeholder=""
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />
                        {/* <Input
                            size="large"
                            name="searchInput"
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                            
                        /> */}
                    </div>
                </div>              
                  
                <div className="fullwidth row m0">
                <div className="col-md-12  p0">
                     <ReactTable
                    data={this.state.selecteditems}
                    columns={this.state.columns}
                    defaultPageSize={20}
                    className="-striped -highlight fullwidth"
                    />  
                </div>      
               </div>               
                    <div className="grdCls">
                    {/* <BootstrapTable
                        data={this.state.selecteditems}
                        striped
                        hover
                        condensed
                        pagination={true}
                        insertRow
                        deleteRow
                        search
                        className=""
                        tableHeaderClass="my-header-class"
                        tableBodyClass="my-body-class"
                        containerClass="my-container-class"
                        tableContainerClass="my-table-container-class"
                        headerContainerClass="usersystem-grid-header-container-class"
                        bodyContainerClass="my-body-container-class"
                    >   
                         <TableHeaderColumn dataField="pageName" width="20%" dataSort>
                        Page Name
                        </TableHeaderColumn>                        
                        <TableHeaderColumn dataField="keyValue" width="25%" isKey dataSort>
                        Label Name
                        </TableHeaderColumn> 
                        <TableHeaderColumn
                            dataAlign="center"
                            width="4%"
                            dataFormat={this.editImgFormatter}
                        >
                            Edit   
                        </TableHeaderColumn>                       
                    </BootstrapTable> */}
                    </div>
               
                <br />
            </div>
        </>
    );
    }
} 