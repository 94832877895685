import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '@material-ui/core/Icon';
import * as moment from "moment";
import "moment/min/moment-with-locales";
import _ from "lodash";
import './Role.css';
  
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 

//const configData= require('config');
const configData = require( './../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
    roles: [{
        name: 'admin',
        status: 'yes'
    }]    
    
}

function afterSearch(searchText, result) {
    console.log('Your search text is ' + searchText);
    console.log('Result is:');
    for (let i = 0; i < result.length; i++) {
      console.log('Fruit: ' + result[i].id + ', ' + result[i].name + ', ' + result[i].price);
    }
  }  
  const options = {
    afterSearch: afterSearch  // define a after search hook
  };

export default class RoleList extends React.Component  {
    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
            roles:  [],
            columns: [],
            originalRoles:[],
            searchInput: "",
            chkisactive:true,
            userrole:{create:''}
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

    }
     componentDidMount() {
     
      this.fetchRolesWithFetchAPI();
      this.getColumns();
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Role';
        });
        let columns = [
          {
            Header: "Role Name",
            accessor: "name",
            sortable: true,
            show: true,
            displayValue: " Role Name" 
          },
          {
            Header: "Short Name",
            accessor: "short_name",
            sortable: true,
            show: true,
            displayValue: " Short Name",
            maxWidth: 100,
            minWidth: 120,
            width: 120 
          },
          {
            Header: "Description",
            accessor: "description",
            sortable: true,
            show: true,
            displayValue: "Description",
            // Cell:row=>(<span>{row.original.description}</span>)
            Cell:row=>(<span>{row.original.description.length > 45 ?
                `${row.original.description.substring(0, 45)}...` : row.original.description} </span>)
             
          },
          {
            Header: "Start Date",
            accessor: "start_date",
            sortable: true,
            show: true,
            displayValue: "Start Date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: "End Date",
            accessor: "end_date",
            sortable: true,
            show: true,
            displayValue: "End Date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },          
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "Status",
            maxWidth: 100,
            minWidth: 56,
            width: 56,
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };
   

    fetchRolesWithFetchAPI = () => {  
              
        this.setState({...this.state, isFetching: true});
        fetch(configData.SERVER_URL+'fincorp_getRoles')
            .then(response => response.json())
            .then(result => {this.bindRoleList(result)
                
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };
    bindRoleList=(result)=>{
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Role';
        });
        
        if(result!='No document(s) found with defined find criteria!' && result.length>0){
            this.setState({roles: result,originalRoles:result,userrole: userrl, isFetching: false})   
            this.globalSearch();
        }       
        else
            // this.setState({roles: [], isFetching: false})
            this.setState({...this.state,userrole: userrl, isFetching: false})

    }
    
    handleChange = e => {
        
        let { value, name } = e.target;
        
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date

        });
        //included filter option 
        if(name=='search')
        {
            this.refs.table.handleFilterData({ name: value });
            // this.refs.table.handleFilterData({value});
        }
        else if(name=='status' && value=='Inactive'){
            const currentdate = new Date();
            currentdate.setDate(currentdate.getDate()-1);
            var momentDateObj = moment(currentdate).format('MM-DD-YYYY');
            var enddate=new Date(this.state.end_date);
            if(currentdate<enddate){
                var stdate=moment(this.state.start_date).format('MM-DD-YYYY')>moment(currentdate).format('MM-DD-YYYY')?moment(currentdate).format('MM-DD-YYYY'):moment(this.state.start_date).format('MM-DD-YYYY');

                this.setState({start_date:stdate, end_date:momentDateObj });
            }
            
        }
    };

 handleSubmit = () => {
    
        // change(state);
        // setState(initialValues);
    };

    handleEditClick(row,event) {       
        this.props.history.push("/Roles?id=" + row.original._id);
        
      }
      handleDeleteClick=(row,event)=>{
        if(window.confirm('Are you sure to delete this record?')){           
            if(row.original._id!=undefined){
                axios
                .post(configData.SERVER_URL+`fincorp_deleteRole`,
                
                {'_id': row.original._id}
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.reloadDataGrid(res.data)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
            // event.preventDefault();
            }
            else{
                //error            
                toast.warn("Role Not Deleted");
            }
        }
      }
      reloadDataGrid=(res)=>{       
              
        toast.success("Role Delete Successfully");
        setTimeout( this.fetchRolesWithFetchAPI(),4000);
      }
    editImgFormatter=(cell, row)=> {
       
        return (
            <a
                className="editbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a>
        );
    }
    deleteImgFormatter=(cell, row)=> {        
        return (
            <a
                className="deletebutton"
                aria-label="Delete"
                onClick={() => this.handleDeleteClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a>
        );
    }

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        
    }

    toggleChange = () => {
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }
      globalActiveSearch = (param) => {
        let { searchInput } = this.state;
        
        let filteredData = this.state.originalRoles.filter(value => {
            if(param){

                return (
                     (value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.description.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.short_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                    
                    value.status.toString().includes('Active')  
                   
                );

            }else{
          return (
                value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.description.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.short_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase() )
          );
        }
        });
        this.setState({ roles: filteredData });
      };
      
    handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };
      globalSearch = () => {
        let { searchInput } = this.state;
        
        let filteredData = this.state.originalRoles.filter(value => {
            if(this.state.chkisactive){

                return (
                     (value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.description.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.short_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||                    
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                    value.status.toString().includes('Active')  
                   
                );

            }else{
          return (
                value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.description.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.short_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase() )
          );
        }
        });
        this.setState({ roles: filteredData });
      };


    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let {  searchInput } = this.state;
    return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='RoleList_Title' && lbl.pageName=='RoleList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>

                </Breadcrumb>

                <div className="centeralign">
                <h1 className="mainheadings">
                {Labels.filter(lbl => lbl.keyPair=='RoleList_Title' && lbl.pageName=='RoleList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                </h1>
                </div>
                <div className="fullwidth">
                    <div className="float-left">                       
                        <Link to="/roles" className= {this.state.userrole.create=='Y'?"btn btn-primary":"hidden"}>
                        {Labels.filter(lbl => lbl.keyPair=='RoleList_AddBtn' && lbl.pageName=='RoleList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}                            
                        </Link>
                    </div>
                   
                    <div className="float-right">
                        <div className="isactivecontainer" >
                            <span>Active</span>
                              
                            <input type="checkbox" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            aria-label='Status'
                            />
                            
                        </div>
                        <div className="float-left txtsearchcontainer">

                        
                            <span className="searchlabel">
                            {Labels.filter(lbl => lbl.keyPair=='RoleList_Search' && lbl.pageName=='RoleList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))}  
                            </span>
                            <Form.Control
                                aria-label="search"
                                type="text"
                                name="search"
                                placeholder=""
                                value={searchInput || ""}
                                onChange={this.handleChange1}
                            />
                        </div>
                    </div>
                    
                </div>   
                <div className="fullwidth row m0">
               <div className="col-md-12 mt-10 p0">
                     <ReactTable
                    data={this.state.roles}
                    columns={this.state.columns}
                    defaultPageSize={20}
                    className="-striped -highlight fullwidth"
                    />  
                </div>
                    

               </div>           
                
                <div className='row col-md-12 m0 p0'>
                    {/* <BootstrapTable
                        ref='table'
                        data={this.state.roles}
                        striped
                        hover                        
                        condensed
                        pagination={true}
                        search={ true } options={ options }
                        insertRow
                        deleteRow                                          
                        className=""
                        tableHeaderClass="my-header-class"
                        tableBodyClass="my-body-class"
                        containerClass="my-container-class"
                        tableContainerClass="my-table-container-class"
                        headerContainerClass="usersystem-grid-header-container-class"
                        bodyContainerClass="my-body-container-class"
                    >
                        <TableHeaderColumn dataField="name" width="35%" isKey dataSort>
                            Role Name
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="start_date" width="10%" dataSort>
                            Start Date
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="end_date" width="10%" dataSort>
                            End Date
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="status" width="10%" dataSort>
                            Status
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataAlign="center"
                            width="5%"
                            dataFormat={this.editImgFormatter}
                        >
                            Edit   
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            width="5%"
                            dataFormat={this.deleteImgFormatter} >
                            Delete   
                        </TableHeaderColumn>
                    </BootstrapTable> */}
                </div>
                <br />
            </div>
        </>
    );
}
}