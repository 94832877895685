import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";
import Moment from 'moment';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
//const configData= require('config');
const configData = require( './../../config.json');


const initialValues = {
    weight: '',
    height: '',
    date: '',
    users: [{
        firstName: 'test1',
        lastName: '32',
        email: "test1@test.com",
        status: 'Active',

    }
    ]
}
const fileType =
"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const fileExtension = ".xlsx";

export default class UserList extends React.Component  {
    constructor(...args) {
        super(...args)
         
        this.state = {
            show: false ,
            originalUsers:[],
            filteredData: [],
            columns: [],
            searchInput: "",
            chkisactive:true,
            userrole:{create:''}
            
        }
         
        
        this.setState({Labels:[],  isFetching: true});
        this.fetchLabelMessagesAPI();
    }
     componentDidMount() {
       
      this.fetchUsersWithFetchAPI();
      
      this.getColumns();

     
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'User';
        });
        let columns = [
          {
            Header: "Last Name",
            accessor: "lastName",
            sortable: true,
            show: true,
            displayValue: " Last Name",
             
          },
          {
            Header: "First Name",
            accessor: "firstName",
            sortable: true,
            show: true,
            displayValue: "First Name "
          },
          {
            Header: "Email",
            accessor: "email",
            sortable: true,
            show: true,
            displayValue: " Email "
          },
          {
            Header: "Last Login",
            accessor: "lastLoginDate",
            sortable: true,
            show: true,
            displayValue: " Email ",
            Cell:row=>(<div> {row.original.lastLoginDate!=undefined? Moment(row.original.lastLoginDate).format('MM-DD-YYYY h:mm:ss a'):""}</div>)
          },
          {
            Header: "Start Date",
            accessor: "startdate",
            sortable: true,
            show: true,
            displayValue: " StartDate ",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: "End Date",
            accessor: "enddate",
            sortable: true,
            show: true,
            displayValue: " EndDate ",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "Status",
            maxWidth: 100,
            minWidth: 56,
            width: 56,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            show:userrl.edit=='Y'?true:false,
            width: 40,
            Cell:row => (<div className="grdbuttoncontainer"><div className={userrl.edit=='Y'?"editcontainer":"hidden"}> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            show:userrl.delete=='Y'?true:false,
            width: 58,

            Cell:row => (<div className="grdbuttoncontainer"><div className={this.state.userrole.delete=='Y'?"deletecontainer":"hidden"}> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };
    
    fetchUsersWithFetchAPI = () => {
        this.setState({...this.state, isFetching: true});
        fetch(configData.SERVER_URL+'fincorp_getUsers')
            .then(response => response.json())
            .then(result => {
                this.bindUserList(result)
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };

    bindUserList=(result)=>{

        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'User';
        });
        
        if(result!='No document(s) found with defined find criteria!' && result.length>0){
         this.setState({...this.state,users: result,originalUsers:result,userrole: userrl, isFetching: false})
         this.globalSearch();
        }
         else
         this.setState({...this.state,userrole: userrl, isFetching: false})
    }
 


    handleDeleteClick=(row,event)=>{ 
        if(window.confirm('Are you sure to delete this record?')){  
                  
            if(row.original._id!=undefined){
                axios
                .post(configData.SERVER_URL+`fincorp_deleteUser`,
                
                {'_id': row.original._id}
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.reloadDataGrid(res.data)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
            // event.preventDefault();
            }
            else{
                //error            
                toast.warn("User Not Deleted");
            }
        }
      }

      reloadDataGrid=(res)=>{       
            
        toast.success("User Delete Successfully");
        setTimeout( this.fetchUsersWithFetchAPI(),4000);
      }

    handleChange = e => {
        // let { value, name } = e.target;
        // if (value > 999) {
        //     value = 999;
        // }
        // const date = new Date().toLocaleString().split(',')[0];
        // setState({
        //     ...state,
        //     [name]: value,
        //     date
        // });
    };
    handleClick = () => {
        //change(state);
        //setState(initialValues);
    };

    handleEditClick(row,event) {        
        this.props.history.push("/User?id=" + row.original._id);
      }

     

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };

      toggleChange = () => {
          
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }

      globalActiveSearch = (param) => {
          
        let { searchInput } = this.state;       
        let filteredData = this.state.originalUsers.filter(value => {
            if(param){

                return (
                    (value.lastName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.email.toLowerCase().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                    
                    value.status.toString().includes('Active')
                );

            }else{
                return (
                  value.lastName.toLowerCase().includes(searchInput.toLowerCase()) ||
                  value.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
                  value.email.toLowerCase().toLowerCase().includes(searchInput.toLowerCase())|| 
                  value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                  ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                  value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        });
        this.setState({ users: filteredData });
      };

      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalUsers.filter(value => {
        if(this.state.chkisactive){    
            return (
                    (value.lastName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.email.toLowerCase().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                    value.status.toString().includes('Active')
            );
        }else{
            return (
                value.lastName.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.email.toLowerCase().toLowerCase().includes(searchInput.toLowerCase())|| 
                value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
      );
    }

        });
        this.setState({ users: filteredData });
      };

      exportToCSV = (fileName) => {
        const ws = XLSX.utils.json_to_sheet(this.state.originalUsers);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      };

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let { data, columns, searchInput } = this.state;
        return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='UserList_Title' && lbl.pageName=='UserList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='UserList_Title' && lbl.pageName=='UserList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}   
                    </span>
                </div>
                <div className="fullwidth">
                    <div className="float-left">
                    
                        <Link to="/user" className= {this.state.userrole.create=='Y'?"btn btn-primary":"hidden"}>
                        {Labels.filter(lbl => lbl.keyPair=='UserList_AddBtn' && lbl.pageName=='UserList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                        </Link>
                    </div>
                    <div className="float-right">
                        <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" className="chkisactive" aria-label="status"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='UserList_Search' && lbl.pageName=='UserList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}  
                        </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"                            
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />
                        </div>
                    </div>                    
                </div>
                <div className="fullwidth row m0">
               <div className="col-md-12 mt-10 p0">
                     <ReactTable
                    data={this.state.users}
                    columns={this.state.columns}
                    defaultPageSize={20}
                    className="-striped -highlight fullwidth"
                    />  
                </div>
                <div className="mb-20">
                <button onClick={(e) => this.exportToCSV('UserList')}>Export</button>
                </div>                

               </div>     
                
            </div>



        </>
    );
}
}

 