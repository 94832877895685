import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { DatePicker, DatePickerInput } from "rc-datepicker"; 
import "rc-datepicker/lib/style.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { Link } from "react-router-dom";
import Icon from '@material-ui/core/Icon';

import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from "lodash";

import * as moment from "moment";
import "moment/min/moment-with-locales";
const queryString = require('query-string');

//const configData= require('config');
const configData = require('./../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
    pageTitle:'',
    lookupItemId: '',    
    lookupvalues: [{
        lookupvalues: 'Reports detail1',
        desc: 'Manual Report ',
        start_date: '',
        end_date: '',        
        status: ''

    }]
}

function afterSearch(searchText, result) {
    console.log('Your search text is ' + searchText);
    console.log('Result is:');
    for (let i = 0; i < result.length; i++) {
      console.log('Fruit: ' + result[i].id + ', ' + result[i].name + ', ' + result[i].price);
    }
  }
  
  const options = {
    afterSearch: afterSearch  // define a after search hook
  };
export default class MasterLookupValues extends React.Component {

    constructor(...args) {
        super(...args)
       
        var querystring = queryString.parse(this.props.location.search);

        console.log(queryString.parse(this.props.location.search));
        initialValues.lookupItemId = querystring.key;

        this.state = {
            show: false,
            items: [],
            originalItems:[],
            lookupValues: {},
            pageTitle:querystring.name,
            filteredData: [],
            columns: [],  
            chkisactive:true,         
            searchInput: "",          
            userrole:{create:''}
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'MasterLookup';
        });
        let columns = [

          {
            Header: "Lookup Value",
            accessor: "lookup_value",
            sortable: true,
            show: true,
            displayValue: "Lookup Value" 
          },
          {
            Header: "Display Name",
            accessor: "value_displayname",
            sortable: true,
            show: true,
            displayValue: "Display Name" 
          },
          {
            Header: "Description",
            accessor: "desc",
            sortable: true,
            show: true,
            displayValue: "Desc" 
          },
          {
            Header: "Start Date",
            accessor: "start_date",
            sortable: true,
            show: true,
            displayValue: "Start Date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: "End Date",
            accessor: "end_date",
            sortable: true,
            show: true,
            displayValue: "End Date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "status",
            maxWidth: 100,
            minWidth: 56,
            width: 56,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          },
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"> <div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };
    
    componentDidMount() {

        this.fetchMasterLookupValuesAPI();

        this.getColumns();
    }
    fetchMasterLookupValuesAPI = () => {

        this.setState({ ...this.state, isFetching: true });

        axios
            .post(configData.SERVER_URL + `fincorp_getMasterLookUpValues/`,

                { '_id': initialValues.lookupItemId }
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
            .then(
                res => { this.bindMasterLookupValues(res.data) }
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };
    bindMasterLookupValues = (result) => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'MasterLookup';
        }); 

        if (result != "No document(s) found with defined find criteria!" && result.length > 0){
        this.setState({items: result,originalItems:result,userrole: userrl, isFetching: false})
        this.globalSearch();
        }
        else
        this.setState({...this.state,userrole: userrl,items: [], isFetching: false})

    }

    handleChange = e => {
        let { value, name } = e.target;        
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date

        });
        if(name=='search')
        {
           this.refs.table.handleFilterData({ lookup_value: value });
            //this.refs.table.handleFilterData({  value });
        }
        else if(name=='lookupvaluestatus' && value=='Inactive'){
            const currentdate = new Date();
            currentdate.setDate(currentdate.getDate()-1);
            var momentDateObj = moment(currentdate).format('MM-DD-YYYY');
            var enddate=new Date(this.state.enddate);
            if(currentdate<enddate){
                var stdate=moment(this.state.startdate).format('MM-DD-YYYY')>moment(currentdate).format('MM-DD-YYYY')?moment(currentdate).format('MM-DD-YYYY'):moment(this.state.startdate).format('MM-DD-YYYY');

                this.setState({startdate:stdate, enddate:momentDateObj });
            }
            var momentDateObj = moment(this.state.enddate);
        }
    };

    handleSubmit = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

    handleEditClick = (row,event) => {
        
        this.state.lookupvalue = row.original.lookup_value;
        this.state.lookupvaluedisplayname = row.original.value_displayname;
        this.state.startdate = row.original.start_date;
        this.state.enddate = row.original.end_date;
        this.state.lookupvaluedescription = row.original.desc;
        this.state.lookupvaluestatus = row.original.status;
        this.state._id = row.original._id;
        this.setState({ show: true, editshow: false });
        event.preventDefault();
        event.stopPropagation();
        
    };



    handleSaveClick = (event) => {
        console.log(this.state.lookupItem);

        var vallist = this.state.items;


        //event.preventDefault();
        if (this.state.lookupvalue == undefined || this.state.lookupvalue == "" || this.state.lookupvalue.length == 0) {
            toast.warn("Enter Item");
            event.preventDefault();


        }       
        else if (this.state.lookupvaluedisplayname == undefined || this.state.lookupvaluedisplayname == "") {
            toast.warn("Enter DisplayName");
            event.preventDefault();


        }
        else if (this.state.lookupvaluestatus == undefined || this.state.lookupvaluestatus == "" || this.state.lookupvaluestatus =='SELECT STATUS') {
            toast.warn("Choose Status");
            event.preventDefault();


        }
        else if (this.state.startdate == undefined || this.state.startdate == "" || this.state.startdate == "Invalid date") {
            toast.warn("Enter StartDate");
            event.preventDefault();


        }  
        else if (Date.parse(this.state.startdate) > Date.parse(this.state.enddate)) {
            toast.warn("End Date should be greater than the StartDate");
            event.preventDefault();


        }     
      
        else {

            if (this.state._id!= undefined) {
                if(window.confirm('Do you want to save changes?')){ 
                this.state.startdate=  this.state.startdate == "Invalid date"?null:this.state.startdate;
                this.state.enddate=  this.state.enddate == "Invalid date"?null:this.state.enddate;

                axios
                    .post(configData.SERVER_URL + `fincorp_updateMasterLookUpValue`,

                        { '_id': this.state._id, 'lookup_value': this.state.lookupvalue, 'value_displayname': this.state.lookupvaluedisplayname, 'desc': this.state.lookupvaluedescription, 'lookup_item_id': initialValues.lookupItemId, 'start_date': this.state.startdate, 'end_date': this.state.enddate, 'status': this.state.lookupvaluestatus }
                        , {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8",
                                "Access-Control-Allow-Origin": "*"
                            }
                        })
                    .then(
                        res =>  this.UpdateChanges(res.data) 


                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });
                }

            }
            else {
                
                axios
                    .post(configData.SERVER_URL + `fincorp_insertMasterLookUpValue`,

                        { 'lookup_value': this.state.lookupvalue, 'desc': this.state.lookupvaluedescription,
                         'lookup_item_id': initialValues.lookupItemId,
                         'value_displayname': this.state.lookupvaluedisplayname, 'start_date': this.state.startdate, 
                         'end_date': this.state.enddate, 'status': this.state.lookupvaluestatus }
                        , {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8",
                                "Access-Control-Allow-Origin": "*"
                            }
                        })
                    .then(
                        res => this.SaveChanges(res.data) 
                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });

            }

        }
    };
    SaveChanges = (res) => {
        if(res.MessageKey!=undefined){
            toast.warn(res.Message);
        }
        else{
         toast.success("MasterLookup Value Inserted Successfully");
         setTimeout( this.fetchMasterLookupValuesAPI(),4000);
         this.setState({ show: false, editshow: false });
        }
    }
    UpdateChanges = (res) => {
        if(res.MessageKey!=undefined){
            toast.warn(res.Message);
        }
        else{
        toast.success("MasterLookup Value Updated Successfully");
        setTimeout( this.fetchMasterLookupValuesAPI(),4000);
        this.setState({ show: false, editshow: false });
        }
   }

    onStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                startdate: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                enddate: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };
    //add new lookup item
    handleAddLookup = () => {
        this.setState({ lookupvalue: '', lookupvaluedescription: '',lookupvaluedisplayname:'', startdate:'', enddate:'', lookupvaluestatus:'', show: true, editshow: false });
    }
    handleClose = () => {

        this.setState({ show: false });
    }
    handleSave = () => {
        this.setState({ show: false });
    }
    handleEdit = () => {
        this.setState({ show: false, editshow: true });
    }
    handleEditClose = () => {
        this.setState({ show: false, editshow: false });
    }
    handleDeleteClick = (row,event) => {
        
        if(window.confirm('Are you sure to delete this record?')){ 
        //delete
        event.preventDefault();
        event.stopPropagation();
        if (row.original._id!= undefined) {
            axios
                .post(configData.SERVER_URL + `fincorp_deleteMasterLookUpValue`,

                    { '_id': row.original._id }
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                .then(
                    res => this.reloadDataGrid(res.data)
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
        else {
            //error            
            toast.warn("MasterLookup Not Deleted");
        }
    }
    }
    reloadDataGrid = (res) => {

        toast.success("Master Lookup Value Delete Successfully");
        setTimeout(this.fetchMasterLookupValuesAPI(), 4000);
    }
   
    masterLookupItemFormat = (cell, row) => {
        return (
           <span>{this.state.pageTitle}</span> 
        );
    }
    editImgFormatter = (cell, row) => {
        return (
            <a
                className="editbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a>
        );
    }
    deleteImgFormatter = (cell, row) => {
        return (
            <a
                className="deletebutton"
                aria-label="Delete"
                onClick={() => this.handleDeleteClick(row)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a>
        );
    }

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }

    handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };
      toggleChange = () => {
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }

      globalActiveSearch = (param) => {
        let { searchInput } = this.state;       
        let filteredData = this.state.originalItems.filter(value => {
            if(param){

                return (
                    (value.lookup_value.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.value_displayname.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.desc.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.status.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                    value.status.toString().includes('Active')  
                   
                );

            }else{
                return (
                    value.lookup_value.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.value_displayname.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.desc.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                    value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        });
        this.setState({ items: filteredData });
      };
      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalItems.filter(value => {
        if(this.state.chkisactive){    
          return (
            (value.lookup_value.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.value_displayname.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.desc.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.status.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
            ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
            value.status.toString().includes('Active')  
          );
        }else{
            return (
                value.lookup_value.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.value_displayname.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.desc.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
      );
    }

        });
        this.setState({ items: filteredData });
      };

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let { data, columns, searchInput } = this.state;
        return (

            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/masterlookupitems">
                        {Labels.filter(lbl => lbl.keyPair=='MasterLookupItems_Title' && lbl.pageName=='MasterLookupItems').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}  
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_Title' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}    
                    </Breadcrumb.Item>
                </Breadcrumb>

                {/*  add/edit popup details of Lookup Values */}
                <div>
                    <Modal
                        className="lookupvalue-modal"
                        show={this.state.show}
                        onHide={this.handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                                <h3>{this.state._id==undefined?"Add":"Edit"} {this.state.pageTitle}</h3>
                                {/* <button type="button" onClick={ this.handleEdit }><Icon >editFilled</Icon> </button> */}
                                {/* <button type="button" onClick={ this.handleDelete }><Icon >deleteRounded</Icon> </button> */}
                                {/* <button type="button" onClick={this.handleClose}><Icon >closeRounded</Icon> </button> */}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Row>
                                    <Col md-6>
                                    <Form.Group as={Col} controlId="formLookUpValue">
                                        <Form.Label className="labelvalue required" for='frmlookupvalue'>
                                        {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_LookupValue' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}                                             
                                        </Form.Label>
                                        <Form.Control
                                            id="frmlookupvalue"
                                            type="text"
                                            name="lookupvalue"
                                            placeholder="LookUp Value Name"
                                            value={this.state.lookupvalue}
                                            disabled={this.state._id!=undefined?true:false}
                                            onChange={this.handleChange}
                                            required
                                        />

                                    </Form.Group>
                                    </Col>
                                   
                                    <Col md-6>
                                    <Form.Group as={Col} controlId="formLookUpValueDesc">
                                    <Form.Label className="labelvalue required" for='frmlookupvalues'>
                                    {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_DisplayName' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}  
                                    </Form.Label>
                                            <Form.Control
                                                id="frmlookupvalues"
                                                type="text"
                                                name="lookupvaluedisplayname"
                                                placeholder="Display Name"
                                                value={this.state.lookupvaluedisplayname}
                                                onChange={this.handleChange}
                                                required
                                            />                                    
                                    </Form.Group>                                  
                                    </Col>
                                </Form.Row>
                                    <Form.Row>
                                        <Col md-6>
                                        <Form.Group as={Col} controlId="formLookUpValue">
                                        <Form.Label for='descr'>
                                        {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_Desc' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}  
                                        </Form.Label>
                                        <Form.Control
                                            id="descr"
                                            className="desc-width"
                                            as="textarea"
                                            rows="1"                                            
                                            name="lookupvaluedescription"
                                            placeholder="Description"
                                            value={this.state.lookupvaluedescription}
                                            onChange={this.handleChange}                                           

                                        />   
                                        </Form.Group>
                                        </Col>
                                        <Col md-6>
                                        <Form.Group as={Col} controlId="formLookUpValueStatus">
                                            <Form.Label className="required" for='lookupvaluestatus'>
                                            {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_Status' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                            </Form.Label>
                                            <Form.Control
                                            as="select"
                                            id="lookupvaluestatus"
                                            controlId="lookupvaluestatus"
                                            name="lookupvaluestatus"
                                            value={this.state.lookupvaluestatus}
                                            onChange={this.handleChange}
                                            required
                                            >
                                            <option value="SELECT STATUS">Select Status</option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                            </Form.Control>
                                        </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col md-6 s-12>
                                        <Form.Group as={Col} controlId="formLookUpValue">
                                        <Form.Label className="labelvalue required" for='startdate'>
                                        {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_StartDate' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                        </Form.Label>{" "}
                                        <DatePickerInput
                                            id="startdate"
                                            onChange={this.onStartDateChange.bind(this, 'startdate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.startdate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            required
                                            readOnly
                                        />
                                        </Form.Group>
                                        </Col>
                                        <Col md-6 s-12>
                                        <Form.Group as={Col} controlId="formLookUpValueStatus" >                                            
                                            <Form.Label for='enddate'>
                                            {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_EndDate' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}  
                                            </Form.Label>
                                            <DatePickerInput
                                            id="enddate"
                                            onChange={this.onEndDateChange.bind(this, 'enddate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.enddate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                            
                                        />
                                        </Form.Group>
                                        </Col>
                                    </Form.Row>
                                
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>                                
                                <Button variant="primary" onClick={this.handleSaveClick}>
                                {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_SaveBtn' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                 </Button>
                                 <Button variant="secondary" onClick={this.handleClose} >
                                {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_CancelBtn' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>



                    <div className="centeralign">
                        <span className="mainheadings">{this.state.pageTitle}</span>
                        <ToastContainer />
                    </div>
                    <div className="fullwidth">
                        <div className="float-left">                           
                            <button className={this.state.userrole.create=='Y'?"btn btn-primary  float-left ":"hidden"}  onClick={this.handleAddLookup} >                                       
                            {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_AddLookupBtn' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}              
                            </button>
                        </div>
                        <div className="float-right">
                        <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            aria-label='Status'
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">
                            <span className="searchlabel">
                            {Labels.filter(lbl => lbl.keyPair=='MasterLookupValues_Search' && lbl.pageName=='MasterLookupValues').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                            </span>
                            <Form.Control
                            aria-label="search"
                            type="text"                           
                            name="searchInput"
                            placeholder=""
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        /> 
                        </div>
                        </div>
                    </div>

                    <div className="fullwidth row m0">
               <div className="col-md-12 mt-10 p0">
                     <ReactTable
                    data={this.state.items}
                    columns={this.state.columns}
                    defaultPageSize={20}
                    // getTrProps={this.onRowClick}
                    className="-striped -highlight fullwidth"
                    />  
                </div>
                    

               </div>                   

                </div>
        
    );
}
}
