import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { DatePicker, DatePickerInput } from "rc-datepicker"; 
import "rc-datepicker/lib/style.css";
import axios from "axios";
import * as moment from "moment";
import { browserHistory } from "react-router";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";
import "moment/min/moment-with-locales";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";

//const configData= require('config');
const configData = require( './../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
    role: {
        name: '',
        status: ''
    }
}
export default class Roles extends React.Component  {
    constructor(...args) {
        super(...args)
        const querystring = queryString.parse(this.props.location.search);
        this.state = {
            show: false,
            role:  {},
            location:[],
            orginallocation:[],
            columns:[],
            isedit:false,
            changedstatus:false
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();


    }
     componentDidMount() {
       
      this.fetchRolesWithFetchAPI();
      this.getColumns();
    }

    handleChangeChk=(row,type,status)=>{
        
        this.state.changedstatus=true;
        var index = _.findIndex(this.state.location, {_id: row._id});
        status=(status=="N" || status==undefined?"Y":"N");
        

        if(type=='all'){
            row.all=row.create= row.view= row.dataUpload= row.delete=row.edit= row.email=row.fileDownload=row.fileUpload= row.others=status;
        }
        else if(type=='view'){
            row.view=status;
        }
        else if(type=='create'){
            row.create=status;
        }
        else if(type=='dataUpload'){
            row.dataUpload=status;
        }
        else if(type=='delete'){
            row.delete=status;
        }
        else if(type=='edit'){
            row.edit=status;
        }
        else if(type=='email'){
            row.email=status;
        }
        else if(type=='fileDownload'){
            row.fileDownload=status;
        }
        else if(type=='fileUpload'){
            row.fileUpload=status;
        }
        else if(type=='others'){
            row.others=status;
        }
         
        if(type!="all" && (row.create=="Y" && row.view=="Y" && row.dataUpload=="Y" && row.delete=="Y" &&row.edit=="Y" && row.email=="Y" &&  row.fileDownload=="Y" && row.fileUpload=="Y" && row.others=="Y")){
            row.all="Y";
        }
        else if(type!="all"){
            row.all="N";
        }
       // Replace item at index using native splice
       var changedlocation=this.state.location.splice(index, 1, row);
       console.log(this.state.location);
       this.setState(prevState => ({
        ...prevState,  
        location:this.state.location  
        }));
       
    }
    getColumns = () => {
        let columns = [
          {
            Header: "Page Name",
            accessor: "location",
            sortable: true,
            show: true,
            displayValue: "location",
            Cell:row=>(<span>{row.original.location}</span>)
          },          
          {
            Header: "All",
            
            sortable: true,
            show: true,
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            //Cell:row=>(<input type="checkbox" defaultChecked={row.original.view=="Y"} onChange={this.handleChangeChk(row)} />)
            Cell: ({ original }) => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={original.all=="Y"}
                        onChange={() => this.handleChangeChk(original,'all',original.all)}
                    />
                );
          }
        } ,          
        {
          Header: "View",
          accessor: "view",
          sortable: true,
          show: true,
          maxWidth: 100,
          minWidth: 90,
          width: 90,
          //Cell:row=>(<input type="checkbox" defaultChecked={row.original.view=="Y"} onChange={this.handleChangeChk(row)} />)
          Cell: ({ original }) => {
              return (
                  <input
                      type="checkbox"
                      className="checkbox"
                      checked={original.view=="Y"}
                      onChange={() => this.handleChangeChk(original,'view',original.view)}
                  />
              );
        }
      }
      ,          
        {
          Header: "Create",
          accessor: "create",
          sortable: true,
          show: true,
          maxWidth: 100,
          minWidth: 90,
          width: 90,
          //Cell:row=>(<input type="checkbox" defaultChecked={row.original.view=="Y"} onChange={this.handleChangeChk(row)} />)
          Cell: ({ original }) => {
              return (
                  <input
                      type="checkbox"
                      className="checkbox"
                      checked={original.create=="Y"}
                      onChange={() => this.handleChangeChk(original,'create',original.create)}
                  />
              );
        }
      },          
      {
        Header: "Edit",
        accessor: "edit",
        sortable: true,
        show: true,
        maxWidth: 100,
        minWidth: 90,
        width: 90,
        Cell: ({ original }) => {
            return (
                <input
                    type="checkbox"
                    className="checkbox"
                    checked={original.edit=="Y"}
                    onChange={() => this.handleChangeChk(original,'edit',original.edit)}
                />
            );
      }
    },          
    {
      Header: "Delete",
      accessor: "delete",
      sortable: true,
      show: true,
      maxWidth: 100,
      minWidth: 90,
      width: 90,
      Cell: ({ original }) => {
          return (
              <input
                  type="checkbox"
                  className="checkbox"
                  checked={original.delete=="Y"}
                  onChange={() => this.handleChangeChk(original,'delete',original.delete)}
              />
          );
    }
  },          
  {
    Header: "Email",
    accessor: "email",
    sortable: true,
    show: true,
    maxWidth: 100,
    minWidth: 90,
    width: 90,
    Cell: ({ original }) => {
        return (
            <input
                type="checkbox"
                className="checkbox"
                checked={original.email=="Y"}
                onChange={() => this.handleChangeChk(original,'email',original.email)}
            />
        );
  }
},          
{
  Header: "Data Upload",
  accessor: "dataUpload",
  sortable: true,
  show: true,
  maxWidth: 100,
  minWidth: 90,
  width: 90,
  Cell: ({ original }) => {
      return (
          <input
              type="checkbox"
              className="checkbox"
              checked={original.dataUpload=="Y"}
              onChange={() => this.handleChangeChk(original,'dataUpload',original.dataUpload)}
          />
      );
}
},          
{
  Header: "File Upload",
  accessor: "fileUpload",
  sortable: true,
  show: true,
  maxWidth: 100,
  minWidth: 90,
  width: 90,
  Cell: ({ original }) => {
      return (
          <input
              type="checkbox"
              className="checkbox"
              checked={original.fileUpload=="Y"}
              onChange={() => this.handleChangeChk(original,'fileUpload',original.fileUpload)}
          />
      );
}
},          
{
  Header: "File Download",
  accessor: "fileDownload",
  sortable: true,
  show: true,
  maxWidth: 100,
  minWidth: 90,
  width: 90,
  Cell: ({ original }) => {
      return (
          <input
              type="checkbox"
              className="checkbox"
              checked={original.fileDownload=="Y"}
              onChange={() => this.handleChangeChk(original,'fileDownload',original.fileDownload)}
          />
      );
}
},          
{
  Header: "Others",
  accessor: "others",
  sortable: true,
  show: true,
  maxWidth: 100,
  minWidth: 90,
  width: 90,
  Cell: ({ original }) => {
      return (
          <input
              type="checkbox"
              className="checkbox"
              checked={original.others=="Y"}
              onChange={() => this.handleChangeChk(original,'others',original.others)}
          />
      );
}
}
        ];
        this.setState({ columns });
      };

    fetchRolesWithFetchAPI = () => {
        this.setState({...this.state, isFetching: true});
        var querystring = queryString.parse(this.props.location.search);
        
         
        axios
        .post(configData.SERVER_URL+`fincorp_getRoleBasedOnId/`,
        
        {'_id': querystring.id}
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindRoleBasedId(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindRoleBasedId=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            if(result[0].location!=""){
                this.setState({role: result[0],location:result[0].location,orginallocation:result[0].location, isFetching: false, isedit:true});
            }
            else{
                this.setState({role: result[0], isFetching: false});
            }
        
            }
        
        var querystring = queryString.parse(this.props.location.search);
        if(querystring.id==undefined || result[0].location==undefined ||result[0].location==""){
            this.fetchLocationListFetchAPI();
        }
    }

    fetchLocationListFetchAPI = () => {
        this.setState({...this.state, isFetching: true});
         
         
        axios
        .get(configData.SERVER_URL+`fincorp_getLocations/`,
         {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindLocationList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindLocationList=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
           //result[0].view="Y";
            this.setState({location: result, isFetching: false});
        }
        

    }


    //const [state, setState] = useState(initialValues);

    handleChange = e => {

        let { value, name } = e.target;
        
        //const date = new Date().toLocaleString().split(',')[0];
        
        var userRole = this.state.role;

        if(e.target.type=='checkbox'){
            userRole[e.target.name] = e.target.checked;
        }        
        else
        {
            userRole[e.target.name] = e.target.value;
        }
        
       
       
        this.setState( { userRole: userRole } );
       
    };

    handleInputChange = e => {
        let { value, name } = e.target;
       
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date

        });
    };

    onStartDateChange = (jsDate, dateString) => {
        var dtvalue=moment(dateString).format("MM-DD-YYYY")
        
        if(dtvalue!="Invalid date"){
            this.setState(prevState => ({
                role: {
                    // object that we want to update
                    ...prevState.role, // keep all other key-value pairs
                    
                    start_date: moment(dateString).format("MM-DD-YYYY")
                    
                }
            }));
        }
        else{
            this.setState(prevState => ({
                role: {
                    // object that we want to update
                    ...prevState.role, // keep all other key-value pairs
                    
                    start_date: ""
                    
                }
            }));
        }
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            role: {
                // object that we want to update
                ...prevState.role, // keep all other key-value pairs
                
                end_date: moment(dateString).format("MM-DD-YYYY")
                
            }
        }));
    };

    handleSubmit = () => {
        //change(state);
        //setState(initialValues);
    };
    handleSaveClick = (event) => {
        console.log(this.state.role);
        
        const regExName = /^[a-zA-Z ]*$/;
        let index = _.findIndex(this.state.location, (e) => {
            return e == 1;
        }, 0);
        if(this.state.role.name ==undefined || this.state.role.name.length==0 ){
            toast.warn("Enter Role Name");
            event.preventDefault();        
        }
        else if (regExName.test(this.state.role.name) === false){
            toast.warn("Role Name Must Contain Only Alphabets");
            event.preventDefault();        
        }    
       
        else if(this.state.role.status ==undefined || this.state.role.status ==""){
            toast.warn("Select Status");
            event.preventDefault();            
        }
        else if (this.state.role.description == undefined || this.state.role.description == "") {
            toast.warn("Enter Description");
            event.preventDefault();

        }
        else if (this.state.role.start_date == undefined || this.state.role.start_date == "" || this.state.role.start_date == "Invalid date") {
            toast.warn("Enter StartDate");
             
            event.preventDefault();   
            console.log(this.state.role.start_date);

        }  
         
        else if (Date.parse(this.state.role.start_date) >  Date.parse(this.state.role.end_date)) {           
            toast.warn("End Date should be greater than the StartDate");
            event.preventDefault();
        } 
        else if(this.state.changedstatus==false && this.state.role._id==undefined)     
        {
            toast.warn("Choose atleast one access");
            event.preventDefault();

        }
        //event.preventDefault();
        // if(this.state.role.status=='SELECT'){
        //     event.preventDefault();        
        // }
        else{
            this.state.role.location=this.state.location;
            //update existing role
            if(this.state.role._id!=undefined){
                if(window.confirm('Do you want to save changes?')){ 
                this.state.role.start_date=  this.state.role.start_date == "Invalid date"?null:this.state.role.start_date;
                this.state.role.end_date=  this.state.role.end_date == "Invalid date"?null:this.state.role.end_date;
                
                axios
                .post(configData.SERVER_URL+`fincorp_updateRole`,
                //  { "id" : "2",
                // "name" : "Administrator1",
                // "created" : "2021-01-02",
                // "modified" : "2021-01-02",
                // "created_by" : "Sam",
                // "modified_by" : "Vikram",
                // "short_name" : "Admin1",
                // "description" : "Admin1 Role Details",
                // "start_date" : "2020-12-25",
                // "end_date" : "2020-12-31",
                // "edit" : "false",
                // "view" : "true",
                // "email" : "true",
                // "fileupload" : "true",
                // "dataupload" : "false",
                // "filedownload" : "true",
                // "status" : "Active",
                // "start_date": "2021-01-02"}
                this.state.role
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.UpdateChange(res.data,event)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
                event.preventDefault();
            }
            }
            else{
                //insert new role
            axios
                    .post(configData.SERVER_URL+`fincorp_insertRole`,
                    
                    this.state.role
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                    .then(
                        res => this.SaveChange(res.data,event)
                        
                         //this.props.history.push("/rolelist")
                        // history.pushState(
                        //     null,
                        //     document.title,
                        //     (location = "/rolelist")
                        // )
                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });
                    event.preventDefault();
            }
        }
        
    };

    SaveChange=(result,event)=>{
        if(result.MessageKey!=undefined){
            toast.warn(result.Message);
        }
        else{
        toast.success("Role Inserted Successfully");      
        setTimeout(()=>this.props.history.push("/rolelist"), 4000);
        }
        
    }
    UpdateChange=(result,event)=>{
        
        toast.success("Role Updated Successfully");      
        setTimeout(()=>this.props.history.push("/rolelist"), 4000);
    }
    handleCancelClick = () => {
        this.props.history.push("/rolelist");
    }

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const savetype= this.state.role._id!=undefined?"Edit":"Add";
    return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/rolelist">
                        {Labels.filter(lbl => lbl.keyPair=='RoleSetup_Title' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='RoleSetup_Title' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <h1 className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='RoleSetup_Title' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                        <span>
                        {savetype} {filteredPerson.keyValue}
                        </span>
                    ))}
                    </h1>
                    <ToastContainer />
                </div>
                <Form
                    style={{ width: "100%" }}
                >

                    <div className="panel top-panel-div " style={{ marginLeft: "-2px" }}>
                        <Form.Row>
                            <Row className="margin-t10 col-md-12">
                                <Col md={6} className="row">
                                    <Col md={5}>
                                        <Form.Label className="required" for="formtxtrolename">
                                        {Labels.filter(lbl => lbl.keyPair=='RoleSetup_RoleName' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                        ))}    
                                        </Form.Label>{" "}
                                    </Col>
                                    <Col md={7}>

                                        <Form.Control
                                            id="formtxtrolename"
                                            type="text"
                                            // className={showUserTextControl}
                                            name="name"
                                            value={this.state.role.name}
                                            placeholder="Role Name"
                                            onChange={this.handleChange}
                                            disabled={this.state.role._id!=undefined?true:false}
                                            required
                                        />
                                    </Col>
                                </Col>
                                <Col md={6} className="row">

                                <Col md={5}>
                                        <Form.Label for="formshortname">
                                        {Labels.filter(lbl => lbl.keyPair=='RoleSetup_ShortName' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                        ))}     
                                        </Form.Label>{" "}
                                    </Col>
                                    <Col md={7}>
                                        <Form.Control
                                            id="formshortname"
                                            type="text"
                                            name="short_name"
                                            value={this.state.role.short_name}
                                            placeholder="Short Name"
                                            onChange={this.handleChange}
                                           
                                        />
                                    </Col>                                    
                                </Col>
                            </Row>
                        </Form.Row>
                        <Form.Row>
                            <Row className="margin-t10 col-md-12">
                                <Col md={6} className="row">
                                <Col md={5}>
                                        <Form.Label className="required" for="rolestatus">
                                        {Labels.filter(lbl => lbl.keyPair=='RoleSetup_RoleStatus' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                        ))}      
                                        </Form.Label>{" "}
                                    </Col>
                                    <Col md={7}>
                                        <Form.Control
                                            as="select"
                                            id="rolestatus"
                                            controlId="rolestatus"
                                            name="status"
                                            value={this.state.role.status}
                                            onChange={this.handleChange}
                                            required
                                        >
                                            <option value="">Select Status</option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </Form.Control>
                                    </Col>   
                                </Col>
                                <Col md={6} className="row">
                                    <Col md={5}>
                                        <Form.Label className="required" for="descr">
                                        {Labels.filter(lbl => lbl.keyPair=='RoleSetup_Desc' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                        ))}    
                                        </Form.Label>{" "}
                                    </Col>
                                    <Col md={7} className="desc-pad-10x">
                                        <Form.Control
                                            id="descr"
                                            className="desc-width"
                                            as="textarea"
                                            rows="1"
                                            name="description"
                                            placeholder="Description"
                                            value={this.state.role.description}
                                            onChange={this.handleChange}
                                            className="required"
                                            
                                        />
                                    </Col>
                                </Col>
                            </Row>
                        </Form.Row>
                        <Form.Row>
                            <Row className="margin-t10 col-md-12">
                                <Col md={6} className="row">
                                    <Col md={5}>
                                        <Form.Label className="required" for="startdate">
                                        {Labels.filter(lbl => lbl.keyPair=='RoleSetup_StartDate' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                        ))}    
                                        </Form.Label>{" "}
                                    </Col>
                                    <Col md={7}>
                                        <DatePickerInput
                                            id="startdate"
                                            onChange={this.onStartDateChange.bind(this, 'startdate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.role.start_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            required
                                            readOnly
                                        />
                                    </Col>
                                </Col>
                                <Col md={6} className="row">
                                    <Col md={5}>
                                        <Form.Label for="enddate">
                                        {Labels.filter(lbl => lbl.keyPair=='RoleSetup_EndDate' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                        ))}    
                                        </Form.Label>{" "}
                                    </Col>
                                    <Col md={7}>
                                        <DatePickerInput
                                            id="enddate"
                                            //onChange={this.onEndDateChange}
                                            onChange={this.onEndDateChange.bind(this, 'enddate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.role.end_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />
                                    </Col>
                                </Col>
                            </Row>
                        </Form.Row>
                        <Row className="row col-md-12 mt-40">

                        
                                        <ReactTable
                                         data={this.state.location}
                                         columns={this.state.columns}
                                         defaultPageSize={20}
                                        className="-striped -highlight fullwidth"
                                        aria-label='rights'
                                        />  
                                        

                            {/* <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th
                                            title="ApplicationPrivileges"
                                            style={{ width: "250px" }}
                                             
                                        >
                                            Application Privileges
                                        </th>
                                        <th title="Create/Add">Create</th>
                                        <th title="Edit">Edit</th>
                                        <th title="View">View</th>
                                        <th title="Email">Email</th>
                                        <th title="File Upload">File Upload</th>
                                        <th title="Data Upload">Data Upload</th>
                                        <th title="File Download">File Download</th>
                                        <th title="Others">Others</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className="subhead">Initial Contact</td>
                                        <td>
                                            <Form.Group controlId="formCreatePrivlige">
                                                <Form.Check
                                                    name="initialcontactcreate"
                                                    type="checkbox"
                                                    checked={this.state.role.initialcontactcreate}
                                                    aria-labelledby="formCreatePrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>

                                        <td>
                                            <Form.Group controlId="formEditPrivlige">
                                                <Form.Check
                                                    name="initialcontactcedit"
                                                    type="checkbox"
                                                    checked={this.state.role.initialcontactcedit}
                                                    aria-labelledby="formEditPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formViewPrivlige">
                                                <Form.Check
                                                    name="initialcontactview"
                                                    type="checkbox"
                                                    checked={this.state.role.initialcontactview}
                                                    aria-labelledby="formViewPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formEmailPrivlige">
                                                <Form.Check
                                                    name="initialcontactemail"
                                                    type="checkbox"
                                                    checked={this.state.role.initialcontactemail}
                                                    aria-labelledby="formEmailPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formFileUploadPrivlige">
                                                <Form.Check
                                                    name="initialcontactfilepload"
                                                    type="checkbox"
                                                    checked={this.state.role.initialcontactfilepload}
                                                    aria-labelledby="formFileUploadPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formDataUploadPrivlige">
                                                <Form.Check
                                                    name="dataUploadPrivlige"
                                                    type="checkbox"
                                                    checked={this.state.role.dataUploadPrivlige}
                                                    aria-labelledby="formDataUploadPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formFileDownloadPrivlige">
                                                <Form.Check
                                                    name="initialcontactfiledownload"
                                                    type="checkbox"
                                                    checked={this.state.role.initialcontactfiledownload}
                                                    aria-labelledby="formFileDownloadPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formOtherPrivlige">
                                                <Form.Check
                                                    name="initialcontactother"
                                                    type="checkbox"
                                                    checked={this.state.role.initialcontactother}
                                                    aria-labelledby="formOtherPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="subhead">Outcome of Contact</td>                                        
                                        <td>
                                            <Form.Group controlId="formCreatePrivlige">
                                                <Form.Check
                                                    name="outcomeofcontactcreate"
                                                    type="checkbox"
                                                    checked={this.state.role.outcomeofcontactcreate}
                                                    aria-labelledby="formCreatePrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formEditPrivlige">
                                                <Form.Check
                                                    name="outcomeofcontactedit"
                                                    type="checkbox"
                                                    checked={this.state.role.outcomeofcontactedit}
                                                    aria-labelledby="formEditPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formViewPrivlige">
                                                <Form.Check
                                                    name="outcomeofcontactview"
                                                    type="checkbox"
                                                    checked={this.state.role.outcomeofcontactview}
                                                    aria-labelledby="formViewPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formEmailPrivlige">
                                                <Form.Check
                                                    name="outcomeofcontactemail"
                                                    type="checkbox"
                                                    checked={this.state.role.outcomeofcontactemail}
                                                    aria-labelledby="formEmailPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formFileUploadPrivlige">
                                                <Form.Check
                                                    name="outcomeofcontactfileupload"
                                                    type="checkbox"
                                                    checked={this.state.role.outcomeofcontactfileupload}
                                                    aria-labelledby="formFileUploadPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formDataUploadPrivlige">
                                                <Form.Check
                                                    name="outcomeofcontactdataupload"
                                                    type="checkbox"
                                                    checked={this.state.role.outcomeofcontactdataupload}
                                                    aria-labelledby="formDataUploadPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formFileDownloadPrivlige">
                                                <Form.Check
                                                    name="outcomeofcontactfiledownload"
                                                    type="checkbox"
                                                    checked={this.state.role.outcomeofcontactfiledownload}
                                                    aria-labelledby="formFileDownloadPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                        <td>
                                            <Form.Group controlId="formOtherPrivlige">
                                                <Form.Check
                                                    name="outcomeofcontactothers"
                                                    type="checkbox"
                                                    checked={this.state.role.outcomeofcontactothers}
                                                    aria-labelledby="formOtherPrivlige"
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        </td>
                                    </tr> 
                                </tbody>
                            </Table> */}
                        </Row>

                        <div className="float-right mb-30"> 
                    <Button type="submit" variant="primary" onClick={this.handleSaveClick} aria-label='Save'>               
                            {Labels.filter(lbl => lbl.keyPair=='RoleSetup_SaveBtn' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                        ))}                         
                    </Button>                  
                    <Button className="" variant="secondary" className="ml-10" onClick={this.handleCancelClick} aria-label='Cancel'>
                            {Labels.filter(lbl => lbl.keyPair=='RoleSetup_CancelBtn' && lbl.pageName=='RoleSetup').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                        ))}
                    </Button>                    
                    </div> 
                       
                    </div>
                </Form>


            </div>
        </>
    );
    }
}
