import React, { useState } from 'react';
import { Button, Modal, Table, Card, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import { ToastContainer, toast} from 'react-toastify';
import axios from "axios";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import "react-table/react-table.css"; 
import _ from "lodash";
import Select from 'react-select';
import ReactTable from "react-table";
import { map,omit } from 'lodash/fp';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./fileupload.css";
import uploadImg from './images/cloud-upload-regular-240.png';
import { ImageConfig } from './ImageConfig'; 
import Icon from '@material-ui/core/Icon';
//const configData= require('config');
const configData = require( '../../config.json');
var convertarray=(array)=>{
  
  var first = array[0];//.rows[0].join('|')
          var headers = first;//.split('|');
           
          var jsonData = [];

          for ( var i = 1, length = array.length; i < length; i++ )
          {
        
            var myRow = array[i];//.join('|');
            var row = myRow;//.split('|');
            // row.push('total');
            var data = {};
            for ( var x = 0; x < row.length; x++ )
            {
              if(headers[x]=="startdate" ||headers[x]=="enddate" ||headers[x]=="start_date" ||headers[x]=="end_date"){
                data[headers[x]] = row[x].length>0? this.ExcelDateToJSDate(row[x]):'';
              }
              else{
                data[headers[x]] = row[x];
              }
            }
           
            jsonData.push(data);
           // array.rows[i][10]=1000;
          }
          var total=0;
          for (i = 0; i < jsonData.length; i++) {  //loop through the array
            total += parseFloat( jsonData[i].activitylevel);  //Do the math!
          }
          return jsonData;
}
const fileType =
"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const fileExtension = ".xlsx";
 
export default class FileUpload extends React.Component  {
    constructor(...args) {
        super(...args)
          
        this.state={
           selectedFile: [],
           orgname:'',
           loginname:'',
           filetype:null,
           fileuploaddetails:[],
           columns:[],
           userrole:{create:''}          
          }
         
          this.onChangeHandler = this.onChangeHandler.bind(this);
        this.fileInput = React.createRef();
        this.setState({Labels:[],  isFetching: true});       
        this.fetchLabelMessagesAPI();        
    }
     componentDidMount() {
      this.fetchFileType();
       this.fetchFileUploadDetails();
       this.getColumns();
       
    }

    getColumns = () => {
      var rolelist= JSON.parse(localStorage.getItem("rolelocation"));      
      var primaryroleval=  localStorage.getItem("primaryrolesession");
      var selectedrolelocation=undefined;
      _.forEach(rolelist, function(value) {
           
          if(primaryroleval==value.name){
              selectedrolelocation=value;
          }
          
      });
     
      var userrl =_.find(selectedrolelocation.location, function(obj) {
          return obj.location === 'FileUpload';
      });
      let columns = [
        {
          Header: "File Name",
            accessor: "filename",
          sortable: true,
          show: true,
          displayValue: "File Name"        
          
        },
        {
          Header: "File Type",
          accessor: "filetype",
          sortable: true,
          show: true,
          displayValue: "File Type"
          
        },
        {
          Header: "File Location",
          accessor: "path",
          sortable: true,
          show: true,
          displayValue: "File Location"
          
        },        
        {
          Header: "File Status",
          accessor: "status",
          sortable: true,
          show: true,
          displayValue: "File Status"
          
        },
        {
          Header: "Uploaded By",
          accessor: "createby",
          sortable: true,
          show: true,
          displayValue: "Uploaded By"
          
        },  
        {
          Header: "Uploaded Date",
          accessor: "createdt",
          sortable: true,
          show: true,
          displayValue: "Uploaded Date"
          
        },
        {
          Header:"Delete",
          maxWidth: 100,
          minWidth: 58,
          width: 58,
          show:userrl.delete=='Y'?true:false,
          Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
              className="deletebutton"
              aria-label="Delete"
              onClick={(event) => this.handleDeleteClick(row,event)}
          >
              <i className="">
                  <img
                      alt="Delete"
                      className=""
                      src="/images/delete_icon.png"
                  />
              </i>
          </a></div></div>)
        }   
       
      ];
      this.setState({ columns });
    };


  handleChange = e => {
      
    let { value, name } = e.target;    
        
         this.setState({...this.state,filetype:value});      
        
};


fetchLabelMessagesAPI = () => {
  this.setState({...this.state, isFetching: true});
  
  axios
  .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
  , {
      headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*"
      }
  })
  .then(
      res=>  {this.setBindMessage(res.data) }
  )
  .catch(err => {
      console.log("AXIOS ERROR: ", err);
  });
};

setBindMessage=(result)=>{
  
  if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
    
    var session=  localStorage.getItem("session");
        
    var userlogin=JSON.parse(session);
    var fullorgname=userlogin.Result[0].org;
    var n=fullorgname.lastIndexOf("/");
    var org=fullorgname.substring(n+1,fullorgname.length)
    this.setState({Labels: result,orgname:org,loginname:userlogin.Result[0].login, isFetching: false});
  }
 

}

onChangeHandler=event=>{

var arrayList=this.state.selectedFile;
for (let i = 0; i < event.target.files.length; i++) {
 
 
arrayList.push(event.target.files[i])
}
 this.setState({...this.state,
    selectedFile: arrayList,
    loaded: 0,
  })
}

// .then(res => { // then print response status
//   console.log(res.statusText)
// })
// }

fetchFileType = () => {        
  this.setState({...this.state, isFetching: true});
  fetch(configData.SERVER_URL+'fincorp_getFileTypes')
      .then(response => response.json())
      .then(result => {
           this.bindFileType(result)
      })
      .catch(e => {
          console.log(e);
          this.setState({...this.state, isFetching: false});
      });
};
bindFileType=(result)=>{
  if(result!='No document(s) found with defined find criteria!' && result.length>0)
  {
      
       this.setState({...this.state,filetypes: result, isFetching: false})
       
  }
  else
  {
       
    this.setState({...this.state,filetypes: [], isFetching: false})
  }
   
}

handleFileTypeSelectChange=selectedOption=>{

  // this.setState(prevState => ({     
  //           // ...prevState.filetypes,  
  //         file_type: selectedOption     
  // }));
  this.setState({...this.state,file_type: selectedOption, isFetching: false}) 
  //this.fetchFileType(selectedOption);
 
}

fetchFileUploadDetails = () => {        
  this.setState({...this.state, isFetching: true});
  fetch(configData.SERVER_URL+'fincorp_getFileUploadDetailsList')
      .then(response => response.json())
      .then(result => {
           this.bindFileUploadDetails(result)
      })
      .catch(e => {
          console.log(e);
          this.setState({...this.state, isFetching: false});
      });
};
bindFileUploadDetails=(result)=>{

  if(result!='No document(s) found with defined find criteria!' && result.length>0)
  {
    var session=  localStorage.getItem("session");
        
    var userlogin=JSON.parse(session);
    var fullorgname=userlogin.Result[0].org;
    var n=fullorgname.lastIndexOf("/");
    var org=fullorgname.substring(n+1,fullorgname.length)
    
    var newArray = result.filter(function(item) {
      return item.orgname==org;
    });
    
       this.setState({...this.state,fileuploaddetails: newArray, isFetching: false})
  }
  else
  {
       
      this.setState({fileuploaddetails: [], isFetching: false})
  }
   
}

 fileRemove = (file) => {
 
  const updatedList = this.state.selectedFile;
  updatedList.splice(this.state.selectedFile.indexOf(file), 1);

  this.setState({...this.state,selectedFile: updatedList, isFetching: false})
}
onClickHandler = (event) => { 

  console.log(this.state.file_type,'file_type');
 
  if(this.state.file_type==undefined || this.state.file_type.label ==undefined || this.state.file_type.label ==""){
    toast.warn("Select File Type");
     event.preventDefault();            
    } 

  else if(this.state.selectedFile ==undefined || this.state.selectedFile.length==0){
    toast.warn("Choose File");
     event.preventDefault();        
    }
         

  else{

  //     }
 
  
  console.log(this.state.selectedFile.name);
  var bodyFormData = new FormData();
  for(var i=0;i<this.state.selectedFile.length;i++){
  bodyFormData.append('files', this.state.selectedFile[i]);
}
var filenames='';
for(var i=0;i<this.state.selectedFile.length;i++){
  filenames=filenames+ this.state.selectedFile[i].name+'~';
}
  const config = {     
    headers: { 'content-type': 'multipart/form-data',
     }
}
axios({
  method: "post",
  url: configData.SERVER_URL+`fincorp_insertFileUpload`,
  data: bodyFormData,
  headers: { "Content-Type": "multipart/form-data","Authorization":this.state.orgname+'|'+filenames
  },
})
  .then(response => {
    // For file already exist
    if(response.data.returnval!=null && response.data.returnval==-1){
      var comments='';
      
      for(var ob=0;ob<response.data.existingfiles.length;ob++){
        comments=comments+response.data.existingfiles[ob].filename+',';
      }
      toast.warn("File Already Exists "+comments);
    }else{    
   
    
    console.log(filenames);
    for(var i=0;i<this.state.selectedFile.length;i++){
      var filedetail={};
   
    filedetail.path=response.data.filepath+this.state.selectedFile[i].name;
    filedetail.filename=this.state.selectedFile[i].name;
    filedetail.filetype=this.state.file_type.label;
    filedetail.orgname=this.state.orgname;
    filedetail.status=response.data.status;
    filedetail.createdt=response.data.createdt;
    filedetail.createby=this.state.loginname;
    filedetail.moved='N';
    
  
    axios.post(configData.SERVER_URL+`fincorp_insertFileUploadDetails`, filedetail, {  })
    .then(res => { // then print response status
        
      toast.success("File Uploaded Successfully");
      this.fetchFileUploadDetails();
      this.setState({...this.state,selectedFile: [], isFetching: false})
    })
    }
   } // file already exist ends

  
  });
    
}
}

handleDeleteClick=(row,event)=>{
  if(window.confirm('Are you sure to delete this record?')){           
      if(row.original._id!=undefined){
          axios
          .post(configData.SERVER_URL+`fincorp_deleteUploadFileReference`,
          
          {'_id': row.original._id}
          , {
              headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                  "Access-Control-Allow-Origin": "*"
              }
          })
          .then(
              res => this.reloadDataGrid(res.data)
              
          )
          .catch(err => {
              console.log("AXIOS ERROR: ", err);
          });
      // event.preventDefault();
      }
      else{
          //error            
          toast.warn("File Reference Not Deleted");
      }
  }
}
reloadDataGrid=(res)=>{       
        
  toast.success("File Reference Delete Successfully");
  setTimeout( this.fetchFileUploadDetails(),4000);
}

insertfileuploaddetails=(filepath,status,createdt)=>{
  
 
}
  render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const optionsArray =this.state.filetypes!=null&& this.state.filetypes!=undefined? this.state.filetypes:[];  
        const fileList= this.state.selectedFile!=null&& this.state.selectedFile!=undefined?this.state.selectedFile:[];       
        return (
        <>
         {/* <ToastContainer autoClose={false} />  */}
            <div className="container userinfo-container fileuploadlist" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='FileUpload_Title' && lbl.pageName=='FileUpload').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='FileUpload_Title' && lbl.pageName=='FileUpload').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                    </span>
                </div> 
                <div className="fullwidth row m0">
                  <div className="  col-md-3 ">
                  
                      <div className="row col-md-12 mt-10 p0 ">                
                      {/* <Form.Control
                                              id="filetype"
                                              as="select"
                                              name="status"
                                              value={this.state.filetype}
                                              onChange={this.handleChange}
                                              aria-label='Select File'
                                              className= "ml-10"
                                          >
                                              <option value="Select File">Choose File Type</option>
                                            
                                              <option value="Sales">Sales</option>
                                              <option value="Purchases">Purchases</option>
                                              <option value="Inventry">Inventry</option>
                                              <option value="Expenses">Expenses</option>
                                              <option value="Income">Income</option>
                                              <option value="Cost Driver">Cost Driver</option>   
                        </Form.Control>  */}
                        
                        
                        <Select         
                                        value={this.state.file_type}
                                        onChange={this.handleFileTypeSelectChange}                                        
                                        options={optionsArray.map(opt => ({ label: opt.file_type, value: opt._id }))}
                                        className= "ml-10"
                                       
                                        />
                                        
                      </div>

                      
                      


                      <div className="fullwidth row m0">
                      <div className="col-md-12 mt-10 p0">
                        <div className="drop-file-input">
                            <div className="drop-file-input__label">
                              <img src={uploadImg} alt="" />
                              <p>Drag & Drop your files here</p>
                          </div>
                          <input type="file" multiple="multiple" name="file" onChange={this.onChangeHandler}  onClick={(event)=> { 
               event.target.value = null
          }}/> 
                      </div>
                      {
                      fileList.length > 0 ? (
                          <div className="drop-file-preview">
                              <p className="drop-file-preview__title">
                                  Files ready for upload
                              </p>
                              {
                                  fileList.map((item, index) => (
                                      <div key={index} className="drop-file-preview__item">
                                          {/* <img src={ImageConfig[item.type.split('/')[1]] || ImageConfig['default']} alt="" /> */}
                                          <div className="drop-file-preview__item__info">
                                              <p className="word-wrapper">{item.name}</p>
                                              <p>{item.size}B</p>
                                          </div>
                                          <span className="drop-file-preview__item__del" onClick={() => this.fileRemove(item)}>
                                            <Icon color="error">close</Icon></span>
                                      </div>
                                  ))
                              }
                          </div>
                      ) : null
                  }                
                      </div>              
                    </div> 
                    <div className="fullwidth row m0">
                        <div className="col-md-12 mt-10 p0">                      
                                <div className="float-left mb-30">                         
                                  <Button type="button" onClick={this.onClickHandler} >
                                  {/* className= {this.state.userrole.create=='Y'?"btn btn-primary":"hidden"} */}
                                  {Labels.filter(lbl => lbl.keyPair=='FileUpload_Upload' && lbl.pageName=='FileUpload').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}  
                                  </Button>                           
                                    </div>  
                                    
                        </div>
                      </div> 
                  </div>
                  <div className="  offset-1 col-md-8 ">   
                        <div className="fullwidth row m0">            
                          <div  className="row col-md-12 mt-10  ml-0 p0">
                          <ReactTable
                              data={this.state.fileuploaddetails}
                              columns={this.state.columns}
                              defaultPageSize={15}
                              className="-striped -highlight fullwidth"
                          />
                          </div> 
                      </div> 
                  </div>
                </div>
            </div>
        </>
    );
                   
}
}


 