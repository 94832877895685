import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import _ from "lodash";
import * as moment from "moment";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "react-table/react-table.css"; 
 
import './report.css';
// import {
//     useExportData
//    } from 'react-table-plugins';
   import Papa from "papaparse";
   //import XLSX from "xlsx";
   import JsPDF from "jspdf";
   import "jspdf-autotable";
//const configData= require('config');
const configData = require( './../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
    searchInput: "",
    chkisactive:true 
   
}
export default class Reports extends React.Component  {

    constructor(...args) {
        super(...args)

        let currentDate = new Date();

        // Subtract 30 days
        currentDate.setDate(currentDate.getDate() - 90);
        
        this.state = {
            isDataLoaded:false,
            periodname:'90',
             show: false,
             fileOutput:[],
             fileOutputOriginal:[],

             fileTypes:[],
             reportname:'',
             processedfileslist: [],
             columns: [],
             originalprocessedfileslist:[],
             searchInput: "",
             chkisactive:false,
             reportlist:[],
             originalreportlist:[],
             end_date: moment(new Date()).format("MM-DD-YYYY"),
             start_date: moment(currentDate).format("MM-DD-YYYY")

        }
        this.setState({Labels:[], isFetching: true});
        // this.fetchLabelMessagesAPI();

    }
    componentDidMount() { 
        this.fetchFileOutputListAPI();  
        // this.fetchReportList();
        this.getColumns(); 
       
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));      
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'FileUpload';
        });
        let columns = [
          {
            Header: "Report Name",
              accessor: "filename",
            sortable: true,
            show: true,
            displayValue: "Report Name"        
            
          },
          {
            Header: "Created By",
            accessor: "createby",
            sortable: true,
            show: true,
            displayValue: "Created By"
            
          },  
          {
            Header: "Created Date",
            accessor: "createdt",
            sortable: true,
            show: true,            
            displayValue: "Created Date"
            
          },
          {
            Header:"Download",
            maxWidth: 100,
            // minWidth: 58,
            // width: 58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="downloadcontainer"> <a
                className="downloadbutton"
                aria-label="Download"
                onClick={(event) => this.handlePDFClick(row,event)}
            >
                <i className="">
                    <img
                        alt="PDF"
                        className=""
                        src="/images/pdf_icon.png"
                        title="PDF"
                    />
                     
                </i>
            </a>
            <a
                className="downloadbutton"
                aria-label="Download"
                onClick={(event) => this.handleCSVClick(row,event)}
            >
                <i className="">
                    
                    <img
                        alt="CSV"
                        className="ml-3"
                        src="/images/csv_icon.png"
                        title="CSV"
                    />
                </i>
            </a>
            </div></div>)
          }   
         
        ];
        this.setState({ columns });
      };
  
      fetchFileOutputListAPI = () => {
       
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getFileOutputList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindFileOutputList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };
    setBindFileOutputList=(result)=>{
         
        var filetypeuniq=_.uniqBy(result,'filetype');
        filetypeuniq.splice(0, 0, {filetype: 'Select One'});
        filetypeuniq.splice(1, 0, {filetype: 'All'});

        result.splice(0, 0, {filename: 'Select One'});


        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({fileOutput: result,fileOutputOriginal:result,fileTypes:filetypeuniq, isFetching: false});
            
        }
        

    }
    onStartDateChange = (jsDate, dateString) => {
       
        this.setState(prevState => ({
            
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                start_date: moment(dateString).format("MM-DD-YYYY")
                
            
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
       
        this.setState(prevState => ({
            
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                end_date: moment(dateString).format("MM-DD-YYYY")
                
            
        }));
    };

    handleSearchFileOutputClick=()=>{

         
    
        var stdate=this.state.start_date!=undefined? new Date( moment(this.state.start_date).format('MM-DD-YYYY')):new Date( moment('01-01-1990').format('MM-DD-YYYY'));
        var etdate=this.state.end_date!=undefined? new Date( moment(this.state.end_date).format('MM-DD-YYYY')):new Date( moment('01-01-2050').format('MM-DD-YYYY'));

        var stname=this.state.reportname;
        let result = _.find(this.state.fileOutput, function (obj) {
            if ( new Date(obj.processedDate)>stdate && new Date(obj.processedDate)<etdate && stname==obj.filename) {
                return obj;
            } 
        });
        
        if(result!=undefined && result.filename!=undefined){
            if(this.state.reportname!=undefined){
            
                this.fetchRowsFetchAPI(this.state.reportname,'');
            }
            else{
                //error            
                toast.warn("Please select the FileName");
            }
        }
        else{
            toast.warn("No matching record found");
            this.getColumns(); 
            this.setState({reportlist: [], isFetching: false})
        }
        
    }
    reloadDataGrid=(res,e)=>{            
                  
        toast.success("Master Lookup Item Delete Successfully");
        setTimeout( this.fetchMasterLookupItemsAPI(),4000);
        e.preventDefault();
        e.stopPropagation();
      }
    
fetchReportList = () => {        
    this.setState({...this.state, isFetching: true});
    fetch(configData.SERVER_URL+'fincorp_getFileOutputList')
        .then(response => response.json())
        .then(result => {
             this.bindReportList(result)
        })
        .catch(e => {
            console.log(e);
            this.setState({...this.state, isFetching: false});
        });
  };
  bindReportList=(result)=>{
   
    if(result!='No document(s) found with defined find criteria!' && result.length>0)
    {
      var session=  localStorage.getItem("session");
          
      var userlogin=JSON.parse(session);
      var fullorgname=userlogin.Result[0].org;
      var n=fullorgname.lastIndexOf("/");
      var org=fullorgname.substring(n+1,fullorgname.length)
      
      var newArray = result.filter(function(item) {
        return item.orgname==org;
      });
      
         this.setState({...this.state,reportlist: newArray,originalreportlist:newArray, isFetching: false})
    }
    else
    {
         
        this.setState({reportlist: [], isFetching: false})
    }
     
  }
   
  handlePDFClick=(row,event)=>{
     
    if(window.confirm('Are you want to download PDF?')){  
         if(this.state.reportname!=undefined){
            var columnlist = Object.keys(this.state.reportlist[0] || []).map((key) => ({
                Header: key,
                accessor: key
              }));
              
            columnlist=columnlist.slice(1);
            var newArray = this.state.columns.filter(function(item) {
                return item.show==true;
              });
              //unchecked array list
              var uncheckArray = this.state.columns.filter(function(item) {
                return item.show==false;
              });
              var pdfdatalist=this.state.originalreportlist;
              _.each(uncheckArray, function (value, key) {
                pdfdatalist=  _.map(pdfdatalist, function (c) {
                    return _.omit(c, [value.accessor]);
                });
              });
            this.getExportFileBlob(newArray,pdfdatalist,'PDF',this.state.reportname);
        }
        else{
            //error            
            toast.warn("File Reference Not Deleted");
        }
    }
  }
  handleCSVClick=(row,event)=>{
    if(window.confirm('Are you want to download CSV ?')){           
        if(this.state.reportname!=undefined){
            var columnlist = Object.keys(this.state.reportlist[0] || []).map((key) => ({
                Header: key,
                accessor: key
              }));
              
            columnlist=columnlist.slice(1);
            var newArray = this.state.columns.filter(function(item) {
                return item.show==true;
              });
              //unchecked array list
              var uncheckArray = this.state.columns.filter(function(item) {
                return item.show==false;
              });
              var pdfdatalist=this.state.originalreportlist;
              _.each(uncheckArray, function (value, key) {
                pdfdatalist=  _.map(pdfdatalist, function (c) {
                    return _.omit(c, [value.accessor]);
                });
              });
            this.getExportFileBlob(newArray,pdfdatalist,'CSV',this.state.reportname);
        }
        else{
            //error            
            toast.warn("File Reference Not Deleted");
        }
    }
  }

     handleChange = e => {
        let { value, name } = e.target;
       
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.componentDidCatchstate,
            [name]: value,
            date
        });
    };

    

     handleClick = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

     handleEditClick = (row) => {
        this.props.history.push("/ProcessedFile?id=" + row.id);
    };

     viewImgFormatter = (cell, row) => {              
        return (
            <a
                className="viewbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="View"
                        className=""
                        src="/images/vieworange_icon.png"
                    />
                </i>
            </a>
        );
    }    

   
     handleSearchClick=(row)=>{
        
        // console.log(this.state.originalreportlist);

        // let { searchInput } = this.state;       
        // let filteredData = this.state.originalItems.filter(value => {
             
        //      return (
        //     (value.lookup_item.toLowerCase().includes(searchInput.toLowerCase()) ||
             
        //     value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
        //     ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
        //     value.status.toString().includes('Active')  
        //     );

             
        // }
        // );
        // this.setState({ items: filteredData });
    }

     selectImgFormatter = (cell, row) => {              
        return (
            <input type="checkbox"
                className="checkbutton"
                // onClick={() => handleSelectClick(row)}
            >
                 
            </input>
        );
    }

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }

    fetchRowsFetchAPI = (filename,filetype) => {

        //var querystring = queryString.parse(this.props.location.search);
        
        this.setState({...this.state, isFetching: true});
       
            axios.post(configData.SERVER_URL+`fincorp_getRowsBasedOnCollectionName/`,
        
            {'genericcolname':filename}
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res=>  {this.setBindRowsBasedOnName(res.data,filetype,filename) }
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };
 
    setBindRowsBasedOnName=(data,filetype,filename)=>{
       
        if(data!='No document(s) found with defined find criteria!' && data.length>0){

            var columnlist = Object.keys(data[0] || []).map((key,index) => ({
                Header: key,
                accessor: key,
                show: index< 8 ?true:false
              }));

              columnlist=columnlist.slice(1);
              this.setState({...this.state,
                isDataLoaded:true,
                reportlist: data,
                originalreportlist:data,
                columns:columnlist, 
                isFetching: false})
             
        }
    }

     handleInputChange = (event) => {
        
        const target = event.target;
        var value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
         
            this.setState(prevState => ({
                
                    ...prevState, // keep all other key-value pairs
                    [name]: value // update the value of specific key
                
            }));

            console.log(this.state);
        
         
    }
    handlePeriodInputChange= (event) => {
        
        const target = event.target;
        var value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        var enddate=this.state.end_date;
         

// Split the string by the dash to separate day, month, and year
let dateParts = enddate.split('-');

// Create a new Date object from the parts
let formattedEndDate = new Date(dateParts[2],  dateParts[0]-1,dateParts[1] );
 debugger;
 
        var startdate= formattedEndDate.setDate(formattedEndDate.getDate() -  parseInt(value)) ;

            this.setState(prevState => ({
                
                    ...prevState, // keep all other key-value pairs
                    [name]: value, // update the value of specific key
                    start_date:startdate
            }));
  
    }

    handleFileTypeChange = (event) => {
        
        const target = event.target;
        var value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        let filtered_array =value=='All'?this.state.fileOutputOriginal: _.filter(this.state.fileOutputOriginal, ['filetype', value]);
        filtered_array.splice(0, 0, {filename: 'Select One'});
        this.setState(prevState => ({
                    ...prevState, // keep all other key-value pairs
                    fileOutput: filtered_array // update the value of specific key
        }));
    }
 
    handleChkInputChange=(event)=>{
         
        const target = event.target;
        var value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const index = this.state.columns.findIndex(obj => obj.accessor ==target.name);
        var collist= this.state.columns;
        collist[index].show=value;
        
       var filteredCollection = _.map(this.state.originalreportlist, function (c) {
            return _.omit(c, [name]);
        });

        var uncheckArray = collist.filter(function(item) {
            return item.show==false;
          });
          var pdfdatalist=this.state.originalreportlist;
          _.each(uncheckArray, function (value, key) {
            pdfdatalist=  _.map(pdfdatalist, function (c) {
                return _.omit(c, [value.accessor]);
            });
          });


        this.setState({ reportlist:pdfdatalist,originalreportlist:this.state.originalreportlist,columns:collist, isFetching: false})
    }
     
    getExportFileBlob=( columns, data, fileType, fileName )=> {
       
        if (fileType === "CSV") {
            debugger;
            // CSV example
            data=  _.map(data, function (c) {
                return _.omit(c, ['_id']);
            });
            const headerNames = columns.filter((c) => c.Header != 'Action').map((col) => col.exportValue);
            const csvString = Papa.unparse({ fields: headerNames, data });
            const csvData = Papa.unparse(data);

            this.exportData(csvData, fileName+'.csv', 'text/csv;charset=utf-8;');
           // return new Blob([csvString], { type: "text/csv" });
        } 
        // else if (fileType === "xlsx") {
        //     // XLSX example

        //     const header = columns.filter((c) => c.Header != 'Action').map((c) => c.exportValue);
        //     const compatibleData = data.map((row) => {
        //         const obj = {};
        //         header.forEach((col, index) => {
        //             obj[col] = row[index];
        //         });
        //         return obj;
        //     });

        //     let wb = XLSX.utils.book_new();
        //     let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        //         header,
        //     });
        //     XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        //     XLSX.writeFile(wb, `${fileName}.xlsx`);

        //     // Returning false as downloading of file is already taken care of
        //     return false;
        // }
        //PDF example
        if (fileType === "PDF") {
            
            let keylist = Object.keys(data[0]);
            var keylist1=[];
            for (let k = 0; k < keylist.length ; k++) {
                if(k!=0)
                    keylist1.push(keylist[k]);
            }
           // let firstKeyValue = firstObj[keylist];
            var rowvallist=[];
            for (let i = 0; i < data.length ; i++) {
                var rowval=[];
                for (let j = 0; j < keylist.length ; j++) {
                    if(j!=0){
                    var colval=data[i][keylist[j]];
                    rowval.push(colval);
                   }
                 
                }
                rowvallist.push(rowval);

            }
            var rowfinal=[];
            rowfinal.push(rowvallist);
            var keyfinal=[];
            keyfinal.push(keylist1);
             
            const doc = new JsPDF();
            doc.autoTable({
                head: keyfinal,
                body: rowvallist,
                styles: {
                    minCellHeight: 9,
                    halign: "left",
                    valign: "center",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }

        // Other formats goes here
        return false;
    }
      exportData = (data, fileName, type) => {
        // Create a link and download the file
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      };

    render() {
   
    return (
        <>
            <div className="container-fuild reports-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Reports</Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">Reports</span>
                    {/* {Labels.filter(lbl => lbl.keyPair=='Title_Reports' && lbl.pageName=='Reports').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))} */}
                <ToastContainer />
                </div>

                    <Form.Row style={{ marginTop: "1%", marginLeft: "10px", border: "1px solid black", borderRadius: "5px", padding: "5px" }}>
                                               
                      <Row className="margin-t10 col-md-12">
                             <Col md={2} className="p-r0">
                                    <Col md={12} className="p-r0">
                                        <Form.Label for="frmfiletype" >
                                            File Type
                                        </Form.Label>
                                        <Form.Control
                                            id="frmfiletype"
                                            as="select"
                                            name="filetype"
                                            placeholder="filetype"
                                            onChange={this.handleFileTypeChange}
                                        >
                                             {this.state.fileTypes &&
                                            this.state.fileTypes.length > 0 &&
                                            this.state.fileTypes.map(file => {
                                                return <option value={file.filetype}> {file.filetype}</option>;
                                            })}
                                        </Form.Control>
                                    </Col>
                                </Col>

                            <Col md={2} >
                                    <Col md={12} className="p-0">
                                    <Form.Label for="frmreportname" >
                                           Report Name
                                        </Form.Label>
                                        <Form.Control
                                        as="select"
                                        id="reportname"
                                        className="form-control"
                                        controlId="reportname"
                                        name="reportname"                                       
                                        onChange={this.handleInputChange}
                                        value={this.state.reportname}                                        
                                        placeholder="Select Report"                                       
                                        required
                                    >
                                         {this.state.fileOutput &&
                                            this.state.fileOutput.length > 0 &&
                                            this.state.fileOutput.map(file => {
                                                return <option value={file.filename}> {file.filename}</option>;
                                            })}
                                      
                                    </Form.Control>
                                    </Col>
                                </Col>
                                <Col md={2}>
                                    <Col md={12}>
                                        <Form.Label for="frmstartdate" >
                                            Start Date
                                        </Form.Label>
                                        <DatePickerInput
                                            id="frmstartdate"
                                            aria-label="frmstartdate"
                                            placeholder="MM/DD/YYYY"
                                            onChange={this.onStartDateChange.bind(this, 'startdate') }
                                            value={this.state.start_date}
                                            name="frmstartdate"
                                            className="my-custom-datepicker-component pull-left"

                                        />
                                    </Col>
                                </Col>
                                <Col md={2}>
                                    <Col md={12}>
                                        <Form.Label for="frmenddate" >
                                            End Date
                                        </Form.Label>
                                        <DatePickerInput
                                            id="frmenddate"
                                            aria-label="frmenddate"
                                            placeholder="MM/DD/YYYY"
                                            onChange={this.onEndDateChange.bind(this, 'enddate') }
                                            value={this.state.end_date}
                                            name="frmenddate"
                                            className="my-custom-datepicker-component pull-left"
                                        />
                                    </Col>
                                </Col>
                                <Col md={2}>
                                    <Col md={12}>
                                    <Form.Label for="frmperiodname" >
                                          Period
                                        </Form.Label>
                                        <Form.Control
                                        as="select"
                                        id="periodname"
                                        className="form-control"
                                        controlId="periodname"
                                        name="periodname"                                       
                                        onChange={this.handlePeriodInputChange}
                                        value={this.state.periodname}                                        
                                        placeholder="Select Period"                                       
                                        required
                                    >
                                        <option value="0">Select Period</option>
                                        <option value="30">1 Month </option>
                                        <option value="60">2 Months </option>
                                        <option value="90">3 Months </option>
                                        <option value="180">6 Months </option>
                                        <option value="365">12 Months </option>
                                        <option value="730">24 Months </option>
                                        <option value="1095">36 Months </option>
                                      
                                    </Form.Control>
                                    </Col>
                                </Col>
                                <Col md={2} className="mt-30 p-0">
                                <Col md={12} className="p-0">
                                        
                                        <Button id="search" type="submit" variant="primary" aria-label='Search' onClick= {this.handleSearchFileOutputClick}>               
                                         Search
                                        </Button> 
                                      
                                        <i className="ml-10" onClick= {this.handlePDFClick}>
                                                <img
                                                    alt="PDF"
                                                    className="primary imgbutton"
                                                    src="/images/pdf_icon.png"
                                                    title="Download PDF"
                                                /> 
                                                
                                            </i>
                                             <i className=""  onClick={(event) => this.handleCSVClick()}>
                                                <img
                                                    alt="CSV"
                                                    className="mgbutton"
                                                    src="/images/csv_icon.png"
                                                    title="Download CSV"
                                                />
                                            </i>
                                           
                                    </Col>
                                </Col>                               
                            </Row>
                            </Form.Row>
                            
                            
                            <Form.Row className="m-10 bdr">
                                <div className={this.state.isDataLoaded?'show':'hide'}>
                                {this.state.columns.map((item, index) => (
                                <div className='chkrootdiv' key={index}>
                                    <input className="chkreport" name={item.accessor} type="checkbox" onChange={this.handleChkInputChange} checked={item.show} value={item.show} />
                                    <span>{item.accessor}</span>
                                </div>
                                ))} 
                               
                            </div>
                            </Form.Row> 
                            <Form.Row>
                            <div className={this.state.isDataLoaded?'show col-md-12':'hide col-md-12'}>   
                            <div className="fullwidth row m0">            
                             <div  className="row col-md-12 mt-10  ml-0 p0">
                                <ReactTable
                                data={this.state.reportlist}
                                columns={this.state.columns}
                                defaultPageSize={15}
                                className="-striped -highlight fullwidth"
                                />
                             </div> 
                            </div> 
                            </div>
                            </Form.Row> 
                        

            </div>
            <div className='btm-height'>
                </div>                           


        </>
    );
}
}

