import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import * as moment from "moment";
import "moment/min/moment-with-locales";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";

//const configData= require('config');
const configData = require( './../../config.json');


const initialValues = {
    weight: '',
    height: '',
    date: '',
    }

// function afterSearch(searchText, result) {
//     console.log('Your search text is ' + searchText);
//     console.log('Result is:');
//     for (let i = 0; i < result.length; i++) {
//       console.log('Fruit: ' + result[i].id + ', ' + result[i].name + ', ' + result[i].price);
//     }
//   }
  
//   const options = {
//     afterSearch: afterSearch  // define a after search hook
//   };
  

  export default class EmailDistributionList extends React.Component  {
    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
             emaildistrlist: [],
             columns: [],
             originalemaildistrlist:[],
             searchInput: "",
             chkisactive:true,            
             userrole:{create:''}
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

    }
    componentDidMount() {
     
        this.fetchEmailDistrbnListAPI();
        this.getColumns();
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'EmailDistribution';
        });
        let columns = [
          {
            Header: "Group Name",
            accessor: "grp_name",
            sortable: true,
            show: true,
            displayValue: " Group Name" 
          },
          {
            Header: "Email Address",
            accessor: "email_addr",
            sortable: true,
            show: true,
            displayValue: "Email Address"
          },
          {
            Header: "Owner",
            accessor: "owner",
            sortable: true,
            show: true,
            displayValue: "Owner"
          },
          {
            Header: "Start Date",
            accessor: "startdate",
            sortable: true,
            show: true,
            displayValue: "State Date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: "End Date",
            accessor: "enddate",
            sortable: true,
            show: true,
            displayValue: "End Date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },          
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "Status",
            maxWidth: 100,
            minWidth: 56,
            width: 56,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };
   
   
      fetchEmailDistrbnListAPI = () => {
          
        this.setState({...this.state, isFetching: true});       
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getEmailDistrbnList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setEmailDistrbnList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });   
    };
   
    setEmailDistrbnList=(result)=>{    

        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'EmailDistribution';
        });
       
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({emaildistrlist: result,originalemaildistrlist:result,userrole: userrl, isFetching: false});
            this.globalSearch();
        }
        else if (result.Message=="Invalid Session")
        {
            this.setState({
                ...this.state,
                loggedusername: undefined 
                });
            localStorage.setItem("session",undefined);
            window.location="/login";
        }
        else{
            this.setState({...this.state,userrole: userrl, isFetching: false})
        }      
        
        

    }

     handleChange = e => {
        let { value, name } = e.target;
      
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date

        });
    };

     handleSelectClick=(row)=>{

    }

     selectImgFormatter = (cell, row) => {              
        return (
            <input type="checkbox"
                className="checkbutton"
                onClick={() => this.handleSelectClick(row)}
            >
                 
            </input>
        );
    }

     handleSubmit = () => {
        this.change(this.state);
        this.setState(initialValues);
    };
     handleSaveClick = () => {

    };

     
    handleEditClick = (row,event) => {
        this.props.history.push("/emailgroupinfo?id=" + row.original._id);
    };
    
    handleDeleteClick=(row,event)=>{
        if(window.confirm('Are you sure to delete this record?')){
            event.preventDefault();
            event.stopPropagation();           
            if(row.original._id!=undefined){
                axios
                .post(configData.SERVER_URL+`fincorp_deleteEmailDistrbn`,
                
                {'_id': row.original._id}
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.reloadDataGrid(res.data)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
            // event.preventDefault();
            }
            else{
                //error            
                toast.warn("EmailDistrbn Not Deleted");
            }
        }
        else{
            event.preventDefault();
            event.stopPropagation();
        }
      }
      reloadDataGrid=(res)=>{       
              
        toast.success("EmailDistrbn Delete Successfully");
        setTimeout( this.fetchEmailDistrbnListAPI(),4000);
      }

     editImgFormatter = (cell, row) => {              
        return (
            <a
                className="editbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a>
        );
    }

     deleteImgFormatter = (cell, row) => {              
        return (
            <a
                className="deletebutton center"
                aria-label="Delete"
                onClick={() => this.handleDeleteClick(row)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a>
        );
    }


    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };
      toggleChange = () => {
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }

      globalActiveSearch = (param) => {
        let { searchInput } = this.state;       
        let filteredData = this.state.originalemaildistrlist.filter(value => {
            if(param){

                return (
                    (value.grp_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.email_addr.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.owner.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                    value.status.toString().includes('Active')  
                   
                );

            }else{
                return (
                    value.grp_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.email_addr.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.owner.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                    value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        });
        this.setState({ emaildistrlist: filteredData });
      };
      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalemaildistrlist.filter(value => {
        if(this.state.chkisactive){    
          return (
            (value.grp_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.email_addr.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.owner.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
            ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
            value.status.toString().includes('Active')  
          );
        }else{
            return (
                value.grp_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.email_addr.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.owner.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
      );
    }

        });
        this.setState({ emaildistrlist: filteredData });
      };


    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let {  searchInput } = this.state;
    return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='EmailDistrbn_Title' && lbl.pageName=='EmailDistributionList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='EmailDistrbn_Title' && lbl.pageName=='EmailDistributionList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </span>
                    <ToastContainer />
                </div>      
                    <div className="fullwidth">
                        <div className="float-left">  
                            <Link to="/emailgroupinfo" className= {this.state.userrole.create=='Y'?"btn btn-primary float-left":"hidden"}>
                            {Labels.filter(lbl => lbl.keyPair=='EmailDistrbn_CreateGroupBtn' && lbl.pageName=='EmailDistributionList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))} 
                            </Link>
                        </div>                   
                        <div className="float-right">
                        <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            aria-label='Status'
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='EmailDistrbn_Search' && lbl.pageName=='EmailDistributionList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                        ))}
                        </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"
                            placeholder=""
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />
                        </div>
                        </div>                    
                    </div>  
                   <div className="fullwidth mt-50">                          
                        <div className="row col-md-12 m0 p0">
                        <ReactTable
                        data={this.state.emaildistrlist}
                        columns={this.state.columns}
                        defaultPageSize={20}
                        className="-striped -highlight fullwidth"
                        /> 
                        
                        </div>
                        <br />
                    </div> 
            </div>
        </>
    );
 }
}

