import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import * as moment from "moment";
import "moment/min/moment-with-locales";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";

//const configData= require('config');
const configData = require( '../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',  
    filetype: ['xls', 'pdf']
}
export default class ExecutableTrackerList extends React.Component  {

    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
            executabletracker:"",
             executabletrackerlist: [],
             columns: [],
             originalexecutabletrackerlist:[],
             searchInput: "",
             chkisactive:false,             
             userrole:{create:''}
        }
        this.setState({Labels:[],  isFetching: true});
        this.fetchLabelMessagesAPI();

    }
    componentDidMount() {     
       
        this.fetchExecutableTrackerList();
        this.getColumns();
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'ExecutableTracker';
        });
        let columns = [
          {
            Header: "Tracker ID",
            accessor: "tracker_id",
            sortable: true,
            show: true,
            displayValue: "Tracker Name",
            width: 100 
          },          
          {
            Header: "Process ID",
            accessor: "process_id",
            sortable: true,
            show: true,
            displayValue: "Program ID", 
            width: 100            
          },
          {
            Header: "Process Status",
            accessor: "process_status",
            sortable: true,
            show: true,
            displayValue: "Process Status" ,
            width: 130  
                   
          },
          {
            Header: "StartTime & Date",
            accessor: "starttime_date",
            sortable: true,
            show: true,
            displayValue: "Start Time & Date"
           
          },  
          {
            Header: "Sequence ID",
            accessor: "seq_id",
            sortable: true,
            show: true,
            displayValue: "Sequence ID",
            width: 100 
          },        
          {
            Header: "Seq ID Status",
            accessor: "seq_id_status",
            sortable: true,
            show: true,
            displayValue: "Sequence ID Status",
            width: 130 
          },
          {
            Header: "Seq Start Time",
            accessor: "seq_id_starttime",
            sortable: true,
            show: true,
            displayValue: "SequenceID Start Time",
            width: 140 
          },
          {
            Header: "Seq End Time",
            accessor: "seq_id_endtime",
            sortable: true,
            show: true,
            displayValue: "SequenceID End Time",
            width: 140
          }, 
                
          {
            Header:"Run",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.edit=='Y'?true:false,
           // disabled={this.state.executabletracker.auto_or_manual=="Manual"?true:false}
            Cell:row => (<div className="grdbuttoncontainer"><div className="runcontainer"> <a
                className={row.original.auto_or_manual=="manual"?"runbutton":"hidden"}
                aria-label="Run"
                onClick={(event) => this.handleRunClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Run"
                        className=""
                        src="/images/run_icon.png"
                    />
                </i>
            </a></div></div>)
          }


        ];
        this.setState({ columns });
      };

      fetchExecutableTrackerList = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getExecutableTrackerList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setExecutableTrackerList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    
    setExecutableTrackerList=(result)=>{ 
        
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'ExecutableTracker';
        });

        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){            
            this.setState({executabletrackerlist: result,originalexecutabletrackerlist:result,userrole: userrl, isFetching: false});
            this.globalSearch();
        }
        else
         this.setState({...this.state,userrole: userrl, isFetching: false})
        

    }

    handleChange = e => {
        let { value, name } = e.target;
        
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date

        });
        if(name=='search')
        {
            this.refs.table.handleFilterData({ name: value });
            // this.refs.table.handleFilterData({value});
        }
        else if(name=='status' && value=='Inactive'){
            const currentdate = new Date();
            currentdate.setDate(currentdate.getDate()-1);
            var momentDateObj = moment(currentdate).format('MM-DD-YYYY');
            var enddate=new Date(this.state.enddate);
            if(currentdate<enddate){
                var stdate=moment(this.state.startdate).format('MM-DD-YYYY')>moment(currentdate).format('MM-DD-YYYY')?moment(currentdate).format('MM-DD-YYYY'):moment(this.state.startdate).format('MM-DD-YYYY');

                this.setState({startdate:stdate, enddate:momentDateObj });
            }
            
        }
    };

    

     selectImgFormatter = (cell, row) => {              
        return (
            <input type="checkbox"
                className="checkbutton"
                // onClick={() => handleSelectClick(row)}
            >
                 
            </input>
        );
    }

    handleEditClick = (row) => {
        
       this.props.history.push("/executabletracker?id=" + row.original._id);
   };

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
    }
        
        
        handleChange1 = event => {
        
            this.setState({ searchInput: event.target.value }, () => {
              this.globalSearch();
            });
          };
          toggleChange = () => {
            this.setState({
                chkisactive: !this.state.chkisactive,
            });
            this.globalActiveSearch(!this.state.chkisactive) ;
          }
    
          globalActiveSearch = (param) => {
              
            let { searchInput } = this.state;    
            console.log(searchInput);  

            let filteredData = this.state.originalexecutabletrackerlist.filter(value => {
                if(param){
    
                    return (
                        (value.tracker_id.toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.process_id.toLowerCase().includes(searchInput.toLowerCase()) || 
                        // value.process_status.toLowerCase().includes(searchInput.toLowerCase()) || 
                        value.starttime_date.toLowerCase().includes(searchInput.toLowerCase()) || 
                        value.seq_id.toLowerCase().includes(searchInput.toLowerCase()) ||                       
                        value.seq_id_status.toLowerCase().includes(searchInput.toLowerCase()) ||  
                        value.seq_id_endtime.toLowerCase().includes(searchInput.toLowerCase()) ||                     
                        ( value.seq_id_starttime!=undefined||value.seq_id_starttime!=null? value.seq_id_starttime.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                        value.process_status.toString().includes('Active') 
                       
                    );
    
                }else{
                    return (
                        value.tracker_id.toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.process_id.toLowerCase().includes(searchInput.toLowerCase()) || 
                        // value.process_status.toLowerCase().includes(searchInput.toLowerCase()) || 
                        value.starttime_date.toLowerCase().includes(searchInput.toLowerCase()) || 
                        value.seq_id.toLowerCase().includes(searchInput.toLowerCase()) ||                       
                        value.seq_id_status.toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.seq_id_endtime.toLowerCase().includes(searchInput.toLowerCase()) ||
                        ( value.seq_id_starttime!=undefined||value.seq_id_starttime!=null? value.seq_id_starttime.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                        value.process_status.toString().toLowerCase().includes(searchInput.toLowerCase())
              );
            }
            });
            this.setState({ executabletrackerlist: filteredData });
          };
          globalSearch = () => {
              
            let { searchInput } = this.state;
            let filteredData = this.state.originalexecutabletrackerlist.filter(value => {
            if(this.state.chkisactive){    
              return (
    
                        (value.tracker_id.toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.process_id.toLowerCase().includes(searchInput.toLowerCase()) || 
                        // value.process_status.toLowerCase().includes(searchInput.toLowerCase()) || 
                        value.starttime_date.toLowerCase().includes(searchInput.toLowerCase()) || 
                        value.seq_id.toLowerCase().includes(searchInput.toLowerCase()) ||                       
                        value.seq_id_status.toLowerCase().includes(searchInput.toLowerCase()) ||     
                        value.seq_id_endtime.toLowerCase().includes(searchInput.toLowerCase()) ||                  
                        ( value.seq_id_starttime!=undefined||value.seq_id_starttime!=null? value.seq_id_starttime.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                        value.process_status.toString().includes('Active') 
                       
                    );
            }else{
                return (
    
                        value.tracker_id.toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.process_id.toLowerCase().includes(searchInput.toLowerCase()) || 
                        // value.process_status.toLowerCase().includes(searchInput.toLowerCase()) || 
                        value.starttime_date.toLowerCase().includes(searchInput.toLowerCase()) || 
                        value.seq_id.toLowerCase().includes(searchInput.toLowerCase()) ||                       
                        value.seq_id_status.toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.seq_id_endtime.toLowerCase().includes(searchInput.toLowerCase()) ||
                        ( value.seq_id_starttime!=undefined||value.seq_id_starttime!=null? value.seq_id_starttime.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                        value.process_status.toString().toLowerCase().includes(searchInput.toLowerCase())
                  );   
            }
            });
            this.setState({ executabletrackerlist: filteredData });
          };

        handleSubmit = () => {
            this.change(this.state);
            this.setState(initialValues);
        };
         handleSaveClick = () => {
    
        };
    
        handleEditClick = (row) => {
         
           this.props.history.push("/executabletracker?id=" + row.original._id);
       };
        
       handleRunClick=(row,event)=>{
       }

        handleDeleteClick=(row,event)=>{
            if(window.confirm('Are you sure to delete this record?')){
                event.preventDefault();
                event.stopPropagation();           
                if(row.original._id!=undefined){
                    axios
                    .post(configData.SERVER_URL+`fincorp_deleteExecutableTracker`,
                    
                    {'_id': row.original._id}
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                    .then(
                        res => this.reloadDataGrid(res.data)
                        
                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });
                // event.preventDefault();
                }
                else{
                    //error            
                    toast.warn("ExecutableProgram Not Deleted");
                }
            }
            else{
                event.preventDefault();
                event.stopPropagation();
            }
          }
          reloadDataGrid=(res)=>{       
                  
            toast.success("ExecutableTracker Deleted Successfully");
            setTimeout( this.fetchExecutableTrackerList(),4000);
          }   
    
    editImgFormatter = (cell, row) => {              
            return (
                <a
                    className="editbutton"
                    aria-label="Edit"
                    onClick={() => this.handleEditClick(row)}
                >
                    <i className="">
                        <img
                            alt="Edit"
                            className=""
                            src="/images/edit_icon.png"
                        />
                    </i>
                </a>
            );
        }
    
    deleteImgFormatter = (cell, row) => {              
            return (
                <a
                    className="deletebutton center"
                    aria-label="Delete"
                    onClick={() => this.handleDeleteClick(row)}
                >
                    <i className="">
                        <img
                            alt="Delete"
                            className=""
                            src="/images/delete_icon.png"
                        />
                    </i>
                </a>
            );
        }
    
        runImgFormatter = (cell, row) => {              
            return (
                <a
                    className="runbutton center"
                    aria-label="Run"
                    onClick={() => this.handleRunClick(row)}
                >
                    <i className="">
                        <img
                            alt="Run"
                            className=""
                            src="/images/run_icon.png"
                        />
                    </i>
                </a>
            );
        }

        ManualRunStatus = (event)=>{
            alert('Manual Run Triggered Successfully');
        }



    
    render() {
   
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const {searchInput} =this.state;
    return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='ExecutableTrackerList_Title' && lbl.pageName=='ExecutableTrackerList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='ExecutableTrackerList_Title' && lbl.pageName=='ExecutableTrackerList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </span>
                <ToastContainer />
                </div>


                <div className="fullwidth">
                                          
                        <div className="float-right">                        
                        <div className="float-left txtsearchcontainer">    
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='ExecutableTrackerList_Search' && lbl.pageName=='ExecutableTrackerList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}                         
                        </span>                       
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"                            
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />
                         </div>
                        </div>                    
                    </div>     


                    <div className="fullwidth mt-50">                          
                        <div className="row col-md-12 m0 p0">
                        <ReactTable
                        data={this.state.executabletrackerlist}
                        columns={this.state.columns}
                        defaultPageSize={20}
                        className="-striped -highlight fullwidth"
                        /> 
                        
                        </div>
                        <br />
                    </div> 
               
                <br />
               
            </div>



        </>
    );
}
}

