import React from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import '../Home/Home.css';
import './ServicesHome.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import ServicesList from './ServicesMasterList'
import ServicesGroupList from './ServicesAreaList' 
import ServicesActivityList from './ServicesActivityList'
import ServicesSubActivityList from './ServicesTaskList'
import ServicesActivityDetailList from './ServicesTaskDetailList'
import CostDriver from './CostDriver'

//const configData= require('config');
const configData = require( '../../config.json');

export default class ServicesHome extends React.Component {
    constructor(...args) {
        super(...args)

        this.state = {
            value: '',
            show: false,
            servicefiles: [{
                ser_name: 'HR Service',
                ser_desc: 'Resources',
                ser_code: '1000',
                ser_type: 'HR',
                level: '1',
                predecessor: '32',
                startdate: '12-20-2020',
                enddate: '12-20-2022',
                status: 'Active'
            }]
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

    }

     handleBtnSubmit = (param) => {
        // change(state);
        //setState(initialValues);
        
        if (param.currentTarget.id == "services-btn")
            window.location = '/serviceslist';
        else if (param.currentTarget.id == "services-grp-btn")
            window.location = '/servicesgrouplist';
        else if (param.currentTarget.id == "services-activity-btn")
            window.location = '/servicesactivitylist';
        else if (param.currentTarget.id == "services-sub-activity-btn")
            window.location = '/servicessubactivitylist';
        else if (param.currentTarget.id == "services-activity-detail-btn")
            window.location = '/servicesactivitydetaillist';
        else if (param.currentTarget.id == "cost-driver-btn")
            window.location = '/costdriver';
    };

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
     
    handleSubmit = () => {
        // change(state);
        //setState(initialValues);
        alert("Work in Progress..");
    };

     handleReportSubmit = () => { 
      
    };
    handleSaveClick = () => {

    };
     //add new member
    
     
      onChangeValueHandler = (val) => {
        this.setState({ value: val.target.value })
      }

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
    return (
        <div className='container-fluid service-container'>

                <Breadcrumb className='ml-20'>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='Home_Services' && lbl.pageName=='Home').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>
                </Breadcrumb>
            {/* <div className='row titles'>  
                           Service Master
                <img src={'images/right-arrow.png'} className="rightarrow"></img>
                           Service Area
                <img src={'images/right-arrow.png'} className="rightarrow"></img>
                           Service Activity
                <img src={'images/right-arrow.png'} className="rightarrow"></img>
                           Service Tasks
                 <img src={'images/right-arrow.png'} className="rightarrow"></img>
                           Service Tasks Detail
                <img src={'images/right-arrow.png'} className="rightarrow"></img>
                           Cost Driver
                <img src={'images/right-arrow.png'} className="rightarrow"></img>
                           Future1 
                <img src={'images/right-arrow.png'} className="rightarrow"></img>                 
                           Future2 
                <img src={'images/right-arrow.png'} className="rightarrow"></img>
                           Future3
                <img src={'images/right-arrow.png'} className="rightarrow"></img>                 
                           Future4
                <img src={'images/right-arrow.png'} className="rightarrow"></img>                 
                           Future5
            </div> */}
            <ToastContainer />
            <br/>
            <Tabs>
                <TabList>
                    <Tab>
                    {Labels.filter(lbl => lbl.keyPair=='ServiceMasterList_Title' && lbl.pageName=='ServiceMasterList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))}
                    </Tab>
                    <Tab>
                    {Labels.filter(lbl => lbl.keyPair=='ServiceAreaList_Title' && lbl.pageName=='ServiceSegmentList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))}
                    </Tab>
                    <Tab>
                    {Labels.filter(lbl => lbl.keyPair=='ServiceActivityList_Title' && lbl.pageName=='ServiceActivityList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))}
                    </Tab>
                    <Tab>
                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskList_Title' && lbl.pageName=='ServiceTaskList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))}
                    </Tab>
                    <Tab>
                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetailList_Title' && lbl.pageName=='ServiceTaskDetailList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))}
                    </Tab>
                    <Tab>
                    {Labels.filter(lbl => lbl.keyPair=='CostDriverList_Title' && lbl.pageName=='CostDriverList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))}
                    </Tab>
                    <Tab>Future 1</Tab>
                    <Tab>Future 2</Tab>  
                    <Tab>Future 3</Tab>
                    <Tab>Future 4</Tab>
                    {/* <Tab>Future 5</Tab> */}
                </TabList>
                
         
                <TabPanel>
                <ServicesList value={this.state.value} onChangeValue={this.onChangeValueHandler} />                
               
                    {/* Grid For Services Master */}
              
                </TabPanel>
                <TabPanel>
                <ServicesGroupList value={this.state.value} onChangeValue={this.onChangeValueHandler} />                    
                </TabPanel>                
                <TabPanel>
                <ServicesActivityList value={this.state.value} onChangeValue={this.onChangeValueHandler} />
                </TabPanel>
                <TabPanel>
                <ServicesSubActivityList value={this.state.value} onChangeValue={this.onChangeValueHandler} />
                </TabPanel>
                <TabPanel>
                <ServicesActivityDetailList value={this.state.value} onChangeValue={this.onChangeValueHandler} />
                </TabPanel>              
                <TabPanel>
                <CostDriver value={this.state.value} onChangeValue={this.onChangeValueHandler} />                
                </TabPanel>
                <TabPanel>
                    <h5>Future 1 Content</h5>
                </TabPanel>   
                <TabPanel>
                    <h5>Future 2 Content</h5>
                </TabPanel>     
                <TabPanel>
                    <h5>Future 3 Content</h5>
                </TabPanel>       
                <TabPanel>
                    <h5>Future 4 Content</h5>
                </TabPanel>     
                {/* <TabPanel>
                    <h5>Future 5 content</h5>
                </TabPanel>      */}
            </Tabs>
          
        </div>
    );
        }
}


 
