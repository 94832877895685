import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from 'react-select';
import { ToastContainer, toast} from 'react-toastify';
import { DatePicker, DatePickerInput } from "rc-datepicker";
import 'react-toastify/dist/ReactToastify.css';
import * as moment from "moment";
import "moment/min/moment-with-locales";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import './ServicesHome.css';
import _ from "lodash";

//const configData= require('config');
const configData = require( './../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: ''   
}
export default class CostDriver extends React.Component  {
    constructor(...args) {
        super(...args)

            this.state = {
            show: false,
            costDriver :'' ,
            costDriverDataSource : '',
            costDriverOffice :'',
            serviceMaster : '',
            serviceArea : '',
            serviceActivity : '',
            serviceTask : '',
            serviceTaskDetail :'',
            start_date :'',
            end_date :'',
            status : '',
            _id:undefined,
            costdriverfiles: [],
            columns:[],
            originalcostdriverfiles:[],
            searchInput: "",
            filteredData: [],
            serviceMasterList:[],
            serviceAreaList:[],
            serviceActivityList:[],
            serviceTaskList:[],
            serviceTaskDetailList:[],
            chkisactive:true,            
            userrole:{create:''}
        

        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

    }
     componentDidMount() {
     
        this.fetchCostDriverListAPI();
        //load master records
        this.fetchServiceMasterListAPI();
        //load service area records 
        this.fetchServiceAreaListAPI();
         //load service activity records 
         this.fetchServiceActivityListAPI();
          //load service task records 
        this.fetchServiceTaskListAPI();
         //load service taskdetail records 
         this.fetchServiceTaskDetailListAPI();         
        this.getColumns();
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Services';
        });
        let columns = [
            {
                Header: "Cost Driver",
                accessor: "costDriver",
                sortable: true,
                show: true,
                displayValue: " Cost Driver" 
              },
              {
                Header: "Data Source",
                accessor: "costDriverDataSource",
                sortable: true,
                show: true,
                displayValue: " Data Source" 
              },
              {
                Header: "Office",
                accessor: "costDriverOffice",
                sortable: true,
                show: true,
                displayValue: "Office"
              },
              {
                Header: "Service Master",
                accessor: "serviceMaster",
                sortable: true,
                show: true,
                displayValue: " Service Master",
                Cell:row=>(<span>{row.original.serviceMaster.label}</span>)
              },              
             {
                Header: "Service Area",
                accessor: "serviceArea",
                sortable: true,
                show: true,
                displayValue: " Service Area" ,
                Cell:row=>(<span>{row.original.serviceArea.label}</span>)
            },
            {
                Header: "Service Activity",
                accessor: "serviceActivity",
                sortable: true,
                show: true,
                displayValue: " Service Activity",
                Cell:row=>(<span>{row.original.serviceActivity.label}</span>) 
          },
          {
            Header: "Service Task",
            accessor: "serviceTask",
            sortable: true,
            show: true,
            displayValue: " Service Task" ,
            Cell:row=>(<span>{row.original.serviceTask.label}</span>)
          },
          {
            Header: "Service Task Detail",
            accessor: "serviceTaskDetail",
            sortable: true,
            show: true,
            displayValue: " Service Task Detail" ,
            Cell:row=>(<span>{row.original.serviceTaskDetail.label}</span>)

          },          
          {
            Header: "Start Date",
            accessor: "start_date",
            sortable: true,
            show: true,
            displayValue: "start_date",
            maxWidth: 100,
            minWidth: 90,
            width: 90
          },
          
          {
            Header: "End Date",
            accessor: "end_date",
            sortable: true,
            show: true,
            displayValue: "end_date",
            maxWidth: 100,
            minWidth: 90,
            width: 90
          },          
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "status",
            maxWidth: 100,
            minWidth: 58,
            width: 58
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };

    fetchCostDriverListAPI = () => {
        this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getCostDriverList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setCostDriverList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setCostDriverList=(result)=>{
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Services';
        });
       
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
           
            this.setState({costdriverfiles: result, originalcostdriverfiles:result,userrole: userrl, isFetching: false});
            this.globalSearch();
        }     
        else
         this.setState({...this.state,userrole: userrl, isFetching: false})   

    }

   
     handleChange = e => {
        let { value, name } = e.target;
       
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date
        });
    };

        
    handleAddMember=()=>{
        this.state.costDriver = '';
        this.state.costDriverDataSource ='';        
        this.state.costDriverOffice = '';
        this.state.serviceMaster = '';
        this.state.serviceArea = '';
        this.state.serviceActivity = '';
        this.state.serviceTask = '';
        this.state.serviceTaskDetail = '';        
        this.state.start_date = '';
        this.state.end_date = '';
        this.state.status = '';

        this.state._id = undefined;
        this.setState({show: true, editshow:false });
    }
    handleClose=()=> {
        this.state.costDriver = '';
        this.state.costDriverDataSource ='';        
        this.state.costDriverOffice = '';
        this.state.serviceMaster = '';
        this.state.serviceArea = '';
        this.state.serviceActivity = '';
        this.state.serviceTask = '';
        this.state.serviceTaskDetail = '';        
        this.state.start_date = '';
        this.state.end_date = '';
        this.state.status = '';

        this.state._id = undefined;
        this.setState({ show: false });
    }

    handleClick = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

    handleEditClick = (row,event) => {
        this.state.costDriver = row.original.costDriver;
        this.state.costDriverDataSource = row.original.costDriverDataSource;       
        this.state.costDriverOffice = row.original.costDriverOffice;
        var servicemasterval=undefined;
        
        _.forEach(this.state.serviceMasterList, function(value) {
          
         if(row.original.serviceMaster==value.serviceName){
             
            servicemasterval=value;
         }
     });
        this.state.serviceMaster = {'label':servicemasterval.serviceName,'value':servicemasterval._id} ;

        var serviceareaval=undefined;
           _.forEach(this.state.serviceAreaList, function(value) {
             
            if(row.original.serviceArea==value.serviceAreaName){
                
                serviceareaval=value;
            }
        });
        this.state.serviceArea ={'label':serviceareaval.serviceAreaName,'value':serviceareaval._id};
        //this.state.serviceArea = row.original.serviceArea;
        var serviceactivityval=undefined;
        _.forEach(this.state.serviceActivityList, function(value) {
         if(row.original.serviceActivity==value.serviceActivityName){
            serviceactivityval=value;
         }
        });
        this.state.serviceActivity ={'label':serviceactivityval.serviceActivityName,'value':serviceactivityval._id};
        //this.state.serviceActivity = row.original.serviceActivity;
        var serviceactivitytaskval=undefined;
        _.forEach(this.state.serviceTaskList, function(value) {
         if(row.original.serviceTask==value.serviceTaskName){
            serviceactivitytaskval=value;
         }
        });
        this.state.serviceTask ={'label':serviceactivitytaskval.serviceTaskName,'value':serviceactivitytaskval._id};
        //this.state.serviceTask = row.original.serviceTask;
        var serviceactivitylistval=undefined;
        _.forEach(this.state.serviceTaskDetailList, function(value) {
         if(row.original.serviceTaskDetail==value.serviceTaskDetailName){
            serviceactivitylistval=value;
         }
        });
        this.state.serviceTaskDetail ={'label':serviceactivitylistval.serviceTaskDetailName,'value':serviceactivitylistval._id};
        //this.state.serviceTaskDetail = row.original.serviceTaskDetail;
        this.state.start_date = row.original.start_date;
        this.state.end_date = row.original.end_date;
        this.state.status = row.original.status;

        this.state._id = row.original._id;
        this.setState({ show: true, editshow: false });
       // this.props.history.push("/Item?id=" + row.id);
    };
    
    editImgFormatter=(cell, row)=> {
       
        return (
            <a
                className="editbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a>
        );
    }
    deleteImgFormatter=(cell, row)=> {        
        return (
            <a
                className="deletebutton"
                aria-label="Delete"
                onClick={() => this.handleDeleteClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a>
        );
    }

    handleDeleteClick = (row,event) => {
        if(window.confirm('Are you sure to delete this record?')){ 
        //delete
        if (row.original._id!= undefined) {
            axios
                .post(configData.SERVER_URL + `fincorp_deleteCostDriver`,

                    { '_id': row.original._id }
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                .then(
                    res => this.reloadDataGrid(res.data)
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
        else {
            //error            
            toast.warn("Cost Driver Not Deleted");
        }
    }
    }
    reloadDataGrid = (res) => {

        toast.success("Cost Driver  Deleted Successfully");
        setTimeout(this.fetchCostDriverListAPI(), 4000);
    }

    onStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                start_date: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                end_date: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };

 

    handleSaveClick = (event) => {
        
        if (this.state.costDriver == undefined || this.state.costDriver == "" || this.state.costDriver.length == 0) {
           toast.warn("Enter Cost Driver ");
           event.preventDefault();

       }       
       else if (this.state.costDriverDataSource == undefined || this.state.costDriverDataSource == "") {
           toast.warn("Enter Data Source");
           event.preventDefault();
       }
       else if (this.state.costDriverOffice == undefined || this.state.costDriverOffice == "") {
        toast.warn("Enter Cost Driver Office");
        event.preventDefault();


    }
    else if (this.state.serviceMaster == undefined || this.state.serviceMaster == "") {
        toast.warn("Enter Service Master");
        event.preventDefault();

    }
    else if (this.state.serviceArea == undefined || this.state.serviceArea == "") {
        toast.warn("Enter Service Area");
        event.preventDefault();

    }
    else if (this.state.serviceActivity == undefined || this.state.serviceActivity == "") {
        toast.warn("Enter Service Activity");
        event.preventDefault();

    }
    else if (this.state.serviceTask == undefined || this.state.serviceTask == "") {
        toast.warn("Enter Service Task");
        event.preventDefault();

    }
    else if (this.state.serviceTaskDetail == undefined || this.state.serviceTaskDetail == "") {
        toast.warn("Enter Task Detail");
        event.preventDefault();

    }

       else if (this.state.start_date == undefined || this.state.start_date == "" || this.state.start_date == "Invalid date") {
           toast.warn("Enter StartDate");
           event.preventDefault();


       }  
       else if (Date.parse(this.state.start_date) > Date.parse(this.state.end_date)) {
           toast.warn("End Date should be greater than the StartDate");
           event.preventDefault();


       }     
       else if (this.state.status == undefined || this.state.status == "") {
           toast.warn("Choose Status");
           event.preventDefault();


       }
       else {
           
        this.state.start_date=  this.state.start_date == "Invalid date"?null:this.state.start_date;
        this.state.end_date=  this.state.end_date == "Invalid date"?null:this.state.end_date;

           if (this.state._id!= undefined) {
            if(window.confirm('Do you want to save changes?')){ 
          
               axios
                   .post(configData.SERVER_URL + `fincorp_updateCostDriver`,

                       { '_id': this.state._id, 'costDriver': this.state.costDriver, 
                       'costDriverDataSource': this.state.costDriverDataSource,
                        'costDriverOffice': this.state.costDriverOffice, 
                        'serviceMaster': this.state.serviceMaster.label,
                        'serviceArea':this.state.serviceArea.label,
                        'serviceActivity':this.state.serviceActivity.label,
                        'serviceTask':this.state.serviceTask.label,
                        'serviceTaskDetail':this.state.serviceTaskDetail.label,                        
                         'start_date': this.state.start_date, 
                         'end_date': this.state.end_date,
                          'status': this.state.status }
                       , {
                           headers: {
                               "Content-Type": "application/json;charset=UTF-8",
                               "Access-Control-Allow-Origin": "*"
                           }
                       })
                   .then(
                       res =>  this.UpdateChanges(res.data) 


                   )
                   .catch(err => {
                       console.log("AXIOS ERROR: ", err);
                   });
                }     

           }
           else {
               
               axios
                   .post(configData.SERVER_URL + `fincorp_insertCostDriver`,

                       { 'costDriver': this.state.costDriver, 
                       'costDriverDataSource': this.state.costDriverDataSource,
                       'costDriverOffice': this.state.costDriverOffice, 
                       'serviceMaster': this.state.serviceMaster,
                       'serviceArea':this.state.serviceArea,
                       'serviceActivity':this.state.serviceActivity,
                       'serviceTask':this.state.serviceTask,
                       'serviceTaskDetail':this.state.serviceTaskDetail,                       
                       'start_date': this.state.start_date, 
                       'end_date': this.state.end_date,
                       'status': this.state.status}
                       , {
                           headers: {
                               "Content-Type": "application/json;charset=UTF-8",
                               "Access-Control-Allow-Origin": "*"
                           }
                       })
                   .then(
                       res => this.SaveChanges(res.data) 
                   )
                   .catch(err => {
                       console.log("AXIOS ERROR: ", err);
                   });

           }

       }
   };
   SaveChanges = (res) => {
    if(res.MessageKey!=undefined){
        toast.warn(res.Message);
    }
    else{
        toast.success("Cost Driver Inserted Successfully");
        setTimeout( this.fetchCostDriverListAPI(),4000);
        this.setState({ show: false, editshow: false });
    }
   }
   UpdateChanges = (res) => {
    if(res.MessageKey!=undefined){
        toast.warn(res.Message);
    }
    else{
       toast.success("Cost Driver Updated Successfully");
       setTimeout( this.fetchCostDriverListAPI(),4000);
       this.state.costDriver = '';
       this.state.costDriverDataSource ='';
       this.state.start_date = '';
       this.state.end_date = '';
       this.state.costDriverOffice = '';
       this.state.serviceMaster = '';
       this.state.serviceArea = '';
       this.state.serviceActivity = '';
       this.state.serviceTask= '';
       this.state.serviceTaskDetail = '';     
       this.state.status = '';

       this.state._id = undefined;

       this.setState({ show: false, editshow: false });
    }
  }

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }        

    }

    fetchServiceMasterListAPI = () => {
        this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getServiceMasterList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setServiceMasterList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setServiceMasterList=(result)=>{
        if(result.Message=='Invalid Session'){
            this.props.history.push("/login")
        }
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            this.setState({serviceMasterList: result,  isFetching: false});
        }     
    }

    handleSelectChange = (selectedOption) => {
        
        this.setState(prevState => ({
                   ...prevState,  
                   serviceMaster: selectedOption
             
        }));
        
      };

      fetchServiceAreaListAPI = () => {
        this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getServiceAreaList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setServiceAreaList(res.data) }           

        )       
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setServiceAreaList=(result)=>{
        if(result.Message=='Invalid Session'){
            this.props.history.push("/login")
        }
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            this.setState({serviceAreaList: result,  isFetching: false});
            console.log(result)
        }     
    }

    handleSelectChangeArea = (selectedOption) => {
        this.setState(prevState => ({
                   ...prevState,  
                   serviceArea: selectedOption
             
        }));
        
      };
    
      fetchServiceActivityListAPI = () => {
        this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getServiceActivityList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setServiceActivityList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setServiceActivityList=(result)=>{
        if(result.Message=='Invalid Session'){
            this.props.history.push("/login")
        }
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            this.setState({serviceActivityList: result,  isFetching: false});
        }     
    }

    handleSelectChangeActivity = (selectedOption) => {
        this.setState(prevState => ({
                   ...prevState,  
                   serviceActivity: selectedOption
             
        }));
        
      }; 
      
      fetchServiceTaskListAPI = () => {
        this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getServiceTaskList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setServiceTaskList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setServiceTaskList=(result)=>{
        if(result.Message=='Invalid Session'){
            this.props.history.push("/login")
        }
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            this.setState({serviceTaskList: result,  isFetching: false});
        }     
    }

    handleSelectChangeTask = (selectedOption) => {
        this.setState(prevState => ({
                   ...prevState,  
                   serviceTask: selectedOption
             
        }));
        
      };
      
      fetchServiceTaskDetailListAPI = () => {
       // this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getServiceTaskDetailList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setServiceTaskDetailList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setServiceTaskDetailList=(result)=>{

        if(result.Message=='Invalid Session'){
            this.props.history.push("/login")
        }
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            this.setState({serviceTaskDetailList: result,  isFetching: false});
        }     
    }

    handleSelectChangeTaskDetail = (selectedOption) => {
        this.setState(prevState => ({
                   ...prevState,  
                   serviceTaskDetail: selectedOption
             
        }));
        
      }; 
      
   
      handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };

      toggleChange = () => {
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }

      globalActiveSearch = (param) => {
        let { searchInput } = this.state;       
        let filteredData = this.state.originalcostdriverfiles.filter(value => {
            if(param){

                return (
                    (value.costDriver.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.costDriverDataSource.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.costDriverOffice.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceMaster.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceArea.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceActivity.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceTask.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceTaskDetail.toLowerCase().includes(searchInput.toLowerCase()) ||           
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                    value.status.toString().includes('Active')  
                   
                );

            }else{
                return (
                    value.costDriver.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.costDriverDataSource.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.costDriverOffice.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceMaster.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceArea.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceActivity.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceTask.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceTaskDetail.toLowerCase().includes(searchInput.toLowerCase()) ||           
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                    value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        });
        this.setState({ costdriverfiles: filteredData });
      };

      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalcostdriverfiles.filter(value => {
           
            if(this.state.chkisactive){ 
          return (
            (value.costDriver.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.costDriverDataSource.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.costDriverOffice.toString().toLowerCase().includes(searchInput.toLowerCase())||
            value.serviceMaster.label.toString().toLowerCase().includes(searchInput.toLowerCase())||
            value.serviceArea.label.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.serviceActivity.label.toString().toLowerCase().includes(searchInput.toLowerCase())||
            value.serviceTask.label.toString().toLowerCase().includes(searchInput.toLowerCase())||
            value.serviceTaskDetail.label.toLowerCase().includes(searchInput.toLowerCase()) ||           
            value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
            ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
            value.status.toString().includes('Active')
            
          );
        }else{
            return (
                value.costDriver.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.costDriverDataSource.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.costDriverOffice.toString().toLowerCase().includes(searchInput.toLowerCase())||
                value.serviceMaster.label.toString().toLowerCase().includes(searchInput.toLowerCase())||
                value.serviceArea.label.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.serviceActivity.label.toString().toLowerCase().includes(searchInput.toLowerCase())||
                value.serviceTask.label.toString().toLowerCase().includes(searchInput.toLowerCase())||
                value.serviceTaskDetail.label.toLowerCase().includes(searchInput.toLowerCase()) ||           
                value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
      );
        }
        });
        this.setState({ costdriverfiles: filteredData });
      };


    render() {
        
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const optionsMasterArray =this.state.serviceMasterList!=null&& this.state.serviceMasterList!=undefined? this.state.serviceMasterList:[]; 
        const optionsAreaArray =this.state.serviceAreaList!=null&& this.state.serviceAreaList!=undefined? this.state.serviceAreaList:[]; 
        const optionsActivityArray =this.state.serviceActivityList!=null&& this.state.serviceActivityList!=undefined? this.state.serviceActivityList:[]; 
        const optionsTaskArray =this.state.serviceTaskList!=null&& this.state.serviceTaskList!=undefined? this.state.serviceTaskList:[]; 
        const optionsTaskDetailArray =this.state.serviceTaskDetailList!=null&& this.state.serviceTaskDetailList!=undefined? this.state.serviceTaskDetailList:[]; 
        let { data, columns, searchInput } = this.state;
    return (
        <>
            <div className="container userinfo-container" role="main">
                {/* <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/serviceshome">Services Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Cost Driver
                    </Breadcrumb.Item>
                </Breadcrumb> */}
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='CostDriverList_Title' && lbl.pageName=='CostDriverList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </span>
                </div>
                <div className="fullwidth">
                    <div className="float-left">
                    <button className={this.state.userrole.create=='Y'?"btn btn-primary  float-left ":"hidden"}  onClick={this.handleAddMember} > 
                    {Labels.filter(lbl => lbl.keyPair=='CostDriverList_Addbtn' && lbl.pageName=='CostDriverList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </button>
                    </div>  
                    <div className="float-right">
                    <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" aria-label="Status Filter" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='CostDriverList_Search' && lbl.pageName=='CostDriverList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                        ))}
                        </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />  
                        </div>                       
                    </div>
                </div>
                <div className="fullwidth row m0">
                <div className="col-md-12 mt-10 p0">
                     <ReactTable
                    data={this.state.costdriverfiles}
                    columns={this.state.columns}
                    defaultPageSize={20}
                    className="-striped -highlight fullwidth"
                    />  
                </div>      
               </div>     
                <div>
                <Modal
                        className="CostDriver-modal"
                         show={this.state.show}
                         onHide={this.handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                            {Labels.filter(lbl => lbl.keyPair=='CostDriver_Title' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                <span>
                                {this.state._id==undefined?"Add":"Edit"} {filteredPerson.keyValue}
                                </span>
                            ))}                           
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Row className="mt-10">                                    
                                <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmcostdriver">
                                    {Labels.filter(lbl => lbl.keyPair=='CostDriver_Name' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                <span>
                                    {filteredPerson.keyValue}
                                </span>
                            ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmcostdriver"
                                        type="text"
                                        name="costDriver"
                                        placeholder="Cost Driver"
                                        disabled={this.state._id!=undefined?true:false}
                                        value={this.state.costDriver}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="frmdatasource">
                                {Labels.filter(lbl => lbl.keyPair=='CostDriverDataSource' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                <span>
                                    {filteredPerson.keyValue}
                                </span>
                            ))}
                                </Form.Label>{" "}
                                </Col>
                                <Col md={8}>   
                                <Form.Control
                                            id="frmdatasource"
                                            className="desc-width"
                                            as="textarea"
                                            rows="1"
                                            name="costDriverDataSource"
                                            placeholder=" Data Source"
                                             value={this.state.costDriverDataSource}
                                            onChange={this.handleChange}
                                            className="required"
                                            
                                        />
                                </Col>
                            </Col>
                                </Form.Row>
                                    <Form.Row className="mt-10">
                                    <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmcostdriveroffice">
                                    {Labels.filter(lbl => lbl.keyPair=='CostDriverOffice' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmcostdriveroffice"
                                        type="text"
                                        className="form-control"
                                        controlId="servicecode"
                                        name="costDriverOffice"
                                        placeholder="Cost Driver Office"
                                        onChange={this.handleChange}
                                        value={this.state.costDriverOffice}
                                        required
                                    >
                                    </Form.Control>
                                </Col>
                            </Col>
                            <Col md={6} className="row" >
                                <Col md={4}>
                                    <Form.Label className="required" for="frmservicemaster">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceMaster' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    {/* <Form.Control
                                        id="frmservicemaster"
                                        type="text"
                                        className="form-control"
                                        controlId="servicecode"
                                        name="serviceMaster"
                                        placeholder="Service Master"
                                        onChange={this.handleChange}                                 
                                         value={this.state.serviceMaster}
                                        onChange={this.handleChange}
                                    >
                                    </Form.Control> */}                                    
                                    <Select
                                    name="serviceMaster"
                                    value={this.state.serviceMaster}
                                    onChange={this.handleSelectChange}
                                     
                                    options={optionsMasterArray.map(opt => ({ label: opt.serviceName, value: opt._id }))}
                                    />
                                </Col>
                            </Col>
                                    </Form.Row>
                                    <Form.Row className="mt-10">
                                    <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmservicearea">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceArea' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    {/* <Form.Control
                                        id="frmservicearea"
                                        type="text"
                                        name="serviceArea"
                                        placeholder="Service Area"
                                        disabled={this.state.isedit}
                                        value={this.state.serviceArea}
                                        onChange={this.handleChange}
                                        required
                                    /> */}
                                    <Select
                                    name="serviceArea"
                                    value={this.state.serviceArea}
                                    onChange={this.handleSelectChangeArea}
                                     
                                    options={optionsAreaArray.map(opt => ({ label: opt.serviceAreaName, value: opt._id }))}
                                    />
                                </Col>
                            </Col>                           
                        <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="frmserviceactivity">
                                {Labels.filter(lbl => lbl.keyPair=='ServiceActivity' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                ))}
                                </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                {/* <Form.Control
                                        id="frmserviceactivity"
                                        type="text"
                                        name="serviceActivity"
                                        placeholder="Service Activity"
                                        disabled={this.state.isedit}
                                        value={this.state.serviceActivity}
                                        onChange={this.handleChange}
                                        required
                                    /> */}

                                    <Select
                                    name="serviceActivity"
                                    value={this.state.serviceActivity}
                                    onChange={this.handleSelectChangeActivity}
                                     
                                    options={optionsActivityArray.map(opt => ({ label: opt.serviceActivityName, value: opt._id }))}
                                    />      
                                </Col>
                            </Col>                                       
                            </Form.Row>
                            <Form.Row className="mt-10">
                                    <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="frmservicetask">
                                {Labels.filter(lbl => lbl.keyPair=='ServiceTask' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                ))}
                                </Form.Label>                                </Col>
                                <Col md={8}>
                                {/* <Form.Control
                                        id="frmservicetask"
                                        type="text"
                                        name="serviceTask"
                                        placeholder="Service Task"
                                        disabled={this.state.isedit}
                                        value={this.state.serviceTask}
                                        onChange={this.handleChange}
                                        required
                                    /> */}
                                 <Select
                                    name="serviceTask"
                                    value={this.state.serviceTask}
                                    onChange={this.handleSelectChangeTask}
                                     
                                    options={optionsTaskArray.map(opt => ({ label: opt.serviceTaskName, value: opt._id }))}
                                    />
                                    
                                </Col>
                            </Col>                           
                        <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="frmservicetaskdetail">
                                {Labels.filter(lbl => lbl.keyPair=='ServiceTaskDetail' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                ))}
                                </Form.Label>
                                </Col>
                                <Col md={8}>
                                {/* <Form.Control
                                        id="frmservicetaskdetail"
                                        type="text"
                                        name="serviceTaskDetail"
                                        placeholder="Service Task Detail"
                                        disabled={this.state.isedit}
                                        value={this.state.serviceTaskDetail}
                                        onChange={this.handleChange}
                                        required
                                    /> */}
                                 <Select
                                    name="serviceTaskDetail"
                                    value={this.state.serviceTaskDetail}
                                    onChange={this.handleSelectChangeTaskDetail}
                                     
                                    options={optionsTaskDetailArray.map(opt => ({ label: opt.serviceTaskDetailName, value: opt._id }))}
                                    />    
                                    
                                </Col>
                            </Col>                                       
                            </Form.Row>


                            <Form.Row className="mt-10">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required">
                                    {Labels.filter(lbl => lbl.keyPair=='CostDriver_StartDate' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                <DatePickerInput
                                            id="startdate"
                                            name="start_date"
                                            onChange={this.onStartDateChange.bind(this, 'start_date') }
                                             placeholder="MM/DD/YYYY"
                                             value={this.state.start_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            aria-label="startdate"
                                            readOnly
                                        />                                       
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label>
                                    {Labels.filter(lbl => lbl.keyPair=='CostDriver_EndDate' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>                                   
                                    <DatePickerInput
                                            id="enddate"
                                            name="end_date"
                                            //onChange={this.onEndDateChange}
                                             onChange={this.onEndDateChange.bind(this, 'end_date') }
                                            placeholder="MM/DD/YYYY"
                                             value={this.state.end_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            aria-label="enddate"
                                            readOnly
                                        />
                                </Col>
                            </Col>                               
                            </Form.Row>

                            <Form.Row  className="mt-10">
                           
                     <Col md={6} className="row" >
                                <Col md={4}>
                                    <Form.Label className="required" for="servicesmasterstatus">
                                    {Labels.filter(lbl => lbl.keyPair=='CostDriver_Status' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="servicesmasterstatus"
                                        as="select"
                                        name="status"
                                     value={this.state.status}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control>
                                </Col>
                        </Col>  
                   
                    </Form.Row>  


                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                               
                                <Button variant="primary" onClick={this.handleSaveClick}>
                                {Labels.filter(lbl => lbl.keyPair=='CostDriver_SaveBtn' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                ))}
                                </Button>
                                 <Button variant="secondary" onClick={this.handleClose} >
                                 {Labels.filter(lbl => lbl.keyPair=='CostDriver_CancelBtn' && lbl.pageName=='CostDriver').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                ))}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        
                </div>
   


            </div>
        </>
    );
 }
}

