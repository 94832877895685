import React from 'react';
import { Redirect } from 'react-router-dom';
import './Home.css';
import Icon from '@material-ui/core/Icon';
import cookie from "react-cookie";
import _ from "lodash";
import axios from "axios";
// import logo from './logo.png';

//const configData= require('config');
const configData = require('./../../config.json');

class Home extends React.Component  { 
  //  var lgsession=cookie.load("session");
   // console.log(cookie.load("session"));

   constructor(...args) {
    super(...args);
  
    this.state = {
        userrole:{
            User:{},
            Role:{},
            Admin:{},
            Dashboard:{},
            FileProcessing:{},
            ProcessedFiles:{},
            Organization:{},
            Services:{},
            MasterLookup:{},
            ApprovalLimits:{},
            EmailDistribution:{},
            Search:{},
            Report:{},
            Calendar:{},
            CustomizeLabels:{},
            FunctionalManager:{},
            DataIntegration:{},
            FileUpload:{},
            LookupValue:{},           
            Executables:{},
            FileViewer:{},
            ExecutableTracker:{}
    }
        
    }
    this.setState({Labels:[], isFetching: true});
    this.fetchLabelMessagesAPI();
    }

    componentDidMount() {

        // if(localStorage.getItem("ispwdexpired")!=undefined && localStorage.getItem("ispwdexpired")=="1" && window.location.pathname != '/changepwd'){
        //     window.location = '/changepwd';
        // }
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'User';
        });
        var rolerl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Role';
        });
        var adminrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Admin';
        });
        var dashboardrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Dashboard';
        });
        var fileProcessingrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'FileProcessing';
        });
        var processedFilesrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'ProcessedFiles';
        });
        var organizationrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Organization';
        });
        var servicesrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Services';
        });
        var masterLookuprl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'MasterLookup';
        });
        var approvalLimitsrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'ApprovalLimits';
        });
        var emailDistributionrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'EmailDistribution';
        });
        var searchrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Search';
        });
        var reportrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Report';
        });
        var schedulerrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Calendar';
        });
        var customizerl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'CustomizeLabels';
        });
        var funcmgrrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'FunctionalManager';
        });
        var pdirl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'DataIntegration';
        });
        var fileuploadrl =_.find(selectedrolelocation.location, function(obj) {
          return obj.location === 'FileUpload';
        });
        var lookupvaluerl =_.find(selectedrolelocation.location, function(obj) {
        return obj.location === 'LookupValue';
        });
        var executablesrl =_.find(selectedrolelocation.location, function(obj) {
        return obj.location === 'Executables';
        });         
        var fileviewerrl =_.find(selectedrolelocation.location, function(obj) {
          return obj.location === 'FileViewer';
          });
        var executabletrackerrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'ExecutableTracker';
            });

        
    
        this.setState(prevState => ({
            userrole: {
                ...prevState.userrole,  
                User:userrl, 
                Role:rolerl,
                Admin:adminrl,
                Dashboard:dashboardrl,
                FileProcessing:fileProcessingrl,
                ProcessedFiles:processedFilesrl,
                Organization:organizationrl,
                Services:servicesrl,
                MasterLookup:masterLookuprl,
                ApprovalLimits:approvalLimitsrl,
                EmailDistribution:emailDistributionrl,
                Search:searchrl,
                Report:reportrl,
                Calendar:schedulerrl,
                CustomizeLabels:customizerl,
                FunctionalManager:funcmgrrl,
                DataIntegration:pdirl,
                FileUpload:fileuploadrl,
                LookupValue:lookupvaluerl,
                Executables:executablesrl,                
                FileViewer:fileviewerrl,
                ExecutableTracker:executabletrackerrl
                
            }
        }));
      

    
   }

      handleBtnSubmit = (param) => {
        // change(state);
        //setState(initialValues);
        
        if (param.currentTarget.id == "user-btn")
            window.location = '/userlist';
        else if (param.currentTarget.id == "role-btn")
            window.location = '/rolelist';
            else if (param.currentTarget.id == "admin-btn")
            window.location = '/adminlist';
        else if (param.currentTarget.id == "processedfiles-btn")
            window.location = '/processedfileslist';
        else if (param.currentTarget.id == "org-btn")
            window.location = '/orglist';
        else if (param.currentTarget.id == "services-btn")
            window.location = '/serviceshome';
        else if (param.currentTarget.id == "masterlookup-btn")
            window.location = '/masterlookupitems';
        else if (param.currentTarget.id == "approvallimits-btn")
            window.location = '/approvallimitslist';
        else if (param.currentTarget.id == "emaildistributionlist-btn")
            window.location = '/emaildistributionlist';
        else if (param.currentTarget.id == "search-btn")
            window.location = '/search';
        else if (param.currentTarget.id == "scheduler-btn")
            window.location = '/scheduler';
        else if (param.currentTarget.id == "customize-btn")
            window.location = '/customize';
        // else if (param.currentTarget.id == "dashboard-btn")
        //     window.location = '/dashboard';
        else if (param.currentTarget.id == "report-btn")
            window.location = '/reports';
        else if (param.currentTarget.id == "fileupload-btn")
            window.location = '/fileupload';
        else if (param.currentTarget.id == "lookupvalue-btn")
            window.location = '/lookupvalueslist';
        else if (param.currentTarget.id == "executables-btn")
            window.location = '/executableshome';          
        else if (param.currentTarget.id == "fileviewer-btn")
            window.location = '/fileviewer';
        else if (param.currentTarget.id == "executabletrackerlist-btn")
            window.location = '/executabletrackerlist';

            
            

    };
     handleSubmit = () => {
        // change(state);
        //setState(initialValues);
        alert("Work in Progress..");
    };

     handleReportSubmit = () => {
        window.open(configData.BREVO_URL, "_blank");
    };
    
    handlePdiSubmit = () => {
        window.open(configData.PENTAHO_URL, "_blank");
    };

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
        // event.preventDefault();
    };

    setBindMessage=(result)=>{
        if((localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined") && window.location.pathname != '/login'){
            // this.props.history.push("/login");
            window.location = '/login';
         }
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
    }

    render(){
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
          
    return (
        <>       
        <div className='container-fluid fin-tile'>
            <div className='row col-md-12'>
                <div className='col-md-6 offset-md-1 ml-19'>
                  <div className='row'> 

                        <div className={this.state.userrole.User.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv"    id="user-btn"  onClick={this.handleBtnSubmit.bind('user')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/User.png'} aria-label='User'></img><br />                               
				              
                            </div>
                            <div className="down">
                                        {Labels.filter(lbl => lbl.keyPair=='Home_User' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                       

                        <div className={this.state.userrole.Role.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv"    id="role-btn"  onClick={this.handleBtnSubmit.bind('roles')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Roles.png'}  aria-label='Roles'></img><br />                               
				              
                            </div>
                            <div className="down">
                                    {Labels.filter(lbl => lbl.keyPair=='Home_Roles' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                             

                        <div className={this.state.userrole.Admin.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv"   id="admin-btn"  onClick={this.handleBtnSubmit.bind('admin')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Admin.png'}  aria-label='Master Data Management'></img><br />                               
				              
                            </div>
                            <div className="down">
                                        {Labels.filter(lbl => lbl.keyPair=='Home_Admin' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                  
                        
                        <div className={this.state.userrole.Dashboard.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv"   id="dashboard-btn"  onClick={this.handleBtnSubmit.bind('dashboard')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Dashboard.png'}  aria-label='Dashboard'></img><br />                               
				              
                            </div>
                            <div className="down">
                                    {Labels.filter(lbl => lbl.keyPair=='Home_Dashboard' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>

                        <div className={this.state.userrole.Report.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv"   id="fileprocess-btn"  onClick={this.handleReportSubmit} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/FileProcessing.png'}  aria-label='File Processing'></img><br />                               
				              
                            </div>
                            <div className="down">
                                        {Labels.filter(lbl => lbl.keyPair=='Home_FileProcessing' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>     

                        <div className={this.state.userrole.FunctionalManager.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv"  id="func-btn"  onClick={this.handleSubmit} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/FunctionalManager.png'}  aria-label='Functional Manager'></img><br />                               
				              
                            </div>
                            <div className="down">
                                        {Labels.filter(lbl => lbl.keyPair=='Home_FunctionalMgr' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                       

                        <div className={this.state.userrole.ProcessedFiles.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv"  id="processedfiles-btn"  onClick={this.handleBtnSubmit.bind('processedfileslist')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/FileProcessed.png'}  aria-label='Processed Files'></img><br />                               
				              
                            </div>
                            <div className="down">
                                         {Labels.filter(lbl => lbl.keyPair=='Home_ProcessedFiles' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                        

                        <div className={this.state.userrole.Organization.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv"  id="org-btn" onClick={this.handleBtnSubmit.bind('orglist')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/OrganizationTree.png'}  aria-label='Organization'></img><br />                               
				              
                            </div>
                            <div className="down">
                                     {Labels.filter(lbl => lbl.keyPair=='Home_Org' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                       

                        <div className={this.state.userrole.Services.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv"  id="services-btn" onClick={this.handleBtnSubmit.bind('services')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/services.png'}  aria-label='Services'></img><br />                               
				              
                            </div>
                            <div className="down">
                                    {Labels.filter(lbl => lbl.keyPair=='Home_Services' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>
                                                 
                        <div  className={this.state.userrole.MasterLookup.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv"  id="masterlookup-btn" onClick={this.handleBtnSubmit.bind('approvallimitslist')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Masterlookup.png'}  aria-label='Master Detail Lookup'></img><br />                               
				              
                            </div>
                            <div className="down">
                                        {Labels.filter(lbl => lbl.keyPair=='Home_MasterLookup' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>

                        <div  className={this.state.userrole.ApprovalLimits.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="approvallimits-btn" onClick={this.handleBtnSubmit.bind('approvallimitslist')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Approvallimits.png'}  aria-label='Approval Limits'></img><br />                               
				              
                            </div>
                            <div className="down">
                                        {Labels.filter(lbl => lbl.keyPair=='Home_ApprovalLimits' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                 
                       
                        <div  className={this.state.userrole.EmailDistribution.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="emaildistributionlist-btn" onClick={this.handleBtnSubmit.bind('emaildistributionlist')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Emaildistribution.png'}  aria-label='Email Distribution'></img><br />                               
				              
                            </div>
                            <div className="down">
                                    {Labels.filter(lbl => lbl.keyPair=='Home_EmailDistrbn' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>
                       
                        <div  className={this.state.userrole.Search.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="search-btn" onClick={this.handleBtnSubmit.bind('search')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Search.png'}  aria-label='Search'></img><br />                                
				              
                            </div>
                            <div className="down">
                                        {Labels.filter(lbl => lbl.keyPair=='Home_Search' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                             
                           
                        <div  className={this.state.userrole.Report.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="report-btn" onClick={this.handleBtnSubmit.bind('report')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Reports.png'}  aria-label='Reports'></img><br />                                
				              
                            </div>
                            <div className="down">
                            {Labels.filter(lbl => lbl.keyPair=='Home_Reports' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>               

                        <div  className={this.state.userrole.Calendar.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="scheduler-btn" onClick={this.handleBtnSubmit.bind('scheduler')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/OrgCalendar.png'}  aria-label='Calendar'></img><br />                                
				              
                            </div>
                            <div className="down">
                            {Labels.filter(lbl => lbl.keyPair=='Home_Calendar' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                     

                        <div  className={this.state.userrole.CustomizeLabels.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="customize-btn" onClick={this.handleBtnSubmit.bind('customize')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Customize.png'}  aria-label='Custamize Labels'></img><br />                                
				              
                            </div>
                            <div className="down">
                            {Labels.filter(lbl => lbl.keyPair=='Home_CustomizeLabels' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                        

                        <div className={this.state.userrole.DataIntegration.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="pdi-btn" onClick={this.handlePdiSubmit} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Dataintegration.png'}  aria-label='Data Integration'></img><br />                                
				              
                            </div>
                            <div className="down">
                                    {Labels.filter(lbl => lbl.keyPair=='Home_PDI' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>   

                        <div  className={this.state.userrole.LookupValue.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="lookupvalue-btn" onClick={this.handleBtnSubmit.bind('lookupvalue')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Lookupvalue.png'}  aria-label='LookupValue Labels'></img><br />                                
				              
                            </div>
                            <div className="down">
                            {Labels.filter(lbl => lbl.keyPair=='Home_LookupValue' && lbl.pageName=='Home').map(filteredPerson => (
                                   <span>
                                   {filteredPerson.keyValue}
                                   </span>
                                   ))}
                            </div>
                          </div>
                        </div>   

                        <div  className={this.state.userrole.FileUpload.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="fileupload-btn" onClick={this.handleBtnSubmit.bind('fileupload')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Fileupload.png'}  aria-label='Fileupload Labels'></img><br />                                
				              
                            </div>
                            <div className="down">
                            {Labels.filter(lbl => lbl.keyPair=='Home_Fileupload' && lbl.pageName=='Home').map(filteredPerson => (
                                   <span>
                                   {filteredPerson.keyValue}
                                   </span>
                                   ))}
                            </div>
                          </div>
                        </div> 

                        <div  className={this.state.userrole.FileViewer.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="fileviewer-btn" onClick={this.handleBtnSubmit.bind('fileviewer')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/FileViewer.png'}  aria-label='File Viewer'></img><br />                                
				              
                            </div>
                            <div className="down">
                            {Labels.filter(lbl => lbl.keyPair=='Home_FileViewer' && lbl.pageName=='Home').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                            </div>
                          </div>
                        </div>  

                        <div  className={this.state.userrole.Executables.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="executables-btn" onClick={this.handleBtnSubmit.bind('executableshome')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Executableprogram.png'}  aria-label='Executables Home'></img><br />                                
				              
                            </div>
                            <div className="down">
                            {Labels.filter(lbl => lbl.keyPair=='Home_ExecutableProgram' && lbl.pageName=='Home').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                            </div>
                          </div>
                        </div>    
                        
                        <div  className={this.state.userrole.ExecutableTracker.view=='Y'?'col m2 s6 pl0':'hidden'}>
                          <div className="wraperdiv" id="executabletrackerlist-btn" onClick={this.handleBtnSubmit.bind('executabletrackerlist')} >
                            <div className="up center">                               
                                    
                            <img src={'images/home/Executabletracker.png'}  aria-label='Executable Tracker Home'></img><br />                                
				              
                            </div>
                            <div className="down">
                            {Labels.filter(lbl => lbl.keyPair=='Home_ExecutableTracker' && lbl.pageName=='Home').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                            </div>
                          </div>
                        </div>
                       

                        <div className='col m3 s3 pl0'>
                          <div className="wraperdiv" id="future1-btn" onClick={this.handleSubmit}>
                            <div className="up center">                               
                                    
                            <img src={'images/home/Future1.png'}  aria-label='Future1'></img><br />                                 
				              
                            </div>
                            <div className="down">
                                    {Labels.filter(lbl => lbl.keyPair=='Home_Future1' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>                       

                         <div className='col m3 s3 pl0'>
                          <div className="wraperdiv" id="future2-btn" onClick={this.handleSubmit}>
                            <div className="up center">                               
                                    
                            <img src={'images/home/Future2.png'}  aria-label='Future2'></img><br />                                  
				              
                            </div>
                            <div className="down">
                                    {Labels.filter(lbl => lbl.keyPair=='Home_Future2' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>


                        <div className='col m3 s3 pl0'>
                          <div className="wraperdiv" id="future3-btn" onClick={this.handleSubmit}>
                            <div className="up center">                               
                                    
                                <img src={'images/home/Future3.png'}  aria-label='Future3'></img><br />                                   
				              
                            </div>
                            <div className="down">
                                {Labels.filter(lbl => lbl.keyPair=='Home_Future3' && lbl.pageName=='Home').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </div>
                          </div>
                        </div>

                  







                    </div> 
                </div>
                <div className='col-md-5 mt-125'>
                    <img className="float-left prodimg" src={'images/home/FinAppLogo.png'} aria-label='Fin Apps Logo'></img>
                </div>

            </div>            
        </div >
          
                             
        </>
    );
    }
 
}
export default Home;
