import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from 'react-select';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker, DatePickerInput } from "rc-datepicker";
import * as moment from "moment";
import "moment/min/moment-with-locales";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";


//const configData= require('config');
const configData = require( '../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: ''   
}

export default class ServicesSubActivityList extends React.Component  {
    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
            serviceTaskName:'',
            serviceTaskDesc:'',
            serviceTaskCode:'',
            predecessor:'',
            serviceTaskType:'',
            level:'',
            status:'',
            start_date:'',
            end_date:'',
            _id:undefined,
            servicesubactivityfiles: [],
            columns:[],
            originalservicesubactivityfiles:[],
            searchInput: "",
            filteredData: [],
            // serviceAreaList:[],
            serviceActivityList:[],
            chkisactive:true,           
            userrole:{create:''}
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

    }
     componentDidMount() {
     
        this.fetchServiceTaskListAPI();
        this.getColumns();
        //load service activity records 
        this.fetchServiceActivityListAPI();
    }   
    
    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Services';
        });
        let columns = [
          {
            Header: "Task Name",
            accessor: "serviceTaskName",
            sortable: true,
            show: true,
            displayValue: " Service Task Name" 
          },
          {
            Header: "Predecessor",
            accessor: "predecessor",
            sortable: true,
            show: true,
            displayValue: "predecessor",
            Cell:row=>(<span>{row.original.predecessor}</span>)
          },
          {
            Header: "Task Description",
            accessor: "serviceTaskDesc",
            sortable: true,
            show: true,
            displayValue: "Service Task Desc",
            Cell:row=>(<span>{row.original.serviceTaskDesc.length > 30 ?
                `${row.original.serviceTaskDesc.substring(0, 30)}...` : row.original.serviceTaskDesc} </span>)
          },
          {
            Header: "Task Code",
            accessor: "serviceTaskCode",
            sortable: true,
            show: true,
            displayValue: "service Task Code "
          },
          {
            Header: "Task Type",
            accessor: "serviceTaskType",
            sortable: true,
            show: true,
            displayValue: "Service Task Type"
          },
          {
            Header: "Level",
            accessor: "level",
            sortable: true,
            show: true,
            displayValue: "level ",
            maxWidth: 100,
            minWidth: 50,
            width: 50,
            Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>
          },
          {
            Header: "Start Date",
            accessor: "start_date",
            sortable: true,
            show: true,
            displayValue: "start_date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          
          {
            Header: "End Date",
            accessor: "end_date",
            sortable: true,
            show: true,
            displayValue: "end_date",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "status",
            maxWidth: 100,
            minWidth: 56,
            width: 56,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };

    fetchServiceTaskListAPI = () => {
       // this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getServiceTaskList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setServiceTaskList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setServiceTaskList=(result)=>{
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'Services';
        });
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            this.setState({servicesubactivityfiles: result,  originalservicesubactivityfiles:result,userrole: userrl, isFetching: false});
            this.globalSearch();
        }
        else
         this.setState({...this.state,userrole: userrl, isFetching: false})
        

    }

    handleChange = e => {
        let { value, name } = e.target;         
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date
        });
    };

    handleSelectChangeActivity = (selectedOption) => {
        this.setState(prevState => ({
                   ...prevState,  
                   predecessor: selectedOption
             
        }));
        
      };
    
    handleAddMember=()=>{
        this.state.serviceTaskName = '';
        this.state.serviceTaskDesc ='';
        this.state.start_date = '';
        this.state.end_date = '';
        this.state.serviceTaskCode = '';
        this.state.predecessor = '';
        this.state.serviceTaskType = '';
        this.state.level = '';
        this.state.status = '';

        this.state._id = undefined;
        this.setState({show: true, editshow:false });
    }
    handleClose=()=> {
        this.state.serviceTaskName = '';
        this.state.serviceTaskDesc ='';
        this.state.start_date = '';
        this.state.end_date = '';
        this.state.serviceTaskCode = '';
        this.state.predecessor = '';
        this.state.serviceTaskType = '';
        this.state.level = '';
        this.state.status = '';

        this.state._id = undefined;
        this.setState({ show: false });
    }
    
    handleClick = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

    handleEditClick = (row,event) => {
        this.state.serviceTaskName = row.original.serviceTaskName;
        this.state.serviceTaskDesc = row.original.serviceTaskDesc;
        this.state.start_date = row.original.start_date;
        this.state.end_date = row.original.end_date;
        this.state.serviceTaskCode = row.original.serviceTaskCode;
        
        var predecessorval=undefined;
        
           _.forEach(this.state.serviceActivityList, function(value) {
             
            if(row.original.predecessor==value.serviceActivityName){
               predecessorval=value;
            }
        });
        this.state.predecessor ={'label':predecessorval.serviceActivityName,'value':predecessorval._id};

       // this.state.predecessor = row.original.predecessor;
        this.state.serviceTaskType = row.original.serviceTaskType;
        this.state.level = row.original.level;
        this.state.status = row.original.status;

        this.state._id = row.original._id;
        this.setState({ show: true, editshow: false });
       // this.props.history.push("/Item?id=" + row.id);
    };

    editImgFormatter = (cell, row) => {              
        return (
            <a
                className="editbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a>
        );
    }

     deleteImgFormatter = (cell, row) => {              
        return (
            <a
                className="deletebutton"
                aria-label="Delete"
                onClick={() => this.handleDeleteClick(row)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a>
        );
    }

    handleDeleteClick = (row,event) => {
        
        if(window.confirm('Are you sure to delete this record?')){ 
        //delete
        if (row.original._id!= undefined) {
            axios
                .post(configData.SERVER_URL + `fincorp_deleteServiceTask`,

                    { '_id': row.original._id }
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                .then(
                    res => this.reloadDataGrid(res.data)
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
        }
        else {
            //error            
            toast.warn("Service Task Not Deleted");
        }
    }
    }
    reloadDataGrid = (res) => {

        toast.success("Service Task  Deleted Successfully");
        setTimeout(this.fetchServiceTaskListAPI(), 4000);
    }



    onStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                start_date: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                end_date: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };

    handleSaveClick = (event) => {

        const regServiceCode= /^[a-zA-Z0-9.-]*$/;        

        if (this.state.serviceTaskName == undefined || this.state.serviceTaskName == "" || this.state.serviceTaskName.length == 0) {
           toast.warn("Enter service Task Name");
           event.preventDefault();
       }       
       else if (this.state.serviceTaskDesc == undefined || this.state.serviceTaskDesc == "") {
           toast.warn("Enter Service Task Desc");
           event.preventDefault();
       }
       else if (this.state.serviceTaskCode == undefined || this.state.serviceTaskCode == "") {
        toast.warn("Enter Service Code");
        event.preventDefault();
        }
       else if (regServiceCode.test(this.state.serviceTaskCode) === false){
        toast.warn("Service Task Code Must Contain Only Alphanumeric");
        event.preventDefault();        
        }
        else if (this.state.serviceTaskType == undefined || this.state.serviceTaskType == "") {
        toast.warn("Choose Service Task Type");
        event.preventDefault();
        }
        else if (this.state.level == undefined || this.state.level == "") {
        toast.warn("Enter Service Task Level");
        event.preventDefault();
        }
        else if (this.state.predecessor == undefined || this.state.predecessor == "") {
        toast.warn("Enter Service Task Predecessor");
        event.preventDefault();
        }
       else if (this.state.start_date == undefined || this.state.start_date == "" || this.state.start_date == "Invalid date") {
           toast.warn("Enter StartDate");
           event.preventDefault();


       }  
       else if (Date.parse(this.state.start_date) > Date.parse(this.state.end_date)) {
           toast.warn("End Date should be greater than the StartDate");
           event.preventDefault();


       }     
       else if (this.state.status == undefined || this.state.status == "") {
           toast.warn("Choose Status");
           event.preventDefault();


       }
       else {

           if (this.state._id!= undefined) {
            if(window.confirm('Do you want to save changes?')){ 
            this.state.start_date=  this.state.start_date == "Invalid date"?null:this.state.start_date;
            this.state.end_date=  this.state.end_date == "Invalid date"?null:this.state.end_date;

               axios
                   .post(configData.SERVER_URL + `fincorp_updateServiceTask`,

                       { '_id': this.state._id, 'serviceTaskName': this.state.serviceTaskName, 
                       'serviceTaskDesc': this.state.serviceTaskDesc,
                        'serviceTaskCode': this.state.serviceTaskCode, 
                        'predecessor': this.state.predecessor.label,
                        'serviceTaskType':this.state.serviceTaskType,
                        'level':this.state.level,
                         'start_date': this.state.start_date, 
                         'end_date': this.state.end_date,
                          'status': this.state.status }
                       , {
                           headers: {
                               "Content-Type": "application/json;charset=UTF-8",
                               "Access-Control-Allow-Origin": "*"
                           }
                       })
                   .then(
                       res =>  this.UpdateChanges(res.data) 


                   )
                   .catch(err => {
                       console.log("AXIOS ERROR: ", err);
                   });
                }

           }
           else {
               
               axios
                   .post(configData.SERVER_URL + `fincorp_insertServiceTask`,

                       { 'serviceTaskName': this.state.serviceTaskName, 
                       'serviceTaskDesc': this.state.serviceTaskDesc,
                       'serviceTaskCode': this.state.serviceTaskCode, 
                       'predecessor': this.state.predecessor.label,
                       'serviceTaskType':this.state.serviceTaskType,
                       'level':this.state.level,
                       'start_date': this.state.start_date, 
                       'end_date': this.state.end_date,
                       'status': this.state.status}
                       , {
                           headers: {
                               "Content-Type": "application/json;charset=UTF-8",
                               "Access-Control-Allow-Origin": "*"
                           }
                       })
                   .then(
                       res => this.SaveChanges(res.data) 
                   )
                   .catch(err => {
                       console.log("AXIOS ERROR: ", err);
                   });

           }

       }
   };
   SaveChanges = (res) => {
    if(res.MessageKey!=undefined){
        toast.warn(res.Message);
    }
    else{
        toast.success("Service Task  Inserted Successfully");
        setTimeout( this.fetchServiceTaskListAPI(),4000);
        this.setState({ show: false, editshow: false });
        }
   }
   UpdateChanges = (res) => {
    if(res.MessageKey!=undefined){
        toast.warn(res.Message);
    }
    else{
       toast.success("Service Task  Updated Successfully");
       setTimeout( this.fetchServiceTaskListAPI(),4000);
       this.state.serviceTaskName = '';
       this.state.serviceTaskDesc ='';
       this.state.start_date = '';
       this.state.end_date = '';
       this.state.serviceTaskCode = '';
       this.state.predecessor = '';
       this.state.serviceTaskType = '';
       this.state.level = '';
       this.state.status = '';

       this.state._id = undefined;

       this.setState({ show: false, editshow: false });
        }
  }    
    
    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }       

    }

    handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };

      toggleChange = () => {
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }

      globalActiveSearch = (param) => {
        let { searchInput } = this.state;       
        let filteredData = this.state.originalservicesubactivityfiles.filter(value => {
            if(param){

                return (
                    (value.serviceTaskName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceTaskDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceTaskCode.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceTaskType.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.level.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.predecessor.toLowerCase().includes(searchInput.toLowerCase())||
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                    value.status.toString().includes('Active')  
                   
                );

            }else{
                return (
                    value.serviceTaskName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceTaskDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceTaskCode.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceTaskType.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.level.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.predecessor.toLowerCase().includes(searchInput.toLowerCase())||
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                    value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        });
        this.setState({ servicesubactivityfiles: filteredData });
      };


      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalservicesubactivityfiles.filter(value => {
            if(this.state.chkisactive){   
          return (
            (value.serviceTaskName.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.serviceTaskDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.serviceTaskCode.toString().toLowerCase().includes(searchInput.toLowerCase())||
            value.serviceTaskType.toString().toLowerCase().includes(searchInput.toLowerCase())||
            value.level.toString().toLowerCase().includes(searchInput.toLowerCase())||
            value.predecessor.toLowerCase().includes(searchInput.toLowerCase())||
            value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
            ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
            value.status.toString().includes('Active')
            
          );
            }else{
                return (
                    value.serviceTaskName.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceTaskDesc.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.serviceTaskCode.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.serviceTaskType.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.level.toString().toLowerCase().includes(searchInput.toLowerCase())||
                    value.predecessor.toLowerCase().includes(searchInput.toLowerCase())||
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                    value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );  
            }
        });
        this.setState({ servicesubactivityfiles: filteredData });
      };

      fetchServiceActivityListAPI = () => {
       // this.setState({...this.state, isFetching: true});
        if(localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined"){
            this.props.history.push("/login");
        }
        else{
        var cookie_value= JSON.parse(localStorage.getItem("session")).key;
        var userId= JSON.parse(localStorage.getItem("session")).Result[0]._id;
        axios
        .get(configData.SERVER_URL+`fincorp_getServiceActivityList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Authorization": `Bearer ${cookie_value}||${userId} `
            }
        })
        .then(
            res=>  {this.setServiceActivityList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    };

    setServiceActivityList=(result)=>{
        
        if(result.Message=='Invalid Session'){
            this.props.history.push("/login")
        }
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            this.setState({serviceActivityList: result, isFetching: false});
        }
        

    }

    handleChange = e => {
        let { value, name } = e.target;       
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date
        });
    };

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let { data, columns, searchInput } = this.state; 
        const optionsActivityArray =this.state.serviceActivityList!=null&& this.state.serviceActivityList!=undefined? this.state.serviceActivityList:[]; 
    return (
        <>
            <div className="container userinfo-container" role="main">
                {/* <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/serviceshome">Services Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Services Tasks List
                    </Breadcrumb.Item>
                </Breadcrumb> */}
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskList_Title' && lbl.pageName=='ServiceTaskList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </span>
                </div>
                <div className="fullwidth">
                    <div className="float-left">
                    <button className={this.state.userrole.create=='Y'?"btn btn-primary  float-left ":"hidden"}  onClick={this.handleAddMember} > 
                    {Labels.filter(lbl => lbl.keyPair=='ServiceTaskList_Addbtn' && lbl.pageName=='ServiceTaskList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </button>
                    </div> 
                    <div className="float-right">
                        <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" aria-label="Status Filter" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='ServiceTaskList_Search' && lbl.pageName=='ServiceTaskList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                        ))}
                        </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />    
                        </div>                    
                    </div>
                    </div>                   

                
                <div className="fullwidth row m0">
                 <div className="col-md-12 mt-10 p0">
                     <ReactTable
                    data={this.state.servicesubactivityfiles}
                    columns={this.state.columns}
                    defaultPageSize={20}
                    className="-striped -highlight fullwidth"
                    />  
                 </div>      
               </div>                
            <div>
                <Modal
                        className="ServicesTask-modal"
                         show={this.state.show}
                         onHide={this.handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                            {Labels.filter(lbl => lbl.keyPair=='ServiceTask_Title' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                <span>
                                 {this.state._id==undefined?"Add":"Edit"} {filteredPerson.keyValue}
                                </span>
                            ))}                            
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Form>                   
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmsubactivityename">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTask_Name' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmsubactivityename"
                                        type="text"
                                        name="serviceTaskName"
                                        placeholder="Tasks Name"
                                        disabled={this.state._id!=undefined?true:false}
                                        value={this.state.serviceTaskName}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="frmdescr">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTask_Desc' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))} 
                                </Form.Label>{" "}
                                </Col>
                                <Col md={8}>   
                                <Form.Control
                                            id="frmdescr"
                                            className="desc-width"
                                            as="textarea"
                                            rows="1"
                                            name="serviceTaskDesc"
                                            placeholder="Description"
                                            value={this.state.serviceTaskDesc}
                                            onChange={this.handleChange}
                                            className="required"
                                            
                                        />
                                </Col>
                            </Col>


                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">                           
                        <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmsubactivitycode">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTask_Code' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmsubactivitycode"
                                        type="text"
                                        className="form-control"
                                        controlId="subactivitycode"
                                        name="serviceTaskCode"
                                        placeholder="Tasks Code"
                                        onChange={this.handleChange}
                                        disabled={this.state._id!=undefined?true:false}
                                        value={this.state.serviceTaskCode}
                                        required
                                    >
                                    </Form.Control>
                                </Col>
                            </Col>
                            <Col md={6} className="row" >
                                <Col md={4}>
                                    <Form.Label className="required" for="frmsubactivitytype">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTask_Type' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmsubactivitytype"
                                        as="select"
                                        name="serviceTaskType"                                        
                                        value={this.state.serviceTaskType}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">Select Type</option>    
                                        <option value="Primary">Primary</option>
                                        <option value="Secondary">Secondary</option>
                                    </Form.Control>
                                </Col>
                            </Col>  
                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">   
                        <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmsubactivitylevel">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTask_Level' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmsubactivitylevel"
                                        type="number"
                                        name="level"
                                        placeholder="Tasks Level"                                       
                                        value={this.state.level}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Col>                           
                        <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for="frmsubactivitypredecessor">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTask_Predecessor' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}  
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    {/* <Form.Control
                                        id="frmsubactivitypredecessor"
                                        type="text"
                                        className="form-control"
                                        controlId="subactivitypredecessor"
                                        name="predecessor"
                                        onChange={this.handleChange}
                                        placeholder="Predecessor"
                                        value={this.state.predecessor}
                                        required
                                    >
                                    </Form.Control> */}
                                    <Select
                                    name="serviceActivity"
                                    value={this.state.predecessor}
                                    onChange={this.handleSelectChangeActivity}
                                     
                                    options={optionsActivityArray.map(opt => ({ label: opt.serviceActivityName, value: opt._id }))}
                                    />
                                </Col>
                            </Col>                             
                        </Row>
                    </Form.Row>      
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTask_StartDate' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                <DatePickerInput
                                            id="startdate"
                                            name="start_date"
                                            onChange={this.onStartDateChange.bind(this, 'start_date') }
                                             placeholder="MM/DD/YYYY"
                                             value={this.state.start_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            aria-label="startdate"
                                            readOnly
                                        />                                       
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label>
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTask_EndDate' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>                                   
                                <DatePickerInput
                                            id="enddate"
                                            name="end_date"
                                             onChange={this.onEndDateChange.bind(this, 'end_date') }
                                            placeholder="MM/DD/YYYY"
                                             value={this.state.end_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            aria-label="enddate"
                                            readOnly
                                        />
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row>    
                                  


                    <Form.Row>
                     <Row className="margin-t10 col-md-12">        
                     <Col md={6} className="row" >
                                <Col md={4}>
                                    <Form.Label className="required" for="servicetaskdetailstatus">
                                    {Labels.filter(lbl => lbl.keyPair=='ServiceTask_Status' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="servicetaskdetailstatus"
                                        as="select"
                                        name="status"
                                        value={this.state.status}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control>
                                </Col>
                            </Col>  
                     </Row>
                    </Form.Row>     
                </Form>
          
                        </Modal.Body>
                            <Modal.Footer>
                               
                                <Button variant="primary" onClick={this.handleSaveClick}>
                                {Labels.filter(lbl => lbl.keyPair=='ServiceTask_SaveBtn' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                ))}
                                </Button>
                                 <Button variant="secondary" onClick={this.handleClose} >
                                 {Labels.filter(lbl => lbl.keyPair=='ServiceTask_CancelBtn' && lbl.pageName=='ServiceTask').map(filteredPerson => (
                                        <span>
                                            {filteredPerson.keyValue}
                                        </span>
                                ))} 
                                 </Button>
                            </Modal.Footer>
                    </Modal>                        
                </div>

        </div>

        </>
    );
}
}

