import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import { Link } from "react-router-dom";
import axios from "axios";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Select from 'react-select';
import Icon from '@material-ui/core/Icon';
import * as moment from "moment";
import { browserHistory } from "react-router";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";
import "moment/min/moment-with-locales";
import _ from "lodash";


 
//const configData= require('config');
const configData = require( '../../config.json');

// const configData1 = require( '../../FinApiControllers/appconfig.json');

export default class LookupValue extends React.Component  {

    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
            selectedOption: null, 
            looktypes:[],
            lookupvalues:[],          
            lookup:{
                lookup_name:'',
                lookup_type : "",
                lookup_value : "",
                object_type: "",   
                startdate : "",
                enddate : "",
                status : "",
                comments : ""
                }    
          
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();
       
    }
     componentDidMount() {

        this.fetchLookupType();
        // this.fetchLookupValue();
           
        var querystring = queryString.parse(this.props.location.search);        
        if(querystring.id!=undefined){
            this.fetchLookupValueWithFetchAPI();
        }

       
        
      
    }   
    
  
    fetchLookupValueWithFetchAPI = () => {
          
        var querystring = queryString.parse(this.props.location.search);        
        this.setState({...this.state, isFetching: true});

        axios.post(configData.SERVER_URL+`fincorp_getLookupValueBasedOnId/`,
    
        {'_id': querystring.id}
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindApprovalBasedId(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };
 
    setBindApprovalBasedId=data=>{  
               
        if(data.length>0)
        
            this.setState({lookup:data[0], isFetching: false})
            this.fetchLookupValue(data[0].lookup_type);
            this.globalSearch();
            
    }

    fetchLookupType = () => {        
        this.setState({...this.state, isFetching: true});
        fetch(configData.SERVER_URL+'fincorp_getLookupTypeList')
            .then(response => response.json())
            .then(result => {
                 this.bindLookupType(result)
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };
    bindLookupType=(result)=>{
     
        if(result!='No document(s) found with defined find criteria!' && result.length>0)
        {
            
             this.setState({...this.state,looktypes: result, isFetching: false})
        }
        else
        {
             
            this.setState({lookuptype: [], isFetching: false})
        }
         
    }

    fetchLookupValue = (typevalue) => {  
              
        //this.setState({...this.state, isFetching: true});
        
         axios.post(configData.SERVER_URL+`fincorp_getLookupValueListBasedOnLookupType/`,
    
        {'lookup_type': typevalue.label}
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
            
            .then(result => {
                 this.bindLookupValue(result)
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };
    bindLookupValue=(result)=>{
        
        if(result.data!='No document(s) found with defined find criteria!' && result.data.length>0)
        {
            
             this.setState({...this.state,lookupvalues: result.data, isFetching: false})
        }
        else
        {
             
            this.setState({...this.state,lookupvalues:[], isFetching: false})
        }
         
    }
 
 
    handleChange = e => {
        let { value, name } = e.target;            
        var approve = this.state.lookup;

        if(e.target.type=='checkbox'){
            approve[e.target.name] = e.target.checked;
        }                   
        else
        {
            approve[e.target.name] = e.target.value;
        }
        this.setState( { lookup: approve } );
        
    };
   
    onStartDateChange = (jsDate, dateString) => {
        
        this.setState(prevState => ({        
            lookup: {
               
                // object that we want to update
                ...prevState.lookup, // keep all other key-value pairs
                
                startdate: moment(dateString).format("MM-DD-YYYY")
                
            }
            
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            lookup: {
                // object that we want to update
                ...prevState.lookup, // keep all other key-value pairs
                
                enddate: moment(dateString).format("MM-DD-YYYY")
                
            }
        }));
    };


   
    handleSaveClick = (event) => {
         
        //  const regExDate = /^[0-9\/]+$/;
        var userorgid= localStorage.getItem("userorgid");
        if(this.state.lookup.lookup_name ==undefined || this.state.lookup.lookup_name.length==0){
            toast.warn("Enter Lookup Name");
            event.preventDefault();        
        }
        else if(this.state.lookup.lookup_type ==undefined || this.state.lookup.lookup_type =="" || this.state.lookup.lookup_type=='Enter Lookup Type'){
            toast.warn("Enter Lookup Type");
            event.preventDefault();            
        }       
        else if (this.state.lookup.lookup_value == undefined || this.state.lookup.lookup_value == ""  ) {
            toast.warn("Enter Lookup Value");
            event.preventDefault();               
        }
        else if (this.state.lookup.object_type == undefined || this.state.lookup.object_type == ""  ) {
            toast.warn("Enter Object Type");
            event.preventDefault();               
        }
        else if (this.state.lookup.startdate == undefined || this.state.lookup.startdate == "" || this.state.lookup.startdate == "Invalid date" ) {
            toast.warn("Enter Start Date");
             event.preventDefault();               
        }  
        else if (Date.parse(this.state.lookup.startdate) > Date.parse(this.state.lookup.enddate)) {
            toast.warn("End Date should be greater than the StartDate");
            event.preventDefault();
        }   
        else if(this.state.lookup.status == undefined || this.state.lookup.status ==""){
            toast.warn("Select Status");
            event.preventDefault();            
        }     
        else if (this.state.lookup.comments == undefined || this.state.lookup.comments == ""  ) {
            toast.warn("Enter Comments");
            event.preventDefault();               
        }  
       
        
        else {
            
          this.state.lookup.orgid=userorgid;    
            //update existing LookupValue 
        if(this.state.lookup._id!=undefined){
              
            if(window.confirm('Do you want to save changes?')){   
                this.state.lookup.startdate=  this.state.lookup.startdate == "Invalid date"?null:this.state.lookup.startdate;
                this.state.lookup.enddate=  this.state.lookup.enddate == "Invalid date"?null:this.state.lookup.enddate;
            axios
            .post(configData.SERVER_URL+`fincorp_updateLookupValue`,
             
            this.state.lookup
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res => this.UpdateChange(res.data,event)
                
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
            event.preventDefault();
            }
        }
        else{            
            //insert new LookupValue
            axios
            .post(configData.SERVER_URL+`fincorp_insertLookupValue`,
            
            this.state.lookup
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res => this.SaveChange(res.data,event)
                
                
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
            event.preventDefault();
        }
        
    }
    };

    SaveChange=(result,event)=>{  
		// if (result.MessageKey!= undefined && (result.MessageKey==-1))
        //   {
        //     toast.warning("Lookup Name Alredy Exist");
        //   }
        // else {                           
             
        this.state.lookup._id=result;
        console.log(result);
        toast.success("Lookup Inserted Successfully");
        setTimeout(()=>this.props.history.push("/lookupvalueslist"), 4000);
        // } 
    }
    UpdateChange=(result,event)=>{
             
        this.state.lookup._id=result;
        console.log(result);
        toast.success("Lookup Updated Successfully");
        setTimeout(()=>this.props.history.push("/lookupvalueslist"), 4000);
        
    }
    handleCancelClick = () => {
        this.props.history.push("/lookupvalueslist");
    }
    
    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    
    handleSelectChange = selectedOption => {
         
        this.setState(prevState => ({
            lookup: {
                ...prevState.lookup,  
                role_name: selectedOption
            }
        }));
        
      };
      handleTypeIconClicks = (e) => {
        console.log(e.target.name);
         this.setState( {lookuptypeshow:true});
    }
    handleValueIconClicks = (e) => {
        console.log(e.target.name);
        this.setState( {lookupvalueshow:true});
    }
    handleCloseTypeClick = (e) => {
     
        this.setState( {lookuptypeshow:false});
    }

     handleCloseValueClick = (e) => {
        
        this.setState( {lookupvalueshow:false});
    }
    handleLookupTypeSelectChange=selectedOption=>{
        
        this.setState(prevState => ({
            lookup: {
                ...prevState.lookup,  
                lookup_type: selectedOption,
                lookup_value:''
            }
        }));
        
        this.fetchLookupValue(selectedOption);
    }
    handleLookupValueSelectChange=selectedOption=>{
        this.setState(prevState => ({
            lookup: {
                ...prevState.lookup,  
                lookup_value: selectedOption
            }
        }));
         
    }
    handleAddLookupTypeClick=(e)=>{
 
        if(this.state.lookup.lookuptypename ==undefined || this.state.lookup.lookuptypename.length==0){
            toast.warn("Enter Lookup Type Name");
            e.preventDefault();        
        }
         
        
        else {
            axios
            .post(configData.SERVER_URL+`fincorp_insertLookupTypeList`,
             
            {'lookup_type':this.state.lookup.lookuptypename}
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res => this.SaveTypeChange(res.data,e)
                
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
        
    }
    };

    SaveTypeChange=(result,event)=>{
             
        
        if(result.MessageKey=="-1"){
            toast.warn(result.Message);
        }else{
        console.log(result);
        this.fetchLookupType();
        toast.success("Lookup Type Added Successfully");
        this.setState( {lookuptypeshow:false});
        }
        //setTimeout(()=>this.props.history.push("/lookupvalueslist"), 4000);
        
    }
    handleAddLookupValueClick=(e)=>{
 
        if(this.state.lookup.lookup_type.label ==undefined || this.state.lookup.lookup_type.label.length==0){
            toast.warn("Enter Lookup Type Name");
            e.preventDefault();        
        }
        else if(this.state.lookup.lookup_value.label ==undefined || this.state.lookup.lookup_value.label.length==0){
            toast.warn("Enter Lookup Value");
            e.preventDefault();        
        }
        
        else {
            axios
            .post(configData.SERVER_URL+`fincorp_insertLookupValueList`,
             
            {'lookup_type':this.state.lookup.lookup_type.label,'lookup_value':this.state.lookup.lookupvaluename}
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res => this.SaveValueChange(res.data,e)
                
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
        
    }
    };

    SaveValueChange=(result,event)=>{
             
        
        if(result.MessageKey=="-1"){
            toast.warn(result.Message);
        }else{
        console.log(result);
        this.fetchLookupValue(this.state.lookup.lookup_type);
        toast.success("Lookup Value Added Successfully");
        this.setState( {lookupvalueshow:false}); 
        }
        //setTimeout(()=>this.props.history.push("/lookupvalueslist"), 4000);
        
    }
     
    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[]; 
          const optionsArray =this.state.looktypes!=null&& this.state.looktypes!=undefined? this.state.looktypes:[];  
          const optionValueArray=this.state.lookupvalues !=null&& this.state.lookupvalues!=undefined? this.state.lookupvalues:[]; 
        const savetype= this.state.lookup._id!=undefined?"Edit":"Add";
    return (
        <>
            <div className="container lookuplimits-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/lookupvalueslist">
                        {Labels.filter(lbl => lbl.keyPair=='LookupValueList_Title' && lbl.pageName=='LookupValueList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='LookupValue_Title' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}  
                   
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Form  >
                    <div className="centeralign">
                    {/* {configData1.folderupload_path} */}
                        <span className="mainheadings">
                        {Labels.filter(lbl => lbl.keyPair=='LookupValue_Title' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                        <span>
                       {savetype} {filteredPerson.keyValue}
                        </span>
                        ))}                      
                        </span>
                    </div>
                    <Form.Row>
                        <Row className="mt-20 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="frmlookupname">
                                    {Labels.filter(lbl => lbl.keyPair=='LookupValue_LookupName' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                
                                <Col>
                                <Form.Control
                                        id="frmlookupname"
                                        type="text"
                                        name="lookup_name"
                                        placeholder="Lookup Name"
                                        value={this.state.lookup.lookup_name}
                                        // value={this.state.lookup.lookup_name}
                                        onChange={this.handleChange}
                                        required
                                    />

                                        </Col>
                            </Col>
                            <Col md={6} className="row">

                            <Col md={4}>
                                    <Form.Label className="required" for="frmobjecttype">
                                    {Labels.filter(lbl => lbl.keyPair=='LookupValue_ObjectType' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmobjecttype"
                                        type="text"
                                        name="object_type"
                                        placeholder="Object Type"
                                        value={this.state.lookup.object_type}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col> 
                               
                            </Col>                            
                        </Row>
                    </Form.Row>
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">                           
                        

                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" id="frmlookuptypename" style ={{color :"#800000"}}>
                                    {Labels.filter(lbl => lbl.keyPair=='LookupValue_LookupType' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={6}>  
                                 
                                   <Select
                                        value={this.state.lookup.lookup_type}
                                        onChange={this.handleLookupTypeSelectChange}
                                        aria-labelledby="frmlookuptypename"
                                        
                                        options={optionsArray.map(opt => ({ label: opt.lookup_type, value: opt._id }))}
                                       
                                        />
                               
                                </Col>
                                <Col md={1}>
                                <Icon style={{fontSize:36, color:'#023047'}} title="Add Lookup Type" onClick={(e) => this.handleTypeIconClicks(e)}>add_circle</Icon>
                                </Col>                

                              
                            </Col> 
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmlookupvalue" style ={{color :"#D03D56"}}>
                                    {Labels.filter(lbl => lbl.keyPair=='LookupValue_LookupValues' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))} 
                                    </Form.Label>
                                </Col>
                                <Col md={6}>
                                   
                                    <Select
                                        value={this.state.lookup.lookup_value}
                                        onChange={this.handleLookupValueSelectChange}
                                    //    id="frmlookupvalue"
                                        aria-label="LookUp Value"
                                        
                                        options={optionValueArray.map(opt => ({ label: opt.lookup_value, value: opt._id }))}
                                        
                                        />
                                    
                                </Col>  
                                <Col md={1}>
                                <Icon style={{fontSize:36, color:'#023047'}} title="Add Lookup Value" onClick={(e) => this.handleValueIconClicks(e)}>add_circle</Icon>
                                </Col> 
                            </Col>     
                        </Row>
                    </Form.Row> 
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for='startdate'>
                                    {Labels.filter(lbl => lbl.keyPair=='LookupValue_StartDate' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                <DatePickerInput
                                            id="startdate"
                                            name="startdate"
                                            onChange={this.onStartDateChange.bind(this, 'startdate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.lookup.startdate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />                                       
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for='enddate'>
                                    {Labels.filter(lbl => lbl.keyPair=='LookupValue_EndDate' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <DatePickerInput
                                            id="enddate"
                                            name="enddate"
                                            //onChange={this.onEndDateChange}
                                            onChange={this.onEndDateChange.bind(this, 'enddate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.lookup.enddate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />
                                </Col>
                            </Col>
                        </Row>
                    </Form.Row> 
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">                           
                          <Col md={6} className="row">
                                <Col md={4}>
                                <Form.Label className="required" for="lookupvaluestatus">
                                    {Labels.filter(lbl => lbl.keyPair=='LookupValue_Status' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>{" "}                                   
                                </Col>
                                <Col md={8}>
                                <Form.Control
                                        id="lookupvaluestatus"
                                        as="select"
                                        name="status"
                                        value={this.state.lookup.status}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control>                                    
                                </Col>
                            </Col>   
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for="frmlookupcomments">
                                    {Labels.filter(lbl => lbl.keyPair=='LookupValue_Comments' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="frmlookupcomments"
                                        type="text"
                                        className="form-control"
                                        controlId="reportingto"
                                        name="comments"
                                        Placeholder="Comments"
                                        onChange={this.handleChange}
                                        value={this.state.lookup.comments }                                        
                                    >
                                    </Form.Control>
                                </Col>

                               
                            </Col>    
                        </Row>
                    </Form.Row>
                     
                    <div className="float-right mt-10 mr-9p"> 
                    <Button type="submit" variant="primary" onClick={this.handleSaveClick} >               
                                    {Labels.filter(lbl => lbl.keyPair=='LookupValueBtn_Save' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}                          
                    </Button>                  
                    <Button  variant="secondary" className="ml-10" onClick={this.handleCancelClick}>
                                    {Labels.filter(lbl => lbl.keyPair=='LookupValueBtn_Cancel' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                    </Button>                    
                    </div>  
                   

                </Form>


                <div>
                    <Modal
                        className="addeditlookuptype-modal"
                         show={this.state.lookuptypeshow}
                          onHide={this.handleCloseTypeClick}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                                <h3>
                                Add {Labels.filter(lbl => lbl.keyPair=='LookupValue_LookupType' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}                              
                                </h3>                               
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                 
                                    <Form.Row>
                                        <Col md-6>
                                        <Form.Group as={Col} controlId="formGrpLookupType">
                                        <Form.Label className="labelvalue required">
                                        {Labels.filter(lbl => lbl.keyPair=='LookupValue_LookupType' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}
                                        
                                        </Form.Label>
                                        <Form.Control
                                        id="frmlookuptypename"
                                        type="text"                                        
                                        name="lookuptypename"
                                        Placeholder="Lookup Type Name"
                                         onChange={this.handleChange}
                                     value={this.state.lookup.lookuptypename }                                        
                                    >
                                    </Form.Control>

                                    </Form.Group>
                                        </Col>
                                       
                                    </Form.Row>
                                    <Form.Row>
                                    <Col md-12 s-12>  
                                    <Button variant="primary" onClick={this.handleAddLookupTypeClick}  >
                                        {this.state.iseditrolemode==true?"Update":"Add"}
                                          
                                    </Button>
                                     <Button variant="secondary" className="btnrolecancel"  onClick={this.handleCloseTypeClick}  >
                                            Cancel
                                     </Button>
                                     </Col>
                                     </Form.Row>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                               
                               
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <div>
                    <Modal
                        className="addeditlookupvalue-modal"
                         show={this.state.lookupvalueshow}
                         onHide={this.handleCloseValueClick}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                                <h3>
                                Add {Labels.filter(lbl => lbl.keyPair=='LookupValue_LookupValues' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))}    
                                </h3>                               
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                 
                                    <Form.Row>
                                        <Col md-6>
                                        <Form.Group as={Col} controlId="formGrpLookupValue">
                                        <Form.Label className="labelvalue required">
                                        {Labels.filter(lbl => lbl.keyPair=='LookupValue_LookupValues' && lbl.pageName=='LookupValueSetup').map(filteredPerson => (
                                    <span>
                                    {filteredPerson.keyValue}
                                    </span>
                                    ))} 
                                        </Form.Label>
                                        <Form.Control
                                        id="frmlookupvaluename"
                                        type="text"                                        
                                        name="lookupvaluename"
                                        Placeholder="Lookup Value Name"
                                         onChange={this.handleChange}
                                         value={this.state.lookup.lookupvaluename }                                        
                                    >
                                    </Form.Control>

                                    </Form.Group>
                                        </Col>
                                       
                                    </Form.Row>
                                    <Form.Row>
                                    <Col md-12 s-12>  
                                    <Button variant="primary" onClick={this.handleAddLookupValueClick}  >
                                        {this.state.iseditrolemode==true?"Update":"Add"}
                                          
                                    </Button>
                                     <Button variant="secondary" className="btnrolecancel"  onClick={this.handleCloseValueClick}  >
                                           
                                            Cancel
                                     </Button>
                                     </Col>
                                     </Form.Row>
                                       
                                      
                                </Form>

                               


                            </Modal.Body>
                            <Modal.Footer>
                               
                               
                            </Modal.Footer>
                        </Modal>
                    </div>

            </div>
        </>
    );
}
}
 