import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import axios from "axios";
import * as moment from "moment";
import { browserHistory } from "react-router";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";
import "moment/min/moment-with-locales";
import _ from "lodash";
import { Link } from "react-router-dom";

import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Autocomplete from 'react-autocomplete';


const configData = require('./../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
   
} 

export default class EmailGroupInfo extends React.Component  {

    constructor(...args) {
        super(...args)
        const querystring = queryString.parse(this.props.location.search);
        this.state = {
            show: false,
            changedMember:{}, 
            userrole:{create:''},                    
            groupMember:{
                name : "",
                designation : "",
                email : "",
                location : "",
                status : ""
            },
            emailgroup: {
                grp_name : "",
                email_addr : "",
                owner : "",
                startdate : "",
                enddate : "",
                status : "",    
                groupMembers:[]
            
            },
            columns: [],
            users:[],
             originalgroupMembers:[],
             chkisactive:true,
             searchInput: "",
             autouser:{
                 firstName:'',
                 email:''
             }
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();
    }

    componentDidMount() {

        this.fetchUsersWithFetchAPI();
         this.fetchOrganizationsAPI();
         var querystring = queryString.parse(this.props.location.search);
         if(querystring.id!=undefined){
            this.fetchEmailDistribnFetchAPI();
         }
         else{
            var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
            console.log(rolelist);
            var primaryroleval=  localStorage.getItem("primaryrolesession");
            var selectedrolelocation=undefined;
            _.forEach(rolelist, function(value) {
                 
                if(primaryroleval==value.name){
                    selectedrolelocation=value;
                }
                
            });
           
            var userrl =_.find(selectedrolelocation.location, function(obj) {
                return obj.location === 'EmailDistribution';
            });
            
                this.setState({...this.state,userrole: userrl, isFetching: false})
         }
         this.getColumns();
    }
    fetchUsersWithFetchAPI = () => {
        this.setState({...this.state, isFetching: true});
        fetch(configData.SERVER_URL+'fincorp_getUsers')
            .then(response => response.json())
            .then(result => {
                this.bindUserList(result)
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };

    bindUserList=(result)=>{

         
        if(result!='No document(s) found with defined find criteria!' && result.length>0){
         this.setState({...this.state,users: result,  isFetching: false})
         
        }
         
    }

    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'EmailDistribution';
        });
        let columns = [
          {
            Header: "S.No",
            accessor: "name",
            sortable: true,
            show: true,
            displayValue: " S.No",
            Cell:row => (<div>{(row.index+1)}</div>)
          },
          {
            Header: "Name",
            accessor: "name",
            sortable: true,
            show: true,
            displayValue: "Name"
          },
          {
            Header: "Org",
            accessor: "org",
            sortable: true,
            show: true,
            displayValue: "Org"
          },
          {
            Header: "Designation",
            accessor: "designation",
            sortable: true,
            show: true,
            displayValue: "designation"
          },
          {
            Header: "Email Id",
            accessor: "email",
            sortable: true,
            show: true,
            displayValue: "Email Id"
          }, 
          {
            Header: "Location",
            accessor: "location",
            sortable: true,
            show: true,
            displayValue: "Location"
          },          
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "Status",
            maxWidth: 100,
            minWidth:56,
            width:56,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header:"Edit",maxWidth: 100,
            minWidth:40,
            width:40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth:58,
            width:58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };


    
   

    fetchEmailDistribnFetchAPI = () => {

        var querystring = queryString.parse(this.props.location.search);
        
        this.setState({...this.state, isFetching: true});

        axios.post(configData.SERVER_URL+`fincorp_getEmailDistrbnBasedOnId/`,
    
        {'_id': querystring.id}
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindEmailDistribnBasedId(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
        // event.preventDefault();
    };
 
    setBindEmailDistribnBasedId=data=>{
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'EmailDistribution';
        });
        if(data.length>0){
            this.setState({emailgroup:data[0],originalgroupMembers:data[0].groupMembers, userrole: userrl, isFetching: false})
            this.globalSearch();
        }
            else
            this.setState({...this.state,userrole: userrl, isFetching: false})
    
    }
    

     

    fetchOrganizationsAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getOrganization` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setOrganization(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });        
    };
    setOrganization=(result)=>{
             
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            var templist=[];
            var orglist=[];
            const maxid = _.maxBy(result, 'id');
          
            _.forEach(result, function(value) { 
                    
                if(templist.length>0){
                    var i=0;
                    _.forEach(templist, function(tmp) {
                        
                        if(tmp.id==value.parentId && tmp.parentId!=value.parentId)
                        {
                            if(templist[i].children==undefined)
                                templist[i].children=[];
                            value.displayname=tmp.name+'/'+value.label;
                            templist[i].children.push(value);
                            orglist.push(value);
                        }
                        else{
                            if(tmp.children.length>0){
                                var j=0;
                                var childlevel1=tmp.children;
                                _.forEach(childlevel1, function(tmpch1) {
                                    if(tmpch1.id==value.parentId && tmpch1.parentId!=value.parentId)
                                    {
                                        if(tmp.children[j].children==undefined){
                                            tmp.children[j].children=[];
                                        }
                                        value.displayname=tmp.name+'/'+tmpch1.label+'/'+ value.label;
                                        tmp.children[j].children.push(value);
                                        orglist.push(value);
                                    // templist[i].children=tmp;
                                    } 
                                    var k=0;
                                
                                    //second level org structure
                                    _.forEach(tmp.children[j].children, function(tmpch2) {
                                        if(tmpch2.id==value.parentId && tmpch2.parentId!=value.parentId)
                                        {
                                            if(tmp.children[j].children[k].children==undefined){
                                                tmp.children[j].children[k].children=[];
                                            }
                                            value.displayname=tmp.name+'/'+tmpch1.label+'/'+tmpch2.label+'/'+ value.label;
                                            tmp.children[j].children[k].children.push(value);
                                            orglist.push(value);
                                            
                                        }
                                        var l=0;
                                
                                        //third level org structure
                                        _.forEach(tmp.children[j].children[k].children, function(tmpch3) {
                                            if(tmpch3.id==value.parentId && tmpch3.parentId!=value.parentId)
                                            {
                                                if(tmp.children[j].children[k].children[l].children==undefined){
                                                    tmp.children[j].children[k].children[l].children=[];
                                                }
                                                value.displayname=tmp.name+'/'+tmpch1.label+'/'+tmpch2.label+'/'+tmpch3.label+'/'+ value.label;
                                                tmp.children[j].children[k].children[l].children.push(value)
                                                
                                            }
                                            var m=0;
                                
                                            //four level org structure
                                            _.forEach(tmp.children[j].children[k].children[l].children, function(tmpch4) {
                                                if(tmpch4.id==value.parentId && tmpch4.parentId!=value.parentId)
                                                {
                                                    if(tmp.children[j].children[k].children[l].children[m].children==undefined){
                                                        tmp.children[j].children[k].children[l].children[m].children=[];
                                                    }
                                                    value.displayname=tmp.name+'/'+tmpch1.label+'/'+tmpch2.label+'/'+tmpch3.label+'/'+tmpch4.label+'/' +value.label;
                                                    tmp.children[j].children[k].children[l].children[m].children.push(value);
                                                    orglist.push(value);
                                                } 
                                                m++;
                                            });  
                                            l++;
                                        }); 
                                        k++;
                                    });
                                    j++;
                                });
                            }
                            else{
                                value.displayname=value.name;
                                templist.push(value);
                                orglist.push(value);
                            }
                            
                        }
                        i++;
                    });
                }
                else{
                    value.displayname=value.name;
                    templist.push(value);
                    orglist.push(value);
                }
                console.log(value); 
            });
               
             console.log( orglist);
            this.setState({...this.state,treeData: orglist,  isFetching: false,maxuniqueid:maxid})
            
        }
        
    
    }


    handleEmailGroupChange = e => {
        let { value, name } = e.target;
             
        var emailgrp = this.state.emailgroup;

        if(e.target.type=='checkbox'){
            emailgrp[e.target.name] = e.target.checked;
        }
        else
        {
            emailgrp[e.target.name] = e.target.value;
        }
        this.setState( { emailgroup: emailgrp } );
        
    };

    handleGroupMemberChange = e => {
        let { value, name } = e.target;
               
        var grpMember = this.state.groupMember;

        if(e.target.type=='checkbox'){
            grpMember[e.target.name] = e.target.checked;
        }
        else
        {
            grpMember[e.target.name] = e.target.value;
        }
        this.setState( { groupMember: grpMember } );
        
    };

    onStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            emailgroup: {
                // object that we want to update
                ...prevState.emailgroup, // keep all other key-value pairs
                
                startdate: moment(dateString).format("MM-DD-YYYY")
                
            }
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            emailgroup: {
                // object that we want to update
                ...prevState.emailgroup, // keep all other key-value pairs
                
                enddate: moment(dateString).format("MM-DD-YYYY")
                
            }
        }));
    };

    handleSaveClick = (event) => {
        const regExEmailGroup = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;
        
        
        if(this.state.emailgroup.grp_name ==undefined || this.state.emailgroup.grp_name.length==0){
            toast.warn("Enter Group Name");
            event.preventDefault();        
        }
        else if (this.state.emailgroup.email_addr == undefined || this.state.emailgroup.email_addr == ""  ) {
            toast.warn("Enter Group Email Id");
             event.preventDefault();               
        }
        else if (regExEmailGroup.test(this.state.emailgroup.email_addr) === false){
            toast.warn("Enter Correct Email");
            event.preventDefault();        
        }
        else if (this.state.emailgroup.owner == undefined || this.state.emailgroup.owner == ""  ) {
            toast.warn("Enter Group Owner Name");
             event.preventDefault();               
        }
        else if(this.state.emailgroup.status ==undefined || this.state.emailgroup.status =="" || this.state.emailgroup.status==""){
            toast.warn("Select Status");
         event.preventDefault();            
        }    
        else if (this.state.emailgroup.startdate == undefined || this.state.emailgroup.startdate == ""|| this.state.emailgroup.startdate == "Invalid date"  ) {
            toast.warn("Enter Start Date");
             event.preventDefault();               
        }
        else if (Date.parse(this.state.emailgroup.startdate) > Date.parse(this.state.emailgroup.enddate)) {
            toast.warn("End Date should be greater than the StartDate");
            event.preventDefault();
        }     

        else if(this.state.emailgroup.groupMembers.length==0){

            toast.warn("Enter atleast one member for email group");
             event.preventDefault(); 
        }
        else{

           
            if(this.state.emailgroup._id!=undefined){
                if(window.confirm('Do you want to save changes?')){ 
                this.state.emailgroup.startdate=  this.state.emailgroup.startdate == "Invalid date"?null:this.state.emailgroup.startdate;
                this.state.emailgroup.enddate=  this.state.emailgroup.enddate == "Invalid date"?null:this.state.emailgroup.enddate;
                axios
                .post(configData.SERVER_URL+`fincorp_updateEmailDistrbn`,
                
                this.state.emailgroup
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.UpdateChange(res.data,event)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
                // event.preventDefault();
            }
            }
            else{
                //insert new email group
                axios
                .post(configData.SERVER_URL+`fincorp_insertEmailDistrbn`,
                
                this.state.emailgroup
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.SaveChange(res.data,event)
                    
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
                // event.preventDefault();
                }
            }
         
        

    };
    SaveChange=(result,event)=>{
        if(result.MessageKey!=undefined){
            toast.warn(result.Message);
        }
        else{        
        console.log(result);
        toast.success("Email Group Inserted Successfully");
        setTimeout(()=>this.props.history.push("/emaildistributionlist"), 4000);
        }
        
    }
    UpdateChange=(result,event)=>{       
        if(result.MessageKey!=undefined){
            toast.warn(result.Message);
        }
        else{   
        console.log(result);
        toast.success("Email Group Updated Successfully");
        setTimeout(()=>this.props.history.push("/emaildistributionlist"), 4000);
        }
        
    }
    //add Member into local cache 
    handleMemberSaveClick = (event) => {
        
        const regExEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;
        const regExName = /^[a-zA-Z ]*$/;

        if(this.state.groupMember.name ==undefined || this.state.groupMember.name.length==0){
            toast.warn("Enter Member Name");
             event.preventDefault();        
        }
        else if (regExName.test(this.state.groupMember.name) === false){
            toast.warn("Member Name Must Contain Only Alphabets");
            event.preventDefault();        
        }
        
        else if (this.state.groupMember.designation == undefined || this.state.groupMember.designation == ""  ) {
            toast.warn("Enter Designation");
             event.preventDefault();               
        }
        else if (this.state.groupMember.email == undefined || this.state.groupMember.email == ""  ) {
            toast.warn("Enter Email");
             event.preventDefault();               
        }
        else if (regExEmail.test(this.state.groupMember.email) === false){
            toast.warn("Enter Correct Email");
            event.preventDefault();        
        }
        else if (this.state.groupMember.location == undefined || this.state.groupMember.location == ""  ) {
            toast.warn("Enter Location");
             event.preventDefault();                          
        }
        else if(this.state.groupMember.status ==undefined || this.state.groupMember.status =="" || this.state.groupMember.status==""){
            toast.warn("Select Status");
             event.preventDefault();            
        }       
       else{
           
           if(this.state.changedMember!=undefined && this.state.changedMember>-1){
            let index = _.findIndex(this.state.emailgroup.groupMembers, (e) => {
                return e.email == this.state.groupMember.email;
            }, 0);
            if(index>-1 && index!=this.state.changedMember){
                toast.warn("Email id already exists.");
            }
            else{
            this.state.emailgroup.groupMembers[this.state.changedMember]=this.state.groupMember;
            toast.success("Group Member Updated Successfully");
            let data= this.state.emailgroup.groupMembers;
            const newData = data.map(d=>({...d, name1:"Test"}));
            //this.setState({data: newData})
            this.setState(prevState => ({...prevState,
                emailgroup: {
                    ...prevState.emailgroup,  
                    groupMembers: newData
                    
                },
                show:false,
                groupMember:{},
                changedMember:-1
            }));
            }
           }
           else{
            let index = _.findIndex(this.state.emailgroup.groupMembers, (e) => {
                return e.email.toLowerCase() == this.state.groupMember.email.toLowerCase();
            }, 0);
            if(index>-1){
                toast.warn("Email id already exists.");
            }
            else{
                this.state.emailgroup.groupMembers.push(this.state.groupMember);
                toast.success("Group Member Inserted Successfully");
                let data= this.state.emailgroup.groupMembers;
                const newData = data.map(d=>({...d, name1:"Test"}));
                //this.setState({data: newData})
                this.setState(prevState => ({
                    emailgroup: {
                        ...prevState.emailgroup,  
                        groupMembers: newData
                    },
                    show:false,
                    groupMember:{},
                    changedMember:-1
                }));
            
                 
            }
        }
    }
}

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
        // event.preventDefault();
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
    }


    //add new member
    handleAddMember=(event)=>{
        // this.setState(prevState => ({
        //     show:true,
        //     editshow:false
        // }));
        this.setState({...this.state,show:true,editshow:false,changedMember:-1});
     event.preventDefault();
        //this.setState({show: true, editshow:false });
    }

     handleClose=()=> {
        this.setState(prevState => ({...prevState,
            show:false,
            groupMember:{},
            changedMember:-1
        }));
         
    }

     handleEditClick = (row, event) => {
        
        console.log(this.state.emailgroup.groupMembers);
        this.setState({...this.state,groupMember:this.state.emailgroup.groupMembers[row.index],show:true,changedMember:row.index});
         //this.props.history.push("/emailgroupinfo?id=" + row._id);
     };

     handleDeleteClick = (row, event) => {
        
        if(window.confirm('Are you sure to delete this record?')){ 
            event.preventDefault();
            event.stopPropagation(); 
            
            console.log(this.state.emailgroup.groupMembers);
            _.pullAt(this.state.emailgroup.groupMembers,[row.index]);
            
            let data= this.state.emailgroup.groupMembers;
            const newData = data.map(d=>({...d, name1:"Test"}));
            //this.setState({data: newData})
            this.setState(prevState => ({
                emailgroup: {
                    ...prevState.emailgroup,  
                    groupMembers: newData
                }
            }));
             
           // this.setState({...this.state,groupMember:this.state.emailgroup.groupMembers})
            toast.success("Group Member Deleted Successfully");
        }
        else{
            event.preventDefault();
            event.stopPropagation(); 
        }
         
     };
    
    handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };
      toggleChange = () => {
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }

      globalActiveSearch = (param) => {
        let { searchInput } = this.state;       
        let filteredData = this.state.originalgroupMembers.filter((value, index, arr) =>  {
            if(param){

                return (
                    ((index+1).toString().includes(searchInput.toLowerCase())||            
                    value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    (value.org!=undefined||value.org!=null? value.org.toLowerCase().includes(searchInput.toLowerCase()):"") ||
                    value.designation.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.email.toLowerCase().includes(searchInput.toLowerCase()) ||                    
                    (value.location!=undefined ||value.location!=null? value.location.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&& 
                    value.status.toString().includes('Active')                   
                );

            }else{
                return (
                    (index+1).toString().includes(searchInput.toLowerCase())||            
                    value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    (value.org!=undefined||value.org!=null? value.org.toLowerCase().includes(searchInput.toLowerCase()):"") ||
                    value.designation.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.email.toLowerCase().includes(searchInput.toLowerCase()) ||
                    (value.location!=undefined ||value.location!=null? value.location.toString().toLowerCase().includes(searchInput.toLowerCase()):'')||            
                    value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        });
        this.setState(prevState => ({
            emailgroup: {
                ...prevState.emailgroup,  
                groupMembers: filteredData
            }
        }));
      };

      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalgroupMembers.filter((value, index, arr) => {
        if(this.state.chkisactive){    
            return (
                ((index+1).toString().includes(searchInput.toLowerCase())||            
                value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                (value.org!=undefined||value.org!=null? value.org.toLowerCase().includes(searchInput.toLowerCase()):"") ||
                value.designation.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.email.toLowerCase().includes(searchInput.toLowerCase()) ||                    
                (value.location!=undefined ||value.location!=null? value.location.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&& 
                value.status.toString().includes('Active')                   
            );
        }else {
            return (
                (index+1).toString().includes(searchInput.toLowerCase())||            
                value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                (value.org!=undefined||value.org!=null? value.org.toLowerCase().includes(searchInput.toLowerCase()):"") ||
                value.designation.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.email.toLowerCase().includes(searchInput.toLowerCase()) ||
                (value.location!=undefined ||value.location!=null? value.location.toString().toLowerCase().includes(searchInput.toLowerCase()):'')||            
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
            );

        }
        });
        this.setState(prevState => ({
            emailgroup: {
                ...prevState.emailgroup,  
                groupMembers: filteredData
            }
        }));
        //this.setState({ emailgroup.groupMember: filteredData });
      };

      setAutoUserValue=(val)=>{
           
        this.setState(prevState => ({
            autouser: {
                ...prevState.autouser,  
                autouser: val
            }
        }));
        this.state.groupMember.email=val.email;
      }
      setAutoUserValueChange=(val)=>{
       
        this.setState(prevState => ({
            autouser: {
                ...prevState.autouser,  
                autouser: val
            }
        }));
        this.state.groupMember.email=val.email;
       
      //this.state.groupMember.email=val.email;
    }

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const savetype= this.state.emailgroup._id!=undefined?"Edit":"Add";
         
        let {  searchInput } = this.state;
    return (
        <>
            <div className="container userinfo-container" grpinfo="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/emaildistributionlist">
                        {Labels.filter(lbl => lbl.keyPair=='EmailDistrbn_Title' && lbl.pageName=='EmailDistributionList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Title' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">                        
                    {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Title' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                        <span>
                        {savetype} {filteredPerson.keyValue}
                        </span>
                    ))}
                    </span>
                    <ToastContainer />
                </div>
                <div>
                    <Modal
                        className="GroupMember-modal"
                         show={this.state.show}
                         onHide={this.handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                                <h3>
                                {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Member_Title' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                <span>
                                {this.state.changedMember==undefined||this.state.changedMember==-1?"Add":"Edit"} {filteredPerson.keyValue} 
                                                                </span>
                                ))}
                                </h3>                               
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Row>
                                    <Col md-6>
                                    <Form.Group as={Col} controlId="formGrpMemberrName">
                                        <Form.Label className="labelvalue required" for='formGrpMemberName'> 
                                            {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Name' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}                                                                             
                                        </Form.Label>
                                        <Form.Control
                                            id="formGrpMemberName"
                                            type="text"
                                            name="name"
                                            placeholder="Name1"
                                            value={this.state.groupMember.name}
                                             disabled={parseInt(this.state.changedMember)>-1?true:false}
                                            onChange={this.handleGroupMemberChange}
                                            required
                                        />
                                        <Autocomplete
  
                                                // Items is the list of suggestions 
                                                // displayed while the user type
                                                // items={[
                                                //     { label: 'C++' },
                                                //     { label: 'C' },
                                                //     { label: 'Python' },
                                                //     { label: 'JavaScript' },
                                                //     { label: 'Julia' },
                                                //     { label: 'Java' },
                                                //     { label: 'Objective C' },
                                                //     { label: 'C#' },
                                                //     { label: 'Dart' },
                                                //     { label: 'Perl' }
                                                // ]}
                                                items={this.state.users}
                                                // To handle the case that when
                                                // the user type, suggested 
                                                // values should be independent
                                                // of upper or lower case 
                                                shouldItemRender={(item, value
                                                    ) => item.firstName.toLowerCase()
                                                    .indexOf(value.toLowerCase()) > -1}
                                                getItemValue={item => item}
                                                renderItem={(item, isHighlighted) =>
                                                    // Styling to highlight selected item
                                                    <div style={{
                                                        background: isHighlighted ?
                                                            '#bcf5bc' : 'white'
                                                    }}
                                                        key={item.id}>
                                                        {item.firstName}
                                                    </div>
                                                }
                                                value={this.state.autouser.firstName}

                                                // The onChange event watches for
                                                // changes in an input field
                                                onChange={e => this.setAutoUserValue(e.target.value)}

                                                // To set the state variable to value
                                                // selected from dropdown
                                                onSelect={(val) => this.setAutoUserValueChange(val)}

                                                // Added style in Autocomplete component
                                                inputProps={{
                                                    style: {
                                                        width: '300px', height: '20px',
                                                        background: 'red', 
                                                         
                                                        border: '2px outset lightgray'
                                                    },
                                                    placeholder: 'Search User'
                                                }}
                                                />

                                    </Form.Group>
                                    </Col>
                                   
                                    <Col md-6>
                                    <Form.Group as={Col} controlId="formMemberOrg">
                                    <Form.Label className="labelvalue required" for='org'>
                                            {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Organization' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))} 
                                    </Form.Label>
                                    {/* <Form.Control
                                            as="select"
                                            id="org"
                                            controlId="org"
                                            name="org"
                                            value={this.state.groupMember.org}
                                            onChange={this.handleGroupMemberChange}
                                           
                                            
                                            required
                                        >
                                            <option value="Select Org">Select Org</option>
                                            <option value="Org1">Org1</option>
                                            <option value="Org2">Org2</option>
                                        </Form.Control>    */}

                                         <Form.Control
                                        id="org"
                                        as="select"
                                        name="org"
                                        placeholder="Select Organization"
                                        value={this.state.groupMember.org}
                                        onChange={this.handleGroupMemberChange}
                                        required
                                    >
                                        {this.state.treeData &&
                                            this.state.treeData.length > 0 &&
                                            this.state.treeData.map((org,index) => {
                                                return <option data-index={JSON.stringify(org)} value={org.displayname}> {org.displayname}  </option>;
                                            })}   

                                    </Form.Control>                        
                                    </Form.Group>                                  
                                    </Col>
                                </Form.Row>
                                    <Form.Row>
                                        <Col md-6>
                                        <Form.Group as={Col} controlId="formGrpMemberDesignation">
                                        <Form.Label className="labelvalue required" for='formGrpMemberdsgn'>
                                            {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Designation' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}                                         
                                        </Form.Label>
                                        <Form.Control
                                            id="formGrpMemberdsgn"
                                            type="text"
                                            name="designation"
                                            placeholder="Designation"
                                            value={this.state.groupMember.designation}
                                            onChange={this.handleGroupMemberChange}
                                           
                                            required
                                        />

                                    </Form.Group>
                                        </Col>
                                        <Col md-6>
                                        <Form.Group as={Col} controlId="formGrpMemberrEmail">
                                        <Form.Label className="labelvalue required" for='formGrpMemberEmail'>
                                            {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Email' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}                                         
                                        </Form.Label>
                                        <Form.Control
                                            id="formGrpMemberEmail"
                                            type="text"
                                            name="email"
                                            placeholder="Email"
                                            value={this.state.groupMember.email}
                                            disabled={parseInt(this.state.changedMember)>-1?true:false}
                                            onChange={this.handleGroupMemberChange}
                                           
                                            required
                                        />

                                    </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                    <Col md-6 s-12>
                                        <Form.Group as={Col} controlId="formGrpMemberLocation">
                                        <Form.Label className="labelvalue required">
                                            {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Location' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}                                                                                    
                                        </Form.Label>
                                        <Form.Control
                                            id="formGrpMemberLocation"
                                            type="text"
                                            name="location"
                                            placeholder="Location"
                                            value={this.state.groupMember.location}
                                            onChange={this.handleGroupMemberChange}
                                           
                                            required
                                        />

                                         </Form.Group>
                                        </Col>
                                        <Col md-6 s-12>
                                        <Form.Group as={Col} controlId="formGrpMemberStatus">
                                            <Form.Label className="required">
                                            {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Status' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                                            </Form.Label>
                                            <Form.Control
                                            as="select"
                                            id="formGrpMemberStatus"
                                            controlId="grpmemstatus"
                                            name="status"
                                            value={this.state.groupMember.status}
                                            onChange={this.handleGroupMemberChange}
                                           
                                            required
                                            >
                                            <option value="">Select Status</option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                            </Form.Control>
                                        </Form.Group>
                                        </Col>
                                       
                                    </Form.Row>
                                
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                               
                                <Button variant="primary" onClick={this.handleMemberSaveClick}>
                                Save
                                 </Button>
                                 <Button variant="secondary" onClick={this.handleClose} >
                               Cancel
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>


                <Form
                    style={{ width: "100%" }}
                >

                    <div className="panel top-panel-div " style={{ marginLeft: "-2px" }}>
                        <Form.Row>
                            <Row className="margin-t10 col-md-12">
                                <Col md={6} className="row">
                                    <Col md={5}>
                                        <Form.Label for="frmgrpname">
                                        {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_GroupName' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                                        </Form.Label>
                                    </Col>
                                    <Col md={7}>

                                        <Form.Control
                                            id="frmgrpname"
                                            type="text"
                                            // className={showUserTextControl}
                                            name="grp_name"
                                            placeholder="Group Name"
                                            value={this.state.emailgroup.grp_name}
                                            disabled={this.state.emailgroup._id!=undefined?true:false}
                                            onChange={this.handleEmailGroupChange}
                                        />
                                    </Col>
                                </Col>
                                <Col md={6} className="row">
                                    <Col md={5}>
                                        <Form.Label for="frmgrpemail" className="required">
                                        {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_GroupEmail' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                                        </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                        <Form.Control
                                            id="frmgrpemail"
                                            type="text"
                                            name="email_addr"
                                            placeholder="Group Email Id"
                                            value={this.state.emailgroup.email_addr}
                                            disabled={this.state.emailgroup._id!=undefined?true:false}
                                            onChange={this.handleEmailGroupChange}
                                            required
                                        />
                                    </Col>
                                </Col>
                            </Row>
                        </Form.Row>                       

                        <Form.Row>
                            <Row className="margin-t10 col-md-12">
                                <Col md={6} className="row">
                                    <Col md={5}>
                                        <Form.Label for="frmowner" className="required">
                                        {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Owner' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                                        </Form.Label>
                                    </Col>
                                    <Col md={7} className="desc-pad-10x">
                                        <Form.Control
                                            id="frmowner"
                                            type="text"
                                            name="owner"
                                            placeholder="Owner"
                                            value={this.state.emailgroup.owner}
                                            onChange={this.handleEmailGroupChange}
                                            required
                                        />
                                    </Col>
                                </Col>
                                <Col md={6} className="row">
                                    <Col md={5}>
                                    <Form.Label for="emaildistbnstatus" className="required">
                                        Status
                                    </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                    <Form.Control
                                        id="emaildistbnstatus"
                                        as="select"
                                        name="status"
                                        value={this.state.emailgroup.status}
                                        onChange={this.handleEmailGroupChange}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </Form.Control> 
                                    </Col>                                    
                                </Col>              

                            </Row>
                        </Form.Row>
                        <Form.Row>
                            <Row className="margin-t10 col-md-12">
                                <Col md={6} className="row">
                                    <Col md={5}>
                                        <Form.Label for="startdate" className="required">
                                        {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_StartDate' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                                        </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                        <DatePickerInput
                                            id="startdate"
                                            name="startdate"
                                            onChange={this.onStartDateChange.bind(this, 'startdate') }
                                            placeholder="MM/DD/YYYY"
                                            minDate={moment().toDate()}
                                            value={this.state.emailgroup.startdate}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />
                                    </Col>
                                </Col>
                                <Col md={6} className="row">
                                    <Col md={5}>
                                        <Form.Label for="enddate">
                                        {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_EndDate' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                                        </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                        <DatePickerInput
                                            id="enddate"
                                            name="enddate"
                                            onChange={this.onEndDateChange.bind(this, 'enddate') }
                                            placeholder="MM/DD/YYYY"
                                            minDate={moment().toDate()}
                                            value={this.state.emailgroup.enddate}
                                            className="my-custom-datepicker-component"
                                            readOnly
                                        />
                                    </Col>
                                </Col>
                            </Row>
                        </Form.Row>                       
                          
                        <div className="fullwidth mt-20">                                          
                            <button className={this.state.userrole.create=='Y'?"btn btn-primary  float-left":"hidden"}  onClick={this.handleAddMember.bind(this)} > 
                            {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_AddMember_btn' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                                            <span>
                                            {filteredPerson.keyValue}
                                            </span>
                                            ))}
                            </button>
                                         
                        <div className="float-right">
                        <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            aria-label='Status'
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='EmailGrpInfo_Search' && lbl.pageName=='EmailGroupInformation').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                            ))}
                        </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"
                            placeholder=""
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />
                        </div>
                        </div>                    
                    </div> 
                    <div className="fullwidth row m0">  
                    <div  className="row col-md-12 mt-10 ml-0 p0">
                       
                            <ReactTable
                            data={this.state.emailgroup.groupMembers}
                            columns={this.state.columns}
                            defaultPageSize={20}
                            className="-striped -highlight fullwidth"
                            /> 
                            </div>
                        </div>
                    </div>
                </Form>
                <div className="float-right mb-30"> 
                <Button type="submit" variant="primary" onClick={this.handleSaveClick} >               
                         Save                          
                    </Button>                 
                {/* <Link to="/save" className="btn btn-primary">Save</Link> */}
                <Link to="/emaildistributionlist" className="btn btn-secondary  ml-10">Cancel</Link>
                </div>              

            </div>
        </>
    
    );
}
}
