import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import { Link } from "react-router-dom";

const initialValues = {
    weight: '',
    height: '',
    date: '',
    user: {
        login: '',
        status: ''
    }
}
const Search = (change) => {

    const [state, setState] = useState(initialValues);

    const handleChange = e => {
        let { value, name } = e.target;
       
        const date = new Date().toLocaleString().split(',')[0];
        setState({
            ...state,
            [name]: value,
            date
        });
    };


    const handleSubmit = () => {
        change(state);
        setState(initialValues);
    };
    const handleSaveClick = () => {

    };
    return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Search</Breadcrumb.Item>
                </Breadcrumb>



                <Form onSubmit={handleSaveClick}>
                    <div className="centeralign">
                        <span className="mainheadings"> Search</span>
                    </div>
                    <div >
                        <h4 style={{marginLeft: "10px"}}>Search Criteria</h4>
                        <Form.Row style={{ marginLeft: "10px", border: "1px solid black", borderRadius: "5px" }}>                        
                            <Row className="margin-t10 col-md-12 m-10">
                                <Col md={8} className="row">

                                    <Form.Control
                                        id="frmsearch"
                                        type="text"
                                        name="search"
                                        placeholder="Search Here"
                                        style={{ marginLeft: "15px" }}
                                        required
                                        aria-label='Search'
                                    />

                                </Col>

                                <Col md={2}>

                                    <Button
                                        className="btn-search"
                                        variant="dark"

                                    >
                                        Search
                                    </Button>

                                </Col>

                            </Row>

                        </Form.Row>
                    </div>
                    <br/>
                    <h4 style={{marginLeft: "10px"}}>Advanced Search</h4>
                    <Form.Row style={{ marginTop: "1%", marginLeft: "10px", border: "1px solid black", borderRadius: "5px", padding: "5px" }}>
                        {/* <Form.Row style={{border: "1px solid black"}}> */}
                       
                            <Row className="margin-t10 col-md-12">
                                <Col md={4}>
                                    <Col md={12}>
                                        <Form.Label for="frmdate" >
                                            From
                                        </Form.Label>
                                        <DatePickerInput
                                            id="frmdate"
                                            aria-label="frmdate"
                                            placeholder="MM/DD/YYYY"
                                            name="frmdate"
                                            className="my-custom-datepicker-component pull-left"
                                        />
                                    </Col>
                                </Col>
                                <Col md={4}>
                                    <Col md={12}>
                                        <Form.Label for="todate" >
                                            To
                                        </Form.Label>
                                        <DatePickerInput
                                            id="todate"
                                            aria-label="todate"
                                            placeholder="MM/DD/YYYY"
                                            name="todate"
                                            className="my-custom-datepicker-component pull-left"
                                        />
                                    </Col>
                                </Col>
                                <Col md={4}>
                                    <Col md={12}>
                                        <Form.Label for="frmquarter" >
                                            Quarter
                                        </Form.Label>
                                        <Form.Control
                                            id="frmquarter"
                                            as="select"
                                            name="quarter"
                                            placeholder="quarter"

                                        >
                                            <option value="">Select Quarter</option>
                                            <option value="First">First</option>
                                            <option value="Second">Second</option>
                                            <option value="Third">Third</option>
                                            <option value="Fourth">Fourth</option>
                                        </Form.Control>
                                    </Col>
                                </Col>
                            </Row>
                        {/* </Form.Row> */}
                        
                        {/* <Form.Row> */}
                            <Row className="margin-t10 col-md-12 ">
                                <Col md={4}>

                                    <Form.Label for="fiscalyr" >
                                        Select Fiscal Year
                                        </Form.Label>

                                    <DatePickerInput
                                        id="fiscalyr"
                                        aria-label="fiscalyr"
                                        placeholder="MM/DD/YYYY"
                                        name="fiscalyr"
                                        className="my-custom-datepicker-component pull-left"
                                    />

                                </Col>
                                <Col md={{ span: 4, offset: 4 }}>

                                    <Form.Label for="frmorg" >
                                        Organization
                                        </Form.Label>

                                    <Form.Control
                                        id="frmorg"
                                        as="select"
                                        name="org"
                                        placeholder="Organization"

                                    >
                                        <option value="">Organization</option>

                                    </Form.Control>

                                </Col>
                            </Row>
                        {/* </Form.Row> */}

                        {/* <Form.Row className="ml-20"> */}
                            <Row className="margin-t10 col-md-12">
                                <Col md={6} >

                                    <Form.Label for="frmfile" >
                                        File Name
                                    </Form.Label>
                                    <Col md={12}>
                                    <Form.Control
                                        id="frmfile"
                                        type="text"
                                        name="frmfile"
                                        placeholder="File Name"
                                        required
                                    />
                                    </Col>
                                    <Col md={12} className="margin-t10">

                                        <Button
                                         className="btn-search"
                                         variant="primary"

                                        >
                                            Apply
                                        </Button>

                                    </Col>   
                                </Col>
                                
                                <Col md={{ span: 4, offset: 2 }}>
                                <Form.Label for="pricerange" >
                                        Amount Range
                                    </Form.Label>
                                    <Row>                                    
                                    <Col md={6}>
                                    <Form.Label for="frmfrmamnt" >
                                        From
                                    </Form.Label>
                                    <Form.Control
                                        id="frmfrmamnt"
                                        type="text"
                                        name="frmfrmamnt"
                                        placeholder="$"
                                        required
                                    />
                                    </Col>
                                    <Col md={6}>
                                    <Form.Label for="frmtoamnt" >
                                        To
                                    </Form.Label>
                                    <Form.Control
                                        id="frmtoamnt"
                                        type="text"
                                        name="frmtoamnt"
                                        placeholder="$"
                                        required
                                    />
                                    </Col>
                                    </Row>
                                </Col>                           
                             
                            </Row>
                            
                        </Form.Row>
                    {/* </Form.Row> */}
                </Form>
            </div>
        </>
    );
}

export default Search;