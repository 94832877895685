  
import React, { Component } from 'react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { Button, Modal, Table, Form, Row, Col, Breadcrumb, Popover, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
//const configData= require('config');
const configData = require( './../../config.json');

export default class OrgList extends Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleAddNewItem = this.handleAddNewItem.bind(this);
        this.handleEditEvent = this.handleEditEvent.bind(this);
        var data = [{
            active: true,            
            address1: null,
            address2: null,
            city: null,
            country: "USA",
            created: "2019-08-30 05:05:43 AM",
            createdBy: null,            
            description: 'desc',           
            endDate: null,            
            id: 1,
            label: "DO",            
            modified: "2020-06-29 02:53:57 AM",
            modifiedBy: "admin",
            name: "Default Origin",           
            parentId: null, 
            startDate: "2019-08-30",
            state: null,           
            zipCode: null
           
        } 
        ];
        
        this.state = {
            treeData: data,
            Labels:[], 
            isFetching: true,
            show:false,
            userrole:{create:''}
            //    [
            //    { title: 'CEO' },
            //    { title: 'President-Finance', children: [{ title: 'Finance-Manager', children: [{ title: 'Manager', children: [{ title: 'Executive', children: [{ title: 'Clerk' }] }] }] }] },
            //    { title: 'President-HR', children: [{ title: 'HR-Manager', children: [{ title: 'Manager', children: [{ title: 'Assistant-Manager', children: [{ title: 'Executive' }] }] }] }] }

            //],
        };

         
        this.fetchLabelMessagesAPI();
        

    }
    componentDidMount() {
        //this.fetchLabelMessagesAPI();
        //Get the org data from db
        this.fetchOrganizationsAPI();
    }

    handleAddNewItem(getNodeKey, node, path) {
        console.log(node);
        var nodeid = node._id != undefined ? node._id : node.id;
        if (nodeid != undefined) {
            this.props.history.push("/org?child=1&id=" + nodeid+'&nextval='+this.state.maxuniqueid.id+'&parentId='+node.id);
        }
    }

    handleEditEvent(node) {
        console.log("edit event", node);

        if (node._id != undefined)
            this.props.history.push(
                "/org?child=0&id=" + node._id
            );
        else if (node.id != undefined)
            this.props.history.push(
                "/org?child=0&id=" + node.id
            );
        else {
            // toaster.notify("Selected item not having edit option!", {
            //     duration: 2000
            // });
        }
    }

    handleClose() {

        this.setState({ show: false });
    }

    onSelectedTree = data => {


        var activestate = data.active ? "Active" : "Inactive";
        this.setState({
            name: data.name,
            label: data.label,
            description: data.description,
            active: data.active,
            startdate: data.startDate,
            enddate: data.endDate,
            address1: data.address1,
            address2: data.address2,
            statename: data.state,
            country:data.country,
            city: data.city,
            cityname:data.cityName,
            zip: data.zipCode,
            poclastname: data.pocLastName,
            pocfirstname: data.pocFirstName,
            phone: data.phoneNumber,
            cellphone: data.cellPhone,
            email: data.email,
            url: data.url,
            fax: data.faxNumber,
            additionalinfo: data.additionalInfo,
            notes: data.notes,
            show: true,
            activestatus: data.status
        });

    };

    // show preview window
    showPreview(node, path) {
        
        console.log("show preview", node);
        var nodeid = node.id != undefined ? node.id : node._id;
        
        this.onSelectedTree(node);

         
    }

    truncate(node) {

        if (node.name != undefined) {
            return node.name.length > 50 ? node.name.substring(0, 47) + "..." : node.name;
        }
        else {
            return "";
        }
    };

 
fetchOrganizationsAPI = () => {
    this.setState({...this.state, isFetching: true});
    
    axios
    .get(configData.SERVER_URL+`fincorp_getOrganization` 
    , {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*"
        }
    })
    .then(
        res=>  {this.setOrganization(res.data) }
    )
    .catch(err => {
        console.log("AXIOS ERROR: ", err);
    });
};
setOrganization=(result)=>{

       var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
    console.log(rolelist);
    var primaryroleval=  localStorage.getItem("primaryrolesession");
    var selectedrolelocation=undefined;
    _.forEach(rolelist, function(value) {
         
        if(primaryroleval==value.name){
            selectedrolelocation=value;
        }
        
    });
   
    var userrl =_.find(selectedrolelocation.location, function(obj) {
        return obj.location === 'Organization';
    });


    if(result=="No document(s) found with defined find criteria!"){
        axios
        .post(configData.SERVER_URL+`fincorp_insertOrganization`,
        
        this.state.treeData[0]
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res => this.SaveChange(res.data)
            
              
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    }
    else if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
        var templist=[];
        const maxid = _.maxBy(result, 'id');
 
        _.forEach(result, function(value) { 
            
            if(templist.length>0){
    
                
                var i=0;
                _.forEach(templist, function(tmp) {
                     
                    if(tmp.id==value.parentId && tmp.parentId!=value.parentId)
                    {
                        if(templist[i].children==undefined)
                            templist[i].children=[];

                        templist[i].children.push(value)
                    }
                    else{
                        if(tmp.children.length>0){
                            var j=0;
                            var childlevel1=tmp.children;
                            _.forEach(childlevel1, function(tmpch1) {
                                if(tmpch1.id==value.parentId && tmpch1.parentId!=value.parentId)
                                {
                                    if(tmp.children[j].children==undefined){
                                        tmp.children[j].children=[];
                                    }
                                    tmp.children[j].children.push(value)
                                   // templist[i].children=tmp;
                                } 
                                var k=0;
                               
                                //second level org structure
                                _.forEach(tmp.children[j].children, function(tmpch2) {
                                    if(tmpch2.id==value.parentId && tmpch2.parentId!=value.parentId)
                                    {
                                        if(tmp.children[j].children[k].children==undefined){
                                            tmp.children[j].children[k].children=[];
                                        }
                                        tmp.children[j].children[k].children.push(value)
                                        
                                    }
                                    var l=0;
                               
                                    //third level org structure
                                    _.forEach(tmp.children[j].children[k].children, function(tmpch3) {
                                        if(tmpch3.id==value.parentId && tmpch3.parentId!=value.parentId)
                                        {
                                            if(tmp.children[j].children[k].children[l].children==undefined){
                                                tmp.children[j].children[k].children[l].children=[];
                                            }
                                            tmp.children[j].children[k].children[l].children.push(value)
                                            
                                        }
                                        var m=0;
                               
                                        //four level org structure
                                        _.forEach(tmp.children[j].children[k].children[l].children, function(tmpch4) {
                                            if(tmpch4.id==value.parentId && tmpch4.parentId!=value.parentId)
                                            {
                                                if(tmp.children[j].children[k].children[l].children[m].children==undefined){
                                                    tmp.children[j].children[k].children[l].children[m].children=[];
                                                }
                                                tmp.children[j].children[k].children[l].children[m].children.push(value)
                                              } 
                                            m++;
                                        });  
                                        l++;
                                    }); 
                                    k++;
                                });
                                j++;
                            });
                        }
                        else{
                            templist.push(value);
                        }
                        
                    }
                    i++;
                });
            }
            else{
                
                templist.push(value);
            }
            console.log(value); 
          });
            
        this.setState({ treeData: templist,  isFetching: false,maxuniqueid:maxid,userrole:userrl});
    }
    

}
SaveChange=(result)=>{
        
           
    setTimeout( this.fetchOrganizationsAPI(),1000);
    
}

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }

    render() {
        
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const getNodeKey = ({ treeIndex }) => treeIndex;
        return (
            <div className="container treeview-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='OrgList_Title' && lbl.pageName=='OrgList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='OrgList_Title' && lbl.pageName=='OrgList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                    </span>
                </div>
                {/* <div className="floatRightLink">
                    <Link to="/org" >Add Org</Link>
                </div> */}

                <div>
                    <Modal
                        className="preview-modal"
                        show={this.state.show}
                        onHide={this.handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="margin_auto">
                                {this.state.name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label className="labelvalue">Name : </Form.Label>
                                        {this.state.name}
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>Short Name : </Form.Label>
                                        {this.state.label}
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>Description :</Form.Label>
                                        {this.state.description}
                                    </Form.Group>
                                    {/* <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>Active Status :</Form.Label>
                                        {this.state.activestatus}
                                    </Form.Group> */}
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>Start Date : </Form.Label>
                                        {this.state.startdate}
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>End Date : </Form.Label> {this.state.enddate}
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>Address1 : </Form.Label> {this.state.address1}
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>Address2 : </Form.Label> {this.state.address2}
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>City : </Form.Label> {this.state.cityname}
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>State : </Form.Label> {this.state.statename}, {this.state.country}
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>Zip : </Form.Label> {this.state.zip}
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridTreeName">
                                        <Form.Label>Active Status :</Form.Label>
                                        {this.state.activestatus}
                                    </Form.Group>
                                    {/* <Form.Group as={Col} controlId="formGridTreeName">
                                         <Form.Label>Web Url : </Form.Label> {this.state.url}
                                     </Form.Group> */}
                                </Form.Row>
                                 
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose} >
                                Close
               </Button>
                            {/* <Button variant="primary" onClick={this.handleSaveChanges}>
                 Save Changes
               </Button> */}
                        </Modal.Footer>
                    </Modal>
                </div>
                <div style={{ height: 600 }}>
                    <SortableTree
                        treeData={this.state.treeData}
                        onChange={treeData => this.setState({ treeData })}
                        generateNodeProps={({ node, path }) => ({
                            buttons: [
                               <div className="title-container"> <a className="title" aria-label="Organization Name">
                                    {this.truncate(node)}
                                </a></div>,
                            //    <Link to="/roles" className= {this.state.userrole.create=='Y'?"btn btn-primary":"hidden"}></Link>
                                // <Link  onClick={() => this.handleAddNewItem(getNodeKey, node, path)}> Add Child </Link>,
                                // <Link  onClick={() => this.handleEditEvent(node)}> Edit  </Link>,
                                // <Link  onClick={() => this.showPreview(node, path)}> Preview </Link>,

                                <Button  variant="link" className= {this.state.userrole.create=='Y'?"add-link":"hidden"} onClick={() => this.handleAddNewItem(getNodeKey, node, path)} > Add Child</Button>,
                                <Button variant="link" className= {this.state.userrole.edit=='Y'?"edit-link":"hidden"} onClick={() => this.handleEditEvent(node)} > Edit </Button>,
                                <Button variant="link" onClick={() => this.showPreview(node, path)}> Preview </Button>
                            ]
                        })}
                    />
                </div>
            </div>
        );
    }
}