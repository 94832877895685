import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";

import { useCookies } from "react-cookie"; 
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from 'prop-types';
import Select from 'react-select';
 import { Link } from "react-router-dom";
 import cookie from 'react-cookies';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";
import axios from "axios";
import './Login.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Icon from '@material-ui/core/Icon';
import _ from "lodash";
const configData = require( './../../config.json');


const initialValues = {
    weight: '',
    height: '',
    date: '',
    user: {
        login: '',
        status: ''
    }
} 
class Login extends React.Component  {
    
        static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };
    
      
    constructor(...args) {
        super(...args)
       
        this.state = {
           // userRoleList:[],
            user: {
               login:undefined,
               unencryptedPassword:undefined,
               //userrole:undefined
            }
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();
        
    }
    componentDidMount(){
        this.setState({
            ...this.state,
            loggedusername: undefined 
            });
        localStorage.setItem("session",undefined);
        // window.location="/login";
        
    }
 
    handleChange = e => {
        let { value, name } = e.target;

         this.setState(prevState => ({
            user: {
                // object that we want to update
                ...prevState.user, // keep all other key-value pairs
                 [name]:value
            }
        })); 
        
    };
    
    handleLoginClick = (event) => {
        // const { login } = this.state;
        // localStorage.setItem('login', true);

        //window.location = '/Home';
        console.log(this.state.user);
        if( this.state.user.login ==undefined){
            toast.warn("Entered username ");
            event.preventDefault();        
        }
        else if( this.state.user.unencryptedPassword ==undefined){
            toast.warn("Entered password ");
            event.preventDefault();        
        }
        // else if( this.state.user.userrole ==undefined){
        //     toast.warn("Entered user role ");
        //     event.preventDefault();        
        // }
        else if( this.state.user.unencryptedPassword ==undefined){
            toast.warn("Entered password ");
            event.preventDefault();        
        }
        else { 
                axios
                .post(configData.SERVER_URL+`fincorp_validateUser`,
                
                this.state.user
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.CheckUserValidation(res.data,event)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
                event.preventDefault();
            
        }
    };

    CheckUserValidation=(result,event)=>{

         if(result.Status==-1){
            toast.warn(result.Message);

         }else{
            const { cookies } = this.props;
              
            var loginuser=result.Result[0];
            var lastlogindate =new Date(result.Result[0].lastLoginDate);
            var currentdate=new Date();
            var Difference_In_Time = currentdate.getTime() - lastlogindate.getTime();
           
            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

            if(Difference_In_Days>60){
               
                loginuser.status="Inactive";
                 
                axios
                .post(configData.SERVER_URL+`fincorp_updateUser`,
                
                loginuser
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    
                    res => this.UpdateChange(res.data,event)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
                event.preventDefault();
            }
            else{
  
            let sessionvalue=JSON.stringify({'userid':JSON.stringify(result.key),'sessionkey':(result.Result[0]._id),'orgid':result.Result[0].orgid});
           
            localStorage.setItem('userorgid',result.Result[0].orgid);
            cookies.set("session", sessionvalue , { path: "/" }); // setting the cookie
            result.loggedrole=this.state.user.userrole;
            localStorage.setItem('session',JSON.stringify(result));
            localStorage.setItem('primaryrolesession',result.Result[0].primaryrole.label);
            this.state.user._id=result;
            

            var userrole=[];
            _.forEach(result.Result[0].role, function(value) {
                
                 userrole.push({name:value.rolename.label});
              });

           // var userrole=[{name:'General User'},{name:'Manager'}];
            //check the user roles
            axios
            .post(configData.SERVER_URL+`fincorp_getRoleBasedOnName`,
            
            userrole
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res => this.CheckUserRoleValidation(res.data,event,result.Result[0])
                
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
            event.preventDefault();
        
            }
     
        
        }
    }
    UpdateChange=(result,event)=>{
          
        toast.warn("Account got disabled, Please contact administrator");
        
    }

    CheckUserRoleValidation=(result,event,userdetail)=>{
        
        if(result.Status==-1){
           toast.warn(result.Message);

        }else{
            
            //check the is first time login
            // if(userdetail.firstExpPeriodOption=="fpExpiryPeriodImmediately" && userdetail.isFirsttimeLogin==false){
            //     localStorage.setItem('rolelocation',JSON.stringify(result));
            //     localStorage.setItem('ispwdexpired',"1");
            //     window.location="/changepwd";
            // }
            // else
            {
                localStorage.setItem('rolelocation',JSON.stringify(result));
                this.setState({ show: true });
               // window.location="/home";
            }
        }
    }

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    handleClose = () => {
        this.setState({ show: false });
      }
    
      handleAccept = () => {
        window.location = '/home';
       // this.setState({ show: true });
      }
    
    
    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
       
    return (
        <>
               <Modal show={this.state.show} onHide={this.handleClose} size="xl">

<Modal.Body>

    <div className="container center">
<h3> {Labels.filter(lbl => lbl.keyPair=='LoginWarningTitleHeading' && lbl.pageName=='Login').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))} Terms of Service and Privacy Policy</h3>
<br/>
<br/>
<p><b>**WARNING**WARNING**WARNING**</b></p>
<ul>
<li>This warning banner provides privacy and security notices consistent with applicable federal laws, directives, and
other federal guidance for accessing this Government system, which includes (1) this computer network, (2) all computers
connected to this network, and (3) all devices and storage media attached to this network or to a computer on this
network.
</li>
<li>This system is provided for Government-authorized use only.</li>
<li>Unauthorized or improper use of this system is prohibited and may result in disciplinary action and/ or civil and criminal
penalties.
</li>
<li>Personal use of social media and networking sites on this system is limited as to not interfere with official work
duties and is subject to monitoring.</li>
<li>By using this system, you understand and consent to the following:</li>
<ul>
<li>The Government may monitor, record, and audit your system usage, including usage of personal devices and email systems
for official duties or to conduct HHS business. Therefore, you have no reasonable expectation of privacy regarding
any communication or data transiting or stored on this system. At any time, and for any lawful Government purpose,
the government may monitor, intercept, and search and seize any communication or data transiting or stored on this
system.
</li>
<li>Any communication or data transiting or stored on this system may be disclosed or used for any lawful Government purpose.</li>
</ul>
</ul>
<p><b>**WARNING**WARNING**WARNING**</b></p>

</div>
</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={this.handleClose}>
    Decline
  </Button>
  <Button variant="primary" onClick={this.handleAccept}>
    Accept
  </Button>
</Modal.Footer>
</Modal>

            <Tabs style={{width:450,marginLeft:'35%'}}>
                <TabList>
                    <Tab style={{width:'50%'}}>
                    {Labels.filter(lbl => lbl.keyPair=='Login_LoginTab' && lbl.pageName=='Login').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Tab>
                    <Tab style={{width:'50%'}}>
                    {Labels.filter(lbl => lbl.keyPair=='Login_SSTab' && lbl.pageName=='Login').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                    </Tab>                    
                </TabList>               
                      
                <TabPanel>
                <div className="container logininfo-container" role="main">
              
                <Form   >

                  <div className="centeralign">
                      <h1 >
                      {Labels.filter(lbl => lbl.keyPair=='Login_LoginTitle' && lbl.pageName=='Login').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}
                      </h1>
                  </div>
                  <Form.Row>
                      <Row className="margin-t10 col-md-12 margin-l5">

                          <Col md={{ span: 12 }} className="row ">
                              <Icon >person</Icon>
                              <Form.Label for="frmusername">
                              {Labels.filter(lbl => lbl.keyPair=='Login_UserName' && lbl.pageName=='Login').map(filteredPerson => (
                                <span>
                                {filteredPerson.keyValue}
                                </span>
                                ))} 
                              </Form.Label>{" "}
                              <Form.Control
                                  id="frmusername"
                                  type="text"
                                  name="login"
                                  placeholder="Name"
                                 value={this.state.user.login}
                                  onChange={this.handleChange}
                                  // onBlur={this.onBlur}
                                  required
                              />

                          </Col>
                      </Row>
                  </Form.Row>
                  <Form.Row>
                      <Row className="margin-t10 col-md-12 margin-l5">
                          {/* <Col md={6} className={this.state.isedit ? "hide" : " row"}> */}
                          <Col md={{ span: 12 }} className="row">
                             <Icon >lock</Icon>
                              <Form.Label for="frmpassword">
                              {Labels.filter(lbl => lbl.keyPair=='Login_Password' && lbl.pageName=='Login').map(filteredPerson => (
                                <span>
                                {filteredPerson.keyValue}
                                </span>
                                ))}
                              </Form.Label>{" "}
                              <Form.Control
                                  id="frmpassword"
                                  type="password"
                                  name="unencryptedPassword"
                                  placeholder="Password"
                                  value={this.state.user.unencryptedPassword}
                                  onChange={this.handleChange}
                                  required
                              />

                          </Col>
                      </Row>
                  </Form.Row>
                  <Form.Row>
                      <Row className="margin-t10 col-md-12 margin-l5">

                          <Col md={{ span: 12 }} className="row">

                              <Button
                                  // type="submit"
                                  variant="primary"
                                  className="full-width"
                                  block
                                  onClick={this.handleLoginClick}

                              >
                                  {Labels.filter(lbl => lbl.keyPair=='Login_LoginBtn' && lbl.pageName=='Login').map(filteredPerson => (
                                <span>
                                {filteredPerson.keyValue}
                                </span>
                                ))}
                      </Button>

                          </Col>
                      </Row>
                  </Form.Row>

              </Form>
          </div>
          
                </TabPanel>   
                <TabPanel>
                <div className="container logininfo-container" role="main">
                    <h2>
                    {Labels.filter(lbl => lbl.keyPair=='Login_SSTab' && lbl.pageName=='Login').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))} 
                    </h2>
                </div>
                </TabPanel>   
            </Tabs>
           
        </>
    );
    }
}

export default withCookies(Login);

