import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Button,
    Modal,
    Table,
    Form,
    Row,
    Col,
    Breadcrumb
} from "react-bootstrap";
// import toaster from "toasted-notes";
import _ from "lodash";
// import "toasted-notes/src/styles.css";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";

import * as moment from "moment";
import "moment/min/moment-with-locales";

import { DatePicker, DatePickerInput } from "rc-datepicker"; 
//const configData= require('config');
const configData = require( './../../config.json');
 

const initialValues = {
    weight: '',
    height: '',
    date: '',
    user: {
        login: '',
        status: ''
    } 
}
 
export default class OrgList extends React.Component  {

    constructor(props) {
        super(props);
        const querystring = queryString.parse(this.props.location.search);
         

        var data = [ 
        {
            active: true,          
            address1: null,
            address2: null,  
            cityName: null,                  
            city: null,           
            country: "",
            created: "",
            createdBy: null,            
            description: null,            
            endDate: null,            
            id: querystring.nextval!=undefined?parseInt(querystring.nextval)+1:1,
            label: "",  
            modified: "",
            modifiedBy: "admin",
            name: "",           
            parentId:querystring.parentId!=undefined? querystring.parentId:2,  
            startDate: "",
            state: null,            
            zipCode: null
        }            
        ];
 

        this.state = {
            treeData: data,
            org: data[0],
            parentName:'',
            savedisablestatus:false
        };

        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();
        this.fetchOrgDetailAPI();
        this.fetchCountryListAPI();

    }
    // componentDidMount() {
               
    //   }
    handleBackClick = () => {
        this.props.history.push("/orglist");
    }

    handleCancelClick = () => {
        this.props.history.push("/orglist");
    }
    handleInputChange = (event) => {
        const target = event.target;
        var value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
          
        if (target.id == "shortname") {

            this.setState(prevState => ({
                org: {
                    // object that we want to update
                    ...prevState.org, // keep all other key-value pairs
                    'label': value // update the value of specific key
                }
            }));
        }
        else{
            if(name=="country"){
                
                if(value=="US"){
                    this.fetchStateListAPI(value);
                }
                else{
                    this.setState(prevState => ({
                         'StateList':[],
                          'CityList':[],
                        org: {
                            // object that we want to update
                            ...prevState.org, // keep all other key-value pairs
                           
                            'state':'',
                           
                            'zipCode': '',
                            'city':'', // update the value of specific key
                            
                           
                        }
                    }));  
                }
            }
            else if(name=="state"){
                this.fetchCityListAPI(value);
                this.setState(prevState => ({
                    org: {
                        // object that we want to update
                        ...prevState.org, // keep all other key-value pairs
                        'zipCode': '',
                        'city':'', // update the value of specific key
                       
                    },
                    savedisablestatus:false
                })); 
            }
            else if(name=="city"){
                
                let obj = JSON.parse(event.target.selectedOptions[0].dataset.index);
                value=obj.city_pk;
                debugger;
                this.setState(prevState => ({
                    org: {
                        // object that we want to update
                        ...prevState.org, // keep all other key-value pairs
                        'zipCode': obj.zipcode,
                        'city':obj.city, // update the value of specific key
                        'cityName':obj.city
                    },
                    savedisablestatus:false
                })); 
            }
            else if(name=="zipCode"){

                
                var cityindex =_.findIndex(this.state.CityList, function(obj) {
                    return obj.zipcode === value;
                  });

                if(cityindex==-1){
                    this.setState(prevState => ({
                        savedisablestatus:true
                    })); 
                    toast.warn("Enter Valid Zip code");
                    event.preventDefault();
                }
                else{
                this.setState(prevState => ({
                    savedisablestatus:false
                })); 
                }
            }

            this.setState(prevState => ({
                org: {
                    // object that we want to update
                    ...prevState.org, // keep all other key-value pairs
                    [name]: value // update the value of specific key
                }
            }));


        }
         
    }

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }

    fetchOrgDetailAPI = () => {
        this.setState({...this.state, isFetching: true});
        const querystring = queryString.parse(this.props.location.search);        
        if(querystring.child!='1'){
            axios
            .post(configData.SERVER_URL+`fincorp_getOrgBasedOnId/`,
            
            {'_id': querystring.id}
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res=>  {this.setBindOrgDetail(res.data) }
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
        }
    };

    setBindOrgDetail=(result)=>{
         
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({org: result[0], isFetching: false});
            this.fetchStateListAPI();
            this.fetchOrganizationsAPI(result[0].parentId)
        }
        

    }

    fetchOrganizationsAPI = (param) => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getOrganization` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindParentOrgDetail(res.data,param) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindParentOrgDetail=(result,paramval)=>{
         
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            let filteredData = result.filter(value => {
               
                return    (value.id==parseInt(paramval))
            });
            
            this.setState({parentName: filteredData[0].name, isFetching: false});
             
        }
    }

    fetchCountryListAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCountryList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindCountryList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindCountryList=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({CountryList: result, isFetching: false});
            //this.fetchStateListAPI();
        }
        

    }

    fetchStateListAPI = (val) => {
        this.setState({...this.state, isFetching: true});
        axios
        .post(configData.SERVER_URL+`fincorp_getStates/`,
        
        {'country_pk': val}
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        
        .then(
            res=>  {this.setBindStateList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindStateList=(result)=>{
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            
            this.setState(prevState => ({
                'StateList':result,
                isFetching: false,
              org: {
                  // object that we want to update
                  ...prevState.org, // keep all other key-value pairs
                   
              }
          }));  
            if(this.state.org._id!=undefined){
                this.fetchCityListAPI();
            }
        }
        else{
            //this.setState({StateList: [], isFetching: false}); 
            this.setState(prevState => ({
                'StateList':[],
                isFetching: false,
              org: {
                  // object that we want to update
                  ...prevState.org, // keep all other key-value pairs
                  city:'',
                  zipCode:''
              }
          })); 
        }
        

    }

    fetchCityListAPI = (val) => {
        this.setState({...this.state, isFetching: true});
        var st_pk=val!=undefined?val:this.state.org.state;
        axios
        .post(configData.SERVER_URL+`fincorp_getCityList/`,
        {'state_pk':st_pk} 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindCityList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindCityList=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            
            this.setState(prevState => ({
                'CityList':result,
                isFetching: false,
              org: {
                  // object that we want to update
                  ...prevState.org, // keep all other key-value pairs
                  
              }
          }));  
        }
        else{
             
            this.setState(prevState => ({
                 
                 'CityList':[],
                 isFetching: false,
               org: {
                   // object that we want to update
                   ...prevState.org, // keep all other key-value pairs
                   'zipCode': '',
                   'city':'' // update the value of specific key
               }
           }));  
                
                
        }

    }
    handleSaveClick = (event) => {
        console.log(this.state.org);
        //event.preventDefault();
        
        if(this.state.org.name== undefined|| this.state.org.name==''){
           
            toast.warn("Enter Name");
            event.preventDefault();
        }
        else if (this.state.org.label == undefined || this.state.org.label == "") {
            toast.warn("Enter ShortName");
            event.preventDefault();
        }
        else if(this.state.org.status ==undefined || this.state.org.status == ""){
            toast.warn("Select Status");
            event.preventDefault();            
        }
        else if (this.state.org.startDate == undefined || this.state.org.startDate == "" || this.state.org.startDate == "Invalid date") {
            toast.warn("Enter StartDate");
            event.preventDefault();     
        }  
        else if (Date.parse(this.state.org.startDate) > Date.parse(this.state.org.endDate)) {            
            toast.warn("End Date should be greater than the StartDate");
            event.preventDefault();
        } 
        else if (this.state.org.address1 == undefined || this.state.org.address1 == "") {
            toast.warn("Enter Address1");
            event.preventDefault();
        }
        else if(this.state.org.country ==undefined || this.state.org.country =="" || this.state.org.country=='SELECT Country'){
            toast.warn("Select Country");
            event.preventDefault();            
        }
        else if(this.state.org.state ==undefined || this.state.org.state =="" || this.state.org.state=='SELECT State'){
            toast.warn("Select State");
            event.preventDefault();            
        }
        else if(this.state.org.city ==undefined || this.state.org.city =="" || this.state.org.city=='SELECT City'){
            toast.warn("Select City");
            event.preventDefault();            
        }
        else if (this.state.org.zipCode == undefined || this.state.org.zipCode == "") {
            toast.warn("Enter Zip Code");
            event.preventDefault();
        }

        else{
            //update existing org
            if(this.state.org._id!=undefined){
                if(window.confirm('Do you want to save changes?')){ 
                axios
                .post(configData.SERVER_URL+`fincorp_updateOrganization`,
                 
                this.state.org
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.UpdateChange(res.data,event)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
                event.preventDefault();
            }
            }
            else{
                debugger;
                //insert new Org
            axios
                    .post(configData.SERVER_URL+`fincorp_insertOrganization`,
                    
                    this.state.org
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                    .then(
                        res => this.SaveChange(res.data,event)
                        
                          
                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });
                    event.preventDefault();
            }
        }
        
    };

    SaveChange=(result,event)=>{
        if(result.MessageKey!=undefined){
            toast.warn(result.Message);
        }
        else{        
        toast.success("Org Inserted Successfully");      
        setTimeout( this.props.history.push("/orglist"),4000);
        }
        
    }
    UpdateChange=(result,event)=>{
        
        if(result.MessageKey!=undefined){
            toast.warn(result.Message);
        }
        else{
        toast.success("Org Updated Successfully");      
        setTimeout( this.props.history.push("/orglist"),4000);
        }
    }

    onStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            
            org: {
                // object that we want to update
                ...prevState.org, // keep all other key-value pairs
                startDate: moment(dateString).format("MM-DD-YYYY") // update the value of specific key
            }
                 
                
            
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
            org: {
                // object that we want to update
                ...prevState.org, // keep all other key-value pairs
                endDate: moment(dateString).format("MM-DD-YYYY") // update the value of specific key
            }
                
           
        }));
    };

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const savetype= this.state.org._id!=undefined?"Edit":"Add";
        const savedisablestatus=this.state.savedisablestatus;
        return (


            <div className="container rolesystemconfig-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/orglist">
                        {Labels.filter(lbl => lbl.keyPair=='OrgList_Title' && lbl.pageName=='OrgList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))} 
                    </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_Title' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                <span className="mainheadings">
                {Labels.filter(lbl => lbl.keyPair=='OrgSetup_Title' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {savetype} {filteredPerson.keyValue}
                        </span>
                    ))}                    
                </span>
                </div>                
                <div className="centeralign">
                <h4 className="mainheadings" >
                {Labels.filter(lbl => lbl.keyPair=='OrgSetup_SubTitle' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {this.state.parentName}
                        </span>
                    ))}
                </h4>
                </div>
                
                <Form >
                    <Form.Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">                                
                                <Col md={4}>
                                    <Form.Label className="required" for='Org Name'>
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_Name' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control id='Org Name' type="text" name="name" placeholder="Name" value={this.state.org.name}  onChange={this.handleInputChange} disabled={this.state.org.name !='Default Origin' &&  this.state.org._id!=undefined?true:false}/>
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for='shortname'>
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_ShortName' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        type="text"
                                        id="shortname"
                                        name="shortname"
                                        placeholder="Short Name"
                                        onChange={this.handleInputChange}
                                        value={this.state.org.label}
                                        // disabled={this.state.org._id!=undefined?true:false}
                                    />
                                </Col>  
                            </Col>
                        </Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for='OrgDesc'>
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_Desc' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="OrgDesc"
                                        type="text"
                                        name="description"
                                        placeholder="Description"
                                        onChange={this.handleInputChange}
                                        value={this.state.org.description}
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for='status'>
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_Status' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    
                                      <Form.Control
                                            as="select"
                                            id="status"
                                            controlId="status"
                                            name="status"
                                            value={this.state.org.status}
                                            onChange={this.handleInputChange}
                                            required
                                            >
                                            <option value="">Select Status</option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                            </Form.Control>
                                </Col>
                            </Col>
                        </Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for='startDate'>
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_StartDate' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                     
                                    <DatePickerInput
                                            id="startDate"
                                            onChange={this.onStartDateChange.bind(this, 'startDate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.org.startDate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            required
                                            readOnly
                                        />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for='endDate'>
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_EndDate' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                <DatePickerInput
                                            id="endDate"
                                            onChange={this.onEndDateChange.bind(this, 'endDate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.org.endDate}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            required
                                            readOnly
                                        />
                                </Col>
                            </Col>
                        </Row>

                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label className="required" for='address'>
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_Address1' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id='address'
                                        type="text"
                                        name="address1"
                                        placeholder="Address1"
                                        onChange={this.handleInputChange}
                                        value={this.state.org.address1}
                                    />
                                </Col>
                            </Col>
                            <Col md={6} className="row">
                                <Col md={4}>
                                    <Form.Label for='address2'>
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_Address2' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="address2"
                                        type="text"
                                        name="address2"
                                        placeholder="Address2"
                                        onChange={this.handleInputChange}
                                        value={this.state.org.address2}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                            <Col md={4}>
                           
                                    <Form.Label className="required" for="country">
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_Country' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))} 
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        as="select"
                                        id="country"
                                        className="form-control"
                                        controlId="country"
                                        name="country"                                       
                                        onChange={this.handleInputChange}
                                        value={this.state.org.country}                                        
                                        placeholder="Select Country"                                       
                                        required
                                    >
                                         {this.state.CountryList &&
                                            this.state.CountryList.length > 0 &&
                                            this.state.CountryList.map(country => {
                                                return <option value={country.code}> {country.Countryname}</option>;
                                            })}
                                      
                                    </Form.Control>
                                </Col>                                
                            </Col>
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label id ="statelabel" className="required">
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_State' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>
                                 <Form.Control
                                        as="select"
                                        id="formstate"
                                        controlId="state"
                                        name="state"
                                        onChange={this.handleInputChange}
                                        value={this.state.org.state}
                                        aria-labelledby="statelabel"
                                        // aria-label="State"
                                        // value={state.formstate}
                                        // onChange={handleChange}
                                        required
                                    
                                    >
                                         {this.state.StateList &&
                                            this.state.StateList.length > 0 &&
                                            this.state.StateList.map(stat => {
                                                return <option value={stat.state}> {stat.state_name}</option>;
                                            })}
                                       
                                         
                                    </Form.Control>
                                </Col>                                
                            </Col>
                        </Row>
                        <Row className="margin-t10 col-md-12">
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for='frmcity'>
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_City' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                                    </Form.Label>{" "}
                                </Col>
                                <Col md={8}>                                   
                                    <Form.Control
                                        id="frmcity"
                                        as="select"
                                        name="city"
                                        placeholder="Select City"
                                        onChange={this.handleInputChange}
                                        value={this.state.org.city}
                                        required
                                    >
                                        {this.state.CityList &&
                                            this.state.CityList.length > 0 &&
                                            this.state.CityList.map((cty,index) => {
                                                return <option data-index={JSON.stringify(cty)} value={cty.city_pk}> {cty.city}  {cty.zipcode}</option>;
                                            })}                                          

                                    </Form.Control>
                                </Col>                                                       
                            </Col>
                            <Col md={6} className="row">
                            <Col md={4}>
                                    <Form.Label className="required" for='zip'>
                                    {Labels.filter(lbl => lbl.keyPair=='OrgSetup_Zip' && lbl.pageName=='OrgSetup').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}  
                                    </Form.Label>
                                </Col>
                                <Col md={8}>
                                    <Form.Control
                                        id="zip"
                                        type="text"
                                        name="zipCode"
                                        placeholder="ZipCode"
                                        onChange={this.handleInputChange}
                                        value={this.state.org.zipCode}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        
                    </Form.Row>
                </Form>
                <br/>
                <div className="float-right"> 
                    <Button type="submit" id="btnorgsave" variant="primary" disabled={savedisablestatus} onClick={this.handleSaveClick} aria-label='Save'>   
                        Save
                     </Button>
                     <Button  variant="secondary" className="ml-10" onClick={this.handleCancelClick} aria-label='Cancel'>
                        Cancel
                    </Button>                   
                    
                </div>
            </div>
        );

    }
}
