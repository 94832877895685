import React, { Component } from 'react';
import { BrowserRouter, Route, Switch,Redirect   } from 'react-router-dom';
import { Link } from "react-router-dom";
import Home from '../Home/Home';
import Roles from '../Roles/Roles';
import RoleList from '../Roles/RoleList';
import Org from '../Org/Org';
import OrgList from '../Org/OrgList';
import User from '../User/User';
import UserList from '../User/UserList';
import ProcessedFilesList from '../ProcessedFiles/ProcessedFilesList';
import Search from '../Search/Search';
import Login from '../Login/Login';
import ChangePassword from '../ChangePassword/ChangePassword';
import ForgetPassword from '../ForgetPassword/ForgetPassword';
import ServicesHome from '../Services/ServicesHome';
import MasterLookupItems from '../MasterLookup/MasterLookupItems';
import MasterLookupValues from '../MasterLookup/MasterLookupValues';
import ApprovalLimits from '../ApprovalLimits/ApprovalLimits';
import ApprovalLimitsList from '../ApprovalLimits/ApprovalLimitsList';
import EmailDistributionList from '../EmailDistributionList/EmailDistributionList';
import EmailGroupInfo from '../EmailDistributionList/EmailGroupInfo';
// import LandingPage from '../../LandingPage';
import Customize from '../Customize/Customize';
import Scheduler from '../Scheduler/Scheduler';
import AdminList from '../Admin/AdminList';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import { DropdownButton,Dropdown,DropdownToggle } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CookiesProvider } from "react-cookie";
import logo from './logo.svg';
import Icon from '@material-ui/core/Icon';
import './App.css';
import Dashboard from '../Dashboard/Dashboard';
import Reports from '../Report/Report';
import Sample from '../Sample/Sample';
import FileUpload from '../FileUpload/FileUpload';
import LookupValuesList from '../LookupValue/LookupValuesList';
import LookupValue from '../LookupValue/LookupValue';
import ExecutablesHome from '../Executables/ExecutablesHome';
import FileViewer from '../FileViewer/FileViewer';
import FileTypeLoader from '../FileTypeManager/FileTypeLoader';
import FileType from '../FileType/FileType';
import ExecutableTrackerList from '../ExecutableTracker/ExecutableTrackerList';





//const configData= require('config');
const configData = require( './../../config.json');

export default class App extends React.Component  {
    constructor(...args) {
        super(...args)
        this.state = {
            title : 'Hello world',
            loggedusername:undefined,
            userRoleList:[],
            showloginuserdropdownmenu:false
         };
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();
         

    }
     componentDidMount() {  
         
          if((localStorage.getItem("session")==undefined || localStorage.getItem("session")=="undefined") && window.location.pathname != '/login'){
           // this.props.history.push("/login");
           window.location = '/login';
        }
        // else if(localStorage.getItem("ispwdexpired")!=undefined && localStorage.getItem("ispwdexpired")=="1" && window.location.pathname != '/changepwd' && window.location.pathname != '/login'){
        //     window.location = '/changepwd';
        // }
        else{
            var sessionval= undefined;
            var roleval=undefined;
             
            if(localStorage.getItem("session")!="undefined" && localStorage.getItem("session")!=undefined && localStorage.getItem("session")!=null){
                sessionval= JSON.parse(localStorage.getItem("session")).Result[0].login;
                
                roleval= localStorage.getItem("primaryrolesession");

                this.setState({
                    ...this.state,
                    loggedusername: sessionval,
                   loggeduserrole:roleval,
                   userRoleList:JSON.parse(localStorage.getItem("session")).Result[0].role 
                    });
                }
                if(window.location.pathname == '/')
                {
                    window.location = '/home';
                }
                 

            }
        
       
        
    }

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    onSettingsClick=()=>{
        window.location = '/home';
        
        
    }
    onLogoutClick=()=>{        
        this.setState({
            ...this.state,
            loggedusername: undefined 
            });
        localStorage.setItem("session",undefined);
        localStorage.setItem("rolelocation",undefined);
        window.location="/login";
        //this.props.history.push("/login");

    }
    onSetRoleClick=(row)=>{
        
        var rolename=row.target.value;
        localStorage.setItem('primaryrolesession',rolename);
        this.setState({
            ...this.state,
            loggeduserrole:rolename  
            });
            window.location.reload(false);
    }
    onLoginuserDropdownmenu=()=>{
        this.setState({
            ...this.state,
            showloginuserdropdownmenu:!this.state.showloginuserdropdownmenu  
            });
    }
    onOptionSelect=(event)=>{
        
        console.log(event);
        this.setState({
            ...this.state,
            showloginuserdropdownmenu:!this.state.showloginuserdropdownmenu  
            });
            window.location="/changepwd";
    }

    handleBtnSubmit = (param) => {
        // change(state);
        //setState(initialValues);
        
        if (param.currentTarget.id == "user-btn")
            window.location = '/userlist';
        else if (param.currentTarget.id == "role-btn")
            window.location = '/rolelist';
            else if (param.currentTarget.id == "admin-btn")
            window.location = '/adminlist';
        else if (param.currentTarget.id == "processedfiles-btn")
            window.location = '/processedfileslist';
        else if (param.currentTarget.id == "org-btn")
            window.location = '/orglist';
        else if (param.currentTarget.id == "services-btn")
            window.location = '/serviceshome';
        else if (param.currentTarget.id == "masterlookup-btn")
            window.location = '/masterlookupitems';
        else if (param.currentTarget.id == "approvallimits-btn")
            window.location = '/approvallimitslist';
        else if (param.currentTarget.id == "emaildistributionlist-btn")
            window.location = '/emaildistributionlist';
        else if (param.currentTarget.id == "search-btn")
            window.location = '/search';
        else if (param.currentTarget.id == "scheduler-btn")
            window.location = '/scheduler';
        else if (param.currentTarget.id == "customize-btn")
            window.location = '/customize';
        else if (param.currentTarget.id == "fileupload-btn")
            window.location = '/fileupload';
        else if (param.currentTarget.id == "lookupvalue-btn")
            window.location = '/lookupvalueslist';
        else if (param.currentTarget.id == "executables-btn")
            window.location = '/executableshome';
        else if (param.currentTarget.id == "fileviewer-btn")
            window.location = '/fileviewer';   
            else if (param.currentTarget.id == "executabletracker-btn")
            window.location = '/executabletrackerlist';
        
        // else if (param.currentTarget.id == "dashboard-btn")
        //     window.location = '/dashboard';
        // else if (param.currentTarget.id == "report-btn")
                //     window.location = '/reports';       
    };
    handleSubmit = () => {
        // change(state);
        //setState(initialValues);
        alert("Work in Progress..");
    };

     handleReportSubmit = () => {
        window.open(configData.BREVO_URL, "_blank");
    };
    
    handlePdiSubmit = () => {
        window.open(configData.PENTAHO_URL, "_blank");
    };

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const optionsRoleArray =this.state.userRoleList!=null&& this.state.userRoleList!=undefined? this.state.userRoleList:[];
         
    return (
        <BrowserRouter>
            <div className='fin-corp'>
            <div className="topnav">
            <div className='row '>
                  <div className='col-md-3 float-left'><span className='logo'><Link to="/Home"><img src={'logo.jpg'} className='logo-img' aria-label="logo"/></Link></span> </div>
                  <div className='col-md-6 text-center mid-title header'> 
                    {Labels.filter(lbl => lbl.keyPair=='App_Title' && lbl.pageName=='AppMainPage').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                   </div> 
                   
                   <div className='col-md-3 float-right'>
                   <div className="col-md-1 float-left" style={{marginLeft: 20, marginTop: 4}}>
            <Clock size={75} timeFormat="12hour" hourFormat="standard" />
          </div>
          <div  className="col-md-9 float-right">

          
                       <div className={this.state.loggedusername==undefined?"invisible":"float-right top-login-info"} >
                       <div className='full-width loggedusercontainer'> <span  onClick={this.onLoginuserDropdownmenu.bind(this)}>{this.state.loggedusername} <span class="material-icons" onClick={this.onLoginuserDropdownmenu.bind(this)}>
keyboard_arrow_down
</span></span>
                       <ul id="loginuserdropdownmenu" className={this.state.showloginuserdropdownmenu?"show":"hidden"} title={this.state.loggedusername} value={this.state.loggedusername}>                       
                        <li onClick={this.onSettingsClick.bind(this)}>Settings</li>
                        <li onClick={this.onOptionSelect.bind(this)}><Link to="/changepwd" > Change Password</Link></li>
                        <li onClick={this.onLogoutClick.bind(this)}>Logout</li>
                        </ul>
                       </div>
                       <div className='full-width  loggedrolecontainer'>
                           <div className='loggedrolelabel'><span>Logged in as </span></div> 
                           <select id="dropdown-role-button" onChange={this.onSetRoleClick.bind(this)} title={this.state.loggeduserrole} value={this.state.loggeduserrole}>  
                           {optionsRoleArray.map(opt => (  
                                  <option onClick={this.onSetRoleClick.bind(this)}>{opt.rolename.label}</option>
                           ))}           
                        
                        </select>
                        </div>
                       </div>
                           </div> 
                   </div>
                   <ToastContainer />  
                   
            </div>
            </div>
            <div className="sidenavr"></div>
           
            {/* <div className="sidenavl"> <img src={'navbarl.png'} aria-label="navbar"/></div> */}
            <div className="sidenavl"></div>         
            {/* <div className="sidenavl1">
            <img id="user-btn" title="User"  onClick={this.handleBtnSubmit.bind('user')} src={'images/home/User.png'} aria-label='User'></img><br /> 
            <img id="role-btn" title="Roles" onClick={this.handleBtnSubmit.bind('roles')} src={'images/home/Roles.png'}  aria-label='Roles'></img><br />
            <img id="admin-btn" title="Admin"  onClick={this.handleBtnSubmit.bind('admin')} src={'images/home/Admin.png'}  aria-label='Master Data Management'></img><br />
            <img id="dashboard-btn" title="Dashboard" onClick={this.handleBtnSubmit.bind('dashboard')} src={'images/home/Dashboard.png'}  aria-label='Dashboard'></img><br /> 
            <img id="fileprocess-btn" title="File Processing" onClick={this.handleReportSubmit} src={'images/home/FileProcessing.png'}  aria-label='File Processing'></img><br /> 
            <img id="func-btn"  title="Functional Manager" onClick={this.handleSubmit} src={'images/home/FunctionalManager.png'}  aria-label='Functional Manager'></img><br />
            <img id="processedfiles-btn"  title="Processed Files" onClick={this.handleBtnSubmit.bind('processedfileslist')} src={'images/home/FileProcessed.png'}  aria-label='Processed Files'></img><br />
            <img id="org-btn" title="Organization" onClick={this.handleBtnSubmit.bind('orglist')} src={'images/home/OrganizationTree.png'}  aria-label='Organization'></img><br />
            <img  id="services-btn" title="Services" onClick={this.handleBtnSubmit.bind('services')} src={'images/home/services.png'}  aria-label='Services'></img><br />
            <img id="masterlookup-btn" title="Master Detail Lookup" onClick={this.handleBtnSubmit.bind('approvallimitslist')} src={'images/home/Masterlookup.png'}  aria-label='Master Detail Lookup'></img><br />
            <img id="approvallimits-btn" title="Approval Limits" onClick={this.handleBtnSubmit.bind('approvallimitslist')} src={'images/home/Approvallimits.png'}  aria-label='Approval Limits'></img><br /> 
            <img id="emaildistributionlist-btn" title="Email Distribution" onClick={this.handleBtnSubmit.bind('emaildistributionlist')} src={'images/home/Emaildistribution.png'}  aria-label='Email Distribution'></img><br />
            <img id="search-btn" title="Search" onClick={this.handleBtnSubmit.bind('search')} src={'images/home/Search.png'}  aria-label='Search'></img><br />
            <img id="report-btn" title="Reports" onClick={this.handleBtnSubmit.bind('report')} src={'images/home/Reports.png'}  aria-label='Reports'></img><br />
            <img id="scheduler-btn" title="Calander" onClick={this.handleBtnSubmit.bind('scheduler')} src={'images/home/OrgCalendar.png'}  aria-label='Calendar'></img><br />
            <img id="customize-btn" title="Customize" onClick={this.handleBtnSubmit.bind('customize')} src={'images/home/Customize.png'}  aria-label='Custamize Labels'></img><br />  
            <img id="pdi-btn" title="Data Integration" onClick={this.handlePdiSubmit} src={'images/home/Dataintegration.png'}  aria-label='Data Integration'></img><br />             
            </div> */}
                      
            {/* <div className="bddrt"></div>  */}
            <div className="content-container">               

                <Switch>
                    <Route path="/" component={Login} exact />                   
                    <Route path="/home" component={Home} />
                    <Route path="/org" component={Org} />
                    <Route path="/orglist" component={OrgList} />
                    <Route path="/roles" component={Roles} />
                    <Route path="/rolelist" component={RoleList} />
                    <Route path="/user" component={User} />
                    <Route path="/userlist" component={UserList} />
                    <Route path="/processedfileslist" component={ProcessedFilesList} />
                    <Route path="/search" component={Search} />
                    <Route path="/login" component={Login} />
                    <Route path="/serviceshome" component={ServicesHome} />                    
                    <Route path="/masterlookupitems" component={MasterLookupItems} />
                    <Route path="/masterlookupvalues" component={MasterLookupValues} />
                    <Route path="/approvallimitslist" component={ApprovalLimitsList} />
                    <Route path="/approvallimits" component={ApprovalLimits} />
                    <Route path="/emaildistributionlist" component={EmailDistributionList} />
                    <Route path="/emailgroupinfo" component={EmailGroupInfo} />
                    <Route path="/customize" component={Customize} />
                    <Route path="/scheduler" component={Scheduler} />   
                    <Route path="/changepwd" component={ChangePassword} />    
                    <Route path="/forgetpwd" component={ForgetPassword} /> 
                    <Route path="/adminlist" component={AdminList} />    
                    <Route path="/dashboard" component={Dashboard} />  
                    <Route path="/reports" component={Reports} /> 
                    <Route path="/sample" component={Sample} />   
                    <Route path="/fileupload" component={FileUpload} /> 
                    <Route path="/lookupvalueslist" component={LookupValuesList} />
                    <Route path="/lookupvalue" component={LookupValue} />
                    <Route path="/executableshome" component={ExecutablesHome} />                     
                    <Route path="/fileviewer" component={FileViewer} /> 
                    <Route path="/filetypeloader" component={FileTypeLoader}/>
                    <Route path="/filetype" component={FileType}/>
                    <Route path="/executabletrackerlist" component={ExecutableTrackerList} /> 
                   
                   
                    {/* <Route path="/index" component={LandingPage} />   */}
                </Switch>
                </div>
               
                <div className="bottomnavb"></div>
                <div className="bddrb"></div>
            </div>
        </BrowserRouter>
    );
}

}

class Clock extends React.Component {
    constructor(props) {
       super(props);
 
       this.state = { time: new Date() };
       this.radius = this.props.size / 2;
       this.drawingContext = null;
       this.draw24hour = this.props.timeFormat.toLowerCase().trim() === "24hour";
       this.drawRoman = !this.draw24hour && this.props.hourFormat.toLowerCase().trim() === "roman";
 
    }
 
    componentDidMount() {
       this.getDrawingContext();
       this.timerId = setInterval(() => this.tick(), 1000);
    }
 
    componentWillUnmount() {
       clearInterval(this.timerId);
    }
 
    getDrawingContext() {
       this.drawingContext = this.refs.clockCanvas.getContext('2d');
       this.drawingContext.translate(this.radius, this.radius);
       this.radius *= 0.9;
    }
 
    tick() {
       this.setState({ time: new Date() });
       const radius = this.radius;
       let ctx = this.drawingContext;
       this.drawFace(ctx, radius);
       this.drawNumbers(ctx, radius);
       this.drawTicks(ctx, radius);
       this.drawTime(ctx, radius);
    }
 
    drawFace(ctx, radius) {
       ctx.beginPath();
       ctx.arc(0,0, radius, 0, 2 * Math.PI);
       ctx.fillStyle = "white";
       ctx.fill();
 
       const grad = ctx.createRadialGradient(0, 0, radius * 0.95, 0, 0, radius * 1.05);
       grad.addColorStop(0, "#333");
       grad.addColorStop(0.5, "white");
       grad.addColorStop(1, "#333");
       ctx.strokeStyle = grad;
       ctx.lineWidth = radius * 0.1;
       ctx.stroke();
 
       ctx.beginPath();
       ctx.arc(0, 0, radius * 0.05, 0, 2 * Math.PI);
       ctx.fillStyle = "#333";
       ctx.fill();
    }
 
    drawNumbers(ctx, radius) {
       const romans = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"];
       const fontBig = radius * 0.25 + "px Arial";
       const fontSmall = radius * 0.075 + "px Arial";
       let ang, num;
 
       ctx.textBaseline = "middle";
       ctx.textAlign = "center";
       for (num = 1; num < 13; num++) {
          ang = num * Math.PI / 6;
          ctx.rotate(ang);
          ctx.translate(0, -radius * 0.78);
          ctx.rotate(-ang);
          ctx.font = fontBig;
          ctx.fillStyle = "black";
          ctx.fillText(this.drawRoman ? romans[num-1] : num.toString(), 0, 0);
          ctx.rotate(ang);
          ctx.translate(0, radius * 0.78);
          ctx.rotate(-ang);
 
          // Draw inner numerals for 24 hour time format
          if (this.draw24hour) {
             ctx.rotate(ang);
             ctx.translate(0, -radius * 0.60);
             ctx.rotate(-ang);
             ctx.font = fontSmall;
             ctx.fillStyle = "red";
             ctx.fillText((num + 12).toString(), 0, 0);
             ctx.rotate(ang);
             ctx.translate(0, radius * 0.60);
             ctx.rotate(-ang);
          }
       }
 
       // Write author text
       ctx.font = fontSmall;
       ctx.fillStyle = "#3D3B3D";
       ctx.translate(0, radius * 0.30);
       ctx.fillText("@AlanMunsonTech", 0, 0);
       ctx.translate(0, -radius * 0.30);
    }
 
    drawTicks(ctx, radius) {
       let numTicks, tickAng, tickX, tickY;
 
       for (numTicks = 0; numTicks < 60; numTicks++) {
 
          tickAng = (numTicks * Math.PI / 30);
          tickX = radius * Math.sin(tickAng);
          tickY = -radius * Math.cos(tickAng);
 
          ctx.beginPath();
          ctx.lineWidth = radius * 0.010;
          ctx.moveTo(tickX, tickY);
          if (numTicks % 5 === 0) {
             ctx.lineTo(tickX * 0.88, tickY * 0.88);
          } else {
             ctx.lineTo(tickX * 0.92, tickY * 0.92);
          }
          ctx.stroke();
       }
    }
 
    drawTime(ctx, radius) {
       const now = this.state.time;
       let hour = now.getHours();
       let minute = now.getMinutes();
       let second = now.getSeconds();
 
       // hour
       hour %= 12;
       hour = (hour * Math.PI / 6) + (minute * Math.PI / (6 * 60)) + (second * Math.PI / (360 * 60));
       this.drawHand(ctx, hour, radius * 0.5, radius * 0.05);
       // minute
       minute = (minute * Math.PI / 30) + (second * Math.PI / (30 * 60));
       this.drawHand(ctx, minute, radius * 0.8, radius * 0.05);
       // second
       second = (second * Math.PI / 30);
       this.drawHand(ctx, second, radius * 0.9, radius * 0.02, "red");
    }
 
    drawHand(ctx, position, length, width, color) {
       color = color || "black";
       ctx.beginPath();
       ctx.lineWidth = width;
       ctx.lineCap = "round";
       ctx.fillStyle = color;
       ctx.strokeStyle = color;
       ctx.moveTo(0, 0);
       ctx.rotate(position);
       ctx.lineTo(0, -length);
       ctx.stroke();
       ctx.rotate(-position);
    }
 
    render() {
       return (
          <div className="Clock" style={{ width: String(this.props.size) + 'px' }}>
             <canvas width={this.props.size} height={this.props.size} aria-label='Clock' ref="clockCanvas"/>
          </div>
       );
    }
 }
 
//  Clock.defaultProps = {
//        size: 400, // size in pixels => size is length & width
//        timeFormat: "24hour", // {standard | 24hour} => if '24hour', hourFormat must be 'standard'
//        hourFormat: "standard" // {standard | roman}
//  };
 
//  Clock.propTypes = {
//     size: React.PropTypes.number,
//     timeFormat: React.PropTypes.string,
//     hourFormat: React.PropTypes.string
//  };
