import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import * as moment from "moment";
import "moment/min/moment-with-locales";
import axios from "axios";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 

//const configData= require('config');
const configData = require( './../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: '',
    searchInput: "",
    chkisactive:true,
    processedfiles: [{
        sno: '100',
        filename: 'test1',
        lastName: '32',
        processeddate: '12-20-2020',
        orgname: 'vissat',
        processedby: 'xxx'
        

    }],
    filetype: ['xls', 'pdf']
}
export default class ProcessedFilesList extends React.Component  {

    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
             processedfileslist: [],
             columns: [],
             originalprocessedfileslist:[],
             searchInput: "",
             chkisactive:false
        }
        this.setState({Labels:[], isFetching: true});
        // this.fetchLabelMessagesAPI();

    }
    componentDidMount() {     
       
        this.getColumns();

        this.getProcessfilelist();
    }

    getColumns = () => {
        let columns = [
        //   {
        //     Header: "Select",
        //     accessor: "selected",
        //     sortable: true,
        //     show: true,
        //     displayValue: "select" ,
        //     maxWidth: 100,
        //     minWidth: 56,
        //     width: 56
        //   },
        //   {
        //     Header: "#",
             
        //     sortable: true,
        //     show: true,
        //     displayValue: "#",
        //     maxWidth: 100,
        //     minWidth: 56,
        //     width: 56 
        //   },
          {
            Header: "File Name",
            accessor: "filename",
            sortable: true,
            show: true,
            displayValue: "File Name"
          },
          {
            Header: "Processed Date",
            accessor: "createdt",
            sortable: true,
            show: true,
            displayValue: "processeddate",            
          },
          {
            Header: "Organization",
            accessor: "orgname",
            sortable: true,
            show: true,
            displayValue: "Organization",
                     
          },          
          {
            Header: "Processed By",
            accessor: "createby",
            sortable: true,
            show: true,
            displayValue: "Status"
                      
          },
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "Status"
                      
          },
        //   {
        //     Header:"View",
        //     maxWidth: 100,
        //     minWidth: 54,
        //     width: 54,
        //     Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
        //         className="editbutton"
        //         aria-label="View"
        //         onClick={(event) => this.handleViewClick(row,event)}
        //     >
        //         <i className="">
        //             <img
        //                 alt="View"
        //                 className=""
        //                 src="/images/view_icon.png"
        //             />
        //         </i>
        //     </a></div></div>)
        //   }
        ];
        this.setState({ columns });
      };

     getProcessfilelist(){
        
        axios
        .get(configData.SERVER_URL+`fincorp_getFileUploadDetailsList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindFile(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
     } 
     setBindFile(result){
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({processedfileslist: result});
        }
     }

     handleChange = e => {
        let { value, name } = e.target;
       
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.componentDidCatchstate,
            [name]: value,
            date
        });
    };
     handleClick = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

     handleEditClick = (row) => {
        this.props.history.push("/ProcessedFile?id=" + row.id);
    };

     viewImgFormatter = (cell, row) => {              
        return (
            <a
                className="viewbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="View"
                        className=""
                        src="/images/vieworange_icon.png"
                    />
                </i>
            </a>
        );
    }    

   
     handleSelectClick=(row)=>{

    }

     selectImgFormatter = (cell, row) => {              
        return (
            <input type="checkbox"
                className="checkbutton"
                // onClick={() => handleSelectClick(row)}
            >
                 
            </input>
        );
    }

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    render() {
   
    return (
        <>
            <div className="container userinfo-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Files Processed</Breadcrumb.Item>
                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">Files Processed</span>
                <ToastContainer />
                </div>

                <div className="fullwidth">
                        <div className="float-left">
                        <div className="btn btn-primary">File Count:  / Year:   </div>    
                        </div>                   
                        {/* <div className="float-right">
                        <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            aria-label='status'
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">
                        <span className="searchlabel">
                        Search
                        </span>
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"
                            placeholder=""
                            // value={searchInput || ""}
                            // onChange={this.handleChange1}
                        />
                        </div>
                        </div>                     */}
                    </div>  

                    <div className="fullwidth mt-50">                          
                        <div className="row col-md-12 m0 p0">
                        <ReactTable
                        data={this.state.processedfileslist}
                        columns={this.state.columns}
                        defaultPageSize={20}
                        className="-striped -highlight fullwidth"
                        /> 
                        
                        </div>
                        <br />
                    </div> 
                <Row>
                    <Col md={3} >                       
                        <Form.Control
                            as="select"
                            id="formProcessedFiles"
                            controlId="processedfiles"
                            name="processedfiles"
                            // value={this.state.groupMember.status}
                            // onChange={this.handleGroupMemberChange}                                           
                            required
                            >
                            <option>Select Format</option>
                            <option value="pdf">PDF</option>
                            <option value="xls">XLS</option>
                            <option value="csv">CSV</option>                            
                            <option value="tsv">TSV</option>
                            <option value="xml">Open XML</option>
                        </Form.Control>
                    </Col>
                    <Col md={3} >
                        <Button>
                            Download
                    </Button>
                    </Col>
                </Row>               
                <br />
               
            </div>



        </>
    );
}
}

