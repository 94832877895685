import React, { useState } from 'react';
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import axios from "axios";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Select from 'react-select';
import * as moment from "moment";
import { browserHistory } from "react-router";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";
import "moment/min/moment-with-locales";
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css"; 
import _ from "lodash";

//const configData= require('config');
const configData = require( './../../config.json');

const initialValues = {
    weight: '',
    height: '',
    date: ''
}

export default class ApprovalLimitsList extends React.Component  {
    constructor(...args) {
        super(...args)

        this.state = {
            show: false,
            originalApprovalLimits:[],
            columns:[],
            searchInput: "",
            approvallimits: [],
            chkisactive:true,
            userrole:{create:''}
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

    }
     componentDidMount() {
     
       this.fetchApprovalLimitsListAPI();
       this.getColumns();
    }
    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'ApprovalLimits';
        });
        let columns = [
          {
            Header: "Org Name",
            accessor: "org_name",
            sortable: true,
            show: true,
            displayValue: "Org Name"
            
          },
          {
            Header: "Role Name",
            accessor: "role_name",
            sortable: true,
            show: true,
            displayValue: "Role Name ",
            Cell: (row)=> (<div className="grdrolebuttoncontainer"> 
             {row.original.role_name.label}
          </div>)
          },
                 
          {
            Header: "Activity Name",
            accessor: "activity_name",
            sortable: true,
            show: true,
            displayValue: " Activity Name "
          },
          {
            Header: "Activity Level",
            accessor: "activitylevel",
            sortable: true,
            show: true,
            displayValue: " Activity Level ",
            Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>  
          },
          {
            Header: "Reporting To",
            accessor: "predecessor_reporting_to",
            sortable: true,
            show: true,
            displayValue: "predecessor_reporting_to "
          },
          {
            Header: "Limit From $",
            accessor: "al_from",
            sortable: true,
            show: true,
            displayValue: "al_from",
            Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>  
            
          },
          {
            Header: "Limit To $",
            accessor: "al_to",
            sortable: true,
            show: true,
            displayValue: "al_to ",
            Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>  
          },
          {
            Header: "Start Date",
            accessor: "startdate",
            sortable: true,
            show: true,
            displayValue: "startdate",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>  
          },
          {
            Header: "End Date",
            accessor: "enddate",
            sortable: true,
            show: true,
            displayValue: "enddate",
            maxWidth: 100,
            minWidth: 90,
            width: 90,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>  
          },
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "Status",
            maxWidth: 100,
            minWidth: 56,
            width: 56,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>  
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          }, 
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
        ];
        this.setState({ columns });
      };
   

    fetchApprovalLimitsListAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getApprovalLimitsList` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setApprovalLimitsList(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setApprovalLimitsList=(result)=>{ 
        
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'ApprovalLimits';
        });

        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){            
            this.setState({approvallimits: result,originalApprovalLimits:result,userrole: userrl, isFetching: false});
            this.globalSearch();
        }
        else
         this.setState({...this.state,userrole: userrl, isFetching: false})      

    }   
     handleChange = e => {
        let { value, name } = e.target;
        
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date

        });
        if(name=='search')
        {
            this.refs.table.handleFilterData({ name: value });
            // this.refs.table.handleFilterData({value});
        }
        else if(name=='status' && value=='Inactive'){
            const currentdate = new Date();
            currentdate.setDate(currentdate.getDate()-1);
            var momentDateObj = moment(currentdate).format('MM-DD-YYYY');
            var enddate=new Date(this.state.end_date);
            if(currentdate<enddate){
                var stdate=moment(this.state.start_date).format('MM-DD-YYYY')>moment(currentdate).format('MM-DD-YYYY')?moment(currentdate).format('MM-DD-YYYY'):moment(this.state.start_date).format('MM-DD-YYYY');

                this.setState({start_date:stdate, end_date:momentDateObj });
            }
            
        }
    };


    handleEditClick(row,event) {       
        this.props.history.push("/ApprovalLimits?id=" + row.original._id);
      }
      handleDeleteClick=(row,event)=>{
        if(window.confirm('Are you sure to delete this record?')){           
            if(row.original._id!=undefined){
                axios
                .post(configData.SERVER_URL+`fincorp_deleteApprovalLimits`,
                
                {'_id': row.original._id}
                , {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(
                    res => this.reloadDataGrid(res.data)
                    
                )
                .catch(err => {
                    console.log("AXIOS ERROR: ", err);
                });
            // event.preventDefault();
            }
            else{
                //error            
                toast.warn("ApprovalLimit Not Deleted");
            }
        }
      }
      reloadDataGrid=(res)=>{       
              
        toast.success("ApprovalLimit Delete Successfully");
        setTimeout( this.fetchApprovalLimitsListAPI(),4000);
      }

     editImgFormatter = (cell, row) => {              
        return (
            <a
                className="editbutton"
                aria-label="Edit"
                onClick={() => this.handleEditClick(row)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a>
        );
    }

     deleteImgFormatter = (cell, row) => {              
        return (
            <a
                className="deletebutton center"
                aria-label="Delete"
                onClick={() => this.handleDeleteClick(row)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a>
        );
    }

     handleSubmit = () => {
        this.change(this.state);
        this.setState(initialValues);
    };
     

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    roleFormatter = (cell, row) => {  
        console.log(row);    
        if(row.role_name[0]!=undefined) {    
        return (
            <span
            >
              { row.role_name[0].label}
            </span>
        );
        }   
         
    }

    handleChange1 = event => {
        
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };
      toggleChange = () => {
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }

      globalActiveSearch = (param) => {
        let { searchInput } = this.state;       
        let filteredData = this.state.originalApprovalLimits.filter(value => {
            if(param){

                return (
                    (value.org_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.role_name.label.toLowerCase().includes(searchInput.toLowerCase()) ||             
                    value.activity_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.activitylevel.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.predecessor_item.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.al_from.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.al_to.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.predecessor_reporting_to.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
                    value.status.toString().includes('Active') 
                   
                );

            }else{
                return (
                    value.org_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.role_name.label.toLowerCase().includes(searchInput.toLowerCase()) ||             
                    value.activity_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.activitylevel.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.predecessor_item.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.al_from.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.al_to.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.predecessor_reporting_to.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        });
        this.setState({ approvallimits: filteredData });
      };
      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalApprovalLimits.filter(value => {
        if(this.state.chkisactive){    
          return (

            (value.org_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.role_name.label.toLowerCase().includes(searchInput.toLowerCase()) ||             
            value.activity_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.activitylevel.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.predecessor_item.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.al_from.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.al_to.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.predecessor_reporting_to.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
            ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
            value.status.toString().includes('Active')
          );
        }else{
            return (

                value.org_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.role_name.label.toLowerCase().includes(searchInput.toLowerCase()) ||             
                value.activity_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.activitylevel.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.predecessor_item.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.al_from.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.al_to.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.predecessor_reporting_to.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.startdate.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.enddate!=undefined||value.enddate!=null? value.enddate.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
              );   
        }
        });
        this.setState({ approvallimits: filteredData });
      };

    

    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        const {searchInput} =this.state;
    return (
        <>
            <div className="container approvallimitslist-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimitsList_Title' && lbl.pageName=='ApprovalLimitsList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="centeralign">
                    <span className="mainheadings">
                    {Labels.filter(lbl => lbl.keyPair=='ApprovalLimitsList_Title' && lbl.pageName=='ApprovalLimitsList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                    ))}
                    </span>
                </div>               

                 <div className="fullwidth">
                        <div className="float-left">
                        <Link to="/approvallimits" className= {this.state.userrole.create=='Y'?"btn btn-primary float-left":"hidden"}>
                        {Labels.filter(lbl => lbl.keyPair=='ApprovalLimitsList_Addbtn' && lbl.pageName=='ApprovalLimitsList').map(filteredPerson => (
                        <span>
                        {filteredPerson.keyValue}
                        </span>
                        ))}
                        </Link>                            
                        </div>                   
                        <div className="float-right">
                        <div className="isactivecontainer">
                            <span>Active</span>
                            <input type="checkbox" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            aria-label='Status'
                            />                            
                        </div>
                        <div className="float-left txtsearchcontainer">    
                        <span className="searchlabel">
                        {Labels.filter(lbl => lbl.keyPair=='ApprovalLimitsList_Search' && lbl.pageName=='ApprovalLimitsList').map(filteredPerson => (
                            <span>
                            {filteredPerson.keyValue}
                            </span>
                        ))}
                        </span>                       
                        <Form.Control
                            aria-label="search"
                            type="text"
                            name="search"
                            placeholder=""
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        />
                         </div>
                        </div>                    
                    </div>      
                <div className="fullwidth row m0">            
                    <div  className="row col-md-12 mt-10 ml-0 p0">
                    <ReactTable
                        data={this.state.approvallimits}
                        columns={this.state.columns}
                        defaultPageSize={20}
                        className="-striped -highlight fullwidth"
                    />
                    </div> 
                </div>
            </div>
             
        </>
    );
}
}

