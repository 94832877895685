import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { Button, Modal, Table, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { Link } from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import * as moment from "moment";
import "moment/min/moment-with-locales";
import Draggable from "react-draggable";
import ReactDOM from "react-dom";
import { Input } from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./filetype.css";

import _ from "lodash";

import { DatePicker, DatePickerInput } from "rc-datepicker"; 
import "rc-datepicker/lib/style.css";
import { blue } from '@material-ui/core/colors';
const queryString = require('query-string');

//const configData= require('config');
const configData = require( '../../config.json');
const initialValues = {
    weight: '',
    height: '',
    date: '',   
    roles: [{
        filetype: 'Reports Master',
        status: 'Active'
    }]
} 

 

function afterSearch(searchText, result) {
    console.log('Your search text is ' + searchText);
    console.log('Result is:');
    for (let i = 0; i < result.length; i++) {
      console.log('Fruit: ' + result[i].id + ', ' + result[i].name + ', ' + result[i].price);
    }
  }
  
  const options = {
    afterSearch: afterSearch  // define a after search hook
  };

export default class FileType extends React.Component  {
    constructor(props) {
        //super(...args)
        super(props);
        var querystring = queryString.parse(this.props.location.search);

        console.log(queryString.parse(this.props.location.search));
        
        this.state = {
            show: false,
            items:  [],
            originalItems:[],
            filetype:{},
            data: [],
            filteredData: [],
            columns: [],            
            searchInput: "",
            chkisactive:true,          
            userrole:{create:''},
            rows:[]
        }
        this.setState({Labels:[], isFetching: true});
        this.fetchLabelMessagesAPI();

       
    }
    getColumns = () => {
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'FileType';
        });
        let columns = [
         
          {
            Header: "File Type Name",
            accessor: "file_type_name",
            sortable: true,
            show: true,            
            displayValue: "File Type Name",
            Cell:row=>(<div><a href="#">{row.original.file_type_name}</a></div>)       
          },
        //   {
        //     Header: "Column Counts",
        //     accessor: "item_displayname",
        //     sortable: true,
        //     show: true,
        //     displayValue: " First Name",
        //     // Cell:row=>(<div><a href="#">{row.original.item_displayname}</a></div>)
        //   },  
        //   {
        //     Header: "Column Counts",
        //     accessor: "item_displayname",
        //     sortable: true,
        //     show: true,
        //     displayValue: " First Name",
        //     // Cell:row=>(<div><a href="#">{row.original.item_displayname}</a></div>)
        //   },
          {
            Header: "Start Date",
            accessor: "start_date",
            sortable: true,
            show: true,
            displayValue: "Start Date",
            maxWidth: 120,
            minWidth: 100,
            width: 100,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: "End Date",
            accessor: "end_date",
            sortable: true,
            show: true,
            displayValue: "End Date",
            maxWidth: 120,
            minWidth: 100,
            width: 100,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: "Status",
            accessor: "status",
            sortable: true,
            show: true,
            displayValue: "Status",
            maxWidth: 120,
            minWidth: 100,
            width: 100,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header:"Edit",
            maxWidth: 100,
            minWidth: 40,
            width: 40,
            // show:userrl.edit=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="editcontainer"> <a
                className="editbutton"
                aria-label="Edit"
                onClick={(event) => this.handleEditClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Edit"
                        className=""
                        src="/images/edit_icon.png"
                    />
                </i>
            </a></div></div>)
          },
          {
            Header:"Delete",
            maxWidth: 100,
            minWidth: 58,
            width: 58,
            // show:userrl.delete=='Y'?true:false,
            Cell:row => (<div className="grdbuttoncontainer"><div className="deletecontainer"> <a
                className="deletebutton"
                aria-label="Delete"
                onClick={(event) => this.handleDeleteClick(row,event)}
            >
                <i className="">
                    <img
                        alt="Delete"
                        className=""
                        src="/images/delete_icon.png"
                    />
                </i>
            </a></div></div>)
          }
          
        ];
        this.setState({ columns });
      };
    
      
    
      handleSetData = data => {
        console.log(data);
        this.setState({ data });
      };
    
     componentDidMount() {
       
      this.fetchFileTypeAPI();

      this.getColumns();

    }
    fetchFileTypeAPI = () => {
        console.log('config data'+configData.SERVER_URL);

        this.setState({...this.state, isFetching: true});
        fetch(configData.SERVER_URL+'fincorp_getFileType')
            .then(response => response.json())
            .then(result => {
                this.bindFileType(result)
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };

    bindFileType=(result)=>{       
        var rolelist= JSON.parse(localStorage.getItem("rolelocation"));
        console.log(rolelist);
        var primaryroleval=  localStorage.getItem("primaryrolesession");
        var selectedrolelocation=undefined;
        _.forEach(rolelist, function(value) {
             
            if(primaryroleval==value.name){
                selectedrolelocation=value;
            }
            
        });
       
        var userrl =_.find(selectedrolelocation.location, function(obj) {
            return obj.location === 'FileType';
        }); 
        
        if(result!='No document(s) found with defined find criteria!' && result.length>0){
            this.setState({items: result,originalItems:result,userrole: userrl, isFetching: false})
            this.globalSearch();
        }
        else
            this.setState({...this.state,userrole: userrl,items: [], isFetching: false})
           

    }

    //const [state, setState] = useState(initialValues);

    handleChange = e => {
        let { value, name } = e.target;
       
        const date = new Date().toLocaleString().split(',')[0];
        this.setState({
            ...this.state,
            [name]: value,
            date

        });
       
        //included filter option 
        if(name=='search')
        {
          // this.refs.table.handleFilterData({ filetype_name: value });
            //this.refs.table.handleFilterData({  value });
        }
        else if(name=='filetypestatus' && value=='Inactive'){
            const currentdate = new Date();
            currentdate.setDate(currentdate.getDate()-1);
            var momentDateObj = moment(currentdate).format('MM-DD-YYYY');
            var enddate=new Date(this.state.end_date);
            if(currentdate<enddate){
                var stdate=moment(this.state.start_date).format('MM-DD-YYYY')>moment(currentdate).format('MM-DD-YYYY')?moment(currentdate).format('MM-DD-YYYY'):moment(this.state.start_date).format('MM-DD-YYYY');

                this.setState({start_date:stdate, end_date:momentDateObj });
            }
            
        }
    };

     handleSelect = ({ start, end }) => {
        this.setState({ show: false, editshow: true, filetype: ''});
        //const title = window.prompt('New Event name')
        //if (title)
        //    this.setState({
        //        events: [
        //            ...this.state.events,
        //            {
        //                start,
        //                end,
        //                title,
        //            },
        //        ],
        //    })
    }

     handleSubmit = () => {
        this.change(this.state);
        this.setState(initialValues);
    };

     handleEditClick = (row,e) => {       
         
         this.state.filetype=row.original.file_type_name;
         this.state.filepath=row.original.filepath;     
         this.state.filetypestatus=row.original.status;
        //  this.state.lookupdisplayname=row.original.item_displayname;
         this.state.start_date=row.original.start_date;
         this.state.end_date=row.original.end_date;
         this.state._id=row.original._id;
         this.state.rows=row.original.data;
        this.setState({ show: true, editshow:false  });
       e.preventDefault();
       e.stopPropagation();
        //this.props.history.push("/Item?id=" + row.id);
    };
    handleSaveClick = (event) => {
       
         var itmlist=this.state.items;
                
            //event.preventDefault();
            if(this.state.filetype == undefined || this.state.filetype.length==0){
                toast.warn("Enter Item");
                event.preventDefault();
            
            }
            // else if (this.state.lookupdisplayname == undefined || this.state.lookupdisplayname == "") {
            //     toast.warn("Enter DisplayName");
            //     event.preventDefault();
    
    
            // }
            else if (this.state.start_date == undefined || this.state.start_date == "" || this.state.start_date == "Invalid date") {
                toast.warn("Enter StartDate");
                event.preventDefault();   
    
            } 
            else if (Date.parse(this.state.start_date) > Date.parse(this.state.end_date)) {
                toast.warn("End Date should be greater than the StartDate");
                event.preventDefault();
    
    
            } 
            else if(this.state.filetypestatus == undefined || this.state.filetypestatus =="" || this.state.filetypestatus =='SELECT STATUS'){
                toast.warn("Select Status");
                event.preventDefault();            
            }
                  
            else{
                 
                //update
                if(this.state._id!=undefined){
                    if(window.confirm('Do you want to save changes?')){ 
                    this.state.start_date=  this.state.start_date == "Invalid date"?null:this.state.start_date;
                    this.state.end_date=  this.state.end_date == "Invalid date"?null:this.state.end_date;
                    axios
                    .post(configData.SERVER_URL+`fincorp_updateFileTypes`,
                    
                    {'_id':this.state._id,'file_type_name':this.state.filetype,'status':this.state.filetypestatus,
                    'start_date':this.state.start_date,'end_date':this.state.end_date,'column_count':this.state.rows.length,'filepath':this.state.filepath,'data':this.state.rows}
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                    .then(
                        res =>  this.UpdateChanges(res.data)
                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });
                }
                    }
                else{
                    //save
                    
                    var columnlenth=this.state.rows;
                    axios
                    .post(configData.SERVER_URL+`fincorp_insertFileTypes`,
                    
                    {'file_type_name':this.state.filetype,'status':this.state.filetypestatus,
                    'start_date':this.state.start_date,'end_date':this.state.end_date,'column_count':this.state.rows.length,'filepath':this.state.filepath,'data':this.state.rows }
                    , {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*"
                        }
                    })
                    .then(
                        res =>  this.SaveChanges(res.data),
                       
                        //window.location.reload(false),
                        // itmlist.push({'_id':res.data.Result.insertedId,'filetype_name':this.state.filetype,'status':this.state.filetypestatus}),
                        // toast.success("MasterLookup Item Inserted Successfully"+res.data.Result.insertedId),
                        // this.setState({  show: false, editshow:false }),
                    )
                    .catch(err => {
                        console.log("AXIOS ERROR: ", err);
                    });
                }
            
        
        }
    };
    
    UpdateChanges=(res)=>{
        this.setState({   show: false, editshow:false });
        toast.success("FileTypes Updated Successfully");
        setTimeout( this.fetchFileTypeAPI(),4000);
    }
    SaveChanges=(res)=>{
        
        if(res.Message=="File Type Already Exists"){
            toast.warn(res.Message);
        }
        else{
        // var itmlist=this.state.items;
        // if(itmlist==null){
        //     itmlist=[];
        this.setState({   show: false, editshow:false });
        toast.success("File Type  Added Successfully");
        setTimeout( this.fetchFileTypeAPI(),4000);
        }
        // itmlist.push({'_id':res.Result.insertedId,'filetype_name':this.state.filetype,'status':this.state.filetypestatus,
        // 'item_displayname':this.state.lookupdisplayname,'start_date':this.state.start_date,'end_date':this.state.end_date});
        // toast.success("MasterLookup Item Inserted Successfully");
        // this.setState({ items:itmlist, show: false, editshow:false });
    
    }

    onStartDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
            
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                start_date: moment(dateString).format("MM-DD-YYYY")
                
            
        }));
    };
    onEndDateChange = (jsDate, dateString) => {
        this.setState(prevState => ({
           
                // object that we want to update
                ...prevState, // keep all other key-value pairs
                
                end_date: moment(dateString).format("MM-DD-YYYY")
                
           
        }));
    };
    // //add new lookup item
    // handleAddLookup=()=>{
    //     this.setState({_id:undefined, filetype:'',filetypestatus:'',start_date:'',end_date:'',lookupdisplayname:'',
    //     show: true, editshow:false });
    // }
     handleClose=()=> {

        this.setState({ show: false });
    }
    //add LooupValue
    // handleItemEditClick = (value) => {

    // this.props.history.push("/masterlookupvalues?key="+value._id+'&name='+value.filetype_name);
    
    // }

    //Delete MasterLoopupItems
    handleDeleteClick=(row,event)=>{

        if(window.confirm('Are you sure to delete this record?')){   
              event.preventDefault();
            event.stopPropagation();
        if(row.original._id!=undefined){
            axios
            .post(configData.SERVER_URL+`fincorp_deleteFileTypes`,
            
            {'_id':row.original._id}
            , {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(     
                res => this.reloadDataGrid(res.data,event),
            )
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });

        }
        else{
            //error            
            toast.warn("File Type Not Deleted");
        }
        }
        else{
            event.preventDefault();
            event.stopPropagation();
        }
    }
        reloadDataGrid=(res,e)=>{            
                  
            toast.success("File Type Delete Successfully");
            setTimeout( this.fetchFileTypeAPI(),4000);
            e.preventDefault();
            e.stopPropagation();
          }
    

     handleEdit = () => {
        this.setState({ show: false, editshow:true });
    }
     handleEditClose = () => {
        this.setState({ show: false, editshow: false });
    }   
    

    fetchLabelMessagesAPI = () => {
        this.setState({...this.state, isFetching: true});
        
        axios
        .get(configData.SERVER_URL+`fincorp_getCustomMessage` 
        , {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(
            res=>  {this.setBindMessage(res.data) }
        )
        .catch(err => {
            console.log("AXIOS ERROR: ", err);
        });
    };

    setBindMessage=(result)=>{
        
        if(result!="No document(s) found with defined find criteria!" && result!=null &&result.length>0){
            
            this.setState({Labels: result, isFetching: false});
        }
        

    }
    // onRowClick = (state, rowInfo, column, instance) => {
    //     return {
    //         onClick: e => {
                
    //             this.props.history.push("/masterlookupvalues?key="+rowInfo.original._id+'&name='+rowInfo.original.filetype_name); 
                
    //         }
    //     }
    // }

    handleChange1 = event => {
        this.setState({ searchInput: event.target.value }, () => {
          this.globalSearch();
        });
      };

      toggleChange = () => {
        this.setState({
            chkisactive: !this.state.chkisactive,
        });
        this.globalActiveSearch(!this.state.chkisactive) ;
      }

      globalActiveSearch = (param) => {
        let { searchInput } = this.state;       
        let filteredData = this.state.originalItems.filter(value => {
            if(param){

             return (
            (value.file_type_name.toLowerCase().includes(searchInput.toLowerCase()) ||
           
            value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
            ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
            value.status.toString().includes('Active')  
            );

            }else{
                return (
                    value.file_type_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                   
                    value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                    ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                    value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
          );
        }
        });
        this.setState({ items: filteredData });
      };
      globalSearch = () => {
        let { searchInput } = this.state;
        let filteredData = this.state.originalItems.filter(value => {
        if(this.state.chkisactive){    
          return (
            (value.file_type_name.toLowerCase().includes(searchInput.toLowerCase()) ||
             value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
            ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):''))&&                                        
            value.status.toString().includes('Active')  
          );
        }else{
            return (
                value.filetype_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                // value.item_displayname.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.start_date.toLowerCase().includes(searchInput.toLowerCase()) ||
                ( value.end_date!=undefined||value.end_date!=null? value.end_date.toString().toLowerCase().includes(searchInput.toLowerCase()):'') ||                
                value.status.toString().toLowerCase().includes(searchInput.toLowerCase())
      );
    }       
           
        });
        this.setState({ items: filteredData });
      };

      handleFileColumnChange = idx => e => {
        const { name, value } = e.target;
        
        const rows = [...this.state.rows];
        var rowval=rows[idx];
        if(name=='column_name'){
            rowval.column_name=value;
        }
        else if(name=='column_type'){
            rowval.column_type=value;
        }
        rows[idx] =  rowval;
        this.setState({
          rows
        });
      };
      handleFileColumnAddRow = (event) => {
        const item = {
          column_name: "",
          column_type: ""
        };
        this.setState({
          rows: [...this.state.rows, item],
          show: true, 
          editshow:false
        });
        event.preventDefault();       
         
      };
    

      handleAddNewFileType=()=>{
        this.state.filetype='';
        this.state.filetypestatus=''; 
        this.state.start_date='';
        this.state.end_date='';
        this.state.filepath='';
        this.state.rows=[];
        this.state._id=undefined;
       this.setState({ show: true, editshow:false  });
      };

      handleColumnDeleteClick =idx=>{
        
        this.setState({ show: true, editshow:false, rows: this.state.rows.filter((s, sidx) => idx !== sidx) });
         
      }
    render() {
        const Labels=this.state.Labels!=null&& this.state.Labels!=undefined?this.state.Labels:[];
        let { data, columns, searchInput } = this.state;
        return (
        <>
            <div className="container filetype-container" role="main">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/Home">Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      
                    {/* {Labels.filter(lbl => lbl.keyPair=='FileType_Title' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))} */}
                                    FileType
                                     
                    </Breadcrumb.Item>

                </Breadcrumb>   
                    {/*  add/edit popup details of Lookup Item */}
                    
                    <div>
                    <Draggable>
                        <Modal
                            className="filetype-modal"
                             show={this.state.show}
                            onHide={this.handleClose}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title className="margin_auto">
                                    <h3>
                                        {Labels.filter(lbl => lbl.keyPair=='FileType_Title' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span> 
                                       {this.state._id==undefined?"Add":"Edit"} {filteredPerson.keyValue}
                                        </span>
                                    ))}    
                                    </h3> 
                                                                        
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Row>
                                    <Col md-6 sm-12>
                                        <Form.Group as={Col} controlId="formLookUpItem">
                                            <Form.Label className="labelvalue required" for='frmfiletype'> File Type Name
                                            {Labels.filter(lbl => lbl.keyPair=='FileType_LookupItem' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}   
                                            </Form.Label>
                                            <Form.Control
                                        id="frmfiletype"
                                        type="text"
                                        name="filetype"
                                        placeholder="File Type Name"
                                        disabled={this.state._id!=undefined?true:false}
                                        value={this.state.filetype}
                                        onChange={this.handleChange}
                                        required
                                    />                                
                                        </Form.Group>
                                        </Col>
                                        <Col md-6 sm-12>
                                        <Form.Group as={Col} controlId="formLookUpItemStatus">
                                            <Form.Label className="required" for='filetypestatus'> Status
                                            {Labels.filter(lbl => lbl.keyPair=='FileType_Status' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}     
                                            </Form.Label>
                                            <Form.Control
                                            as="select"
                                            id="filetypestatus"
                                            controlId="filetypestatus"
                                            name="filetypestatus"
                                            value={this.state.filetypestatus}
                                            onChange={this.handleChange}
                                            required
                                            >
                                            <option value="SELECT STATUS">Select Status</option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                            </Form.Control>
                                        </Form.Group>
                                        </Col>
                                    </Form.Row>  
                                   
                                    <Form.Row>
                                        <Col md-6 sm-12>
                                        <Form.Group as={Col} controlId="formLookUpValue">
                                        <Form.Label className="labelvalue required" for='startdate'> Start Date
                                        {Labels.filter(lbl => lbl.keyPair=='FileType_StartDate' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}      
                                        </Form.Label>
                                        <DatePickerInput
                                            id="startdate"
                                            onChange={this.onStartDateChange.bind(this, 'startdate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.start_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component"
                                            required
                                            readOnly
                                        />
                                        </Form.Group>
                                        </Col>
                                        <Col md-6 sm-12>
                                        <Form.Group as={Col} controlId="formLookUpValueStatus">                                            
                                            <Form.Label for='enddate'> End Date
                                            {Labels.filter(lbl => lbl.keyPair=='FileType_EndDate' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}      
                                            </Form.Label>
                                            <DatePickerInput
                                            id="enddate"
                                            onChange={this.onEndDateChange.bind(this, 'enddate') }
                                            placeholder="MM/DD/YYYY"
                                            value={this.state.end_date}
                                            minDate={moment().toDate()}
                                            className="my-custom-datepicker-component" 
                                            readOnly                                           
                                        />
                                        </Form.Group>
                                        </Col>
                                    </Form.Row>   
                                    <Form.Row>
                                    <Col md-6 sm-12>
                                        <Form.Group as={Col} controlId="formLookUpItem">
                                            <Form.Label className="labelvalue required" for='frmfilepath'> File Path
                                            {Labels.filter(lbl => lbl.keyPair=='FileType_LookupItem' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}   
                                            </Form.Label>
                                            <Form.Control
                                        id="frmfiletype"
                                        type="text"
                                        name="filepath"
                                        placeholder="File Path"                                       
                                        value={this.state.filepath}
                                        onChange={this.handleChange}
                                        required
                                    />                                
                                        </Form.Group>
                                        </Col>
                                    </Form.Row> 
                                     
                                    <Form.Row>
                                    <Col md-12>
                                    <div className="container">
                                        <div className="row clearfix">
                                            <div className="col-md-12 column">
                                            <table
                                                className="table table-bordered table-hover"
                                                id="tab_logic"
                                            >
                                                
                                                <tbody>
                                                {this.state.rows.map((item, idx) => (
                                                    <tr id="addr0" key={idx}>
                                                    <td>{idx}</td>
                                                    <td>
                                                        <input
                                                        type="text"
                                                        name="column_name"
                                                        value={this.state.rows[idx].column_name}
                                                        onChange={this.handleFileColumnChange(idx)}
                                                        className="form-control"
                                                        />
                                                    </td>
                                                    <td>
                                                        {/* <input
                                                        type="text"
                                                        name="mobile"
                                                        value={this.state.rows[idx].mobile}
                                                        onChange={this.handleFileColumnChange(idx)}
                                                        className="form-control"
                                                        /> */}
                                                        <Form.Control
                                                            as="select"
                                                            name="column_type"
                                                            value={this.state.rows[idx].column_type}
                                                            onChange={this.handleFileColumnChange(idx)}
                                                            required
                                                            >
                                                                <option value="">select type</option>
                                                            <option value="string">String</option>
                                                            <option value="integer">Integer</option>
                                                            <option value="boolean">Boolean</option>
                                                            <option value="date">Date</option>
                                                            </Form.Control>
                                                    </td>
                                                    <td>
                                                    <div className="deletecontainer"> <a
                                                        className="deletebutton"
                                                        aria-label="Delete"
                                                        onClick={(event) => this.handleColumnDeleteClick(idx)}
                                                    >
                                                        <i className="">
                                                            <img
                                                                alt="Delete"
                                                                className=""
                                                                src="/images/delete_icon.png"
                                                            />
                                                        </i>
                                                    </a></div>
                                                    </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                            <button
                                                onClick={this.handleFileColumnAddRow}
                                                className="btn btn-default pull-left"
                                            >
                                                Add Row
                                            </button>
                                            {/* <button
                                                onClick={this.handleFileColumnRemoveRow}
                                                className="pull-right btn btn-default"
                                            >
                                                Delete Row
                                            </button> */}
                                            </div>
                                        </div>
                                        </div>
                                        
                                        </Col>    
                                    </Form.Row>   
                                                            
                                    
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="primary" onClick={this.handleSaveClick}> Save
                                {Labels.filter(lbl => lbl.keyPair=='FileType_SaveBtn' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}   
                                 </Button> 
                                <Button variant="secondary" onClick={this.handleClose} > Close
                                {Labels.filter(lbl => lbl.keyPair=='FileType_CancelBtn' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}   
                                </Button>
                                
                            </Modal.Footer>
                        </Modal>
                        </Draggable>
                    </div>
                    


                <div className="centeralign">
                    <span className="mainheadings"> 
                    {/* {Labels.filter(lbl => lbl.keyPair=='FileType_Title' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))} */}
                                    File Types List
                    </span>
                    <ToastContainer />
                </div>
               
                    
        
         <div className="fullwidth">
                    <div className="float-left">
                    {/* className={this.state.userrole.create=='Y'?"btn btn-primary  float-left ":"hidden"} */}
                    <button className= "btn btn-primary  float-left "   onClick={this.handleAddNewFileType} >  Add New File Type                                     
                    {/* {Labels.filter(lbl => lbl.keyPair=='FileType_AddLookupBtn' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))} */}
                     </button> 
                    </div>
                    <div className="float-right">
                    <div className="isactivecontainer" >
                            <span>Active</span>
                            <input type="checkbox" className="chkisactive"
                            defaultChecked={this.state.chkisactive}
                            onChange={this.toggleChange}
                            aria-label='Status'
                            />                            
                    </div>
                    <div className="float-left txtsearchcontainer">
                    <span className="searchlabel">{Labels.filter(lbl => lbl.keyPair=='FileType_Search' && lbl.pageName=='FileType').map(filteredPerson => (
                                        <span>
                                        {filteredPerson.keyValue}
                                        </span>
                                    ))}    </span>
                              <Form.Control
                            aria-label="search"
                            type="text"                            
                            name="searchInput"
                            placeholder=""
                            value={searchInput || ""}
                            onChange={this.handleChange1}
                        /> 
                        </div>  
                    </div>
                </div>  
         
        <div className="fullwidth row m0">
               <div className="col-md-12 mt-10 p0">
                     <ReactTable
                    data={this.state.items}
                    columns={this.state.columns}
                    defaultPageSize={20}
                    getTrProps={this.onRowClick}
                    className="-striped -highlight fullwidth"
                    />  
                </div>
               </div>
                           
                <div>
                </div>
                <br />
              {/* <Draggable>
                    <div style={{height:100, width:100, backgroundColor:'yellow', color:'red'}}> Hello </div>
              </Draggable>            */}
               
            </div>
        </>
    );
    }
} 